import React, {ReactNode} from "react";
import {Box, Button, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		alignItems: "center",
	},
	border: {
		borderBottom: "2px solid lightgray",
		width: "100%",
	},
	content: {
		fontWeight: theme.typography.fontWeightMedium,
		textTransform: "uppercase",
		display: "inline-block",
		padding: theme.spacing(0, 10),
		color: theme.palette.primary.main,
		width: "max-content",
	},
	width: {
		"& .MuiButton-label": {
			width: "max-content",
		},
	},
}));

type Props = {
	children: ReactNode | ReactNode[];
	buttonHidden?: boolean;
	onClick: () => void;
};

const DividerWithButton = (props: Props): JSX.Element => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Box className={classes.border} ml={-3} />
			{!props.buttonHidden && (
				<Button onClick={props.onClick} className={classes.width}>
					<Box className={classes.content}>{props.children}</Box>
				</Button>
			)}
			<Box className={classes.border} mr={-6} />
		</Box>
	);
};
export default DividerWithButton;
