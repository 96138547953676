import {Localized} from "@fluent/react";
import {Box, Button, useTheme} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, {
	ReactNode,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";

import ExerciseParameters from "./ExerciseParameters";
import ExerciseQuestion from "./ExerciseQuestion";
import ExerciseTitle from "./ExerciseTitle";
import ExternalResponseArea from "./external/ResponseArea";
import MathSolutionForm from "./math/MathSolutionForm";
import MultiResponseAreaSelector from "./multi/MultiResponseAreaSelector";
import OpenResponseArea from "./open/ResponseArea";
import AskTutorButton from "../../sidebars/askTutor/AskTutorButton";
import AnswerButton from "../../sidebars/exerciseAnswers/AnswerButton";
import selectAnswerShowingAllowed from "../../../store/chapterExercises/selectAnswerShowingAllowed";
import ExerciseType from "../../../store/exercises/ExerciseType";
import {useAppSelector} from "../../../store/hooks";
import {CompletionStatus} from "../../../store/outcomes/ExerciseOutcomes";
import ProgResponseArea from "./prog/ResponseArea";
import type {OpenConfirmationDialog} from "../../../hooks/useConfirmationDialog";
import {
	MathResponse,
	MultiResponse,
	OpenResponse,
	ProgResponse,
} from "../../../store/studentResponses/Response";
import {
	ResponseToSave,
	ResponseToSubmit,
} from "../../../store/studentResponses/Response";
import Feedback from "../../../store/studentResponses/Feedback";
import SubmissionResult from "../../../store/studentResponses/SubmissionResult";
import ExercisePlaceholder from "../ExercisePlaceholder";
import type FileUploader from "../../../utils/attachments/FileUploader";
import AssessmentDeliveryContext from "../../AssessmentDeliveryContext";
import AssessorComment from "./AssessorComment";
import ExerciseExpandablePanel from "./ExerciseExpandablePanel";
import ExerciseStatusHint from "./ExerciseStatusHint";

type Props = {
	sectionNumber: number;
	exerciseKey: string;
	userId: number;
	courseId: number;
	emptyAnswerAllowed: boolean;
	difficultyVisible: boolean;
	selfAssessment: boolean;
	readonly?: boolean;
	submissionDisabled?: boolean;
	tutoringEnabled: boolean;

	onSave: (
		exerciseId: number,
		response: ResponseToSave,
		successMessage?: string
	) => Promise<Feedback>;
	onSubmit?: (
		exerciseId: number,
		response: ResponseToSubmit
	) => Promise<SubmissionResult>;
	redoExercise?: (chapterId: number, exerciseId: number) => Promise<void>;

	onPostOutcomes: (
		chapterId: number,
		exerciseId: number,
		outcomes: {
			completionStatus: CompletionStatus;
			score?: number;
		}
	) => Promise<void>;

	openConfirmDialog: OpenConfirmationDialog;

	createFileUploader: (
		chapterId: number,
		exerciseId: number
	) => FileUploader | null;
};

const ExerciseContent = (props: Props): JSX.Element => {
	const {
		courseId,
		userId,
		exerciseKey,
		onSubmit,
		redoExercise,
		onPostOutcomes,
		createFileUploader: fileUploaderFactory,
		tutoringEnabled,
	} = props;

	const theme = useTheme();

	const [submitInProgress, setSubmitInProgress] = useState(false);

	const {
		makeSelectChapterExerciseWithContent,
		selectResponse,
		selectOutcomes,
	} = useContext(AssessmentDeliveryContext);

	const selectExercise = useMemo(makeSelectChapterExerciseWithContent, [
		makeSelectChapterExerciseWithContent,
	]);

	const exercise = useAppSelector((state) =>
		selectExercise(state, exerciseKey)
	);

	const response = useAppSelector((state) =>
		selectResponse(state, exerciseKey)
	);

	const outcomes = useAppSelector((state) =>
		selectOutcomes(state, exerciseKey)
	);
	const completionStatus = outcomes?.completionStatus;

	const type = exercise?.type;

	const answerVisible = useAppSelector(
		(state) =>
			type &&
			type !== ExerciseType.Theory &&
			selectAnswerShowingAllowed(state, exerciseKey, completionStatus)
	);

	const additionalActions = useMemo(() => {
		const actions: ReactNode[] = [];

		if (
			exercise &&
			completionStatus === CompletionStatus.Complete &&
			redoExercise
		) {
			actions.push(
				<Button
					key="redo-exercise"
					color="primary"
					onClick={() => redoExercise(exercise.chapterId, exercise.id)}
				>
					<Localized id="content-exercise-redo">Redo</Localized>
				</Button>
			);
		}

		if (answerVisible) {
			actions.push(
				<AnswerButton key="answer" chapterExerciseKey={exerciseKey} />
			);
		}

		if (tutoringEnabled && type && type !== ExerciseType.Theory) {
			actions.push(
				<AskTutorButton key="ask-tutor" chapterExerciseKey={exerciseKey} />
			);
		}

		return actions;
	}, [
		exercise,
		completionStatus,
		redoExercise,
		answerVisible,
		tutoringEnabled,
		type,
		exerciseKey,
	]);

	const createFileUploader = useCallback(
		() => fileUploaderFactory(exercise?.chapterId ?? 0, exercise?.id ?? 0),
		[exercise?.chapterId, exercise?.id, fileUploaderFactory]
	);

	if (!exercise) {
		return <ExercisePlaceholder />;
	}

	const submitResponse =
		onSubmit &&
		(async (response: ResponseToSubmit) => {
			setSubmitInProgress(true);

			try {
				return await onSubmit(exercise.id, response);
			} finally {
				setSubmitInProgress(false);
			}
		});

	const readonly =
		props.readonly || completionStatus === CompletionStatus.Complete;
	const submissionDisabled =
		props.submissionDisabled || completionStatus === CompletionStatus.Complete;

	const responseAreaProps = {
		readonly,
		submissionDisabled,
		submitting: submitInProgress,
		additionalActions: additionalActions,
		onSave: (response: ResponseToSave, successMessage?: string) =>
			props.onSave(exercise.id, response, successMessage),
		onSubmit: submitResponse,
	};

	let responseArea = null;

	switch (exercise.type) {
		case ExerciseType.Multi:
			responseArea = (
				<MultiResponseAreaSelector
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					response={response as MultiResponse}
					id={`response-area-${exercise.id}`}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Open:
			responseArea = (
				<OpenResponseArea
					subtype={exercise.subtype}
					emptyResponseAllowed={props.emptyAnswerAllowed}
					selfAssessment={props.selfAssessment}
					response={response as OpenResponse}
					userId={userId}
					courseId={courseId}
					chapterId={exercise.chapterId}
					exerciseId={exercise.id}
					createFileUploader={createFileUploader}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Math:
			responseArea = (
				<MathSolutionForm
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					response={response as MathResponse}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Prog:
			responseArea = (
				<ProgResponseArea
					interactions={exercise.interactions}
					response={response as ProgResponse}
					openConfirmDialog={props.openConfirmDialog}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.External:
			responseArea = (
				<ExternalResponseArea
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					onPostOutcomes={async (outcomes, maxScore) => {
						let score = outcomes.score;
						if (outcomes.score !== undefined) {
							score = scaleScore(outcomes.score, maxScore, exercise.maxScore);
						}
						await onPostOutcomes(exercise.chapterId, exercise.id, {
							...outcomes,
							score,
						});
					}}
					{...responseAreaProps}
				/>
			);
	}

	return (
		<>
			<ExerciseTitle
				sectionNumber={props.sectionNumber}
				exerciseNumber={exercise.number}
				title={exercise.title}
				exerciseId={exercise.id}
				metaIcon={
					outcomes.completionStatus === CompletionStatus.Complete ? (
						<ExerciseStatusHint
							outcomes={outcomes}
							maxScore={exercise.maxScore}
						>
							<CheckCircleIcon style={{color: theme.palette.success.main}} />
						</ExerciseStatusHint>
					) : undefined
				}
			/>

			<Box display="flex" flexDirection="row" justifyContent="space-between">
				<ExerciseParameters
					maxScore={exercise.maxScore}
					category={exercise.category}
					difficultyLevel={
						props.difficultyVisible ? exercise.difficultyLevel : 0
					}
				/>

				{tutoringEnabled && type === ExerciseType.Theory && (
					<AskTutorButton size="small" chapterExerciseKey={exerciseKey} />
				)}
			</Box>

			<ExerciseQuestion text={exercise.question} />

			<Box mt={4} mb={outcomes.assessorComment ? 3 : 0}>
				{responseArea}
			</Box>

			{outcomes.assessorComment && (
				<ExerciseExpandablePanel
					lazyLoading
					summary={
						<Localized id="content-exercise-show-assessor-comment">{`Show assessor's comment`}</Localized>
					}
				>
					<AssessorComment
						comment={outcomes.assessorComment}
						chapterId={exercise.chapterId}
						courseId={courseId}
						exerciseId={exercise.id}
						studentId={userId}
					/>
				</ExerciseExpandablePanel>
			)}
		</>
	);
};

export default ExerciseContent;

function scaleScore(score: number, maxScore: number, exerciseMax: number) {
	return Math.floor(score * (exerciseMax / maxScore));
}
