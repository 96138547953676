import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import React from "react";

import type TableData from "../../../../store/exercises/prog/TableData";

const DbData = (props: {data: TableData}): JSX.Element => {
	const {data} = props;

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						{data.columns.map((cell) => (
							<TableCell key={cell}>{cell}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.data.map((row, index) => (
						<TableRow hover key={index}>
							{row.map((cell, i) => (
								<TableCell key={i}>{cell}</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default DbData;
