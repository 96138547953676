import {Localized} from "@fluent/react";
import {
	Box,
	Button,
	Container,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import {Skeleton} from "@material-ui/lab";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {textFile} from "../../helpers/fileHelpers";

import ProgFile from "../../store/studentResponses/ProgFile";
import CodeEditor from "../../utils/CodeEditor";
import {CodeEditorApi} from "../../utils/CodeEditor/CodeEditor";
import SubmitButton from "../../utils/SubmitButton";

const ProgFileEditorDialog = (props: {
	onClose: () => void;
	fileManager: {
		filename: string;
		load: () => Promise<ProgFile>;
		update: (content: string) => Promise<boolean>;
	};
}): JSX.Element => {
	const {onClose, fileManager} = props;

	const [files, setFile] = useState<ProgFile[]>(() => [
		{
			name: fileManager.filename,
			content: "",
		},
	]);

	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);

	const [editable, setEditable] = useState(true);

	const [dirty, setDirty] = useState(false);

	const [loadingFailed, setLoadingFailed] = useState(false);

	const editor = useRef<CodeEditorApi>(null);

	useEffect(() => {
		if (!textFile(fileManager.filename)) {
			setEditable(false);
			return;
		}

		const load = async () => {
			setLoading(true);
			try {
				const file = await fileManager.load();
				setFile([file]);
			} catch {
				setLoadingFailed(true);
			} finally {
				setLoading(false);
			}
		};

		load();
	}, [fileManager]);

	const markDirty = useCallback(() => {
		setDirty(true);
	}, []);

	const save = async () => {
		const content = editor.current?.getFiles()[0].content ?? "";
		setSaving(true);

		const saved = (await fileManager.update(content)) ?? false;

		setSaving(false);
		setDirty(!saved);
	};

	let content = <></>;

	switch (true) {
		case loadingFailed:
			content = (
				<EmptyState
					icon={
						<ErrorIcon
							style={{height: "50px", width: "50px"}}
							color="primary"
						/>
					}
				>
					<Localized id="exercise-prog-editor-file-loading-error">
						Failed to load file content
					</Localized>
				</EmptyState>
			);

			break;
		case editable:
			content = loading ? (
				<Skeleton height={500} variant="rect" />
			) : (
				<Box width="100%">
					<CodeEditor initialFiles={files} onChange={markDirty} ref={editor} />
				</Box>
			);

			break;
		case !editable:
			content = (
				<EmptyState
					icon={
						<NotInterestedIcon
							style={{height: "50px", width: "50px"}}
							color="primary"
						/>
					}
				>
					<Localized id="exercise-prog-editor-binary-file-warning">
						Binary files cannot be edited
					</Localized>
				</EmptyState>
			);

			break;
	}

	return (
		<>
			<DialogTitle disableTypography>
				<Typography variant="h6">{files[0].name}</Typography>
			</DialogTitle>
			<Box position="absolute" top={0} right={0} m={1}>
				<IconButton size="small" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Box>
			<DialogContent style={{minHeight: 520, display: "flex"}}>
				{content}
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={onClose}>
					{editable && !loadingFailed ? (
						<Localized id="learning-material-content-editor-footer-cancel">
							Cancel
						</Localized>
					) : (
						"OK"
					)}
				</Button>
				{editable && !loadingFailed && (
					<SubmitButton
						disabled={loading || saving || !dirty}
						onClick={save}
						inProgress={saving}
						variant="text"
					>
						<Localized id="learning-material-content-editor-footer-save">
							Save
						</Localized>
					</SubmitButton>
				)}
			</DialogActions>
		</>
	);
};

function EmptyState(props: {icon: React.ReactNode; children: React.ReactNode}) {
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{props.icon}
			<Typography variant="h6">{props.children}</Typography>
		</Container>
	);
}

export default ProgFileEditorDialog;
