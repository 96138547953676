import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import {
	Box,
	CircularProgress,
	Paper,
	Typography,
	useTheme,
} from "@mui/material";
import React from "react";

import type FetchStatus from "../store/FetchStatus";

function StatusInfo(props: {
	status: FetchStatus;
	labelOnPending: React.ReactNode;
	labelOnSuccess: React.ReactNode;
	labelOnFailure: React.ReactNode;
}) {
	const theme = useTheme();

	let content = <></>;

	switch (props.status) {
		case "pending":
			content = (
				<>
					<CircularProgress
						color="primary"
						size="1.5rem"
						style={{display: "flex"}}
					/>
					<Typography align="justify">{props.labelOnPending}</Typography>
				</>
			);

			break;
		case "succeeded":
			content = (
				<>
					<DoneIcon style={{color: theme.palette.success.main}} />
					<Typography align="justify">{props.labelOnSuccess}</Typography>
				</>
			);

			break;
		case "failed":
			content = (
				<>
					<WarningIcon style={{color: theme.palette.warning.main}} />
					<Typography align="justify">{props.labelOnFailure}</Typography>
				</>
			);

			break;
	}

	return (
		<Paper style={{padding: theme.spacing(2), marginBottom: theme.spacing(2)}}>
			<Box display="flex" alignItems="center" style={{gap: theme.spacing(2)}}>
				{content}
			</Box>
		</Paper>
	);
}

export default StatusInfo;
