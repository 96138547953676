import {Localized, useLocalization} from "@fluent/react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {
	Box,
	Chip,
	CircularProgress,
	TextField,
	Typography,
	withStyles,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Page} from "../../helpers/paginatedSearchHelpers";
import TagSearchResult from "../../store/exercises/TagSearchResult";
import {makeStyles} from "@material-ui/core/styles";
import LightTooltip from "../../utils/LightTooltip";

const useStyles = makeStyles((theme) => ({
	helpIcon: {
		marginBottom: theme.spacing(0.4),
		marginLeft: theme.spacing(1),
	},
	moreItemDiv: {
		width: "100%",
	},
	moreItemSpan: {
		color: theme.palette.primary.main,
	},
}));

const TagTooltip = withStyles(() => ({
	tooltip: {
		maxWidth: 650,
		whiteSpace: "pre-line",
	},
}))(LightTooltip);

const loadMoreOption = "";

const ExerciseTagsSelector = (props: {
	freeSolo?: boolean;
	value: string[];
	onChange: (value: string[]) => void;
	searchTags: (
		prefix: string,
		pageSize: number
	) => Promise<Page<TagSearchResult>>;
}): JSX.Element => {
	const classes = useStyles();

	const searchTags = props.searchTags;

	const [open, setOpen] = useState(false);
	const [lastRequestedPage, setLastRequestedPage] = useState<
		Page<TagSearchResult>
	>({content: [], request: {}});
	const [tags, setTags] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	const [inputInvalid, setInputInvalid] = useState(false);

	const [query, setQuery] = useState("");

	const fetchTags = useCallback(
		async (query: string) => {
			setLoading(true);

			try {
				const page = await searchTags(query, 10);

				setLastRequestedPage(page);

				setTags(page.content.map((tag) => tag.name));
			} finally {
				setLoading(false);
			}
		},
		[searchTags]
	);

	const gotFocus = useRef(false);

	useEffect(() => {
		if (!gotFocus.current || inputInvalid) {
			return () => {
				return;
			};
		}

		const timeoutId = setTimeout(() => fetchTags(query), 500);
		return () => clearTimeout(timeoutId);
	}, [fetchTags, inputInvalid, query]);

	const loadMoreTags = async () => {
		if (!lastRequestedPage.request.next) {
			return;
		}

		setLoading(true);

		try {
			const newPage = await lastRequestedPage.request.next();
			setLastRequestedPage(newPage);
			setTags((prev) => [...prev, ...newPage.content.map((c) => c.name)]);
		} finally {
			setLoading(false);
		}
	};

	const onChange = (_: unknown, value: string[]) => {
		const last = value.length - 1;

		if (value.length === 0 || validTag(value[last])) {
			if (value.length > 0) {
				value = [...value];
				value[last] = normaliseTag(value[last]);
			}

			props.onChange(value);

			setQuery("");
			setInputInvalid(false);
		}
	};

	const optionRenderer = (option: string) => {
		let item = <></>;

		if (option.length === 0) {
			item = (
				<>
					<div className={classes.moreItemDiv} onClick={loadMoreTags}>
						<span className={classes.moreItemSpan}>{"More"}</span>
					</div>
				</>
			);
		} else {
			item = <>{option}</>;
		}

		return item;
	};

	const {l10n} = useLocalization();
	return (
		<Box display="flex" alignItems="flex-end">
			<Autocomplete
				multiple
				id="exercise-tags"
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				fullWidth
				disableCloseOnSelect
				options={
					lastRequestedPage?.request.next ? tags.concat(loadMoreOption) : tags
				}
				disableClearable
				filterSelectedOptions
				clearOnBlur={false}
				onChange={onChange}
				value={props.value}
				onInputChange={(_, value, reason) => {
					if (reason === "reset" && query && !validTag(query)) {
						setInputInvalid(true);
						return;
					} else if (reason === "input") {
						setInputInvalid(!validTagPrefix(value));
					}

					setQuery(value);
				}}
				inputValue={query}
				freeSolo={props.freeSolo}
				onFocus={() => {
					if (!gotFocus.current) {
						gotFocus.current = true;
						fetchTags("");
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label={l10n.getString(
							"learning-material-exercise-tags-label",
							null,
							"Tags"
						)}
						placeholder={l10n.getString(
							"learning-material-exercise-tags-placeholder",
							null,
							"namespace:predicate=value"
						)}
						error={inputInvalid}
						helperText={
							inputInvalid &&
							l10n.getString(
								"learning-material-exercise-tags-invalid-tag",
								null,
								"The tag is invalid"
							)
						}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading && <CircularProgress color="inherit" size={20} />}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
				renderOption={optionRenderer}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							variant="outlined"
							key={option}
							label={option}
							size="small"
							{...getTagProps({index})}
						/>
					))
				}
			/>
			<TagTooltip
				title={
					<>
						<Typography variant="subtitle2">
							<Localized id="learning-material-exercise-tags-tooltip-title">
								Syntax
							</Localized>
						</Typography>
						<Typography variant="body2" color="textSecondary" paragraph>
							<Localized id="learning-material-exercise-tags-tooltip-subtitle">
								namespace:predicate=value
							</Localized>
						</Typography>
						<Typography variant="body1" align="justify" paragraph>
							<Localized id="learning-material-exercise-tags-tooltip-triple-tags">
								Triple tags consist of a namespace, a predicate and a value. The
								namespace defines a class to which a tag belongs. The predicate
								is a name of the property in the namespace. The value is some
								data.
							</Localized>
						</Typography>
						<Typography variant="body1" align="justify" paragraph>
							<Localized id="learning-material-exercise-tags-tooltip-namespace">
								Namespace and predicate can start only with a letter and contain
								Latin letters, digits and underscores. They are
								case-insensitive. Values can contain any symbols.
							</Localized>
						</Typography>
						<Typography variant="subtitle2">
							<Localized id="learning-material-exercise-tags-tooltip-title-examples">
								Examples
							</Localized>
						</Typography>
						<Typography variant="body2" color="textSecondary">
							<Localized id="learning-material-exercise-tags-tooltip-examples">
								school_textbooks:subject=Mathematics
								school_textbooks:subject=Physics
								school_textbooks:topic=Equations
								school_textbooks:topic=Differentiation
								school_textbooks:module=MAA2 school_textbooks:module=MAB2
							</Localized>
						</Typography>
					</>
				}
				placement="top-end"
			>
				<HelpIcon color="primary" className={classes.helpIcon} />
			</TagTooltip>
		</Box>
	);
};

function normaliseTag(tag: string): string {
	const i = tag.indexOf("=");
	return tag.substring(0, i).toLowerCase() + tag.substring(i);
}

function validTag(tag: string): boolean {
	return (
		tag.length >= 4 &&
		/^[a-zA-Z][a-zA-Z0-9_]*:[a-zA-Z][a-zA-Z0-9_]*=.*$/.test(tag)
	);
}

function validTagPrefix(prefix: string): boolean {
	return (
		prefix.length === 0 ||
		/^[a-zA-Z][a-zA-Z0-9_]*(:([a-zA-Z][a-zA-Z0-9_]*(=.*)?)?)?$/.test(prefix)
	);
}

export default ExerciseTagsSelector;
