import {Localized, useLocalization} from "@fluent/react";
import React, {useEffect, useState} from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import SubmitButton from "../../../utils/SubmitButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Course from "../../../store/services/dtos/Course";
import UserRole from "../../../store/models/UserRole";
import type DialogResult from "../../../utils/DialogResult";
import {validEmail, validUserName} from "../../../helpers/validationHelpers";
import {userService} from "../../../store/services/userService";
import {courseService} from "../../../store/services/courseService";

const useStyles = makeStyles((theme) =>
	createStyles({
		autocomplete: {
			marginTop: theme.spacing(2),
		},
	})
);

type Draft = {
	courseId?: number;
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	repeatPassword: string;
	userName: string;
};

const initialDraft = {
	email: "",
	firstName: "",
	lastName: "",
	password: "",
	repeatPassword: "",
	userName: "",
};

const CreateUserDialog = (props: {
	organisationName: string;
	open: boolean;
	onClose: (result: DialogResult) => void;
}): JSX.Element => {
	const {l10n} = useLocalization();
	const classes = useStyles();

	const {organisationName, open, onClose} = props;

	const [draft, setDraft] = useState<Draft>(initialDraft);
	const [role, setRole] = useState<UserRole>(UserRole.Student);
	const [course, setCourse] = useState<Course | null>(null);
	const [courses, setCourses] = useState<Course[]>([]);

	const [showPassword, setShowPassword] = useState(false);

	const [requiredEmpty, setRequiredEmpty] = useState<{
		email?: boolean;
		firstName?: boolean;
		lastName?: boolean;
		password?: boolean;
		repeatPassword?: boolean;
		userName?: boolean;
	}>({});

	const [errorUserCreate, setErrorUserCreate] = useState<string | null>("");
	const [inProgress, setInProgress] = useState(false);
	const [invalidUserName, setInvalidUserName] = useState(false);
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [invalidPassword, setInvalidPassword] = useState(false);
	const [invalidRepeatPassword, setInvalidRepeatPassword] = useState(false);
	const [mismatchPasswords, setMismatchPasswords] = useState(false);

	useEffect(() => {
		setDraft(initialDraft);
		setRole(UserRole.Student);
		setCourse(courses[0]);
	}, [courses, open]);

	useEffect(() => {
		if (!open) {
			return;
		}

		async function fetchCourses() {
			let page = await courseService.searchOrganisationCourses(
				organisationName,
				{},
				{field: "name"},
				100
			);

			setCourses(page.content);

			while (page.request.next) {
				page = await page.request.next();
				setCourses((prev) => [...prev, ...page.content]);
			}
		}

		fetchCourses();
	}, [organisationName, open]);

	if (!draft) {
		return <></>;
	}

	const userChangeHandler = (property: keyof Draft, value: string) => {
		setDraft((prevState) => {
			return {...prevState, [property]: value};
		});

		switch (property) {
			case "userName": {
				setRequiredEmpty((prevState) => ({...prevState, userName: false}));
				setInvalidUserName(false);
				break;
			}
			case "email": {
				setRequiredEmpty((prevState) => ({...prevState, email: false}));
				setInvalidEmail(false);
				break;
			}
			case "password": {
				setRequiredEmpty((prevState) => ({...prevState, password: false}));
				setInvalidPassword(false);
				setMismatchPasswords(false);
				break;
			}
			case "repeatPassword": {
				setRequiredEmpty((prevState) => ({
					...prevState,
					repeatPassword: false,
				}));
				setInvalidRepeatPassword(false);
				setMismatchPasswords(false);
				break;
			}
			case "firstName": {
				setRequiredEmpty((prevState) => ({...prevState, firstName: false}));
				break;
			}
			case "lastName": {
				setRequiredEmpty((prevState) => ({...prevState, lastName: false}));
				break;
			}
			default: {
				break;
			}
		}

		setErrorUserCreate("");
	};

	const showPasswordClickHandler = () => {
		setShowPassword((prevState) => !prevState);
	};

	const createUser = async () => {
		let validationError = false;

		if (
			draft.userName.length === 0 ||
			draft.email.length === 0 ||
			draft.password.length === 0 ||
			draft.repeatPassword.length === 0 ||
			draft.firstName.length === 0 ||
			draft.lastName.length === 0
		) {
			setRequiredEmpty({
				email: draft.email.length === 0,
				firstName: draft.firstName.length === 0,
				lastName: draft.lastName.length === 0,
				password: draft.password.length === 0,
				repeatPassword: draft.repeatPassword.length === 0,
				userName: draft.userName.length === 0,
			});

			validationError = true;
		}

		if (
			draft.userName.length < 3 ||
			draft.userName.length > 40 ||
			!validUserName(draft.userName)
		) {
			setInvalidUserName(true);

			validationError = true;
		}

		if (!validEmail(draft.email)) {
			setInvalidEmail(true);

			validationError = true;
		}

		if (
			draft.password.length < 8 ||
			draft.repeatPassword.length < 8 ||
			draft.password !== draft.repeatPassword
		) {
			setInvalidPassword(draft.password.length < 8);
			setInvalidRepeatPassword(draft.repeatPassword.length < 8);
			setMismatchPasswords(draft.password !== draft.repeatPassword);

			validationError = true;
		}

		if (validationError) {
			return;
		}

		const user = {
			userName: draft.userName,
			email: draft.email,
			firstName: draft.firstName,
			lastName: draft.lastName,
			password: draft.password,
			role: role,
			courseId: course ? course.id : undefined,
		};

		setInProgress(true);

		try {
			await userService.createNewUser(props.organisationName, user);

			onClose({
				status: "success",
				message: l10n.getString("administration-user-created"),
			});

			resetChanges();
		} catch (e) {
			let message = l10n.getString("administration-user-creation-failed");

			if (e instanceof Error) {
				message = e.message;
			}

			setErrorUserCreate(message);
		}

		setInProgress(false);
	};

	const resetChanges = () => {
		setDraft(initialDraft);
		setRequiredEmpty({});
		setInvalidUserName(false);
		setInvalidEmail(false);
		setInvalidPassword(false);
		setInvalidRepeatPassword(false);
		setMismatchPasswords(false);
		setErrorUserCreate("");
	};

	const close = () => {
		onClose({status: "cancelled"});
		resetChanges();
	};

	return (
		<Dialog
			open={open}
			onClose={close}
			aria-labelledby="create-users-dialogue-title"
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>
				<Box
					id="create-users-dialogue-title"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					mt={-1}
					mb={-1}
					mr={-2}
				>
					<Typography variant="h6">
						<Localized id="administration-user-creation-form-title">
							New user
						</Localized>
					</Typography>

					<IconButton onClick={close}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				<Box mt={1} mb={1}>
					<Grid
						container
						spacing={2}
						direction="column"
						justifyContent="flex-start"
					>
						<Grid item>
							<TextField
								fullWidth
								required
								id="userName"
								label={
									<Localized id="administration-user-creation-form-username">
										User
									</Localized>
								}
								error={requiredEmpty.userName || invalidUserName}
								value={draft.userName}
								helperText={
									(requiredEmpty.userName && (
										<Localized id="administration-user-creation-form-required-field">
											The field is required
										</Localized>
									)) ||
									(invalidUserName && (
										<Localized id="administration-user-creation-form-username-requirements">
											User name should start with a letter and may only contain
											digits, lowercase Latin letters and non-repeated symbols
											.-_
										</Localized>
									))
								}
								onChange={(event) =>
									userChangeHandler("userName", event.target.value)
								}
							/>
						</Grid>

						<Grid item>
							<TextField
								fullWidth
								required
								id="email"
								label={
									<Localized id="administration-user-creation-form-email">
										Email address
									</Localized>
								}
								error={requiredEmpty.email || invalidEmail}
								value={draft.email}
								helperText={
									(requiredEmpty.email && (
										<Localized id="administration-user-creation-form-required-field">
											The field is required
										</Localized>
									)) ||
									(invalidEmail && (
										<Localized id="administration-user-creation-form-email-incorrect">
											Email is incorrect
										</Localized>
									))
								}
								onChange={(event) =>
									userChangeHandler("email", event.target.value)
								}
							/>
						</Grid>

						<Grid item>
							<FormControl fullWidth>
								<InputLabel
									htmlFor="password"
									error={requiredEmpty.password || invalidPassword}
									required
								>
									<Localized id="administration-user-creation-form-password">
										Password
									</Localized>
								</InputLabel>
								<Input
									id="password"
									type={showPassword ? "text" : "password"}
									error={requiredEmpty.password || invalidPassword}
									value={draft.password}
									onChange={(event) =>
										userChangeHandler("password", event.target.value)
									}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={showPasswordClickHandler}
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									required
								/>
								<FormHelperText color="error" error>
									{(requiredEmpty.password && (
										<Localized id="administration-user-creation-form-required-field">
											The field is required
										</Localized>
									)) ||
										(invalidPassword && (
											<Localized id="administration-user-creation-form-password-invalid-length">
												Password should be at least 8 characters long
											</Localized>
										))}
								</FormHelperText>
							</FormControl>
						</Grid>

						<Grid item>
							<FormControl fullWidth>
								<InputLabel
									htmlFor="password"
									error={requiredEmpty.repeatPassword || invalidRepeatPassword}
									required
								>
									<Localized id="administration-user-creation-form-repeat-password">
										Repeat password
									</Localized>
								</InputLabel>
								<Input
									id="repeat-password"
									type={showPassword ? "text" : "password"}
									error={requiredEmpty.repeatPassword || invalidRepeatPassword}
									value={draft.repeatPassword}
									onChange={(event) =>
										userChangeHandler("repeatPassword", event.target.value)
									}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={showPasswordClickHandler}
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									required
								/>
								<FormHelperText color="error" error>
									{(requiredEmpty.repeatPassword && (
										<Localized id="administration-user-creation-form-required-field">
											The field is required
										</Localized>
									)) ||
										(mismatchPasswords && (
											<Localized id="administration-user-creation-form-password-mismatch">
												Passwords mismatch
											</Localized>
										))}
								</FormHelperText>
							</FormControl>
						</Grid>

						<Grid item>
							<TextField
								fullWidth
								required
								id="firstName"
								label={
									<Localized id="administration-user-creation-form-first-name">
										First name
									</Localized>
								}
								error={requiredEmpty.firstName}
								value={draft.firstName}
								helperText={
									requiredEmpty.firstName && (
										<Localized id="administration-user-creation-form-required-field">
											The field is required
										</Localized>
									)
								}
								onChange={(event) =>
									userChangeHandler("firstName", event.target.value)
								}
							/>
						</Grid>

						<Grid item>
							<TextField
								fullWidth
								id="lastName"
								label={
									<Localized id="administration-user-creation-form-last-name">
										Last name
									</Localized>
								}
								error={requiredEmpty.lastName}
								value={draft.lastName}
								helperText={
									requiredEmpty.lastName && (
										<Localized id="administration-user-creation-form-required-field">
											The field is required
										</Localized>
									)
								}
								onChange={(event) =>
									userChangeHandler("lastName", event.target.value)
								}
								required
							/>
						</Grid>

						<Grid item>
							<Box mt={1}>
								<Typography variant="subtitle1" component="span">
									<Localized id="administration-user-creation-form-add-as">
										Add as
									</Localized>
								</Typography>
							</Box>
						</Grid>

						<Grid item>
							<TextField
								select
								label={
									<Localized id="administration-user-creation-form-role-selector-label">
										Role
									</Localized>
								}
								value={role}
								onChange={(event) => setRole(event.target.value as UserRole)}
								fullWidth
							>
								<MenuItem value={UserRole.Administrator}>
									<Localized id="administration-user-creation-form-role-selector-admin">
										Administrator
									</Localized>
								</MenuItem>
								<MenuItem value={UserRole.Teacher}>
									<Localized id="administration-user-creation-form-role-selector-teacher">
										Teacher
									</Localized>
								</MenuItem>
								<MenuItem value={UserRole.Tutor}>
									<Localized id="administration-user-creation-form-role-selector-tutor">
										Tutor
									</Localized>
								</MenuItem>
								<MenuItem value={UserRole.Student}>
									<Localized id="administration-user-creation-form-role-selector-student">
										Student
									</Localized>
								</MenuItem>
							</TextField>
						</Grid>

						{(role === UserRole.Teacher ||
							role === UserRole.Tutor ||
							role === UserRole.Student) &&
							course &&
							courses.length > 0 && (
								<Grid item>
									<Autocomplete
										id="multiple-courses"
										options={courses}
										getOptionLabel={(option) => option.name}
										value={course}
										defaultValue={courses[0]}
										onChange={(_, newValue) => setCourse(newValue)}
										disableClearable
										renderInput={(params) => (
											<TextField
												{...params}
												label={
													<Localized id="administration-user-creation-form-course-selector-label">
														Course
													</Localized>
												}
											/>
										)}
										className={classes.autocomplete}
										fullWidth
									/>
								</Grid>
							)}
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				<Box display="flex" alignItems="center" flex="1" mx={2} my={1}>
					<Box flex="1">
						{errorUserCreate && errorUserCreate.length > 0 && (
							<Typography variant="body2" color="error">
								{errorUserCreate}
							</Typography>
						)}
					</Box>
					<Box display="flex" alignItems="center">
						<Box mr={2}>
							<Button color="primary" onClick={close}>
								<Localized id="administration-user-creation-form-action-cancel">
									Cancel
								</Localized>
							</Button>
						</Box>
						<SubmitButton onClick={createUser} inProgress={inProgress}>
							<Localized id="administration-user-creation-form-action-create">
								Create
							</Localized>
						</SubmitButton>
					</Box>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default CreateUserDialog;
