import {Box, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";

import TutorQuestion from "../../store/tutorQuestions/TutorQuestion";
import type FileLoader from "../../utils/attachments/FileLoader";
import Message from "./Message";
import StyledAvatar from "./StyledAvatar";

const Conversation = (props: {
	expandedByDefault: "answer" | "question";
	message: TutorQuestion | null;
	studentName?: string;
	tutorName?: string;
	questionFileLoader?: FileLoader;
	answerFileLoader?: FileLoader;
	onReply?: () => void;
}): JSX.Element => {
	const {expandedByDefault, message, studentName, tutorName} = props;

	const [questionExpanded, setQuestionExpanded] = useState<boolean>(
		expandedByDefault === "question"
	);
	const [answerExpanded, setAnswerExpanded] = useState<boolean>(
		expandedByDefault === "answer"
	);

	useEffect(() => {
		setQuestionExpanded(expandedByDefault === "question");
		setAnswerExpanded(expandedByDefault === "answer");
	}, [expandedByDefault, message?.id]);

	let questionMessage = <></>;
	let answerMessage = <></>;

	if (message) {
		questionMessage = (
			<Message
				expanded={questionExpanded}
				avatar={
					<StyledAvatar>
						<Typography>{studentName?.charAt(0).toUpperCase()}</Typography>
					</StyledAvatar>
				}
				name={studentName ?? ""}
				date={message.sendTime}
				content={message.question}
				fileLoader={props.questionFileLoader}
				onChange={(expanded) => setQuestionExpanded(expanded)}
				onReply={props.onReply}
			/>
		);
	}

	if (message?.answerTime) {
		answerMessage = (
			<Message
				expanded={answerExpanded}
				avatar={
					<StyledAvatar>
						<Typography>{tutorName?.charAt(0).toUpperCase()}</Typography>
					</StyledAvatar>
				}
				name={tutorName ?? ""}
				date={message.answerTime}
				content={message.answer ?? ""}
				fileLoader={props.answerFileLoader}
				onChange={(expanded) => setAnswerExpanded(expanded)}
			/>
		);
	}

	return (
		<Box overflow="auto" px={3} py={2}>
			{questionMessage}
			{answerMessage}
		</Box>
	);
};

export default Conversation;
