import {Box} from "@material-ui/core";
import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";

import ChapterManagement from "./ChapterManagement";
import LearningMaterialManage from "./LearningMaterialManage";
import NewChapterEditor from "./NewChapterEditor";
import NewSectionEditor from "./NewSectionEditor";
import SectionContentManagement from "./SectionContentManagement";
import {resolvePath} from "../../helpers/pathHelpers";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import CourseChaptersHaveOldStructureWarning from "./CourseChaptersHaveOldStructureWarning";
import {useAppSelector} from "../../store/hooks";
import selectNumberOfChaptersWithSupportedStructure from "../../store/courses/selectNumberOfChaptersWithSupportedStructure";

const navigationWidth = "35vw";

function LearningMaterial(props: {
	organisationName: string;
	courseId: number;
	viewOnly?: boolean;
}): JSX.Element {
	const {viewOnly} = props;

	const {path: basePath} = useRouteMatch();
	const newSectionPath = resolvePath(
		basePath,
		"/chapters/:chapterId/sections/new"
	);

	const sectionContentPath = resolvePath(
		basePath,
		"/chapters/:chapterId/sections/:sectionId"
	);
	const addChapterPath = resolvePath(basePath, "/chapters/new");
	const chapterContentPath = resolvePath(basePath, "/chapters/:chapterId");

	const [confirmDialog, openConfirmDialog] = useConfirmationDialog();

	const [supported, total] = useAppSelector((state) =>
		selectNumberOfChaptersWithSupportedStructure(state, props.courseId)
	);

	return (
		<Box display="flex" maxWidth={2048}>
			<LearningMaterialManage
				courseId={props.courseId}
				width={navigationWidth}
				maxWidth={820}
				viewOnly={viewOnly}
				openConfirmDialog={openConfirmDialog}
			/>
			<Box flexGrow={1} mt={6} mr={6} ml={3} minWidth={0}>
				<Switch>
					{!viewOnly && (
						<Route exact path={addChapterPath}>
							<NewChapterEditor courseId={props.courseId} />
						</Route>
					)}

					<Route exact path={chapterContentPath}>
						<ChapterManagement
							viewOnly={viewOnly}
							openConfirmDialog={openConfirmDialog}
						/>
					</Route>

					{!viewOnly && (
						<Route exact path={newSectionPath}>
							<NewSectionEditor />
						</Route>
					)}

					<Route exact path={sectionContentPath}>
						<SectionContentManagement
							organisationName={props.organisationName}
							viewOnly={viewOnly}
							openConfirmDialog={openConfirmDialog}
						/>
					</Route>

					{supported !== total && (
						<Route>
							<CourseChaptersHaveOldStructureWarning
								courseId={props.courseId}
								all={supported === 0}
							/>
						</Route>
					)}
				</Switch>
			</Box>

			{confirmDialog}
		</Box>
	);
}

export default LearningMaterial;
