import {Localized, useLocalization} from "@fluent/react";
import {Typography} from "@material-ui/core";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useState} from "react";

import {useAppDispatch, useAppSelector} from "../../store/hooks";
import sendAnswer from "../../store/tutorQuestions/sendAnswer";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import Message from "./Message";
import NewMessageForm from "./NewMessageForm";
import StyledAvatar from "./StyledAvatar";
import useAnswerFileUploader from "./useAnswerFileUploader";
import useQuestionFileLoader from "./useQuestionFileLoader";
import useMessageData from "./useMessageData";

const NewAnswerForm = (props: {
	courseId: number;
	selectedMessage: string | null;
	mobileMode: boolean;
	onClose: () => void;
}): JSX.Element => {
	const dispatch = useAppDispatch();
	const {courseId, onClose, selectedMessage} = props;

	const [questionExpanded, setQuestionExpanded] = useState(false);

	const {message, chapter, section, exercise} = useMessageData(
		selectedMessage,
		courseId
	);

	const {l10n} = useLocalization();

	const subject = chapter
		? `${chapter?.title} / ${section?.title} / ${exercise?.title}`
		: l10n.getString(
				"tutoring-subject-general-question",
				null,
				"General question"
		  );

	const questionId = message?.id ?? 0;
	const studentName = message?.studentName;
	const personId = useAppSelector(selectUserId);

	const submit = async (answer: string) => {
		if (selectedMessage) {
			const res = await dispatch(
				sendAnswer({
					courseId,
					questionId,
					answer,
					messageKey: selectedMessage,
				})
			);
			unwrapResult(res);
		}
	};

	const questionFileLoader = useQuestionFileLoader(
		courseId,
		questionId,
		message?.studentId
	);

	const [files, fileUploader] = useAnswerFileUploader(
		courseId,
		personId,
		questionId
	);

	const originalQuestion = (
		<Message
			expanded={questionExpanded}
			avatar={
				<StyledAvatar>
					<Typography>{studentName?.charAt(0).toUpperCase()}</Typography>
				</StyledAvatar>
			}
			name={studentName ?? ""}
			date={message?.sendTime}
			content={message?.question ?? ""}
			fileLoader={questionFileLoader}
			onChange={(expanded) => setQuestionExpanded(expanded)}
		/>
	);

	return (
		<NewMessageForm
			title={<Localized id="tutoring-tutor-new-answer">New answer</Localized>}
			subject={`Re: ${subject}`}
			senderName={studentName}
			fileUploader={fileUploader}
			previousMessage={originalQuestion}
			attachments={files}
			mobileMode={props.mobileMode}
			onCancel={onClose}
			onSubmit={submit}
		/>
	);
};

export default NewAnswerForm;
