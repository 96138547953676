import {Localized} from "@fluent/react";
import {MenuItem, TextField} from "@material-ui/core";
import React from "react";

import ExercisePrivacy from "../../store/exercises/ExercisePrivacy";

const PrivacyLevelSelector = (props: {
	onChange: (value: ExercisePrivacy) => void;
	value: ExercisePrivacy;
}): JSX.Element => {
	return (
		<TextField
			select
			label={
				<Localized id="exercise-editor-privacy-level-selector-label">
					Privacy level
				</Localized>
			}
			value={props.value}
			onChange={(event) => {
				props.onChange(event.target.value as ExercisePrivacy);
			}}
			fullWidth
		>
			<MenuItem value={ExercisePrivacy.Private}>
				<Localized id="exercise-editor-privacy-level-private">
					Private
				</Localized>
			</MenuItem>
			<MenuItem value={ExercisePrivacy.PublicToOrganisation}>
				<Localized id="exercise-editor-privacy-level-public-organisation">
					Public to organisation
				</Localized>
			</MenuItem>
			<MenuItem value={ExercisePrivacy.Public}>
				<Localized id="exercise-editor-privacy-level-public">Public</Localized>
			</MenuItem>
		</TextField>
	);
};

export default PrivacyLevelSelector;
