import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Button,
	Collapse,
	DialogContentText,
	Divider,
	Fade,
	Grid,
	Menu,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {useHistory} from "react-router-dom";

import CourseTypeSelector from "../courses/CourseTypeSelector";
import parseDate from "../../helpers/parseDate";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import useDateFormat from "../../i18n/useDateFormat";
import type RoleInCourse from "../../store/courses/RoleInCourse";
import type UserCourse from "../../store/models/UserCourse";
import UserRole from "../../store/models/UserRole";
import {adminService} from "../../store/services/adminService";
import type {UserCourseSearchCriteria} from "../../store/services/courseService";
import {courseService} from "../../store/services/courseService";
import type {
	UserGroupOrganisationSearchCriteria,
	UserGroupOrganisationSearchResult,
} from "../../store/services/organisationService";
import {organisationService} from "../../store/services/organisationService";
import useSnackbar from "../../store/ui/useSnackbar";
import useAssignToGroupCoursesAction from "../users/useAssignToGroupCoursesAction";
import useEnrolInGroupCourseAction from "../users/useEnrolInGroupCourseAction";
import useGrantAdminInGroupOrganisationAction from "../users/useGrantAdminInGroupOrganisationAction";
import UserRoleChips from "../users/UserRoleChips";
import ChipsSelector from "../../utils/ChipsSelector";
import DualTextLabel from "../../utils/DualTextLabel";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";
import SearchToolbar from "../../utils/SearchToolbar";
import SubmitButton from "../../utils/SubmitButton";
import type HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import ServerPaginationControls from "../../utils/tables/ServerPaginationControls";
import SortingHeader from "../../utils/tables/SortingHeader";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import usePaginationState from "../../utils/tables/usePaginationState";
import useSorting from "../../utils/tables/useSorting";

const useRootStyles = makeStyles<Theme, {navBarHeight: number}>((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
			height: ({navBarHeight}) => `calc(100vh - ${navBarHeight + 1}px)`,
			width: "100%",
		},
		container: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
		},
		overlay: {
			background: theme.palette.background.paper,
			height: "100%",
			width: "100%",
			position: "relative",
		},
	})
);

const emptyOrg = {
	city: "",
	countryCode: "",
	displayName: "",
	name: "",
	roles: [],
};

type StaffRole = UserRole.Teacher | UserRole.Tutor;

const staffRoles: StaffRole[] = [UserRole.Teacher, UserRole.Tutor];

function GroupUserOrganisations(props: {
	groupName: string;
	userId: number;
	userName: string;
}) {
	const {groupName, userId} = props;

	const [orgs, setOrgs] = useState<UserGroupOrganisationSearchResult[]>([]);

	const [expanded, setExpanded] = useState(false);
	const [expandedOrgName, setExpandedOrgName] = useState("");

	const navBarHeight = useNavBarHeight();
	const classes = useRootStyles({navBarHeight});

	const history = useHistory();

	const mobileMode = useMobileMode("sm");

	const organisationPage = useRef<{reload?: () => void}>({});

	const expandedOrg = orgs.find((o) => o.name === expandedOrgName) ?? emptyOrg;

	const memberOfMultipleOrganisations = orgs.length > 1;

	return (
		<Box
			pt={mobileMode ? 3 : 5}
			px={mobileMode ? 3 : 6}
			pb={1.5}
			className={classes.root}
		>
			<div className={classes.container}>
				<Organisations
					groupName={groupName}
					userId={userId}
					userName={props.userName}
					onExpand={(name) => {
						setExpandedOrgName(name);
						setExpanded(true);
					}}
					onFetched={setOrgs}
					hidden={expanded}
					pageRef={organisationPage}
				/>
				<Fade in={expanded} mountOnEnter unmountOnExit>
					<Box position="absolute" style={{inset: 0}}>
						<Box className={classes.overlay} zIndex={2}>
							<CoursesInOrganisation
								userId={userId}
								organisation={expandedOrg}
								userName={props.userName}
								memberOfMultipleOrganisations={memberOfMultipleOrganisations}
								onCollapse={() => setExpanded(false)}
								onRolesUpdated={() => organisationPage.current.reload?.()}
								onDelete={() => {
									setExpanded(false);

									if (memberOfMultipleOrganisations) {
										organisationPage.current.reload?.();
									} else {
										history.push("/users");
									}
								}}
							/>
						</Box>
					</Box>
				</Fade>
			</div>
		</Box>
	);
}

const useOrgTableStyles = makeStyles((theme) =>
	createStyles({
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
		mainAction: {
			whiteSpace: "nowrap",
		},
	})
);

const organisationHeadCells: HeadCell<UserGroupOrganisationSearchResult>[] = [
	{
		id: "name",
		label: "group-user-organisations-table-column-name",
		sortable: true,
	},
	{
		id: "roles",
		label: "group-user-organisations-table-column-roles",
		sortable: false,
		width: 400,
		align: "right",
	},
];

const columnNumber = organisationHeadCells.length + 1;

const rolesInOrg = [
	UserRole.Student,
	UserRole.Teacher,
	UserRole.Tutor,
	UserRole.Administrator,
];

const initialOrgSearchCriteria: UserGroupOrganisationSearchCriteria = {};

function Organisations(props: {
	groupName: string;
	userId: number;
	userName: string;
	hidden?: boolean;
	onExpand: (organisationName: string) => void;
	onFetched: (orgs: UserGroupOrganisationSearchResult[]) => void;

	pageRef: React.Ref<{reload?: () => void}>;
}) {
	const {groupName, userId, onFetched} = props;

	const classes = useOrgTableStyles();

	const [criteria, setCriteria] = useState(initialOrgSearchCriteria);

	const [sortField, sortOrder, changeOrder] = useSorting<
		keyof UserGroupOrganisationSearchResult
	>("name");

	const [orgsPerPage, setOrgsPerPage] = useState(10);

	const {
		page: orgsPage,
		pageFetchStatus,
		fetchFirstPage,
		fetchRelatedPage,
		retryFetching,
	} = usePaginationState<UserGroupOrganisationSearchResult, string>();

	const organisations = orgsPage.content;

	const {l10n} = useLocalization();
	const mobileMode = useMobileMode("sm");

	const [addRoleMenu, setAddRoleMenu] = useState<HTMLButtonElement | null>(
		null
	);

	useImperativeHandle(props.pageRef, () => ({reload: retryFetching}), [
		retryFetching,
	]);

	const [enrolInCourse, enrolInCourseDialog] = useEnrolInGroupCourseAction(
		groupName,
		mobileMode,
		retryFetching
	);

	const [
		assignToCourses,
		assignToCoursesDialog,
	] = useAssignToGroupCoursesAction(groupName, mobileMode, retryFetching);

	const [grantAdmin, grantAdminDialog] = useGrantAdminInGroupOrganisationAction(
		groupName,
		retryFetching
	);

	useEffect(() => {
		fetchFirstPage(() =>
			organisationService.searchGroupOrganisationsOfUser(
				groupName,
				userId,
				criteria,
				{field: sortField, descending: sortOrder === SortOrder.Desc},
				orgsPerPage
			)
		);
	}, [
		criteria,
		fetchFirstPage,
		groupName,
		orgsPerPage,
		sortField,
		sortOrder,
		userId,
	]);

	useEffect(() => {
		onFetched(orgsPage.content);
	}, [orgsPage.content, onFetched]);

	if (props.hidden) {
		return <></>;
	}

	return (
		<>
			<SearchToolbar
				criteria={criteria}
				searchPlaceholder={l10n.getString(
					"group-user-organisations-search-placeholder"
				)}
				mainAction={
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddIcon />}
						onClick={(event) => setAddRoleMenu(event.currentTarget)}
						className={classes.mainAction}
					>
						<Localized id="group-user-organisations-main-action-add-role">
							Add role
						</Localized>
					</Button>
				}
				onCriteriaChange={setCriteria}
			>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<ChipsSelector
							options={rolesInOrg}
							color="secondary"
							variant="outlined"
							value={criteria.roles ?? []}
							label={
								<Localized id="group-user-organisations-filter-roles">
									Roles
								</Localized>
							}
							onChange={(roles) =>
								setCriteria((prev) =>
									roles.length === rolesInOrg.length || roles.length === 0
										? {...prev, roles: undefined}
										: {...prev, roles}
								)
							}
							getOptionLabel={(role) => (
								<Localized id="user-role-chips-role" vars={{role}} />
							)}
						/>
					</Grid>
				</Grid>
			</SearchToolbar>
			<TableContainer className={classes.container} style={{}}>
				<Table stickyHeader>
					<SortingHeader
						onOrderChange={changeOrder}
						order={sortOrder}
						orderBy={sortField}
						headCells={organisationHeadCells}
						rightAnnex={<TableCell />}
						loading={pageFetchStatus === "pending"}
					/>
					<TableBody>
						{pageFetchStatus === "failed" && (
							<TableRow>
								<LoadingErrorState
									description={
										<Localized id="group-user-organisations-loading-error-descr">
											Something has gone wrong, and we cannot load organisations
										</Localized>
									}
									colSpan={columnNumber}
									onReload={retryFetching}
								/>
							</TableRow>
						)}
						{pageFetchStatus === "succeeded" && organisations.length === 0 && (
							<TableRow>
								<NoSearchResultsState
									colSpan={columnNumber}
									title={
										<Localized id="group-user-organisations-no-organisations">
											No organisations
										</Localized>
									}
									description={
										criteria === initialOrgSearchCriteria ? (
											<Localized id="group-user-organisations-no-organisations-descr">
												There are no organisations for the user
											</Localized>
										) : (
											<Localized id="group-user-organisations-no-results-descr">
												No organisations were found matching your search
												criteria. Try to adjust filters
											</Localized>
										)
									}
								/>
							</TableRow>
						)}
						{pageFetchStatus !== "failed" &&
							organisations.length > 0 &&
							organisations.map((o, index) => {
								const labelId = `table-checkbox-${index}`;
								return (
									<TableRow hover key={o.name} tabIndex={-1}>
										<TableCell component="th" id={labelId} scope="row">
											<DualTextLabel
												primaryText={o.displayName}
												secondaryText={o.name}
											/>
										</TableCell>
										<TableCell>
											<UserRoleChips roles={o.roles} align="right" />
										</TableCell>
										<TableCell padding="checkbox">
											<IconButtonWithTooltip
												tooltipTitle={
													<Localized id="group-user-organisations-action-expand">
														Expand
													</Localized>
												}
												aria-label={l10n.getString(
													"group-user-organisations-action-expand"
												)}
												onClick={() => props.onExpand(o.name)}
											>
												<FullscreenIcon />
											</IconButtonWithTooltip>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<Box display="flex" justifyContent="flex-end" alignItems="center" ml={2}>
				<TablePagination
					onPageChange={fetchRelatedPage}
					pageSize={orgsPerPage}
					onPageSizeChange={setOrgsPerPage}
					first={Boolean(orgsPage.request.first)}
					last={Boolean(orgsPage.request.last)}
					next={Boolean(orgsPage.request.next)}
					previous={Boolean(orgsPage.request.previous)}
					label={
						<Localized id="group-organisations-per-page">
							Organisations per page
						</Localized>
					}
					disabled={pageFetchStatus !== "succeeded"}
				/>
			</Box>

			<Menu
				open={Boolean(addRoleMenu)}
				anchorEl={addRoleMenu}
				onClose={() => setAddRoleMenu(null)}
			>
				<MenuItem
					onClick={() => {
						setAddRoleMenu(null);
						enrolInCourse([userId]);
					}}
				>
					<Localized id="group-user-organisations-action-enrol">
						Enrol in a course
					</Localized>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAddRoleMenu(null);
						assignToCourses([userId]);
					}}
				>
					<Localized id="group-user-organisations-action-assign-to-courses">
						Assign to courses
					</Localized>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAddRoleMenu(null);
						grantAdmin([userId], props.userName);
					}}
				>
					<Localized id="group-user-organisations-action-grant-admin">
						Grant administrator role
					</Localized>
				</MenuItem>
			</Menu>

			{enrolInCourseDialog}
			{assignToCoursesDialog}
			{grantAdminDialog}
		</>
	);
}

const useCoursesTableStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginTop: theme.spacing(2),
			height: "100%",
		},
		root: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},
		toolbar: {
			display: "flex",
			gap: theme.spacing(0.5),
		},
	})
);

const coursesHeadCells: HeadCell<UserCourse>[] = [
	{
		id: "name",
		label: "group-user-organisation-courses-table-column-name",
		sortable: true,
	},
	{
		id: "userRoles",
		label: "group-user-organisation-courses-table-column-roles",
		sortable: false,
		width: 300,
		align: "right",
	},
];

const courseColumnNumber = coursesHeadCells.length + 1;

const initialCourseSearchCriteria: UserCourseSearchCriteria = {};

const rolesInCourse: RoleInCourse[] = [
	UserRole.Student,
	UserRole.Teacher,
	UserRole.Tutor,
];

function CoursesInOrganisation(props: {
	userId: number;
	userName: string;
	organisation: UserGroupOrganisationSearchResult;
	memberOfMultipleOrganisations: boolean;
	onCollapse: () => void;
	onRolesUpdated: () => void;
	onDelete: () => void;
}) {
	const {userId, organisation, onRolesUpdated} = props;

	const classes = useCoursesTableStyles();

	const [criteria, setCriteria] = useState(initialCourseSearchCriteria);

	const [sortField, sortOrder, changeOrder] = useSorting<keyof UserCourse>(
		"name"
	);

	const {
		page: coursesPage,
		pageFetchStatus,
		fetchFirstPage,
		fetchRelatedPage,
		retryFetching,
	} = usePaginationState<UserCourse>();

	const courses = coursesPage.content;

	const {l10n} = useLocalization();
	const showSnackbar = useSnackbar();

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();

	useEffect(() => {
		if (organisation.name) {
			fetchFirstPage(() =>
				courseService.searchUserCourses(
					organisation.name,
					userId,
					criteria,
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					10
				)
			);
		}
	}, [
		criteria,
		fetchFirstPage,
		organisation.name,
		sortField,
		sortOrder,
		userId,
	]);

	function withAdminRoleUpdate(
		action: (name: string, ids: number[]) => Promise<void>
	) {
		return async function () {
			try {
				await action(organisation.name, [userId]);
				onRolesUpdated();
			} catch {
				showSnackbar("error", l10n.getString("error-general"));
			}
		};
	}

	async function deleteUser() {
		try {
			await adminService.deleteUsers(organisation.name, [userId]);
			props.onDelete();
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	function confirmGrantAdmin() {
		openConfirmationDialog({
			title: (
				<Localized id="group-user-organisation-confirm-grant-admin-title">
					Grant administrator role?
				</Localized>
			),
			description: (
				<Localized
					id="group-user-organisation-confirm-grant-admin-descr"
					vars={{organisation: organisation.displayName, user: props.userName}}
				>
					Are you sure you want the user to be an administrator in organisation?
				</Localized>
			),
			confirmBtnText: (
				<Localized id="group-user-organisation-confirm-grant-admin-action-confirm">
					Grant
				</Localized>
			),
			onConfirm: withAdminRoleUpdate(adminService.grantAdminRigts),
		});
	}

	function confirmRevokeAdmin() {
		openConfirmationDialog({
			title: (
				<Localized id="group-user-organisation-confirm-revoke-admin-title">
					Revoke administrator role?
				</Localized>
			),
			description: (
				<Localized
					id="group-user-organisation-confirm-revoke-admin-descr"
					vars={{organisation: organisation.displayName, user: props.userName}}
				>
					Are you sure you want the user to no more be an administrator in
					organisation?
				</Localized>
			),
			confirmBtnText: (
				<Localized id="group-user-organisation-confirm-revoke-admin-action-confirm">
					Revoke
				</Localized>
			),
			onConfirm: withAdminRoleUpdate(adminService.revokeAdminRights),
		});
	}

	function confirmDelete() {
		openConfirmationDialog({
			title: (
				<Localized id="group-user-organisation-confirm-delete-title">
					Delete user?
				</Localized>
			),
			description: (
				<>
					<DialogContentText>
						<Localized
							id="group-user-organisation-confirm-delete-descr"
							vars={{
								user: props.userName,
								organisation: organisation.displayName,
							}}
						>
							You are going to remove user from organisation. All his data will
							be deleted as well, although courses and course content which he
							created will be retained.
						</Localized>
					</DialogContentText>
					{!props.memberOfMultipleOrganisations && (
						<DialogContentText>
							<Localized
								id="group-user-organisation-confirm-delete-descr-notice"
								elems={{bold: <span style={{fontWeight: 500}}></span>}}
							>
								<>
									Attention: the user has no other organisations in the group,
									so you will be no more able to manage his account. In
									addition, his account will be permanently deleted from the
									system if he also is not a member of any organisation outside
									the group.
								</>
							</Localized>
						</DialogContentText>
					)}
				</>
			),
			confirmBtnText: (
				<Localized id="group-user-organisation-confirm-delete-action-confirm">
					Delete
				</Localized>
			),
			onConfirm: deleteUser,
		});
	}

	const notFetched = pageFetchStatus !== "succeeded";

	return (
		<div className={classes.root}>
			<SummaryOrganisationRow
				organisation={organisation}
				onCollapse={props.onCollapse}
				onGrantAdmin={confirmGrantAdmin}
				onRevokeAdmin={confirmRevokeAdmin}
				onDelete={confirmDelete}
			/>
			<Box className={classes.toolbar}>
				<SearchToolbar
					criteria={criteria}
					searchPlaceholder={l10n.getString(
						"group-user-organisation-courses-search-placeholder"
					)}
					onCriteriaChange={setCriteria}
				>
					<Grid container spacing={4} alignItems="flex-end">
						<Grid item xs={12} md={6}>
							<ChipsSelector
								options={rolesInCourse}
								color="secondary"
								variant="outlined"
								value={criteria.userRole ?? []}
								label={
									<Localized id="group-user-organisation-courses-filter-roles">
										Roles
									</Localized>
								}
								onChange={(roles) =>
									setCriteria((prev) =>
										roles.length === rolesInCourse.length || roles.length === 0
											? {...prev, userRole: undefined}
											: {...prev, userRole: roles}
									)
								}
								getOptionLabel={(role) => (
									<Localized id="user-role-chips-role" vars={{role}} />
								)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CourseTypeSelector
								value={criteria.type}
								onChange={(val) =>
									setCriteria((prev) => ({...prev, type: val}))
								}
							/>
						</Grid>
					</Grid>
				</SearchToolbar>
				<ServerPaginationControls
					disabledControls={{
						first: notFetched || !coursesPage.request.first,
						next: notFetched || !coursesPage.request.next,
						previous: notFetched || !coursesPage.request.previous,
					}}
					onClick={fetchRelatedPage}
				/>
			</Box>

			<TableContainer className={classes.container}>
				<Table stickyHeader>
					<SortingHeader
						onOrderChange={changeOrder}
						order={sortOrder}
						orderBy={sortField}
						headCells={coursesHeadCells}
						loading={pageFetchStatus === "pending"}
						rightAnnex={<TableCell />}
					/>
					<TableBody>
						{pageFetchStatus === "failed" && (
							<TableRow>
								<LoadingErrorState
									description={
										<Localized id="group-user-organisation-courses-loading-error-descr">
											Something has gone wrong, and we cannot load courses
										</Localized>
									}
									colSpan={courseColumnNumber}
									onReload={retryFetching}
								/>
							</TableRow>
						)}
						{pageFetchStatus === "succeeded" && courses.length === 0 && (
							<TableRow>
								<NoSearchResultsState
									colSpan={courseColumnNumber}
									title={
										<Localized id="group-user-organisation-courses-no-courses">
											No courses
										</Localized>
									}
									description={
										criteria === initialCourseSearchCriteria ? (
											<Localized id="group-user-organisation-courses-no-courses-descr">
												There are no courses for the user in the organisation
											</Localized>
										) : (
											<Localized id="group-user-organisation-courses-no-results-descr">
												No courses were found matching your search criteria. Try
												to adjust filters
											</Localized>
										)
									}
								/>
							</TableRow>
						)}
						{pageFetchStatus !== "failed" &&
							courses.map((c, index) => (
								<CourseRow
									key={c.id}
									course={c}
									userId={userId}
									labelId={`table-checkbox-${index}`}
									onRolesChanged={() => {
										retryFetching?.();
										onRolesUpdated();
									}}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>

			{confirmationDialog}
		</div>
	);
}

const useRowStyles = makeStyles(() =>
	createStyles({
		noBottomBorder: {
			"& > td": {
				borderBottom: 0,
			},
			"& > th": {
				borderBottom: 0,
			},
		},
	})
);

function CourseRow(props: {
	labelId: string;
	course: UserCourse;
	userId: number;
	onRolesChanged: () => void;
}) {
	const {course} = props;

	const rowClasses = useRowStyles();

	const [expanded, setExpanded] = useState(false);

	const formatDate = useDateFormat();

	return (
		<>
			<TableRow hover tabIndex={-1} className={rowClasses.noBottomBorder}>
				<TableCell component="th" id={props.labelId} scope="row">
					<DualTextLabel
						primaryText={course.name}
						secondaryText={`${formatDate(
							parseDate(course.startDate)
						)} - ${formatDate(parseDate(course.endDate))}`}
					/>
				</TableCell>
				<TableCell>
					<UserRoleChips roles={course.userRoles} align="right" />
				</TableCell>
				<TableCell padding="checkbox">
					<IconButtonWithTooltip
						tooltipTitle={
							expanded ? (
								<Localized id="group-user-organisation-courses-course-collapse">
									Collapse
								</Localized>
							) : (
								<Localized id="group-user-organisation-courses-course-expand">
									Expand
								</Localized>
							)
						}
						onClick={() => setExpanded((prev) => !prev)}
					>
						{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButtonWithTooltip>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell colSpan={3} style={{padding: 0}}>
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<CoursePanel
							course={course}
							userId={props.userId}
							onRolesChanged={props.onRolesChanged}
						/>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

const useCoursePanelStyles = makeStyles(() =>
	createStyles({
		sectionTitle: {
			fontWeight: 500,
		},
	})
);

function CoursePanel(props: {
	course: UserCourse;
	userId: number;
	onRolesChanged: () => void;
}) {
	const classes = useCoursePanelStyles();
	const rowClasses = useRowStyles();

	const [rolesEditorOpen, setRolesEditorOpen] = useState(false);

	const staffRoles: StaffRole[] = [];
	props.course.userRoles.forEach((r) => {
		if (r === UserRole.Teacher || r === UserRole.Tutor) {
			staffRoles.push(r);
		}
	});

	const student = props.course.userRoles.includes(UserRole.Student);

	return (
		<Table>
			<TableBody>
				{!rolesEditorOpen && (
					<TableRow className={rowClasses.noBottomBorder} hover>
						<TableCell colSpan={2}>
							<Grid container spacing={1} alignItems="center">
								<Grid item xs={12} sm={4} className={classes.sectionTitle}>
									<Localized id="group-user-organisation-courses-course-section-staff-membership">
										Staff member status
									</Localized>
								</Grid>
								<Grid item xs={12} sm={8}>
									{staffRoles.length > 0 ? (
										<UserRoleChips roles={staffRoles} />
									) : (
										<Localized id="group-user-organisation-courses-course-section-staff-membership-no-roles">
											No roles
										</Localized>
									)}
								</Grid>
							</Grid>
						</TableCell>
						<TableCell padding="checkbox">
							<IconButtonWithTooltip
								tooltipTitle={
									<Localized id="group-user-organisation-courses-course-section-staff-membership-action-change-roles">
										Change roles
									</Localized>
								}
								onClick={() => {
									setRolesEditorOpen(true);
								}}
							>
								<EditIcon />
							</IconButtonWithTooltip>
						</TableCell>
					</TableRow>
				)}
				{rolesEditorOpen && (
					<TableRow className={rowClasses.noBottomBorder}>
						<TableCell colSpan={3}>
							<StaffMembershipEditor
								initialRoles={staffRoles}
								courseId={props.course.id}
								userId={props.userId}
								onSaved={() => {
									setRolesEditorOpen(false);
									props.onRolesChanged();
								}}
								onCancel={() => setRolesEditorOpen(false)}
							/>
						</TableCell>
					</TableRow>
				)}

				<TableRow hover className={rowClasses.noBottomBorder}>
					<TableCell colSpan={2}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={4} className={classes.sectionTitle}>
								<Localized id="group-user-organisation-courses-course-section-enrolment">
									Student status
								</Localized>
							</Grid>
							<Grid item xs={12} sm={8}>
								{student ? (
									<Localized id="group-user-organisation-courses-course-section-enrolment-enrolled">
										Enrolled
									</Localized>
								) : (
									<Localized id="group-user-organisation-courses-course-section-enrolment-not-enrolled">
										Not enrolled
									</Localized>
								)}
							</Grid>
						</Grid>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
}

const useStaffMembershipEditorStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: "flex",
			padding: theme.spacing(2, 0, 1, 1.5),
			marginLeft: theme.spacing(-1.5),
		},
		divider: {
			marginLeft: theme.spacing(-1.5),
			marginTop: theme.spacing(2),
		},
	})
);

function StaffMembershipEditor(props: {
	initialRoles: StaffRole[];
	courseId: number;
	userId: number;
	onSaved: () => void;
	onCancel: () => void;
}) {
	const classes = useStaffMembershipEditorStyles();

	const [roles, setRoles] = useState(props.initialRoles);

	const [saving, setSaving] = useState(false);

	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	async function save() {
		setSaving(true);

		try {
			if (roles.length > 0) {
				await courseService.changeRolesOfStaffMember(
					props.courseId,
					props.userId,
					roles
				);
			} else {
				await courseService.removeStaffMember(props.courseId, props.userId);
			}

			props.onSaved();
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		} finally {
			setSaving(false);
		}
	}

	return (
		<Paper className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={4}>
					<Typography variant="subtitle2">
						<Localized id="group-user-organisation-courses-course-section-staff-membership">
							Staff membership
						</Localized>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={8}>
					<ChipsSelector
						variant="outlined"
						options={staffRoles}
						value={roles}
						label={
							<Localized id="group-user-organisation-courses-course-section-staff-membership-editor-roles">
								Roles
							</Localized>
						}
						onChange={setRoles}
						getOptionLabel={(role) => (
							<Localized id="user-role-chips-role" vars={{role}} />
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Divider className={classes.divider} />
				</Grid>
				<Grid item xs={12} container justifyContent="flex-end" spacing={1}>
					<Grid item>
						<Button color="primary" onClick={props.onCancel}>
							<Localized id="group-user-organisation-courses-course-section-staff-membership-editor-action-cancel">
								Cancel
							</Localized>
						</Button>
					</Grid>
					<Grid item>
						<SubmitButton
							color="primary"
							variant="text"
							inProgress={saving}
							onClick={save}
						>
							<Localized id="group-user-organisation-courses-course-section-staff-membership-editor-action-save">
								Save
							</Localized>
						</SubmitButton>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
}

const useSummaryStyles = makeStyles((theme) =>
	createStyles({
		root: {
			marginBottom: theme.spacing(1),
		},
		menu: {
			minWidth: theme.spacing(14),
		},
		menuDivider: {
			margin: theme.spacing(1, 0),
		},
	})
);

function SummaryOrganisationRow(props: {
	organisation: UserGroupOrganisationSearchResult;
	onCollapse: () => void;
	onGrantAdmin: () => void;
	onRevokeAdmin: () => void;
	onDelete: () => void;
}) {
	const {organisation: o} = props;

	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

	const {l10n} = useLocalization();

	const classes = useSummaryStyles();
	const rowClasses = useRowStyles();

	function closeMenu() {
		setMenuAnchor(null);
	}

	const admin = o.roles.includes(UserRole.Administrator);

	return (
		<Table stickyHeader className={classes.root}>
			<TableHead>
				<TableRow className={rowClasses.noBottomBorder}>
					<TableCell>
						<DualTextLabel primaryText={o.displayName} secondaryText={o.name} />
					</TableCell>
					<TableCell>
						<UserRoleChips roles={o.roles} align="right" />
					</TableCell>
					<TableCell padding="checkbox">
						<IconButtonWithTooltip
							tooltipTitle={
								<Localized id="group-user-organisations-actions-label">
									Actions
								</Localized>
							}
							aria-label={l10n.getString(
								"group-user-organisations-actions-label"
							)}
							onClick={(e) => {
								setMenuAnchor(e.currentTarget);
							}}
						>
							<MoreVertIcon />
						</IconButtonWithTooltip>
					</TableCell>
					<TableCell padding="checkbox">
						<IconButtonWithTooltip
							tooltipTitle={
								<Localized id="group-user-organisations-action-collapse">
									Collapse
								</Localized>
							}
							aria-label={l10n.getString(
								"group-user-organisations-action-collapse"
							)}
							onClick={props.onCollapse}
						>
							<FullscreenExitIcon />
						</IconButtonWithTooltip>
					</TableCell>
				</TableRow>
			</TableHead>

			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				PaperProps={{className: classes.menu}}
				onClose={closeMenu}
			>
				{!admin && (
					<MenuItem
						onClick={() => {
							closeMenu();
							props.onGrantAdmin();
						}}
					>
						<Localized id="group-user-organisations-action-grant-admin">
							Grant administrator role
						</Localized>
					</MenuItem>
				)}
				{admin && (
					<MenuItem
						onClick={() => {
							closeMenu();
							props.onRevokeAdmin();
						}}
					>
						<Localized id="group-user-organisations-action-revoke-admin">
							Revoke administrator role
						</Localized>
					</MenuItem>
				)}

				<Divider component="li" className={classes.menuDivider} />

				<MenuItem
					onClick={() => {
						closeMenu();
						props.onDelete();
					}}
				>
					<Localized id="group-user-organisations-action-delete">
						Delete
					</Localized>
				</MenuItem>
			</Menu>
		</Table>
	);
}

export default GroupUserOrganisations;
