import React from "react";
import {Box, Typography} from "@material-ui/core";

const IndexedLabel = (props: {
	index: string;
	text: React.ReactNode;
	classes: {
		index: string;
		label: string;
	};
	variant: "body1" | "body2";
}): JSX.Element => {
	return (
		<Box display="flex" alignItems="flex-start" component="span">
			<Typography
				variant={props.variant}
				className={props.classes.index}
				color="textSecondary"
				component="span"
			>
				{props.index}
			</Typography>
			<Typography
				variant={props.variant}
				className={props.classes.label}
				color="textPrimary"
				component="span"
			>
				{props.text}
			</Typography>
		</Box>
	);
};

export default IndexedLabel;
