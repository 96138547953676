import {Localized} from "@fluent/react";
import {Grid, MenuItem, TextField} from "@material-ui/core";
import React from "react";

import type {
	AssessmentStatus,
	StudentResultsSearchCriteria,
} from "../../store/services/analyticsService";
import SearchToolbar from "../../utils/SearchToolbar";

type ChangeStudentResultsSearchCriteria = (
	value:
		| StudentResultsSearchCriteria
		| ((
				prevState: StudentResultsSearchCriteria
		  ) => StudentResultsSearchCriteria)
) => void;

const StudentResultsTableToolbar = (props: {
	onCriteriaChange: ChangeStudentResultsSearchCriteria;
	criteria: StudentResultsSearchCriteria;
}): JSX.Element => {
	const {criteria, onCriteriaChange} = props;

	return (
		<SearchToolbar criteria={criteria} onCriteriaChange={onCriteriaChange}>
			<CriteriaPanel criteria={criteria} onCriteriaChange={onCriteriaChange} />
		</SearchToolbar>
	);
};

function CriteriaPanel(props: {
	criteria: StudentResultsSearchCriteria;
	onCriteriaChange: ChangeStudentResultsSearchCriteria;
}) {
	const {criteria, onCriteriaChange} = props;

	return (
		<Grid container>
			<Grid item xs={12}>
				<TextField
					select
					fullWidth
					label={
						<Localized id="follow-up-student-results-widget-assessment-status-filter">
							Assessment status
						</Localized>
					}
					value={criteria.assessmentStatus ?? ""}
					onChange={({target}) =>
						onCriteriaChange((prev) => ({
							...prev,
							assessmentStatus: target.value as AssessmentStatus,
						}))
					}
				>
					<MenuItem value="">
						<em>
							<Localized id="follow-up-student-results-widget-assessment-status-filter-not-specified">
								Not specified
							</Localized>
						</em>
					</MenuItem>
					<MenuItem value="assessed">
						<Localized id="follow-up-student-results-widget-assessment-status-filter-assessed">
							Assessed
						</Localized>
					</MenuItem>
					<MenuItem value="not_assessed">
						<Localized id="follow-up-student-results-widget-assessment-status-filter-not-assessed">
							Not assessed
						</Localized>
					</MenuItem>
				</TextField>
			</Grid>
		</Grid>
	);
}

export default StudentResultsTableToolbar;
