import {Localized} from "@fluent/react";
import {
	Box,
	Dialog,
	DialogTitle,
	IconButton,
	Typography,
} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

import ChooseExerciseDialogContent from "./ChooseExerciseDialogContent";

const useStyles = makeStyles(() =>
	createStyles({
		dialogPaper: {minHeight: "90vh", maxHeight: "90vh"},
	})
);

const ChooseExerciseDialog = (props: {
	open: boolean;
	courseId: number;
	mobileMode?: boolean;
	onClose: (selectedExerciseKey?: string) => void;
}): JSX.Element => {
	const classes = useStyles();

	const close = () => {
		props.onClose();
	};

	return (
		<Dialog
			open={props.open}
			onClose={close}
			maxWidth="xs"
			aria-labelledby="choose-exercise-dialogue-title"
			classes={{paper: classes.dialogPaper}}
			fullWidth
			fullScreen={props.mobileMode}
		>
			<DialogTitle id="choose-exercise-dialogue-title">
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6" component="span">
						<Localized id="tutoring-new-message-dialog-title">
							Choose a subject
						</Localized>
					</Typography>
					<IconButton onClick={close}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<ChooseExerciseDialogContent
				courseId={props.courseId}
				onExerciseSelected={props.onClose}
			/>
		</Dialog>
	);
};

export default ChooseExerciseDialog;
