import {CssBaseline} from "@material-ui/core";
import {ThemeProvider, createTheme} from "@material-ui/core/styles";
import React, {useEffect, useMemo} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import "@digabi/mathquill/build/mathquill.css";
import "rich-text-editor/dist/rich-text-editor.css";

import "./index.css";

import Account from "./components/account/Account";
import Login from "./components/login/Login";
import PasswordReset from "./components/login/PasswordReset";
import SignUp from "./components/login/SignUp";
import BackNavProvider from "./components/nav/BackNavProvider";
import Nav from "./components/nav/Nav";
import usePreferredLanguageInApiRequests from "./i18n/usePreferredLanguageInApiRequests";
import defaultLogo from "./images/logo.png";
import GroupApp from "./group/App";
import OrganisationApp from "./organisation/App";
import {useAppDispatch, useAppSelector} from "./store/hooks";
import createOldSiteMessageListener from "./store/oldSiteMessageListener";
import fetchUserOrganisations from "./store/organisation/fetchUserOrganisations";
import selectUserOrganisations from "./store/organisation/selectUserOrganisations";
import fetchTheme from "./store/theme/fetchTheme";
import Snackbar from "./store/ui/Snackbar";
import {selectUserId} from "./store/userProfile/selectUserProfile";
import LogOutOnUnauthorisedError from "./store/userSession/LogOutOnUnauthorisedError";
import refreshUserSession from "./store/userSession/refreshUserSession";
import selectLoggedIn from "./store/userSession/selectLoggedIn";
import {selectUserSessionFetchStatus} from "./store/userSession/selectSession";
import UserSessionExpirationReminder from "./store/userSession/UserSessionExpirationReminder";
import Condition from "./utils/Condition";

function createAppTheme(primary: string, secondary: string) {
	return createTheme({
		mixins: {
			toolbar: {
				minHeight: "48px",
			},
		},
		palette: {
			primary: {
				main: primary,
				contrastText: "#FFFFFF",
			},
			secondary: {
				main: secondary,
				contrastText: "#FFFFFF",
			},
			text: {
				primary: "#1E2021",
				secondary: "#66757A",
				disabled: "#BFBFBF",
			},
			error: {
				main: "#FF0C3E",
			},
			success: {
				main: "#4caf50",
			},
		},
		typography: {
			overline: {
				fontSize: "0.625rem",
				letterSpacing: "0.09375rem",
			},
		},
		zIndex: {
			drawer: 1150,
		},
	});
}

const defaultTheme = createAppTheme("#2196F3", "#8BC34A");

type ResourceType = "organisation" | "group" | "";

function determineBasePath(): [string, string, ResourceType] {
	let base = "/";
	let resourceName = "";
	let resourceType: ResourceType = "";

	const match = window.location.pathname.match(
		/^\/(organisations|organisation-groups)\/([^/]+)/
	);

	if (match) {
		base = match[0];
		resourceType = match[1] === "organisations" ? "organisation" : "group";
		resourceName = decodeURIComponent(match[2]);
	}

	return [base, resourceName, resourceType];
}

function App(): JSX.Element {
	const [basename, resourceName, resourceType] = useMemo(determineBasePath, []);

	usePreferredLanguageInApiRequests();

	const dispatch = useAppDispatch();

	const organisationTheme = useAppSelector((state) => state.theme);

	const theme = useMemo(() => {
		return organisationTheme.enabled
			? createAppTheme(organisationTheme.primary, organisationTheme.secondary)
			: defaultTheme;
	}, [organisationTheme]);

	useEffect(() => {
		if (resourceName) {
			dispatch(fetchTheme({organisationName: resourceName}));
		}
	}, [dispatch, resourceName]);

	useEffect(() => {
		const listener = createOldSiteMessageListener(dispatch);

		window.addEventListener("message", listener);

		return () => {
			window.removeEventListener("message", listener);
		};
	}, [dispatch]);

	const logo = resourceName
		? `/api/organisations/${resourceName}/theme/logo`
		: defaultLogo;

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter basename={basename}>
				<BackNavProvider>
					<CssBaseline />

					<Switch>
						<Route path={"/login"} exact>
							<Login
								organisationName={resourceName}
								logo={logo}
								signUpAvailable={resourceType === "organisation"}
							/>
						</Route>

						{resourceType === "organisation" && (
							<Route path={"/sign-up"} exact>
								<SignUp organisationName={resourceName} logo={logo} />
							</Route>
						)}

						{false && (
							<Route path={"/password-reset"} exact>
								<PasswordReset />
							</Route>
						)}

						<Route>
							<NonAnonymousRoutes organisationName={resourceName}>
								{!resourceType && (
									<>
										<Nav logo={logo}></Nav>
										<Switch>
											<Route path="/account">
												<Account />
											</Route>
											<Route>
												<RedirectToFirstOrganisation />
											</Route>
										</Switch>
									</>
								)}
								{resourceType === "organisation" && (
									<OrganisationApp organisationName={resourceName} />
								)}
								{resourceType === "group" && (
									<GroupApp groupName={resourceName} />
								)}
							</NonAnonymousRoutes>
						</Route>
					</Switch>

					<Snackbar />
				</BackNavProvider>
			</BrowserRouter>
		</ThemeProvider>
	);
}

function NonAnonymousRoutes(props: {
	children: React.ReactNode;
	organisationName: string;
}) {
	const userSessionFetchStatus = useAppSelector(selectUserSessionFetchStatus);
	const loggedIn = useAppSelector(selectLoggedIn);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (userSessionFetchStatus === "none") {
			dispatch(refreshUserSession());
		}
	}, [dispatch, userSessionFetchStatus]);

	const awaitingSession =
		userSessionFetchStatus === "none" || userSessionFetchStatus === "pending";

	return (
		<>
			<LogOutOnUnauthorisedError />

			<Route
				render={({location}) => (
					<Condition
						value={loggedIn}
						pending={awaitingSession && !loggedIn}
						otherwise={
							<Redirect
								to={{
									pathname: "/login",
									state: {from: location},
								}}
							/>
						}
					>
						<UserSessionExpirationReminder />

						{props.children}
					</Condition>
				)}
			/>
		</>
	);
}

function RedirectToFirstOrganisation() {
	const userId = useAppSelector(selectUserId);

	const organisations = useAppSelector(selectUserOrganisations);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (userId) {
			dispatch(fetchUserOrganisations(userId));
		}
	}, [userId, dispatch]);

	if (organisations.length > 0) {
		window.location.replace(`/organisations/${organisations[0].name}`);
	}

	return <></>;
}

export default App;
