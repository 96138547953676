import {Localized, useLocalization} from "@fluent/react";
import {Grid, TextField} from "@material-ui/core";
import type {ReactNode} from "react";
import React, {useEffect, useRef, useState} from "react";

import ConfirmResponseDialog from "./ConfirmResponseDialog";
import AdditionalActions from "../AdditionalActions";
import ExerciseExpandablePanel from "../ExerciseExpandablePanel";
import SubmitResultCard from "../SubmitResultCard";
import createSubmitSummary from "../createSubmitSummary";
import {OpenExerciseSubtype} from "../../../../store/exercises/ExerciseSubtype";
import ExerciseType from "../../../../store/exercises/ExerciseType";
import type {
	OpenResponse,
	ResponseToSave,
	ResponseToSubmit,
} from "../../../../store/studentResponses/Response";
import type SubmissionResult from "../../../../store/studentResponses/SubmissionResult";
import SaveButton from "../../../../utils/SaveButton";
import SubmitButton from "../../../../utils/SubmitButton";
import TextEditorWithAttachments from "../../../../utils/TextEditor/TextEditorWithAttachments";
import type FileUploader from "../../../../utils/attachments/FileUploader";
import useFileUploader from "../../../../utils/attachments/useFileUploader";
import makeResponse from "./makeResponse";

type Props = {
	subtype: OpenExerciseSubtype;
	response: Readonly<OpenResponse> | null;

	readonly?: boolean;
	submissionDisabled?: boolean;
	submitting: boolean;
	emptyResponseAllowed: boolean;
	selfAssessment: boolean;

	additionalActions?: ReactNode | ReactNode[];

	userId?: number;
	courseId: number;
	chapterId?: number;
	exerciseId: number;

	onSave: (
		response: ResponseToSave,
		successMessage: string
	) => Promise<unknown>;
	onSubmit?: (response: ResponseToSubmit) => Promise<SubmissionResult>;

	createFileUploader: () => FileUploader | null;
};

const emptyResponse: {[key in OpenExerciseSubtype]: Readonly<OpenResponse>} = {
	[OpenExerciseSubtype.Ordinary]: {
		exerciseType: ExerciseType.Open,
		exerciseSubtype: OpenExerciseSubtype.Ordinary,
		solution: "",
	},
	[OpenExerciseSubtype.AutoAssessed]: {
		exerciseType: ExerciseType.Open,
		exerciseSubtype: OpenExerciseSubtype.AutoAssessed,
		solution: "",
		finalAnswer: "",
	},
};

const ResponseArea = (props: Props): JSX.Element => {
	const {userId, courseId, chapterId, exerciseId, onSubmit} = props;

	const {l10n} = useLocalization();

	const [solution, setSolution] = useState("");
	const [finalAnswer, setFinalAnswer] = useState("");

	const initialValue = useRef("");
	const saveRequested = useRef(false);

	const [
		submissionResult,
		setSubmissionResult,
	] = useState<SubmissionResult | null>(null);

	const [saved, setSaved] = useState(true);
	const [openConfirmResponse, setOpenConfirmResponse] = useState(false);

	const selfAssessment =
		props.selfAssessment && props.subtype === OpenExerciseSubtype.Ordinary;

	useEffect(() => {
		const response = props.response ?? emptyResponse[props.subtype];

		setSolution(response.solution);

		if (!saveRequested.current) {
			initialValue.current = response.solution;
		}

		if ("finalAnswer" in response) {
			setFinalAnswer(response.finalAnswer);
		}

		if (!props.response) {
			setSubmissionResult(null);
		}
	}, [props.response, props.subtype]);

	const [files, fileUploader] = useFileUploader(props.createFileUploader);

	const solutionChangeHandler = (value: string) => {
		setSolution(value);

		if (saved && value !== solution) {
			setSaved(false);
		}
	};

	const finalAnswerChangeHandler = (value: string) => {
		setFinalAnswer(value);

		if (saved) {
			setSaved(false);
		}
	};

	const save = async () => {
		saveRequested.current = true;

		await props.onSave(
			makeResponse(props.subtype, solution, finalAnswer),
			"Saved"
		);

		setSaved(true);
	};

	const submit =
		onSubmit &&
		(async (eventId?: number) => {
			let response: ResponseToSubmit = makeResponse(
				props.subtype,
				solution,
				finalAnswer
			);

			if (
				response.exerciseSubtype === OpenExerciseSubtype.Ordinary &&
				eventId
			) {
				response = {...response, eventId};
			}

			const result = await onSubmit(response);
			if (!result) {
				return;
			}

			setSubmissionResult(result);

			if (result.selfAssessmentId) {
				setOpenConfirmResponse(true);
			}
		});

	const readonly = props.readonly || props.submitting;
	const submissionDisabled = props.submissionDisabled || props.submitting;

	const finalAnswerIsVisible =
		props.subtype === OpenExerciseSubtype.AutoAssessed;

	const submitSummary =
		submissionResult && createSubmitSummary(l10n, submissionResult);

	return (
		<>
			<ExerciseExpandablePanel
				lazyLoading
				summary={
					<Localized id="content-exercise-do">Do the exercise</Localized>
				}
			>
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<AdditionalActions>{props.additionalActions}</AdditionalActions>
					</Grid>

					<Grid item>
						<TextEditorWithAttachments
							initialValue={initialValue.current}
							value={solution}
							onChange={solutionChangeHandler}
							readonly={readonly}
							fileUploader={fileUploader ?? undefined}
							files={files}
						/>
					</Grid>

					{finalAnswerIsVisible && (
						<Grid item>
							<TextField
								fullWidth
								value={finalAnswer}
								id="final-answer"
								label="Final answer"
								disabled={readonly}
								onChange={(e) => finalAnswerChangeHandler(e.target.value)}
							/>
						</Grid>
					)}

					{submitSummary && (
						<Grid item>
							<SubmitResultCard
								success={submitSummary.success}
								text={submitSummary.text}
							/>
						</Grid>
					)}

					{!(submitSummary && submitSummary.success) && (
						<Grid
							item
							container
							justifyContent="flex-end"
							spacing={1}
							alignContent="center"
						>
							<Grid item>
								<SaveButton disabled={saved || readonly} onClick={save} />
							</Grid>

							{submit && (
								<Grid item>
									<SubmitButton
										disabled={
											submissionDisabled ||
											(!props.emptyResponseAllowed &&
												(solution.length === 0 ||
													(finalAnswerIsVisible && finalAnswer.length === 0)))
										}
										onClick={() => submit()}
										inProgress={props.submitting}
									/>
								</Grid>
							)}
						</Grid>
					)}
				</Grid>
			</ExerciseExpandablePanel>

			{selfAssessment && userId && chapterId && (
				<ConfirmResponseDialog
					open={openConfirmResponse}
					studentResponse={solution}
					exerciseId={exerciseId}
					studentId={userId}
					onCancel={() => {
						setOpenConfirmResponse(false);
					}}
					onConfirm={() => {
						setOpenConfirmResponse(false);
						submit && submit(submissionResult?.selfAssessmentId);
					}}
					courseId={courseId}
					chapterId={chapterId}
				/>
			)}
		</>
	);
};

export default ResponseArea;
