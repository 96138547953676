import {Localized, useLocalization} from "@fluent/react";
import DoneIcon from "@mui/icons-material/Done";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WarningIcon from "@mui/icons-material/Warning";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useRef, useState} from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";

import useNavBarHeight from "../../hooks/useNavBarHeight";
import enrolInCourse from "../../store/enrolment/enrolInCourse";
import type EnrolmentApplicationStatus from "../../store/enrolment/EnrolmentApplicationStatus";
import fetchCoursesAvailableForEnrolment from "../../store/enrolment/fetchCoursesAvailableForEnrolment";
import selectCoursesAvailableForEnrolment, {
	selectCoursesAvailableForEnrolmentFetchStatus,
} from "../../store/enrolment/selectCoursesAvailableForEnrolment";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import type UserCourse from "../../store/models/UserCourse";
import selectUserOrganisations, {
	selectUserOrganisationsFetchStatus,
} from "../../store/organisation/selectUserOrganisations";
import type User from "../../store/services/dtos/User";
import {userService} from "../../store/services/userService";
import useSnackbar from "../../store/ui/useSnackbar";
import selectUserProfile from "../../store/userProfile/selectUserProfile";
import LoadingError from "../../utils/errors/LoadingError";
import PageContainer from "../../utils/PageContainer";
import SubmitButton from "../../utils/SubmitButton";
import fetchUserOrganisations from "../../store/organisation/fetchUserOrganisations";

const EnrolmentApplicationForm = (props: {
	organisationName: string;
	userId: number;
}): JSX.Element => {
	const {userId, organisationName} = props;

	const location = useLocation();

	const [selectedCourse, setSelectedCourse] = useState<UserCourse | null>(null);

	const [emptyField, setEmptyField] = useState<{
		course?: boolean;
		password?: boolean;
	}>({});

	const [applying, setApplying] = useState(false);
	const [
		enrolmentStatus,
		setEnrolmentStatus,
	] = useState<EnrolmentApplicationStatus>();

	const {l10n} = useLocalization();
	const showSnackbar = useSnackbar();
	const theme = useTheme();

	const dispatch = useAppDispatch();

	const availableCourses = useAppSelector((state) =>
		selectCoursesAvailableForEnrolment(state, userId)
	);
	const coursesFetchStatus = useAppSelector((state) =>
		selectCoursesAvailableForEnrolmentFetchStatus(state, userId)
	);

	const user = useAppSelector(selectUserProfile);
	const orgs = useAppSelector(selectUserOrganisations);
	const orgsFetchStatus = useAppSelector(selectUserOrganisationsFetchStatus);
	const [fullProfile, setFullProfile] = useState<User | null>(null);

	const password = useRef<HTMLInputElement>();
	const [showPassword, setShowPassword] = useState(false);

	const userNotInOrg =
		orgsFetchStatus === "succeeded" &&
		orgs.every((o) => o.name !== organisationName);

	useEffect(() => {
		if (userId && userNotInOrg) {
			userService.getUserProfile(userId).then((res) => {
				setFullProfile(res);
			});
		}
	}, [userId, userNotInOrg]);

	useEffect(() => {
		if (coursesFetchStatus === "none" && userId) {
			dispatch(
				fetchCoursesAvailableForEnrolment({orgName: organisationName, userId})
			);
		}
	}, [coursesFetchStatus, dispatch, organisationName, userId]);

	useEffect(() => {
		const courseIdQuery = new URLSearchParams(location.search).get("courseId");

		let courseId = 0;
		if (courseIdQuery) {
			courseId = Number(courseIdQuery);
		}

		if (courseId) {
			const course = availableCourses.find((c) => c.id === courseId);
			if (course) {
				setSelectedCourse(course);
			}
		}
	}, [availableCourses, location.search]);

	function validate() {
		const emptyCourse = !selectedCourse;
		const emptyPassword = userNotInOrg && password.current?.value.length === 0;

		setEmptyField({course: emptyCourse, password: emptyPassword});

		return !emptyCourse && !emptyPassword;
	}

	async function apply() {
		if (!validate()) {
			return;
		}

		try {
			setApplying(true);

			const res = await dispatch(
				enrolInCourse({
					courseId: selectedCourse?.id ?? 0,
					studentId: userId,
					password: password.current?.value,
				})
			);

			setEnrolmentStatus(unwrapResult(res).status);

			if (userNotInOrg) {
				dispatch(fetchUserOrganisations(userId));
			}
		} catch (err) {
			showSnackbar(
				"error",
				(err as {code: string}).code === "forbidden"
					? l10n.getString(
							"enrolment-application-error-incorrect-password",
							null,
							"Incorrect password"
					  )
					: l10n.getString("error-general")
			);
		} finally {
			setApplying(false);
		}
	}

	if (enrolmentStatus && selectedCourse) {
		return <Success status={enrolmentStatus} courseId={selectedCourse.id} />;
	}

	if (coursesFetchStatus === "none" || coursesFetchStatus === "pending") {
		return <Loading />;
	}

	if (coursesFetchStatus === "succeeded" && availableCourses.length === 0) {
		return <EmptyState />;
	}

	if (coursesFetchStatus === "failed") {
		return (
			<LoadingError
				description={
					<Localized id="enrolment-application-course-loading-error-description">
						Failed to load courses available for enrolment
					</Localized>
				}
				onReload={() => {
					dispatch(
						fetchCoursesAvailableForEnrolment({
							orgName: organisationName,
							userId,
						})
					);
				}}
			/>
		);
	}

	const enrolmentImpossible =
		userNotInOrg &&
		Boolean(fullProfile) &&
		(!fullProfile?.passwordSet ||
			!fullProfile.email ||
			!fullProfile.firstName ||
			!fullProfile.lastName);

	return (
		<Container maxWidth="md" style={{paddingBottom: theme.spacing(3)}}>
			<Box mt={2} mb={2}>
				<Typography variant="h6">
					<Localized id="enrolment-application-form-title">
						Enrolment application
					</Localized>
				</Typography>
			</Box>
			{enrolmentImpossible && (
				<Paper style={{marginBottom: theme.spacing(2)}}>
					<Box
						display="flex"
						alignItems="center"
						p={2}
						style={{gap: theme.spacing(2)}}
					>
						<WarningIcon style={{color: theme.palette.warning.main}} />
						<Typography align="justify">
							{!fullProfile?.passwordSet && fullProfile?.email && (
								<Localized id="enrolment-application-warning-no-password">
									Unfortunately, it is impossible to enrol in a course in
									another organisation using this account because it has no
									password. You can go to Account page to set a password.
								</Localized>
							)}
							{!fullProfile?.email && fullProfile?.passwordSet && (
								<Localized id="enrolment-application-warning-no-email">
									Unfortunately, it is impossible to enrol in a course in
									another organisation using this account because it has no
									email address. You can go to Account page to specify your
									email address.
								</Localized>
							)}
							{!fullProfile?.email && !fullProfile?.passwordSet && (
								<Localized id="enrolment-application-warning-no-email-and-no-password">
									Unfortunately, it is impossible to enrol in a course in
									another organisation using this account.
								</Localized>
							)}
							{fullProfile?.email &&
								fullProfile.passwordSet &&
								(!fullProfile?.firstName || !fullProfile?.lastName) && (
									<Localized id="enrolment-application-warning-no-first-or-last-name">
										Unfortunately, it is impossible to enrol in a course in
										another organisation using this account because it has no
										first or last name. You can go to Account page to to fill in
										missing information.
									</Localized>
								)}
						</Typography>
					</Box>
				</Paper>
			)}
			<Grid container spacing={2} component="form">
				<Grid item xs={12}>
					<Autocomplete
						fullWidth
						id="courses"
						options={applying ? [] : availableCourses}
						getOptionLabel={(option) => option.name}
						value={selectedCourse}
						onChange={(_, newValue) => {
							setEmptyField((prev) => ({...prev, course: false}));
							setSelectedCourse(newValue);
						}}
						renderInput={(params) => (
							<TextField
								required
								{...params}
								helperText={
									<Localized id="enrolment-application-course-selector-helper-text">
										A course in which you want to enrol
									</Localized>
								}
								error={emptyField.course}
								label={
									<Localized id="enrolment-application-course-selector-label">
										Course
									</Localized>
								}
							/>
						)}
					/>
				</Grid>

				{userNotInOrg && (
					<>
						<Grid item xs={12}>
							<TextField
								label={
									<Localized id="enrolment-application-username-label">
										Username
									</Localized>
								}
								required
								value={user.userName}
								fullWidth
								disabled
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label={
									<Localized id="enrolment-application-first-name-label">
										First name
									</Localized>
								}
								required
								value={user.firstName}
								fullWidth
								disabled
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label={
									<Localized id="enrolment-application-last-name-label">
										Last name
									</Localized>
								}
								required
								value={user.lastName}
								fullWidth
								disabled
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label={
									<Localized id="enrolment-application-email-label">
										Email address
									</Localized>
								}
								required
								value={fullProfile?.email ?? ""}
								fullWidth
								disabled
							/>
						</Grid>

						<Grid item xs={12} style={{marginTop: theme.spacing(2)}}>
							<Localized
								id="enrolment-application-notice-enrolment-in-another-org"
								elems={{
									paragraph: (
										<Typography gutterBottom align="justify"></Typography>
									),
								}}
							>
								<>
									You are applying to enrol to the course in the other
									organisation. After application sending, your personal
									information will be available to the staff of that
									organisation. Please, ensure that you have access to the email
									address specified: after applying, neither administrators of
									the new organisation nor of the old ones will not be able to
									change your credentials, including email address and password.
									Only you will be able to manage your credentials, and access
									to the email address is required to avoid loosing access to
									the Viope account. Optionally, you can log out and create a
									separate account using a different email address to enrol to
									the course.
								</>
							</Localized>
						</Grid>

						<Grid item xs={12}>
							<Typography>
								<Localized id="enrolment-application-confirmation-request">
									Please, confirm the action with your password.
								</Localized>
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<input
								type="text"
								autoComplete="username"
								style={{display: "none"}}
							/>
							<TextField
								label={
									<Localized id="enrolment-application-password-label">
										Password
									</Localized>
								}
								required
								fullWidth
								inputRef={password}
								error={emptyField.password}
								type={showPassword ? "text" : "password"}
								autoComplete="password"
								disabled={enrolmentImpossible}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												disabled={enrolmentImpossible}
												onClick={() => setShowPassword((prev) => !prev)}
											>
												{showPassword ? (
													<VisibilityOffIcon />
												) : (
													<VisibilityIcon />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
								onChange={() =>
									setEmptyField((prev) => ({...prev, password: false}))
								}
							/>
						</Grid>
					</>
				)}

				<Grid item xs={12}>
					<Box display="flex" justifyContent="flex-end" mt={2}>
						<SubmitButton
							onClick={apply}
							inProgress={applying}
							disabled={enrolmentImpossible}
						>
							<Localized id="enrolment-application-apply-btn">Apply</Localized>
						</SubmitButton>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};

function EmptyState() {
	const navBarHeight = useNavBarHeight();

	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: `calc(100vh - ${navBarHeight}px)`,
			}}
		>
			<EventBusyIcon style={{height: "50px", width: "50px"}} color="primary" />
			<Typography variant="h6">
				<Localized id="enrolment-application-empty-state-message">
					No courses available for enrolment
				</Localized>
			</Typography>
		</Container>
	);
}

function Success(props: {
	status: EnrolmentApplicationStatus;
	courseId: number;
}) {
	const theme = useTheme();

	return (
		<PageContainer>
			<DoneIcon style={{height: "50px", width: "50px"}} color="primary" />

			<Typography variant="h6">
				{props.status === "accepted" ? (
					<Localized id="enrolment-application-success-message">
						Success
					</Localized>
				) : (
					<Localized id="enrolment-application-applied-message">
						Application has been sent
					</Localized>
				)}
			</Typography>

			<Typography>
				{props.status === "accepted" ? (
					<Localized id="enrolment-application-success-message-description">
						You have enrolled in the course
					</Localized>
				) : (
					<Localized id="enrolment-application-applied-message-description">
						You will receive a notification when a teacher will approve it
					</Localized>
				)}
			</Typography>

			<Box
				display="flex"
				style={{gap: theme.spacing(1), marginTop: theme.spacing(2)}}
			>
				<Button component={RouterLink} to="/" color="primary">
					<Localized id="enrolment-application-go-to-dashboard-btn">
						Go to dashboard
					</Localized>
				</Button>
				{props.status === "accepted" && (
					<Button
						component={RouterLink}
						to={`/courses/${props.courseId}`}
						color="primary"
						variant="contained"
					>
						<Localized id="enrolment-application-open-course-btn">
							Open course
						</Localized>
					</Button>
				)}
			</Box>
		</PageContainer>
	);
}

function Loading() {
	return (
		<PageContainer>
			<CircularProgress color="primary" />
		</PageContainer>
	);
}

export default EnrolmentApplicationForm;
