import {Localized, useLocalization} from "@fluent/react";
import {
	Card,
	CardContent,
	CardMedia,
	Grid,
	Typography,
	useTheme,
} from "@material-ui/core";
import React from "react";

import parseDate from "../../helpers/parseDate";
import useDateFormat from "../../i18n/useDateFormat";
import selectCoursesAvailableForEnrolment from "../../store/enrolment/selectCoursesAvailableForEnrolment";
import {useAppSelector} from "../../store/hooks";
import AvailableForEnrolmentCourseCard from "./AvailableForEnrolmentCourseCard";
import EmptyState from "./EmptyState";
import type {StudentCardProps} from "./StudentCardDashboard";

function EnrolmentCards(props: {userId: number}): JSX.Element {
	const enrolmentApplications = useAppSelector(
		(state) => state.studentdashboard.enrolmentApplications
	);

	const toEnrol = useAppSelector((state) =>
		selectCoursesAvailableForEnrolment(state, props.userId)
	);

	const {l10n} = useLocalization();

	const theme = useTheme();

	if (enrolmentApplications.length === 0 && toEnrol.length === 0) {
		return (
			<Grid item xs={12}>
				<EmptyState
					message={l10n.getString(
						"dashboard-no-courses-to-enrol",
						null,
						"There are no courses available for enrolment"
					)}
				/>
			</Grid>
		);
	}

	return (
		<>
			{enrolmentApplications.length > 0 && (
				<>
					<Grid item xs={12}>
						<Typography variant="h6">
							<Localized id="dashboard-student-pending-applications-title">
								Pending applications
							</Localized>
						</Typography>
					</Grid>
					{enrolmentApplications.map((course) => (
						<Grid item xs={12} sm={6} md={4} xl={3} key={course.id}>
							<PendingCard
								title={course.name}
								startDate={course.startDate}
								endDate={course.endDate}
								id={course.id}
								image={course.picture || course.defaultPicture}
							/>
						</Grid>
					))}

					{toEnrol.length > 0 && (
						<Grid item xs={12} style={{marginTop: theme.spacing(4)}}>
							<Typography variant="h6">
								<Localized id="dashboard-student-available-for-enrolment-title">
									Available for enrolment
								</Localized>
							</Typography>
						</Grid>
					)}
				</>
			)}

			{toEnrol.length > 0 && (
				<>
					{toEnrol.map((course) => (
						<Grid item xs={12} sm={6} md={4} xl={3} key={course.id}>
							<AvailableForEnrolmentCourseCard
								title={course.name}
								startDate={course.startDate}
								endDate={course.endDate}
								id={course.id}
								image={course.picture || course.defaultPicture}
							/>
						</Grid>
					))}
				</>
			)}
		</>
	);
}

function PendingCard(props: StudentCardProps) {
	const formatDate = useDateFormat();

	return (
		<Card>
			<CardMedia component="img" alt="" height="136" image={props.image} />
			<CardContent>
				<Typography
					variant="subtitle1"
					component="h2"
					gutterBottom
					style={{fontWeight: 500}}
				>
					{props.title}
				</Typography>
				<Typography color="textSecondary" variant="body2">
					{`${formatDate(parseDate(props.startDate))} - ${formatDate(
						parseDate(props.endDate)
					)}`}
				</Typography>
			</CardContent>
		</Card>
	);
}

export default EnrolmentCards;
