import React from "react";
import {Typography} from "@material-ui/core";
import {ExerciseWithContent} from "../../store/exercises/Exercise";
import ExerciseType from "../../store/exercises/ExerciseType";
import ExternalResponseArea from "../content/exercises/external/ResponseArea";
import MultiResponseAreaSelector from "../content/exercises/multi/MultiResponseAreaSelector";
import OpenResponseArea from "../content/exercises/open/ResponseArea";
import MathSolutionForm from "../content/exercises/math/MathSolutionForm";
import ProgResponseArea from "../content/exercises/prog/ResponseArea";
import ExerciseQuestion from "../content/exercises/ExerciseQuestion";
import Feedback from "../../store/studentResponses/Feedback";
import SubmissionResult from "../../store/studentResponses/SubmissionResult";

type ExercisePreviewProps = {
	courseId: number;
	exercise: ExerciseWithContent;
};

const ExercisePreview = (props: ExercisePreviewProps): JSX.Element => {
	const {courseId, exercise} = props;

	const responseAreaProps = {
		response: null,
		readonly: true,
		submitting: false,
		onSave: save,
		onSubmit: submit,
	};

	let responseArea = null;

	switch (exercise.type) {
		case ExerciseType.Multi:
			responseArea = (
				<MultiResponseAreaSelector
					id={`response-area-${exercise.id}`}
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Open:
			responseArea = (
				<OpenResponseArea
					subtype={exercise.subtype}
					emptyResponseAllowed={false}
					selfAssessment={false}
					courseId={courseId}
					exerciseId={exercise.id}
					createFileUploader={createFileUploader}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Math:
			responseArea = (
				<MathSolutionForm
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Prog:
			responseArea = (
				<ProgResponseArea
					interactions={exercise.interactions}
					openConfirmDialog={noop}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.External:
			responseArea = (
				<ExternalResponseArea
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					onPostOutcomes={postOutcomes}
					{...responseAreaProps}
				/>
			);

			break;
	}

	return (
		<>
			<Typography variant="h5">{exercise.title}</Typography>

			<ExerciseQuestion text={exercise.question} />

			{responseArea}
		</>
	);
};

export default ExercisePreview;

function noop() {
	return;
}

function save(): Promise<Feedback> {
	throw new Error("This function should not be called.");
}

function submit(): Promise<SubmissionResult> {
	throw new Error("This function should not be called.");
}

function createFileUploader(): null {
	return null;
}

async function postOutcomes(): Promise<void> {
	return;
}
