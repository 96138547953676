import {useMediaQuery, useTheme} from "@material-ui/core";
import type {Breakpoint} from "@material-ui/core/styles/createBreakpoints";

function useMobileMode(mobileModeBreakpoint: Breakpoint): boolean {
	const theme = useTheme();

	const mobileMode = useMediaQuery(
		theme.breakpoints.down(mobileModeBreakpoint)
	);

	return mobileMode;
}

export default useMobileMode;
