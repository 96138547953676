import {Localized, useLocalization} from "@fluent/react";
import {Box, Grid, TextField, Typography} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {unwrapResult} from "@reduxjs/toolkit";

import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {saveTheoryDraft} from "../../store/exercises/saveTheoryDraft";
import useExerciseFileUploader from "./useExerciseFileUploader";
import {editingEnded} from "../../store/exercises/exericseDraftSlice";
import ContentEditorFooter from "./ContentEditorFooter";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import selectExerciseDraft from "../../store/exercises/selectExerciseDraft";
import useSnackbar from "../../store/ui/useSnackbar";

type Props = {
	courseId: number;
	chapterId: number;
	sectionId: number;
	onClose: (updated: boolean) => void;
};

const TheoryExerciseEditor = (props: Props): JSX.Element => {
	const {courseId, chapterId, sectionId} = props;
	const dispatch = useAppDispatch();

	const userId = useAppSelector(selectUserId);

	const draft = useAppSelector(selectExerciseDraft);

	const {l10n} = useLocalization();
	const [title, setTitle] = useState(
		draft?.title ||
			l10n.getString(
				"learning-material-theory-exercise-editor-section-new-text-block-title"
			)
	);

	const content = useRef<TextEditorApi | null>(null);
	const initialContent = useRef(draft?.content ?? "");

	const [editingStarted, setEditingStarted] = useState(!draft?.id);

	const updated = useRef(false);

	const showSnackbar = useSnackbar();

	useEffect(() => {
		return () => {
			dispatch(editingEnded());
		};
	}, [dispatch]);

	const [files, fileUploader] = useExerciseFileUploader(
		userId,
		courseId,
		draft?.id ?? 0
	);

	const saveDisabled = !editingStarted || title === "";

	const handleDraftSave = async () => {
		const res = await dispatch(
			saveTheoryDraft({
				courseId,
				chapterId,
				sectionId,
				exerciseItem: {
					title: title,
					content: content.current?.getContent() ?? "",
				},
			})
		);

		try {
			unwrapResult(res);

			setEditingStarted(false);
			updated.current = true;

			return true;
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}
	};

	const handleDraftDone = async () => {
		const saved = await handleDraftSave();
		if (saved) {
			props.onClose(updated.current);
		}
	};

	const handleTitleUpdate = (newTitle: string) => {
		setTitle(newTitle);
		setEditingStarted(true);
	};

	return (
		<>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<TextField
						style={{display: "flex"}}
						value={title}
						id="exercise title"
						label={
							<Localized id="exercise-editor-title-label">
								Exercise title
							</Localized>
						}
						onChange={(e) => handleTitleUpdate(e.target.value)}
					/>
				</Grid>

				<Grid item>
					<Box mt={2} mb={1}>
						<Typography variant="body2">
							<Localized id="exercise-editor-description-label">
								Description
							</Localized>
						</Typography>
					</Box>

					<TextEditorWithAttachments
						onChange={() => setEditingStarted(true)}
						fileUploader={fileUploader ?? undefined}
						files={files}
						initialValue={initialContent.current}
						ref={content}
					/>
				</Grid>
			</Grid>

			<ContentEditorFooter
				onCancel={() => props.onClose(updated.current)}
				onCompeleted={handleDraftDone}
				onSave={handleDraftSave}
				disabled={saveDisabled}
			/>
		</>
	);
};

export default TheoryExerciseEditor;
