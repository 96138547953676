import type {Theme} from "@material-ui/core";
import {
	Box,
	IconButton,
	Typography,
	createStyles,
	lighten,
	makeStyles,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";

const useAdditionalFileStyles = makeStyles((theme: Theme) =>
	createStyles({
		fileNameBtn: {
			cursor: "pointer",
			"&:hover": {
				color: lighten(theme.palette.primary.main, 0.5),
			},
		},
	})
);

function ProgAdditionalFile(props: {
	file: string;
	onClick?: () => void;
	onDelete: () => void;
}): JSX.Element {
	const classes = useAdditionalFileStyles();
	const {file, onClick, onDelete} = props;
	return (
		<Box display="flex" alignItems="center" pt={1} pb={1}>
			<Typography
				color="primary"
				onClick={onClick}
				className={classes.fileNameBtn}
			>
				{file}
			</Typography>
			<IconButton size="small" onClick={onDelete}>
				<ClearIcon />
			</IconButton>
		</Box>
	);
}

export default ProgAdditionalFile;
