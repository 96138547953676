import {Localized} from "@fluent/react";
import {Box, Paper, Typography} from "@material-ui/core";
import ForumIcon from "@material-ui/icons/Forum";
import React from "react";

const EmptyState = (): JSX.Element => {
	return (
		<Paper variant="outlined" square style={{height: "inherit"}}>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				height="100%"
			>
				<ForumIcon style={{height: "8vh", width: "8vw"}} color="secondary" />
				<Typography variant="h6">
					<Localized id="tutoring-empty-state-choose-message">
						Choose a message
					</Localized>
				</Typography>
			</Box>
		</Paper>
	);
};

export default EmptyState;
