import {Localized} from "@fluent/react";
import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	useMediaQuery,
} from "@material-ui/core";
import {Theme, createStyles, makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router";
import AutoSizer from "react-virtualized-auto-sizer";

import ConfirmCloseDialog from "./ConfirmCloseDialog";
import TableOfExercises from "./TableOfExercises";
import {exerciseTypes} from "../exerciseBank/ExerciseTypeLabel";
import {getSectionPath} from "../../helpers/pathHelpers";
import {getChapterContent} from "../../store/chapters/getChapterContent";
import selectCourse from "../../store/courses/selectCourse";
import ExerciseType from "../../store/exercises/ExerciseType";
import createTagSearchCriteria from "../exerciseBank/createTagSearchCriteria";
import {RequestInPage} from "../../helpers/paginatedSearchHelpers";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {addExercisesToSection} from "../../store/sections/addExercisesToSection";
import {ExerciseFromBank} from "../../store/services/dtos/ExerciseFromBank";
import exerciseService from "../../store/services/exerciseService";
import {closeDialog} from "../../store/ui/modalDialogSlice";
import SortOrder from "../../utils/tables/SortOrder";
import {getSectionContent} from "../../store/sections/getSectionContent";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import {ExerciseSearchScope} from "../exerciseBank/exerciseSearchScope";
import DialogFilters from "./DialogFilters";
import {ExerciseSearchCriteria} from "../exerciseBank/ExerciseSearchCriteria";

const pageSize = 20;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		privacySelector: {
			minWidth: "190px",
			marginRight: theme.spacing(3),
			marginLeft: theme.spacing(4),
		},
		languageSelector: {
			minWidth: "100px",
		},
		typeFilterItem: {
			margin: theme.spacing(1),
		},
		dialogContent: {
			height: "100vh",
			background: theme.palette.background.paper,
			padding: 0,
			overflowY: "hidden",

			"& tr td:first-child": {
				paddingLeft: theme.spacing(2),
			},
		},
		selectedExerciseChip: {
			marginLeft: theme.spacing(1),
			marginTop: theme.spacing(1),

			maxWidth: "25ch",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		},
		addButton: {
			marginLeft: theme.spacing(1),
		},
	})
);

const ExerciseBankDialog = (props: {
	organisationName: string;
	courseId: number;
	sectionId: number;
	chapterId: number;
}): JSX.Element => {
	const classes = useStyles();

	const {courseId, chapterId, sectionId, organisationName} = props;

	const [sortField, setSortField] = useState<keyof ExerciseFromBank>("title");
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

	const [isNextPageLoading, setIsNextPageLoading] = useState(false);

	const [exercises, setExercises] = useState<ExerciseFromBank[]>([]);
	const [
		exercisesPageRequest,
		setExercisesPageRequest,
	] = useState<RequestInPage<ExerciseFromBank> | null>(null);

	const [selectedExercises, setSelectedExercises] = useState<
		{id: number; type: ExerciseType; title: string}[]
	>([]);

	const [confirmOpen, setConfirmOpen] = useState(false);

	const userId = useAppSelector((state) => selectUserId(state));

	const [featureEnabled] = useFeatureEnabled();

	const {core, language, availableExerciseTypes} = useAppSelector((state) =>
		selectCourse(state, keyProvider.course(courseId))
	) || {
		core: "",
		language: "en",
		availableExerciseTypes: [] as ExerciseType[],
	};

	const [searchFilter, setSearchFilter] = useState<ExerciseSearchCriteria>(
		() => ({
			type: null,
			language,
			query: "",
			scope: ExerciseSearchScope.Course,
			tags: [],
		})
	);

	const history = useHistory();

	useEffect(() => {
		if (!core) {
			return;
		}

		exerciseService
			.searchExercises({
				pageSize: pageSize,
				sort: {field: sortField, order: sortOrder},
				core: core,
				language: searchFilter.language,
				exerciseType: searchFilter.type ?? undefined,
				query: searchFilter.query,
				courseId:
					searchFilter.scope === ExerciseSearchScope.Course
						? courseId
						: undefined,
				organisationName:
					searchFilter.scope === ExerciseSearchScope.Organisation
						? organisationName
						: undefined,
				userId:
					searchFilter.scope === ExerciseSearchScope.Personal
						? userId
						: undefined,
				tags: searchFilter.tags,
			})
			.then((response) => {
				setExercisesPageRequest(response.request);
				setExercises(response.content);
			});
	}, [
		core,
		courseId,
		organisationName,
		searchFilter.type,
		searchFilter.language,
		searchFilter.query,
		searchFilter.scope,
		searchFilter.tags,
		sortField,
		sortOrder,
		userId,
	]);

	const searchTags = useCallback(
		async (prefix: string, pageSize: number) => {
			const criteria = createTagSearchCriteria(
				searchFilter.scope,
				courseId,
				userId,
				organisationName
			);

			const page = await exerciseService.searchTags(
				{
					...criteria,
					prefix,
				},
				pageSize
			);

			return page;
		},
		[courseId, organisationName, searchFilter.scope, userId]
	);

	const dispatch = useAppDispatch();

	const smallScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("sm")
	);

	const orderChangeHandler = (
		orderBy: keyof ExerciseFromBank,
		order: SortOrder
	) => {
		setSortOrder(order);
		setSortField(orderBy);
	};

	const exerciseSelectedHandler = (exerciseIndex: number) => {
		const exercise = exercises[exerciseIndex];
		setSelectedExercises((prev) => {
			const existingInd = prev.findIndex((ex) => ex.id === exercise.id);
			if (existingInd >= 0) {
				return prev.slice(0, existingInd).concat(prev.slice(existingInd + 1));
			}
			return prev.concat({
				id: exercise.id,
				type: exercise.type,
				title: exercise.title,
			});
		});
	};

	const deleteSelectedHandler = (exerciseId: number) => {
		setSelectedExercises((prev) => prev.filter((ex) => ex.id !== exerciseId));
	};
	const clearAllSelectedHandler = () => {
		setSelectedExercises([]);
	};
	const addSelectedHandler = async () => {
		await dispatch(
			addExercisesToSection({
				courseId,
				chapterId,
				sectionId,
				exercises: selectedExercises,
			})
		);
		await dispatch(getChapterContent({courseId, chapterId}));
		dispatch(
			getSectionContent({
				courseId,
				sectionKey: keyProvider.section(chapterId, sectionId),
			})
		);

		close();

		moveToSection();
	};

	const loadNextPage = async () => {
		if (!exercisesPageRequest?.next) {
			return;
		}

		setIsNextPageLoading(true);
		const newPage = await exercisesPageRequest.next();
		setIsNextPageLoading(false);

		setExercisesPageRequest(newPage.request);
		setExercises((prev) => prev.concat(newPage.content));
	};

	const closeHandler = () => {
		if (selectedExercises.length) {
			setConfirmOpen(true);
		} else {
			close();
		}
	};

	const leaveDialogConfirmedHandler = () => {
		setConfirmOpen(false);
		close();
	};

	const continueAddingHandler = () => {
		setConfirmOpen(false);
	};

	function close() {
		dispatch(closeDialog());
	}

	function moveToSection() {
		const sectionPath = getSectionPath(
			history.location.pathname,
			props.chapterId,
			props.sectionId
		);

		history.push(sectionPath);
	}

	const clickIconExerciseBank = () => {
		history.push(`/courses/${courseId}/management/exercise-bank`);
		closeHandler();
	};

	function clearFilters() {
		setSearchFilter({
			type: null,
			language,
			query: "",
			scope: ExerciseSearchScope.Course,
			tags: [],
		});
	}

	return (
		<>
			<Dialog
				open
				onClose={closeHandler}
				maxWidth="lg"
				fullWidth
				fullScreen={smallScreen}
			>
				<DialogTitle>
					<Box display="flex" flexGrow={1} justifyContent="space-between">
						<Box display="flex" alignItems="center">
							<Localized id="course-management-subtitlenav-label-exercise-bank">
								Exercise bank
							</Localized>
							<IconButton onClick={clickIconExerciseBank}>
								<CreateIcon fontSize="small" color="action" />
							</IconButton>
						</Box>
						<IconButton onClick={closeHandler}>
							<CloseIcon />
						</IconButton>
					</Box>
					<DialogFilters
						availableExerciseTypes={exerciseTypes.filter(
							(type) =>
								(type.name !== ExerciseType.External ||
									featureEnabled(Feature.ExternalExercises)) &&
								availableExerciseTypes.includes(type.name)
						)}
						tagsHidden={!featureEnabled(Feature.ExerciseTags)}
						onSearchTags={searchTags}
						onClearFilters={clearFilters}
						onFilterChange={setSearchFilter}
						filter={searchFilter}
					/>
				</DialogTitle>
				<DialogContent dividers className={classes.dialogContent}>
					<AutoSizer disableWidth>
						{({height}) => (
							<TableOfExercises
								hasNextPage={Boolean(exercisesPageRequest?.next)}
								isNextPageLoading={isNextPageLoading}
								items={exercises}
								loadNextPage={loadNextPage}
								height={height}
								onOrderChange={orderChangeHandler}
								order={sortOrder}
								orderBy={sortField}
								onExerciseSelected={exerciseSelectedHandler}
								selectedExercises={selectedExercises.map((ex) => ex.id)}
								chapterId={chapterId}
							/>
						)}
					</AutoSizer>
				</DialogContent>
				{selectedExercises.length > 0 && (
					<DialogActions>
						<Box
							display="flex"
							flexDirection="column"
							width="100%"
							ml={1}
							mr={1}
							mb={1}
						>
							<Box
								display="flex"
								justifyContent="flex-start"
								flexWrap="wrap"
								mb={1}
							>
								{selectedExercises.map((ex) => (
									<Chip
										key={ex.id}
										label={ex.title}
										icon={exerciseTypes.find((t) => t.name === ex.type)?.icon}
										onDelete={() => deleteSelectedHandler(ex.id)}
										color="primary"
										className={classes.selectedExerciseChip}
									/>
								))}
							</Box>
							<Box display="flex" justifyContent="flex-end" m={1}>
								<Button color="primary" onClick={clearAllSelectedHandler}>
									<Localized id="exercise-bank-dialog-button-clear-selected">
										Clear selected
									</Localized>
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={addSelectedHandler}
									className={classes.addButton}
								>
									<Localized id="exercise-bank-dialog-button-add-selected">
										Add selected
									</Localized>
								</Button>
							</Box>
						</Box>
					</DialogActions>
				)}
			</Dialog>
			<ConfirmCloseDialog
				open={confirmOpen}
				onLeave={leaveDialogConfirmedHandler}
				onContinue={continueAddingHandler}
			/>
		</>
	);
};

export default ExerciseBankDialog;
