import {
	Box,
	Theme,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, {useState} from "react";
import type {ReactNode} from "react";

import useEnsureChaptersLoaded from "../../hooks/useEnsureChaptersLoaded";
import selectCourseChaptersGroupedByDate from "../../store/chapters/selectCourseChapters";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {OngoingTest, PastTest, UpcomingTest} from "./Test";
import ConfirmChapterSubmissionDialog from "./ConfirmChapterSubmissionDialog";
import DialogResult from "../../utils/DialogResult";
import {createChapterSession} from "../../store/chapterSessions/createChapterSession";
import {useHistory} from "react-router-dom";
import {unwrapResult} from "@reduxjs/toolkit";
import parseDate from "../../helpers/parseDate";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import useSnackbar from "../../store/ui/useSnackbar";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			maxWidth: "2048px",
			padding: theme.spacing(4, 6, 6),
			"& .tests-group__title": {
				marginBottom: theme.spacing(3),
			},
			"& .tests-group:not(:first-child)": {
				marginTop: theme.spacing(4),
			},
		},
	})
);

function TestsGroup(props: {title: string; children: ReactNode[]}) {
	return (
		<div className="tests-group">
			{props.title && (
				<Typography variant="h5" className="tests-group__title">
					{props.title}
				</Typography>
			)}
			{props.children}
		</div>
	);
}

const Tests = (props: {courseId: number}): JSX.Element => {
	const {courseId} = props;

	const classes = useStyles();

	const studentId = useAppSelector((state) => selectUserId(state));

	const [chapterToSubmit, setChapterToSubmit] = useState(0);

	useEnsureChaptersLoaded(courseId);

	const [pasts, ongoing, upcoming] = useAppSelector((state) =>
		selectCourseChaptersGroupedByDate(state, keyProvider.course(courseId))
	);

	const showSnackbar = useSnackbar();

	const dispatch = useAppDispatch();

	const history = useHistory();

	const openChapter = (id: number) => {
		history.push(`/courses/${courseId}/chapters/${id}/`);
	};

	const submitChapter = (chapterId: number) => {
		setChapterToSubmit(chapterId);
	};

	const startSession = async (chapterId: number, endDate?: string) => {
		if (warnIfEnded(endDate)) {
			return;
		}

		const res = await dispatch(
			createChapterSession({studentId, courseId, chapterId})
		);
		unwrapResult(res);

		openChapter(chapterId);
	};

	const closeConfirmSubmission = (result: DialogResult) => {
		setChapterToSubmit(0);

		if (result.status !== "cancelled") {
			showSnackbar(result.status, result.message ?? "");
		}
	};

	function warnIfEnded(endDate?: string) {
		if (endDate && parseDate(endDate) < new Date()) {
			showSnackbar("warning", "The test is already ended.");
			return true;
		}
		return false;
	}

	return (
		<>
			<Box className={classes.root}>
				{ongoing.length > 0 && (
					<TestsGroup
						title={
							upcoming.length > 0 || pasts.length > 0 ? "Ongoing tests" : ""
						}
					>
						{ongoing.map((key) => (
							<OngoingTest
								key={key}
								chapterKey={key}
								studentId={studentId}
								onSubmit={submitChapter}
								onStart={startSession}
								onOpenChapter={openChapter}
							/>
						))}
					</TestsGroup>
				)}
				{upcoming.length > 0 && (
					<TestsGroup title="Upcoming tests">
						{upcoming.map((key) => (
							<UpcomingTest key={key} chapterKey={key} />
						))}
					</TestsGroup>
				)}
				{pasts.length > 0 && (
					<TestsGroup title="Past tests">
						{pasts.map((key) => (
							<PastTest
								key={key}
								chapterKey={key}
								onOpenChapter={openChapter}
								studentId={studentId}
							/>
						))}
					</TestsGroup>
				)}
			</Box>

			<ConfirmChapterSubmissionDialog
				open={chapterToSubmit > 0}
				onClose={closeConfirmSubmission}
				studentId={studentId}
				courseId={courseId}
				chapterId={chapterToSubmit}
			/>
		</>
	);
};

export default Tests;
