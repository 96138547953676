import {Localized, useLocalization} from "@fluent/react";
import {
	Checkbox,
	MenuItem,
	Select,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, {useMemo} from "react";

import UserRole from "../../store/models/UserRole";

const useStyles = makeStyles((theme) =>
	createStyles({
		menuItem: {
			paddingTop: theme.spacing(0.375),
			paddingBottom: theme.spacing(0.375),
		},
		checkbox: {
			marginRight: theme.spacing(1.5),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	})
);

function UserRoleSelector(props: {
	selected: UserRole[];
	onChange: (roles: UserRole[]) => void;
}) {
	const classes = useStyles();

	const {l10n} = useLocalization();

	const allRoles = useMemo(
		() => [
			{
				name: l10n.getString(
					"filters-user-roles-option-student",
					null,
					"Student"
				),
				id: UserRole.Student,
			},
			{
				name: l10n.getString(
					"filters-user-roles-option-teacher",
					null,
					"Teacher"
				),
				id: UserRole.Teacher,
			},
			{
				name: l10n.getString("filters-user-roles-option-tutor", null, "Tutor"),
				id: UserRole.Tutor,
			},
			{
				name: l10n.getString("filters-user-roles-option-admin", null, "Admin"),
				id: UserRole.Administrator,
			},
		],
		[l10n]
	);

	const selected =
		props.selected.length === 0 ? allRoles.map((r) => r.id) : props.selected;

	return (
		<Select
			fullWidth
			multiple
			displayEmpty
			value={selected}
			onChange={(event) => {
				const selected = event.target.value as UserRole[];
				props.onChange(selected.length === allRoles.length ? [] : selected);
			}}
			renderValue={(value) => {
				const selected = value as UserRole[];
				if (selected.length === allRoles.length) {
					return (
						<em>
							<Localized id="filters-user-roles-option-any">Any</Localized>
						</em>
					);
				}

				return selected
					.map((r) => allRoles.find((rr) => rr.id === r)?.name)
					.join(", ");
			}}
		>
			{allRoles.map((role) => (
				<MenuItem key={role.id} value={role.id} className={classes.menuItem}>
					<Checkbox
						className={classes.checkbox}
						checked={selected.includes(role.id)}
						disableRipple
					/>
					{role.name}
				</MenuItem>
			))}
		</Select>
	);
}

export default UserRoleSelector;
