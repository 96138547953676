import {Localized} from "@fluent/react";
import {Box, IconButton, Typography, useTheme} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {TreeView} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";

import {getChapterPathByKey} from "../../helpers/pathHelpers";
import {scrollToTop} from "../../helpers/scrollHelpers";
import type Chapter from "../../store/chapters/Chapter";
import AppBarDependentDrawer from "../../utils/AppBarDependentDrawer";
import ChapterNavItem from "../treeview/ChapterNavItem";
import ExerciseLabel from "../treeview/ExerciseLabel";
import type LabelProps from "../treeview/LabelProps";
import SectionLabel from "../treeview/SectionLabel";
import {useAppSelector} from "../../store/hooks";
import selectChapter from "../../store/chapters/selectChapter";
import parseDate from "../../helpers/parseDate";
import selectCourseType from "../../store/courses/selectCourseType";
import {requiresExplicitSessionStart} from "../../store/chapters/Chapter";
import CourseType from "../../store/courses/CourseType";

const ContentNavHeader = (props: {onClose: () => void}): JSX.Element => {
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			my={1}
			ml={2}
			mr={0.5}
		>
			<Typography variant="h6">
				<Localized id="content-navigation-header-title">
					Table of contents
				</Localized>
			</Typography>
			<IconButton onClick={props.onClose} color="primary">
				<ChevronLeftIcon />
			</IconButton>
		</Box>
	);
};

const ContentNavigation = (props: {
	open: boolean;
	courseId: number;
	currentChapterKey: string;
	chapterKeys: string[];
	onClose: () => void;
	width: number;
	mobileMode?: boolean;
}): JSX.Element => {
	const [expanded, setExpanded] = useState([props.currentChapterKey]);

	const history = useHistory();
	const theme = useTheme();

	const currentChapter = useAppSelector((state) =>
		selectChapter(state, props.currentChapterKey)
	);

	const courseType =
		useAppSelector((state) => selectCourseType(state, props.courseId)) ||
		CourseType.Ordinary;

	let chapterKeys = props.chapterKeys;
	if (
		currentChapter &&
		requiresExplicitSessionStart(courseType, currentChapter) &&
		history.location.pathname.includes(`${props.currentChapterKey}/sections`)
	) {
		chapterKeys = [props.currentChapterKey];
	}

	const oneChapter = chapterKeys.length === 1;

	useEffect(() => {
		if (oneChapter) {
			setExpanded([props.currentChapterKey]);
		}
	}, [oneChapter, props.currentChapterKey]);

	let icons = {};

	if (!oneChapter) {
		icons = {
			defaultCollapseIcon: <ExpandLessIcon color="primary" />,
			defaultExpandIcon: <ExpandMoreIcon color="primary" />,
		};
	}

	function defineChapterNavItemStyle(chapterKey: string, chapter: Chapter) {
		const test = requiresExplicitSessionStart(courseType, chapter);

		const inChapter = history.location.pathname.includes(`${chapterKey}`);
		const inSection = history.location.pathname.includes("sections");

		return {
			expandable: !test || (inChapter && inSection),
			clickable: !oneChapter,
			disabled: chapter.startDate
				? !test && parseDate(chapter.startDate) > new Date()
				: true,
			active: inChapter && !inSection,
		};
	}

	let width: number | string = props.width;
	if (props.mobileMode) {
		width = `calc(100vw - ${theme.spacing(7)}px)`;
	}

	function closeIfMobile() {
		if (props.mobileMode) {
			props.onClose();
		}
	}

	return (
		<AppBarDependentDrawer
			open={props.open}
			variant={props.mobileMode ? "temporary" : "persistent"}
			width={width}
			anchor="left"
			topFixedPanel={<ContentNavHeader onClose={props.onClose} />}
			onClose={props.onClose}
		>
			<Box m={2}>
				<TreeView expanded={expanded} {...icons}>
					{chapterKeys.map((key) => (
						<ChapterNavItem
							key={key}
							courseId={props.courseId}
							chapterKey={key}
							renderSectionLabel={createSectionLabelRenderer(closeIfMobile)}
							renderExerciseLabel={createExerciseLabelRenderer(closeIfMobile)}
							defineItemType={(chapter) =>
								defineChapterNavItemStyle(key, chapter)
							}
							onClick={(chapter) => {
								const {expandable} = defineChapterNavItemStyle(key, chapter);
								if (expandable) {
									setExpanded((prev) => (prev.includes(key) ? [] : [key]));
								} else {
									closeIfMobile();

									const path = getChapterPathByKey(
										history.location.pathname,
										key
									);
									history.push(path);
								}
							}}
						/>
					))}
				</TreeView>
			</Box>
		</AppBarDependentDrawer>
	);
};

function createSectionLabelRenderer(onClick: () => void) {
	return function render(params: LabelProps) {
		return (
			<SectionLabel
				active={params.active}
				index={params.index}
				text={params.text}
				link={params.link}
				onClick={() => {
					scrollToTop();
					onClick();
				}}
			/>
		);
	};
}

function createExerciseLabelRenderer(onClick: () => void) {
	return function render(params: LabelProps) {
		return (
			<ExerciseLabel
				active={params.active}
				index={params.index}
				text={params.text}
				link={params.link}
				metaIcon={params.metaIcon}
				onClick={onClick}
			/>
		);
	};
}

export default ContentNavigation;
