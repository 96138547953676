import {useLocalization} from "@fluent/react";
import React from "react";
import {Box, Typography} from "@material-ui/core";
import {Theme, createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textField: {
			marginRight: theme.spacing(5),
		},
	})
);

type Props = {
	maxScore?: number;
	category: string;
	difficultyLevel: number;
};

const ExerciseParameters = (props: Props): JSX.Element => {
	const classes = useStyles();

	const {l10n} = useLocalization();

	const ExeciseParameter = (
		description: string,
		value: string | number | undefined
	): JSX.Element => {
		return (
			<Typography className={classes.textField} variant="overline">
				<Typography variant="inherit" color="textSecondary">
					{description + ": "}
				</Typography>

				{value}
			</Typography>
		);
	};

	return (
		<Box display="flex" alignItems="center">
			{props.maxScore !== 0 &&
				ExeciseParameter(
					l10n.getString("content-exercise-max-score", null, "Max score"),
					props.maxScore
				)}
			{props.category && ExeciseParameter("Category", props.category)}
			{props.difficultyLevel !== 0 &&
				ExeciseParameter("Difficulty", props.difficultyLevel)}
		</Box>
	);
};

export default ExerciseParameters;
