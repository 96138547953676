import {Localized} from "@fluent/react";
import {Box, Tab, useTheme} from "@material-ui/core";
import React, {useState} from "react";
import {Link, Redirect, Switch} from "react-router-dom";

import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import selectCourse from "../../store/courses/selectCourse";
import SubtitleNav from "../nav/SubtitleNav";
import useSubrouteCreator from "../../hooks/useSubrouteCreator";
import NotificationsSettings from "./NotificationsSettings";
import GeneralSettings from "./GeneralSettings";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";
import CourseFeature from "../../store/courses/CourseFeature";
import Condition from "../../utils/Condition";
import ObservableRoute from "../../utils/ObservableRoute";
import StudySettings from "./StudySettings";

const CourseSettings = (props: {
	courseId: number;
	viewOnly?: boolean;
}): JSX.Element => {
	const {courseId, viewOnly} = props;

	const theme = useTheme();

	const course = useAppSelector((state) =>
		selectCourse(state, keyProvider.course(courseId))
	);

	const createSubroute = useSubrouteCreator();

	const generalSettingsPage = createSubroute("");
	const notificationsSettingsPage = createSubroute("/notifications");
	const studySettingsPage = createSubroute("/study");

	const [featureEnabled, awaitingFeatures] = useFeatureEnabled();

	const [selectedTab, setSelectedTab] = useState<string | boolean>(false);

	if (!course) {
		return <></>;
	}

	const newAnalyticsEnabled =
		featureEnabled(Feature.NewAnalytics) &&
		course.features.includes(CourseFeature.NewAnalytics);

	return (
		<>
			<SubtitleNav
				title={
					<Localized id="course-settings-header-title">
						Course settings
					</Localized>
				}
				subtitle={course.name}
				selectedTab={selectedTab}
			>
				<Tab
					label={
						<Localized id="course-settings-general-tab">General</Localized>
					}
					component={Link}
					value={generalSettingsPage.link}
					to={generalSettingsPage.link}
				/>

				<Tab
					label={<Localized id="course-settings-study-tab">Study</Localized>}
					component={Link}
					value={studySettingsPage.link}
					to={studySettingsPage.link}
				/>

				{newAnalyticsEnabled && (
					<Tab
						label={
							<Localized id="course-settings-notifications-tab">
								Notifications
							</Localized>
						}
						component={Link}
						value={notificationsSettingsPage.link}
						to={notificationsSettingsPage.link}
					/>
				)}
			</SubtitleNav>

			<Box maxWidth={theme.breakpoints.values.xl} p={6} pb={12}>
				<Switch>
					<ObservableRoute
						path={notificationsSettingsPage.route}
						onRender={() => setSelectedTab(notificationsSettingsPage.link)}
					>
						<Condition
							value={newAnalyticsEnabled}
							pending={awaitingFeatures}
							otherwise={<Redirect to={generalSettingsPage.link} />}
						>
							<NotificationsSettings courseId={courseId} readOnly={viewOnly} />
						</Condition>
					</ObservableRoute>

					<ObservableRoute
						path={studySettingsPage.route}
						onRender={() => setSelectedTab(studySettingsPage.link)}
					>
						<StudySettings courseId={courseId} readOnly={viewOnly} />
					</ObservableRoute>

					<ObservableRoute
						path={generalSettingsPage.route}
						onRender={() => setSelectedTab(generalSettingsPage.link)}
					>
						<GeneralSettings course={course} viewOnly={viewOnly} />
					</ObservableRoute>
				</Switch>
			</Box>
		</>
	);
};

export default CourseSettings;
