import React, {useMemo} from "react";
import {
	Button,
	Card,
	Grid,
	Popper,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import TeX from "@matejmazur/react-katex";
import "katex/dist/katex.min.css";
import AsciiMathParser from "../../../../utils/asciiMathParser";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		symbolsCard: {
			backgroundColor: theme.palette.background.default,
			padding: theme.spacing(2),
			maxWidth: "490px",
		},
		symbolButton: {
			backgroundColor: theme.palette.background.paper,
			textTransform: "none",
			minWidth: "85px",
		},
	})
);

const symbols = [
	{id: "pow", val: "x^y", expr: "x^y"},
	{id: "frac", val: "x/y", expr: "x/y"},
	{id: "sqrt", val: "sqrt(x)", expr: "sqrt(x)"},
	{id: "root", val: "root(y)(x)", expr: "root(x|y)"},
	{id: "exp", val: "e^(x)", expr: "e^(x)"},
	{id: "sin", val: "sin(x)", expr: "sin(x)"},
	{id: "cos", val: "cos(x)", expr: "cos(x)"},
	{id: "tan", val: "tan(x)", expr: "tan(x)"},
	{id: "log", val: "log(x)", expr: "log(x)"},
	{id: "ln", val: "ln(x)", expr: "ln(x)"},
	{id: "asin", val: "sin^-1(x)", expr: "asin(x)"},
	{id: "acos", val: "cos^-1(x)", expr: "acos(x)"},
	{id: "atan", val: "tan^-1(x)", expr: "atan(x)"},
	{id: "brac", val: "(x)", expr: "(x)"},
	{id: "multi", val: "\\times", expr: "*"},
];

type Props = {
	parser: AsciiMathParser;
	onSymbolClick: (selectedSymbol: string) => void;
	anchorRef: HTMLElement | null;
	open: boolean;
};

const SymbolsPanel = (props: Props): JSX.Element => {
	const classes = useStyles();

	const {parser, anchorRef: anchorEl, open} = props;

	const texSymbols = useMemo(() => {
		return symbols.map((s) => ({...s, val: parser.parse(s.val)}));
	}, [parser]);

	const symbolClickHandler = (symbolId: string) => () => {
		const ind = symbols.findIndex((s) => s.id === symbolId);
		props.onSymbolClick(symbols[ind].expr);
	};

	return (
		<Popper
			open={open}
			anchorEl={anchorEl}
			placement="bottom"
			modifiers={{
				flip: {
					enabled: false,
				},
			}}
		>
			<Card className={classes.symbolsCard}>
				<Grid container spacing={1}>
					{texSymbols.map((symbol) => {
						return (
							<Grid item key={symbol.id} xs>
								<Button
									className={classes.symbolButton}
									variant="contained"
									onClick={symbolClickHandler(symbol.id)}
									size="small"
								>
									<TeX math={symbol.val} />
								</Button>
							</Grid>
						);
					})}
				</Grid>
			</Card>
		</Popper>
	);
};

export default SymbolsPanel;
