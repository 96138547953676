import {Localized} from "@fluent/react";
import {
	Box,
	Link,
	Table,
	TableBody,
	TableHead,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import React, {useContext, useEffect, useState} from "react";

import type {
	ProgressByChapter,
	ProgressByType,
	StudentResultsInCourse,
} from "../../store/services/analyticsService";
import {analyticsService} from "../../store/services/analyticsService";
import StudentResultsRow from "./StudentResultsRow";
import {useAppSelector} from "../../store/hooks";
import selectChapter from "../../store/chapters/selectChapter";
import selectCourseStats from "../../store/courseStatistics/selectCourseStats";
import selectCourseChapterKeys from "../../store/chapters/selectCourseChapterKeys";
import {keyProvider} from "../../store/keyProvider";
import type ChapterProgress from "../../store/courseProgress/ChapterProgress";
import type ExerciseOutcomes from "../../store/courseProgress/ExerciseOutcomes";
import AssessmentPageDialogContext from "./AssessmentPageDialogContext";
import {resolveLinkToOldSite} from "../../helpers/pathHelpers";
import ProgressTableExerciseRow from "../analytics/ProgressTableExerciseRow";
import ContentItemTitle from "../analytics/ContentItemTitle";
import type {ChapterExercise} from "../../store/services/dtos/ChapterContent";
import ProgressTableSections from "../analytics/ProgressTableSections";
import ProgressTableChapterRow from "../analytics/ProgressTableChapterRow";

const useStyles = makeStyles((theme) =>
	createStyles({
		progressTable: {
			paddingBottom: theme.spacing(3),
		},
	})
);

const StudentProgressInChapters = (props: {
	student: StudentResultsInCourse;
	studentProgressInChapters: ProgressByChapter[];
	courseId: number;
	barType: "stacked" | "plain";
	viewOnly?: boolean;
	onCollapse: () => void;
}): JSX.Element => {
	const {courseId, student, barType, studentProgressInChapters} = props;

	const classes = useStyles();

	const courseStats = useAppSelector((state) =>
		selectCourseStats(state, keyProvider.course(courseId))
	);

	const [progress, setProgress] = useState<{
		[key: string]: ChapterProgress;
	}>({});

	const [loading, setLoading] = useState(true);

	const studentId = student.id;

	useEffect(() => {
		if (!studentId) {
			return;
		}

		setLoading(true);

		analyticsService
			.getStudentCourseProgress(studentId, courseId)
			.then((res) => {
				const byChapters: {[key: string]: ChapterProgress} = {};

				res.forEach((p) => {
					const key = keyProvider.chapter(p.chapterId);
					byChapters[key] = p;
				});

				setProgress(byChapters);
			})
			.finally(() => setLoading(false));
	}, [courseId, studentId]);

	if (!courseStats) {
		return <></>;
	}

	return (
		<Table stickyHeader className={classes.progressTable}>
			<TableHead>
				<StudentResultsRow
					courseId={courseId}
					courseStats={courseStats}
					student={student}
					progressByTypeInChapters={studentProgressInChapters}
					actionIcon={<FullscreenExitIcon />}
					onAction={props.onCollapse}
					barType={barType}
					loading={loading}
				/>
			</TableHead>
			<TableBody>
				<ProgressRows
					courseId={courseId}
					studentId={student.id}
					loading={loading}
					progress={progress}
					progressByType={studentProgressInChapters.reduce(
						(prev, curr) => ({
							...prev,
							[keyProvider.chapter(curr.chapterId)]: curr.progress,
						}),
						{}
					)}
					barType={barType}
					viewOnly={props.viewOnly}
				/>
			</TableBody>
		</Table>
	);
};

function ProgressRows(props: {
	courseId: number;
	studentId: number;
	loading: boolean;
	progress: {[key: string]: ChapterProgress | undefined};
	progressByType: {[key: string]: ProgressByType[] | undefined};
	barType: "stacked" | "plain";
	viewOnly?: boolean;
}) {
	const {courseId, studentId, loading, progress, progressByType} = props;

	const chapters = useAppSelector((state) =>
		selectCourseChapterKeys(state, keyProvider.course(courseId))
	);

	return (
		<>
			{chapters.map((key) => (
				<ProgressTableChapterRow
					studentId={studentId}
					chapterKey={key}
					chapterProgress={progress[key] ?? null}
					loading={loading}
					key={key}
					bar={
						props.barType === "stacked"
							? {type: "stacked", progressByType: progressByType[key] ?? []}
							: {type: "plain"}
					}
					renderTitle={(chapter) => (
						<ContentItemTitle
							name={chapter.title}
							number={`${chapter.number}`}
						/>
					)}
					renderSectionTable={(
						chapterId,
						_,
						chapterProgress,
						sessionRequired
					) => (
						<ChapterContent
							chapterId={chapterId}
							chapterProgress={chapterProgress}
							studentId={studentId}
							sessionRequired={sessionRequired}
							viewOnly={props.viewOnly}
						/>
					)}
				/>
			))}
		</>
	);
}

function ChapterContent(props: {
	chapterId: number;
	studentId: number;
	chapterProgress: ChapterProgress;
	sessionRequired?: boolean | undefined;
	viewOnly?: boolean;
}) {
	const {chapterId, studentId} = props;

	const chapter = useAppSelector((state) =>
		selectChapter(state, keyProvider.chapter(chapterId))
	);

	if (!chapter) {
		return <></>;
	}

	return chapter.structureType === "sections" ? (
		<ProgressTableSections
			studentId={studentId}
			chapterId={chapter.id}
			courseId={chapter.courseId}
			chapterProgress={props.chapterProgress}
			sessionRequired={props.sessionRequired}
			renderExerciseRow={(section, exercise, outcomes) => (
				<ExerciseRow
					studentId={studentId}
					exercise={exercise}
					sectionNumber={section.number}
					outcomes={outcomes}
					key={exercise.id}
					viewOnly={props.viewOnly}
				/>
			)}
		/>
	) : (
		<ChapterHasOldStructureWarning courseId={chapter.courseId} />
	);
}

function ChapterHasOldStructureWarning(props: {courseId: number}) {
	const link = resolveLinkToOldSite(`teacher/${props.courseId}/#/followup`);

	return (
		<Box display="flex" flexDirection="column" alignItems="center" py={1}>
			<Typography variant="subtitle2">
				<Localized id="follow-up-student-results-widget-old-chapter-warning-title">
					Chapter has old structure
				</Localized>
			</Typography>
			<Typography variant="body2">
				<Localized
					id="follow-up-student-results-widget-old-chapter-warning-link"
					elems={{ref: <Link href={link}></Link>}}
				>
					<>{"You can find student results in <ref>the old interface</ref>"}</>
				</Localized>
			</Typography>
		</Box>
	);
}

function ExerciseRow(props: {
	studentId: number;
	exercise: ChapterExercise;
	sectionNumber: number;
	outcomes?: ExerciseOutcomes;
	viewOnly?: boolean;
}) {
	const {outcomes, exercise} = props;

	const {openDialog} = useContext(AssessmentPageDialogContext);

	const title = (
		<ContentItemTitle
			number={`${props.sectionNumber}.${exercise.number}`}
			name={exercise.title}
		/>
	);

	return (
		<ProgressTableExerciseRow
			outcomes={outcomes}
			maxScore={exercise.maxScore ?? 0}
			title={
				!props.viewOnly && outcomes ? (
					<Link
						color="inherit"
						style={{cursor: "pointer"}}
						onClick={() =>
							openDialog({
								chapterId: exercise.chapterId,
								exerciseId: exercise.id,
								exerciseType: exercise.type,
								studentId: props.studentId,
							})
						}
					>
						{title}
					</Link>
				) : (
					title
				)
			}
		/>
	);
}

export default StudentProgressInChapters;
