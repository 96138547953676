import {Localized, useLocalization} from "@fluent/react";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
	Box,
	Button,
	Container,
	DialogActions,
	DialogContent,
	Grid,
	InputAdornment,
	Paper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, {useCallback, useImperativeHandle, useState} from "react";
import {Link} from "react-router-dom";

import {validEmail, validUserName} from "../../helpers/validationHelpers";
import type FetchStatus from "../../store/FetchStatus";
import {organisationService} from "../../store/services/organisationService";
import {userService} from "../../store/services/userService";
import PaginatedSingleSelectionAutocomplete from "../../utils/autocomplete/PaginatedSingleSelectionAutocomplete";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function NewGroupUserDialog(props: {
	titleElementId: string;
	groupName: string;
	onCancel: () => void;

	dialogRef?: React.Ref<{status: () => FetchStatus}>;
}) {
	const {groupName} = props;

	const [organisation, setOrganisation] = useState("");
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	const [invalid, setInvalid] = useState<{
		username?: string;
		password?: string;
		email?: string;
	}>({});

	const [passwordsMismatch, setPasswordsMismatch] = useState(false);

	const [empty, setEmpty] = useState<{
		organisation?: boolean;
		username?: boolean;
		email?: boolean;
		password?: boolean;
		repeatPassword?: boolean;
		firstName?: boolean;
		lastName?: boolean;
	}>({});

	const [showPassword, setShowPassword] = useState(false);

	const [userId, setUserId] = useState(0);
	const [status, setStatus] = useState<FetchStatus>("none");

	const {l10n} = useLocalization();
	const theme = useTheme();

	const fetchOrganisations = useCallback(
		(query: string, pageSize: number) => {
			return organisationService.searchGroupOrganisations(
				groupName,
				{query},
				{field: "displayName"},
				pageSize
			);
		},
		[groupName]
	);

	useImperativeHandle(
		props.dialogRef,
		() => ({
			status: () => status,
		}),
		[status]
	);

	function valid() {
		const empty = {
			organisation: organisation === "",
			username: username === "",
			email: email === "",
			password: password === "",
			repeatPassword: repeatPassword === "",
			firstName: firstName === "",
			lastName: lastName === "",
		};

		setEmpty(empty);

		let passwordError = "";
		if (password.length < 8) {
			passwordError = l10n.getString(
				"new-group-user-dialog-error-invalid-password-too-short"
			);
		} else if (password.length > 128) {
			passwordError = l10n.getString(
				"new-group-user-dialog-error-invalid-password-too-long"
			);
		}

		const invalid = {
			username:
				username.length < 3 || username.length > 40 || !validUserName(username)
					? l10n.getString("new-group-user-dialog-error-invalid-username")
					: "",
			password: passwordError,
			email: !validEmail(email)
				? l10n.getString("new-group-user-dialog-error-invalid-email")
				: "",
		};

		setInvalid(invalid);

		setPasswordsMismatch(password !== repeatPassword);

		if (
			password !== repeatPassword ||
			Object.values(empty).some((v) => v) ||
			Object.values(invalid).some((v) => v)
		) {
			return false;
		}

		return true;
	}

	async function create() {
		if (!valid()) {
			setStatus("none");
			return;
		}

		setStatus("pending");

		try {
			organisation;

			const id = await userService.createUser(organisation, {
				name: username,
				emailAddress: email,
				password,
				firstName,
				lastName,
			});

			setUserId(id);
			setStatus("succeeded");
		} catch (error) {
			const err = error as {code: string; field: string};
			if (err.code === "duplicate_id") {
				if (err.field === "emailAddress") {
					setInvalid((prev) => ({
						...prev,
						email: l10n.getString(
							"new-group-user-dialog-error-duplicate-email"
						),
					}));
				} else if (err.field === "name") {
					setInvalid((prev) => ({
						...prev,
						username: l10n.getString(
							"new-group-user-dialog-error-duplicate-username"
						),
					}));
				}

				setStatus("none");
			} else {
				setStatus("failed");
			}
		}
	}

	const title = (
		<WindowedDialogTitle
			id={props.titleElementId}
			title={<Localized id="new-group-user-dialog-title">New user</Localized>}
			onClose={props.onCancel}
		/>
	);

	if (status === "succeeded" && userId) {
		return (
			<>
				{title}

				<DialogContent dividers style={{height: "100vh"}}>
					<ContentOnSuccess userId={userId} />
				</DialogContent>
			</>
		);
	}

	const togglePassword = showPassword
		? l10n.getString("new-group-user-dialog-tooltip-hide-password")
		: l10n.getString("new-group-user-dialog-tooltip-show-password");

	const passwordAdornment = (
		<InputAdornment position="end">
			<IconButtonWithTooltip
				tooltipTitle={togglePassword}
				onClick={() => setShowPassword((prev) => !prev)}
				aria-label={togglePassword}
			>
				{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
			</IconButtonWithTooltip>
		</InputAdornment>
	);

	return (
		<>
			{title}

			<DialogContent dividers style={{height: "100vh"}}>
				{status === "failed" && (
					<Paper
						style={{padding: theme.spacing(2), marginBottom: theme.spacing(2)}}
					>
						<Box
							display="flex"
							alignItems="center"
							style={{gap: theme.spacing(2)}}
						>
							<ErrorIcon color="error" />
							<Typography align="justify">
								<Localized id="error-general" />
							</Typography>
						</Box>
					</Paper>
				)}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PaginatedSingleSelectionAutocomplete
							label={
								<Localized id="new-group-user-dialog-label-organisation">
									Organisation
								</Localized>
							}
							required
							error={empty.organisation}
							pageFetcher={fetchOrganisations}
							optionMapper={(v) => ({
								id: v.name,
								name: v.displayName,
								secondaryName: v.name,
							})}
							onChange={(name) => {
								setOrganisation(name ?? "");
								setEmpty((prev) => ({...prev, organisation: false}));
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="username"
							fullWidth
							required
							label={
								<Localized id="new-group-user-dialog-label-username">
									Username
								</Localized>
							}
							error={empty.username || Boolean(invalid.username)}
							value={username}
							helperText={!empty.username && invalid.username}
							onChange={({target}) => {
								setUsername(target.value);
								setEmpty((prev) => ({...prev, username: false}));
								setInvalid((prev) => ({...prev, username: ""}));
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="email"
							fullWidth
							required
							label={
								<Localized id="new-group-user-dialog-label-email">
									Email address
								</Localized>
							}
							error={empty.email || Boolean(invalid.email)}
							value={email}
							helperText={!empty.email && invalid.email}
							onChange={({target}) => {
								setEmail(target.value);
								setEmpty((prev) => ({...prev, email: false}));
								setInvalid((prev) => ({...prev, email: ""}));
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="password"
							fullWidth
							required
							label={
								<Localized id="new-group-user-dialog-label-password">
									Password
								</Localized>
							}
							type={showPassword ? "text" : "password"}
							value={password}
							InputProps={{
								endAdornment: passwordAdornment,
							}}
							error={empty.password || Boolean(invalid.password)}
							helperText={!empty.password && invalid.password}
							onChange={({target}) => {
								setPassword(target.value);
								setEmpty((prev) => ({...prev, password: false}));
								setInvalid((prev) => ({...prev, password: ""}));
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="repeat"
							fullWidth
							required
							label={
								<Localized id="new-group-user-dialog-label-repeat-password">
									Repeat password
								</Localized>
							}
							type={showPassword ? "text" : "password"}
							value={repeatPassword}
							InputProps={{
								endAdornment: passwordAdornment,
							}}
							error={empty.repeatPassword || passwordsMismatch}
							helperText={
								passwordsMismatch && (
									<Localized id="new-group-user-dialog-error-passwords-mismatch">
										Passwords mismatch
									</Localized>
								)
							}
							onChange={({target}) => {
								setRepeatPassword(target.value);
								setEmpty((prev) => ({...prev, repeatPassword: false}));
								setPasswordsMismatch(false);
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="firstName"
							fullWidth
							required
							label={
								<Localized id="new-group-user-dialog-label-first-name">
									First name
								</Localized>
							}
							value={firstName}
							error={empty.firstName}
							onChange={({target}) => {
								setFirstName(target.value);
								setEmpty((prev) => ({...prev, firstName: false}));
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="lastName"
							fullWidth
							required
							label={
								<Localized id="new-group-user-dialog-label-last-name">
									Last name
								</Localized>
							}
							value={lastName}
							error={empty.lastName}
							onChange={({target}) => {
								setLastName(target.value);
								setEmpty((prev) => ({...prev, lastName: false}));
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					<Localized id="new-group-user-dialog-action-cancel">Cancel</Localized>
				</Button>
				<SubmitButton
					variant="text"
					inProgress={status === "pending"}
					onClick={create}
				>
					<Localized id="new-group-user-dialog-action-create">Create</Localized>
				</SubmitButton>
			</DialogActions>
		</>
	);
}

function ContentOnSuccess(props: {userId: number}) {
	const theme = useTheme();

	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<DoneIcon
				style={{
					height: "50px",
					width: "50px",
					color: theme.palette.success.main,
				}}
			/>
			<Typography variant="h6">
				<Localized id="new-group-user-dialog-success-title">
					Successfully created
				</Localized>
			</Typography>
			<Typography>
				<Localized id="new-group-user-dialog-success-descr">
					The new user was added to the organisation
				</Localized>
			</Typography>
			<Box mt={2} display="flex" style={{gap: theme.spacing(2)}}>
				<Button color="primary" component={Link} to={`/users/${props.userId}`}>
					<Localized id="new-group-user-dialog-success-action-view">
						View profile
					</Localized>
				</Button>
				<Button
					color="primary"
					component={Link}
					to={`/users/${props.userId}/roles`}
				>
					<Localized id="new-group-user-dialog-success-action-enrol">
						Enrol or assign
					</Localized>
				</Button>
			</Box>
		</Container>
	);
}

export default NewGroupUserDialog;
