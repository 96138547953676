import {Localized} from "@fluent/react";
import React, {useContext} from "react";
import {Button} from "@material-ui/core";
import SidebarContext, {SidebarType} from "../SidebarContext";

function AskTutorButton(props: {
	chapterExerciseKey: string;
	size?: "small" | "medium";
}): JSX.Element {
	const ctx = useContext(SidebarContext);

	const open = () => {
		ctx.open(SidebarType.AskTutor, props.chapterExerciseKey);
	};

	return (
		<Button color="primary" size={props.size} onClick={open}>
			<Localized id="sidebars-ask-tutor-button-label">Ask a tutor</Localized>
		</Button>
	);
}

export default AskTutorButton;
