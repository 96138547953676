import {Paper, createStyles, makeStyles} from "@material-ui/core";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {scrollToElement} from "../../../helpers/scrollHelpers";

const useStyles = makeStyles((theme) =>
	createStyles({
		anchorLink: {
			position: "absolute",
			marginTop: theme.spacing(-25),
		},
	})
);

function Widget(props: {id: string; children: React.ReactNode}): JSX.Element {
	const classes = useStyles();

	const {id} = props;

	const {hash} = useLocation();

	useEffect(() => {
		if (hash.slice(1) === id) {
			scrollToElement(id);
		}
	}, [hash, id]);

	return (
		<Paper elevation={1} style={{height: "100%"}}>
			<a id={id} className={classes.anchorLink} />
			{props.children}
		</Paper>
	);
}

export default Widget;
