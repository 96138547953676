import {Localized} from "@fluent/react";
import {Grid, MenuItem, TextField, Typography} from "@material-ui/core";
import React from "react";

import type {CourseStudentSearchCriteria} from "../../store/services/courseService";
import SearchToolbar from "../../utils/SearchToolbar";

type ChangeCourseStudentSearchCriteria = (
	value:
		| CourseStudentSearchCriteria
		| ((prevState: CourseStudentSearchCriteria) => CourseStudentSearchCriteria)
) => void;

const StudentResultsTableToolbar = (props: {
	criteria: CourseStudentSearchCriteria;
	onCriteriaChange: ChangeCourseStudentSearchCriteria;
}): JSX.Element => {
	const {criteria: criteria, onCriteriaChange} = props;

	return (
		<SearchToolbar criteria={criteria} onCriteriaChange={onCriteriaChange}>
			<CriteriaPanel criteria={criteria} onCriteriaChange={onCriteriaChange} />
		</SearchToolbar>
	);
};

function CriteriaPanel(props: {
	criteria: CourseStudentSearchCriteria;
	onCriteriaChange: ChangeCourseStudentSearchCriteria;
}) {
	const {criteria, onCriteriaChange} = props;

	const enrolledAfter = criteria.enrolledAfter?.slice(0, 10) ?? "";
	const enrolledBefore = criteria.enrolledBefore?.slice(0, 10) ?? "";

	return (
		<Grid container spacing={4}>
			<Grid item container xs={12} spacing={1}>
				<Grid item xs={12}>
					<Typography variant="caption" color="textSecondary">
						<Localized id="follow-up-student-activity-widget-enrolment-date-filter">
							Enrolment date
						</Localized>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6} lg={4}>
					<TextField
						fullWidth
						type="date"
						label={
							<Localized id="follow-up-student-activity-widget-enrolment-date-filter-after">
								After
							</Localized>
						}
						InputLabelProps={{
							shrink: true,
						}}
						value={enrolledAfter}
						onChange={({target}) =>
							onCriteriaChange((prev) => ({
								...prev,
								enrolledAfter: target.value ?? undefined,
							}))
						}
					/>
				</Grid>
				<Grid item xs={12} sm={6} lg={4}>
					<TextField
						fullWidth
						type="date"
						label={
							<Localized id="follow-up-student-activity-widget-enrolment-date-filter-before">
								Before
							</Localized>
						}
						InputLabelProps={{
							shrink: true,
						}}
						value={enrolledBefore}
						onChange={({target}) =>
							onCriteriaChange((prev) => ({
								...prev,
								enrolledBefore: target.value ?? undefined,
							}))
						}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TextField
					select
					fullWidth
					label={
						<Localized id="follow-up-student-activity-widget-study-status-filter">
							Study status
						</Localized>
					}
					value={criteria.studyStatus ?? ""}
					onChange={({target}) =>
						onCriteriaChange((prev) => ({
							...prev,
							studyStatus: target.value as "not_started" | "started",
						}))
					}
				>
					<MenuItem value="">
						<em>
							<Localized id="follow-up-student-activity-widget-study-status-filter-not-specified">
								Not specified
							</Localized>
						</em>
					</MenuItem>
					<MenuItem value="started">
						<Localized id="follow-up-student-activity-widget-study-status-filter-started">
							Started
						</Localized>
					</MenuItem>
					<MenuItem value="not_started">
						<Localized id="follow-up-student-activity-widget-study-status-filter-not-started">
							Not started
						</Localized>
					</MenuItem>
				</TextField>
			</Grid>
		</Grid>
	);
}

export default StudentResultsTableToolbar;
