import {Theme, createStyles, makeStyles} from "@material-ui/core/styles";
import {TreeItem} from "@material-ui/lab";
import React, {useContext} from "react";
import type {ReactNode} from "react";

import ChapterLabel from "./ChapterLabel";
import DisabledChapterNavItem from "./DisabledChapterNavItem";
import type LabelProps from "./LabelProps";
import SectionNavItem from "./SectionNavItem";
import selectChapter from "../../store/chapters/selectChapter";
import {useAppSelector} from "../../store/hooks";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import DotIcon from "../../icons/DotIcon";
import type Chapter from "../../store/chapters/Chapter";
import selectChapterSessionKeys from "../../store/chapterSessions/selectChapterSessionKeys";
import AssessmentDeliveryContext, {
	AssessmentDeliveryContextProvider,
	SessionAssessmentDeliveryContextProvider,
} from "../AssessmentDeliveryContext";
import selectCourseType from "../../store/courses/selectCourseType";
import {requiresExplicitSessionStart} from "../../store/chapters/Chapter";

const useStyles = makeStyles<Theme, {clickable: boolean}>((theme: Theme) =>
	createStyles({
		label: {
			"&.MuiTreeItem-root > .MuiTreeItem-content:hover > .MuiTreeItem-label": {
				background: theme.palette.background.paper,
			},
			"&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
				background: theme.palette.background.paper,
			},
		},
		content: {
			marginBottom: theme.spacing(3),
			cursor: ({clickable}) => (clickable ? "cursor" : "default"),
		},
		group: {
			marginLeft: theme.spacing(2.5),
		},
	})
);

const ChapterNavItem = (props: {
	courseId: number;
	chapterKey: string;

	clickable?: boolean;
	onClick: (chapter: Chapter) => void;

	renderSectionLabel: (params: LabelProps) => React.ReactNode;
	renderExerciseLabel: (
		params: LabelProps,
		exerciseKey: string
	) => React.ReactNode;

	defineItemType: (
		chapter: Chapter
	) => {
		clickable: boolean;
		disabled: boolean;
		expandable: boolean;
		active?: boolean;
	};
}): JSX.Element => {
	const {chapterKey, defineItemType, courseId} = props;

	const chapter = useAppSelector((state) => selectChapter(state, chapterKey));

	const sessionKey = useAppSelector((state) => {
		const [sessions] = selectChapterSessionKeys(state, chapterKey);
		return sessions.length > 0 ? sessions[0] : "";
	});

	const studentId = useAppSelector(selectUserId);

	const courseType = useAppSelector((state) =>
		selectCourseType(state, courseId)
	);

	if (!chapter || !courseType) {
		return <></>;
	}

	const {clickable, disabled, expandable, active} = defineItemType(chapter);

	if (disabled || chapter.structureType !== "sections") {
		return (
			<DisabledChapterNavItem
				index={chapter.number}
				chapterId={chapter.id}
				title={chapter.title}
			/>
		);
	}

	const label = (
		<ChapterLabel
			index={`${chapter.number}`}
			label={chapter.title}
			active={active}
		/>
	);

	if (!expandable) {
		return (
			<UnexpandableChapterNavItem
				chapterKey={chapterKey}
				label={label}
				onClick={clickable ? () => props.onClick(chapter) : undefined}
				clickable={clickable}
				active={active}
			/>
		);
	}

	const sessionRequired = requiresExplicitSessionStart(courseType, chapter);

	const navItem = (
		<ExpandableChapterNavItem
			courseId={courseId}
			chapterKey={chapterKey}
			label={label}
			renderSectionLabel={props.renderSectionLabel}
			renderExerciseLabel={props.renderExerciseLabel}
			onClick={clickable ? () => props.onClick(chapter) : undefined}
			clickable={clickable}
		/>
	);

	return sessionRequired ? (
		<SessionAssessmentDeliveryContextProvider
			sessionKey={sessionKey}
			courseId={courseId}
			chapterId={chapter.id}
			studentId={studentId}
		>
			{navItem}
		</SessionAssessmentDeliveryContextProvider>
	) : (
		<AssessmentDeliveryContextProvider
			courseId={courseId}
			chapterId={chapter.id}
			studentId={studentId}
		>
			{navItem}
		</AssessmentDeliveryContextProvider>
	);
};

function ExpandableChapterNavItem(props: {
	courseId: number;
	chapterKey: string;
	onClick?: () => void;

	label: ReactNode;

	renderSectionLabel: (params: LabelProps) => React.ReactNode;
	renderExerciseLabel: (
		params: LabelProps,
		exerciseKey: string
	) => React.ReactNode;

	clickable: boolean;
}) {
	const {onClick, chapterKey, courseId, clickable} = props;

	const classes = useStyles({clickable});

	const {
		fetchContent,
		fetchOutcomes,
		selectContentStatus,
		selectSectionKeys,
	} = useContext(AssessmentDeliveryContext);

	const sections = useAppSelector(selectSectionKeys);

	const contentRequested = useAppSelector(
		(state) => selectContentStatus(state) !== "none"
	);

	function click() {
		if (!contentRequested) {
			fetchContent();
			fetchOutcomes();
		}
		onClick && onClick();
	}

	return (
		<TreeItem
			nodeId={chapterKey}
			label={props.label}
			onClick={click}
			classes={{
				root: classes.label,
				content: classes.content,
				group: classes.group,
			}}
		>
			{/* this line below is necessary for rendering expand icon */}
			{""}
			{sections.map((key: string, i: number) => (
				<SectionNavItem
					key={key}
					sectionKey={key}
					index={`${i + 1}`}
					courseId={courseId}
					renderLabel={props.renderSectionLabel}
					renderExerciseLabel={props.renderExerciseLabel}
				/>
			))}
		</TreeItem>
	);
}

function UnexpandableChapterNavItem(props: {
	chapterKey: string;
	label: ReactNode;
	onClick?: () => void;
	clickable: boolean;
	active?: boolean;
}) {
	const {chapterKey, onClick, clickable, active} = props;

	const classes = useStyles({clickable});

	return (
		<TreeItem
			nodeId={chapterKey}
			label={props.label}
			onClick={onClick}
			classes={{
				root: classes.label,
				content: classes.content,
			}}
			icon={
				<DotIcon
					color={active ? "secondary" : "primary"}
					style={{marginLeft: "3px"}}
				/>
			}
		/>
	);
}

export default ChapterNavItem;
