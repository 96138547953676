import {Localized} from "@fluent/react";
import {DialogContentText} from "@material-ui/core";
import {unwrapResult} from "@reduxjs/toolkit";
import React from "react";

import {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import deleteChapter from "../../store/chapters/deleteChapter";
import type {AppDispatch} from "../../store/store";

function createDeleteChapterAction(
	courseId: number,
	dispatch: AppDispatch,
	openConfirmationDialog: OpenConfirmationDialog
): (id: number, title: string) => void {
	return function (chapterId, title) {
		openConfirmationDialog({
			title: (
				<Localized id="learning-material-chapter-management-delete-chapter-dialog-title">
					Delete chapter?
				</Localized>
			),
			description: (
				<Localized
					id="learning-material-chapter-management-delete-chapter-dialog-description"
					elems={{paragraph: <DialogContentText></DialogContentText>}}
					vars={{title}}
				>
					<>
						{[
							"<paragraph>You are going to delete chapter '{$title}'.</paragraph>",
							"<paragraph>It will be deleted permanently, along with all sections, theory subsections and links to exercises. Deleted chapter's exercises remain in the exercise bank after deletion.</paragraph>",
						].join("")}
					</>
				</Localized>
			),
			confirmBtnText: (
				<Localized id="learning-material-chapter-management-button-delete">
					Delete
				</Localized>
			),
			onConfirm: async () => {
				const res = await dispatch(deleteChapter({courseId, chapterId}));
				unwrapResult(res);
			},
		});
	};
}

export default createDeleteChapterAction;
