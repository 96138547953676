import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
	Typography,
} from "@material-ui/core";
import React, {useState} from "react";
import CloseIcon from "@material-ui/icons/Close";
import {unwrapResult} from "@reduxjs/toolkit";
import type {SerializedError} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../store/hooks";
import scheduleChapter from "../../store/chapters/scheduleChapter";
import {
	convertLocalTimeToUTC,
	formatToLocalTimeZone,
} from "../../helpers/dateTimeHelpers";
import useSnackbar from "../../store/ui/useSnackbar";

const ScheduleChapterDialog = (props: {
	courseId: number;
	chapterId: number;
	onClose: () => void;
}): JSX.Element => {
	const {courseId, chapterId, onClose} = props;
	const dispatch = useAppDispatch();
	const [startDate, setStartDate] = useState(() => formatToLocalTimeZone());

	const showSnackbar = useSnackbar();

	const {l10n} = useLocalization();

	const handleShedule = async () => {
		const res = await dispatch(
			scheduleChapter({
				courseId,
				chapterId,
				startDate: convertLocalTimeToUTC(startDate),
			})
		);

		try {
			unwrapResult(res);
		} catch (error) {
			let message = l10n.getString(
				"error-general",
				null,
				"An error has occured"
			);

			if ((error as SerializedError).code === "conflict") {
				message = l10n.getString(
					"learning-material-schedule-dialog-error-empty-or-inconsistent-settings",
					null,
					"Publication cannot be schedulled: the chapter is empty or has inconsistent settings"
				);
			}

			showSnackbar("error", message);
		}

		onClose();
	};

	return (
		<>
			<DialogTitle disableTypography>
				<Typography variant="h6">
					<Localized id="learning-material-schedule-dialog-title">
						Schedule publication
					</Localized>
				</Typography>
			</DialogTitle>
			<Box position="absolute" top={0} right={0} m={1}>
				<IconButton size="small" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Box>
			<DialogContent>
				<TextField
					fullWidth
					type="datetime-local"
					label={l10n.getString(
						"learning-material-schedule-dialog-datetime",
						null,
						"Date and time"
					)}
					InputLabelProps={{
						shrink: true,
					}}
					value={startDate}
					onChange={(e) => setStartDate(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={onClose}>
					<Localized id="learning-material-schedule-dialog-button-cancel">
						Cancel
					</Localized>
				</Button>
				<Button color="primary" onClick={handleShedule}>
					<Localized id="learning-material-schedule-dialog-button-schedule">
						Schedule
					</Localized>
				</Button>
			</DialogActions>
		</>
	);
};

export default ScheduleChapterDialog;
