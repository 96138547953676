import React, {useEffect, useRef, useState} from "react";
import {Localized} from "@fluent/react";
import {
	FormControlLabel,
	Grid,
	Hidden,
	Switch,
	TextField,
} from "@material-ui/core";

function SectionSettingsEditor(props: {
	disabled: boolean;
	settings: {selectionSize: number; maxScorePerExercise: number};
	onChange: (settings: {
		selectionSize?: number;
		maxScorePerExercise?: number;
	}) => void;
}): JSX.Element {
	const {disabled, onChange, settings} = props;

	const [enableRandomSelection, setEnableRandomSelection] = useState(
		settings.selectionSize !== 0
	);
	const [selectionSize, setSelectionSize] = useState(
		settings.selectionSize || 1
	);
	const [maxScorePerExercise, setMaxScorePerExercise] = useState(
		settings.maxScorePerExercise
	);

	const selectionSizeDirty = useRef(false);
	useEffect(() => {
		if (!selectionSizeDirty.current) {
			return noop;
		}
		const id = setTimeout(() => {
			selectionSizeDirty.current = false;
			onChange({selectionSize});
		}, 500);
		return () => clearTimeout(id);
	}, [onChange, selectionSize]);

	const maxScorePerExerciseDirty = useRef(false);
	useEffect(() => {
		if (!maxScorePerExerciseDirty.current) {
			return noop;
		}
		const id = setTimeout(() => {
			maxScorePerExerciseDirty.current = false;
			onChange({maxScorePerExercise});
		}, 500);
		return () => clearTimeout(id);
	}, [onChange, maxScorePerExercise]);

	const changeEnableRandomSelection = (checked: boolean) => {
		setEnableRandomSelection(checked);
		const selectionSizeValue = checked ? selectionSize : 0;
		onChange({selectionSize: selectionSizeValue});
	};

	const changeSelectionSize = (value: string) => {
		const selectionSizeValue = parseInt(value);
		if (selectionSizeValue > 0 && selectionSizeValue !== selectionSize) {
			setSelectionSize(selectionSizeValue);
			selectionSizeDirty.current = true;
		}
	};

	const changeMaxScorePerExercise = (value: string) => {
		const maxScorePerExerciseValue = parseInt(value);
		if (
			maxScorePerExerciseValue >= 0 &&
			maxScorePerExerciseValue !== maxScorePerExercise
		) {
			setMaxScorePerExercise(maxScorePerExerciseValue);
			maxScorePerExerciseDirty.current = true;
		}
	};

	return (
		<Grid container spacing={3} alignItems="center">
			<Grid item xs={12} md={12} xl={4}>
				<FormControlLabel
					control={
						<Switch
							checked={enableRandomSelection}
							onChange={(e) => changeEnableRandomSelection(e.target.checked)}
						/>
					}
					label={
						<Localized id="learning-material-section-management-random-selection-switch">
							Random selection
						</Localized>
					}
					disabled={disabled}
				/>
			</Grid>
			<Hidden smDown={!enableRandomSelection}>
				<Grid item xs={12} md={6} xl={4}>
					<TextField
						label={
							<Localized id="learning-material-section-management-selection-size-field">
								Number of exercises
							</Localized>
						}
						type="number"
						InputProps={{inputProps: {min: 1}}}
						value={selectionSize}
						onChange={(e) => changeSelectionSize(e.target.value)}
						style={{
							visibility: enableRandomSelection ? "visible" : "hidden",
						}}
						fullWidth
						disabled={disabled}
					/>
				</Grid>
				<Grid item xs={12} md={6} xl={4}>
					<TextField
						label={
							<Localized id="learning-material-section-management-max-score-per-exercise-field">
								Max score per exercise
							</Localized>
						}
						type="number"
						InputProps={{inputProps: {min: 0}}}
						value={maxScorePerExercise}
						onChange={(e) => changeMaxScorePerExercise(e.target.value)}
						style={{
							visibility: enableRandomSelection ? "visible" : "hidden",
						}}
						fullWidth
						disabled={disabled}
					/>
				</Grid>
			</Hidden>
		</Grid>
	);
}

export default SectionSettingsEditor;

function noop() {
	return;
}
