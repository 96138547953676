import {Box, Typography} from "@material-ui/core";
import React, {ReactNode} from "react";

function SidebarSection(props: {
	title: React.ReactNode;
	children: ReactNode | ReactNode[];
}): JSX.Element {
	const {title, children} = props;

	return (
		<>
			<Box my={1} mx={2}>
				<Typography variant="caption" color="textSecondary">
					{title}
				</Typography>
			</Box>

			<Box mb={2} mx={2}>
				{children}
			</Box>
		</>
	);
}

export default SidebarSection;
