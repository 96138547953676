import {Box, CircularProgress} from "@material-ui/core";
import React from "react";

const Loading = (): JSX.Element => {
	return (
		<Box display="flex" justifyContent="center">
			<CircularProgress color="primary" />
		</Box>
	);
};

export default Loading;
