import React from "react";
import {Box, Typography} from "@material-ui/core";
import {Theme, createStyles, makeStyles} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import type UploadedFile from "./UploadedFile";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		item: {
			marginBottom: theme.spacing(2),
		},
		fileSize: {
			color: theme.palette.text.secondary,
			marginLeft: theme.spacing(1),
		},
		clearIcon: {
			color: theme.palette.text.secondary,
			marginLeft: theme.spacing(1.5),
			cursor: "pointer",
		},
	})
);

const AttachmentItem = (props: {
	item: UploadedFile;
	onDelete?: (deleteUrl: string) => void;
}): JSX.Element => {
	const classes = useStyles();

	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			className={classes.item}
		>
			<Typography color="primary">
				<a
					href={props.item.url}
					download
					style={{textDecoration: "none", color: "inherit"}}
				>
					{props.item.name}
				</a>
			</Typography>
			<Typography className={classes.fileSize}>
				{Math.round(props.item.size / 10) / 100} {"KB"}
			</Typography>
			{props.onDelete && (
				<ClearIcon
					fontSize="small"
					className={classes.clearIcon}
					onClick={() => props.onDelete && props.onDelete(props.item.deleteUrl)}
				/>
			)}
		</Box>
	);
};

export default AttachmentItem;
