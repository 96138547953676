import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Button,
	Checkbox,
	DialogActions,
	DialogContent,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";

import CourseTypeSelector from "./CourseTypeSelector";
import useDateFormat from "../../i18n/useDateFormat";
import parseDate from "../../helpers/parseDate";
import OrganisationSelector from "../organisations/OrganisationSelector";
import {courseService} from "../../store/services/courseService";
import type {OrganisationGroupCourseSearchCriteria} from "../../store/services/courseService";
import type OrganisationGroupCourseSearchResult from "../../store/services/dtos/OrganisationGroupCourseSearchResult";
import type {PaginatedAutocompleteApi} from "../../utils/autocomplete/PaginatedAutocomplete";
import ContentLanguageSelector from "../../utils/ContentLanguageSelector";
import LightTooltip from "../../utils/LightTooltip";
import SearchToolbar from "../../utils/SearchToolbar";
import type HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import ServerPaginationControls from "../../utils/tables/ServerPaginationControls";
import SortingHeader from "../../utils/tables/SortingHeader";
import SortOrder from "../../utils/tables/SortOrder";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import usePaginationState from "../../utils/tables/usePaginationState";
import useSorting from "../../utils/tables/useSorting";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginTop: theme.spacing(2),
			height: "100%",
		},
		dialogContent: {
			display: "flex",
			flexDirection: "column",
			height: "100vh",
			paddingBottom: 0,
		},
		toolbar: {
			display: "flex",
			gap: theme.spacing(0.5),
		},
		selectedNumber: {
			flexGrow: 1,
			paddingLeft: theme.spacing(4),
		},
	})
);

const headCells: HeadCell<OrganisationGroupCourseSearchResult>[] = [
	{
		id: "organisationName",
		label: "group-courses-selector-dialog-table-column-organisation",
		width: 160,
		sortable: false,
	},
	{
		id: "name",
		label: "group-courses-selector-dialog-table-column-name",
		sortable: true,
	},
	{
		id: "startDate",
		label: "group-courses-selector-dialog-table-column-start-date",
		sortable: true,
		width: 160,
		align: "right",
	},
	{
		id: "type",
		label: "group-courses-selector-dialog-table-column-type",
		sortable: false,
		width: 100,
	},
];

const columnNumber = headCells.length + 1;

const initialCriteria: OrganisationGroupCourseSearchCriteria = {};

function GroupCoursesSelectorDialog(props: {
	groupName: string;
	actionLabel: NonNullable<React.ReactNode>;
	multiple?: boolean;
	onSelected: (ids: number[]) => void;
	onCancel: () => void;
}) {
	const {multiple} = props;

	const classes = useStyles();

	const [sortField, sortOrder, changeOrder] = useSorting<
		keyof OrganisationGroupCourseSearchResult
	>("name");

	const [criteria, setCriteria] = useState(initialCriteria);

	const {
		page: coursesPage,
		fetchFirstPage,
		fetchRelatedPage,
		pageFetchStatus,
		retryFetching,
	} = usePaginationState<OrganisationGroupCourseSearchResult>();

	const courses = coursesPage.content;

	const {
		select: selectCourse,
		bulkSelectionCheckbox,
		selected,
		resetSelection,
	} = useBulkSelection(coursesPage, (o) => o.id);

	const [singleSelectedCourse, setSingleSelectedCourse] = useState(0);

	const formatDate = useDateFormat();

	useEffect(() => {
		fetchFirstPage(
			() =>
				courseService.searchCoursesInOrganisationGroup(
					props.groupName,
					criteria,
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					10
				),
			resetSelection
		);
	}, [
		criteria,
		fetchFirstPage,
		props.groupName,
		resetSelection,
		sortField,
		sortOrder,
	]);

	let selectedCourses = selected;
	if (!multiple && singleSelectedCourse) {
		selectedCourses = [singleSelectedCourse];
	}

	function selectedCourse(id: number) {
		return selectedCourses.indexOf(id) !== -1;
	}

	const notFetched = pageFetchStatus !== "succeeded";

	return (
		<>
			<DialogContent className={classes.dialogContent} dividers>
				<Box className={classes.toolbar}>
					<Toolbar
						criteria={criteria}
						groupName={props.groupName}
						onCriteriaChange={setCriteria}
					/>
					<ServerPaginationControls
						disabledControls={{
							first: notFetched || !coursesPage.request.first,
							next: notFetched || !coursesPage.request.next,
							previous: notFetched || !coursesPage.request.previous,
						}}
						onClick={fetchRelatedPage}
					/>
				</Box>
				<TableContainer className={classes.container}>
					<Table stickyHeader>
						<SortingHeader
							onOrderChange={changeOrder}
							order={sortOrder}
							orderBy={sortField}
							headCells={headCells}
							leftAnnex={
								props.multiple ? (
									<TableCell padding="checkbox" style={{zIndex: 3}}>
										{bulkSelectionCheckbox}
									</TableCell>
								) : (
									<TableCell padding="checkbox" />
								)
							}
							loading={pageFetchStatus === "pending"}
						/>
						<TableBody>
							{pageFetchStatus === "failed" && (
								<TableRow>
									<LoadingErrorState
										description={
											<Localized id="group-courses-loading-error-descr">
												Something has gone wrong, and we cannot load courses
											</Localized>
										}
										colSpan={columnNumber}
										onReload={retryFetching}
									/>
								</TableRow>
							)}
							{pageFetchStatus === "succeeded" && courses.length === 0 && (
								<TableRow>
									<NoSearchResultsState
										colSpan={columnNumber}
										title={
											<Localized id="group-courses-no-courses">
												No courses
											</Localized>
										}
										description={
											criteria === initialCriteria ? (
												<Localized id="group-courses-no-courses-descr">
													There are no courses in the organisation group
												</Localized>
											) : (
												<Localized id="group-courses-no-results-descr">
													No courses were found matching your search criteria.
													Try to adjust filters
												</Localized>
											)
										}
									/>
								</TableRow>
							)}
							{pageFetchStatus !== "failed" &&
								courses.length > 0 &&
								courses.map((c, index) => {
									const selected = selectedCourse(c.id);
									const labelId = `table-checkbox-${index}`;
									return (
										<TableRow
											hover
											key={c.id}
											tabIndex={-1}
											selected={selected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													checked={selected}
													inputProps={{"aria-labelledby": labelId}}
													onClick={() => {
														if (multiple) {
															selectCourse(c.id);
														} else {
															setSingleSelectedCourse(selected ? 0 : c.id);
														}
													}}
												/>
											</TableCell>
											<TableCell style={{width: 160}}>
												<LightTooltip
													title={<Typography>{c.organisationName}</Typography>}
													enterDelay={500}
													enterNextDelay={500}
												>
													<div
														style={{
															whiteSpace: "nowrap",
															textOverflow: "ellipsis",
															overflow: "hidden",
															width: "inherit",
														}}
													>
														{c.organisationName}
													</div>
												</LightTooltip>
											</TableCell>
											<TableCell component="th" id={labelId} scope="row">
												{c.name}
											</TableCell>
											<TableCell align="right">
												{formatDate(parseDate(c.startDate))}
											</TableCell>
											<TableCell>
												<Localized id={`group-courses-type-${c.type}`}>
													{c.type}
												</Localized>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				{selectedCourses.length > 0 && (
					<Typography
						variant="subtitle2"
						component="span"
						className={classes.selectedNumber}
					>
						<Localized
							id="group-courses-selector-dialog-selected-row-number"
							vars={{selected: selectedCourses.length}}
						>{`${selectedCourses.length} selected`}</Localized>
					</Typography>
				)}
				<Button color="primary" onClick={props.onCancel}>
					<Localized id="group-courses-selector-dialog-action-cancel">
						Cancel
					</Localized>
				</Button>
				<Button
					color="primary"
					disabled={selectedCourses.length === 0}
					onClick={() => props.onSelected(selectedCourses)}
				>
					{props.actionLabel}
				</Button>
			</DialogActions>
		</>
	);
}

function Toolbar(props: {
	groupName: string;
	criteria: OrganisationGroupCourseSearchCriteria;
	onCriteriaChange: (
		value:
			| OrganisationGroupCourseSearchCriteria
			| ((
					prevState: OrganisationGroupCourseSearchCriteria
			  ) => OrganisationGroupCourseSearchCriteria)
	) => void;
}) {
	const {criteria, onCriteriaChange} = props;

	const {l10n} = useLocalization();

	const orgSelector = useRef<PaginatedAutocompleteApi>(null);

	return (
		<>
			<SearchToolbar
				criteria={criteria}
				searchPlaceholder={l10n.getString(
					"group-courses-selector-dialog-search-placeholder"
				)}
				onCriteriaChange={onCriteriaChange}
				onClear={orgSelector.current?.resetSelection}
			>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<OrganisationSelector
							ref={orgSelector}
							groupName={props.groupName}
							label={
								<Localized id="group-courses-selector-dialog-filters-organisations">
									Organisations
								</Localized>
							}
							onChange={(organisationNames) =>
								onCriteriaChange((prev) => ({...prev, organisationNames}))
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<CourseTypeSelector
							value={criteria.type}
							onChange={(type) => onCriteriaChange((prev) => ({...prev, type}))}
						/>
					</Grid>
					<Grid item xs={4}>
						<ContentLanguageSelector
							value={criteria.language ?? ""}
							withAny
							onChange={(language) =>
								onCriteriaChange((prev) => ({...prev, language}))
							}
						/>
					</Grid>
				</Grid>
			</SearchToolbar>
		</>
	);
}

export default GroupCoursesSelectorDialog;
