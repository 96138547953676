import {Box, Toolbar} from "@material-ui/core";
import React from "react";

import useNavBarHeight from "../hooks/useNavBarHeight";

const AppBarDependentContainer = (props: {
	children: React.ReactNode;
}): JSX.Element => {
	const navBarHeight = useNavBarHeight();

	return (
		<Box
			position="fixed"
			top={0}
			bottom={0}
			display="flex"
			flexDirection="column"
		>
			<Toolbar style={{minHeight: navBarHeight}} />
			{props.children}
		</Box>
	);
};

export default AppBarDependentContainer;
