import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import enrolledInCourse from "../courses/enrolledInCourse";
import staffMemberRolesChanged from "../courses/staffMemberRolesChanged";
import enrolInCourse from "../enrolment/enrolInCourse";
import type FetchStatus from "../FetchStatus";
import inferFetchStatusFromError from "../inferFetchStatusFromError";
import type UserRole from "../models/UserRole";
import {userService} from "../services/userService";

export const fetchUserRolesInOrganisation = createAsyncThunk(
	"userRolesInOrganisation/getRolesInOrganisation",
	(params: {userId: number; orgName: string}) =>
		userService.getUserRolesInOrganisation(params.userId, params.orgName)
);

const initialState: {
	roles: UserRole[];
	status: FetchStatus;
} = {
	roles: [],
	status: "none",
};

export const organisationUserRolesSlice = createSlice({
	name: "userRolesInOrganisation",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			fetchUserRolesInOrganisation.fulfilled,
			(state, {payload}) => {
				state.status = "succeeded";
				state.roles = payload;
			}
		);
		builder.addCase(fetchUserRolesInOrganisation.pending, (state) => {
			state.status = "pending";
		});
		builder.addCase(fetchUserRolesInOrganisation.rejected, (state, action) => {
			state.status = inferFetchStatusFromError(action);
		});

		builder.addCase(enrolInCourse.fulfilled, (state) => {
			state.status = "none";
		});

		builder.addCase(staffMemberRolesChanged, (state) => {
			state.status = "none";
		});

		builder.addCase(enrolledInCourse, (state) => {
			state.status = "none";
		});
	},
});

export default organisationUserRolesSlice;
