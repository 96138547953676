import React from "react";
import {
	Divider,
	Drawer,
	Theme,
	Toolbar,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import useNavBarHeight from "../hooks/useNavBarHeight";

const useStyles = makeStyles<
	Theme,
	{width: string | number; maxWidth?: string | number}
>(() =>
	createStyles({
		drawer: {
			width: ({width}) => width,
			maxWidth: ({maxWidth}) => maxWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: ({width}) => width,
			maxWidth: ({maxWidth}) => maxWidth,
		},
		drawerContainer: {
			overflow: "auto",
			height: "100%",
		},
	})
);

const AppBarDependentDrawer = (props: {
	anchor: "left" | "right";
	children: React.ReactElement;
	topFixedPanel?: React.ReactElement;
	bottomFixedPanel?: React.ReactElement;
	open: boolean;
	variant: "permanent" | "persistent" | "temporary";
	width: string | number;
	maxWidth?: string | number;
	unmountOnExit?: boolean;
	onClose?: () => void;
}): JSX.Element => {
	const classes = useStyles({width: props.width, maxWidth: props.maxWidth});

	const navBarHeight = useNavBarHeight();

	return (
		<Drawer
			open={props.open}
			className={classes.drawer}
			variant={props.variant}
			classes={{
				paper: classes.drawerPaper,
			}}
			anchor={props.anchor}
			SlideProps={{
				unmountOnExit: props.unmountOnExit,
			}}
			ModalProps={{
				keepMounted: !props.unmountOnExit,
			}}
			onClose={props.onClose}
		>
			{props.variant !== "temporary" && (
				<Toolbar style={{minHeight: navBarHeight}} />
			)}

			{props.topFixedPanel}
			{props.topFixedPanel && <Divider />}

			<div className={classes.drawerContainer}>{props.children}</div>

			{props.bottomFixedPanel && <Divider />}
			{props.bottomFixedPanel}
		</Drawer>
	);
};

export default AppBarDependentDrawer;
