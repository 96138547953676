import {createAsyncThunk} from "@reduxjs/toolkit";

import enrolmentService from "../services/enrolmentService";

const enrolInCourse = createAsyncThunk(
	"enrolment/enrolInCourse",
	async (params: {courseId: number; studentId: number; password?: string}) => {
		const {courseId, studentId, password} = params;

		const status = await enrolmentService.sendEnrolmentApplication(
			courseId,
			studentId,
			password
		);

		return {status, courseId};
	}
);

export default enrolInCourse;
