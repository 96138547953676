import React, {useState} from "react";
import {
	Box,
	IconButton,
	InputAdornment,
	TextField,
	makeStyles,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import {Theme, createStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		checkIcon: {
			color: theme.palette.success.main,
		},
	})
);

type Props = {
	label: string;
	initialValue: string;
	onSave: (value: string) => void;
	onCancel: () => void;
};

const TitleEditor = (props: Props): JSX.Element => {
	const classes = useStyles();

	const [title, setTitle] = useState(props.initialValue);

	function cancel() {
		setTitle(props.initialValue);
		props.onCancel();
	}

	return (
		<TextField
			label={props.label}
			value={title}
			onChange={(e) => setTitle(e.target.value)}
			fullWidth
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Box mr={1}>
							<IconButton size="small" onClick={cancel}>
								<ClearIcon fontSize="inherit" />
							</IconButton>
						</Box>
						<Box>
							<IconButton size="small" onClick={() => props.onSave(title)}>
								<CheckIcon fontSize="inherit" className={classes.checkIcon} />
							</IconButton>
						</Box>
					</InputAdornment>
				),
			}}
			InputLabelProps={{shrink: true}}
		/>
	);
};

export default TitleEditor;
