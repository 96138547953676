import React from "react";
import SubItemLabel from "./SubItemLabel";
import {Theme, createStyles, makeStyles} from "@material-ui/core/styles";
import LabelProps from "./LabelProps";

const useStyles = makeStyles<Theme, {active?: boolean}>((theme: Theme) =>
	createStyles({
		root: {
			marginBottom: theme.spacing(3),
			marginLeft: theme.spacing(2),
		},
		index: {
			color: ({active}) =>
				active ? theme.palette.secondary.main : theme.palette.text.secondary,
			marginRight: theme.spacing(1),
		},
		label: {
			color: ({active}) =>
				active ? theme.palette.secondary.main : theme.palette.text.primary,
		},
	})
);

const ExerciseLabel = (props: LabelProps): JSX.Element => {
	const classes = useStyles({active: props.active});

	return (
		<SubItemLabel
			link={props.link}
			index={props.index}
			text={props.text}
			classes={{
				root: classes.root,
				index: classes.index,
				label: classes.label,
			}}
			onClick={props.onClick}
			metaIcon={props.metaIcon}
		/>
	);
};

export default ExerciseLabel;
