import {Localized} from "@fluent/react";
import {Button, useTheme} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import React, {useEffect, useRef, useState} from "react";

import {useAppDispatch, useAppSelector} from "../hooks";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import logoutUser from "./logoutUser";
import refreshUserSession from "./refreshUserSession";
import {logoutRequest} from "../services/authService";
import {axiosInstance as client} from "../services/axiosInstance";
import sessionStore from "./sessionStore";
import type UserSession from "./UserSession";
import {calculateTimeLeft} from "../../utils/TimeLeft";
import parseDate from "../../helpers/parseDate";
import {selectUserSessionFetchStatus} from "./selectSession";

const noticePeriod = 2 * 60 * 1000;

function UserSessionExpirationReminder(): JSX.Element {
	const dispatch = useAppDispatch();

	const [notificationShown, setNotificationShown] = useState(false);
	const [session, setSession] = useState<UserSession | null>(null);

	const sessionFetchStatus = useAppSelector(selectUserSessionFetchStatus);

	useEffect(() => {
		if (sessionFetchStatus === "succeeded") {
			setSession(sessionStore.getSession());
		}
	}, [sessionFetchStatus]);

	useEffect(() => {
		if (!session?.endTime) {
			return noop;
		}

		const timeLeft = parseDate(session.endTime).getTime() - Date.now();

		const timeout = timeLeft - noticePeriod;
		if (timeout <= 0) {
			setNotificationShown(true);
			return noop;
		}

		setNotificationShown(false);

		const timer = setTimeout(setNotificationShown, timeout, true);

		return () => clearTimeout(timer);
	}, [session?.endTime]);

	useEffect(() => {
		const unsubscribe = sessionStore.addUpdateListener((s) => {
			setSession(s);
			if (!s) {
				dispatch(logoutUser({logoutOnServer: false}));
			}
		});
		return unsubscribe;
	}, [dispatch]);

	const lastUpdatedAt = useRef(0);

	useEffect(() => {
		if (!session?.inactivityTimeout) {
			return noop;
		}

		const id = client.interceptors.request.use((req) => {
			const now = Date.now();
			if (now - lastUpdatedAt.current > 1000 && !logoutRequest(req)) {
				const endTime = nSecondsFromNow(session.inactivityTimeout);

				sessionStore.updateEndTime(endTime);

				setSession((prev) => prev && {...prev, endTime});
				lastUpdatedAt.current = now;
			}

			return req;
		});

		return () => {
			client.interceptors.request.eject(id);
		};
	}, [session?.inactivityTimeout]);

	function refreshSession() {
		dispatch(refreshUserSession());
	}

	if (!session || !notificationShown) {
		return <></>;
	}

	return (
		<ExpirationTimer deadline={session.endTime} onProlong={refreshSession} />
	);
}

function ExpirationTimer(props: {deadline: string; onProlong: () => void}) {
	const {deadline} = props;

	const height = useNavBarHeight();
	const theme = useTheme();

	const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(deadline));

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (timeLeft.total <= 0) {
			dispatch(logoutUser({logoutOnServer: true}));
			return noop;
		}

		const timer = setTimeout(() => {
			const t = calculateTimeLeft(deadline);
			setTimeLeft(t);
		}, 1000);

		return () => clearTimeout(timer);
	}, [deadline, dispatch, timeLeft.total]);

	return (
		<Snackbar
			anchorOrigin={{vertical: "top", horizontal: "right"}}
			open
			style={{top: height + theme.spacing(3)}}
		>
			<Alert
				elevation={6}
				variant="filled"
				severity="warning"
				action={
					<Button onClick={props.onProlong} color="inherit">
						<Localized id="session-expires-notification-prolong-btn">
							Prolong
						</Localized>
					</Button>
				}
			>
				<Localized
					id="session-expires-notification-message"
					vars={{minutes: timeLeft.minutes, seconds: timeLeft.seconds}}
				>
					{"Your session will expire in"}
				</Localized>
			</Alert>
		</Snackbar>
	);
}

function noop() {
	return;
}

function nSecondsFromNow(seconds: number) {
	return new Date(Date.now() + seconds * 1000).toISOString();
}

export default UserSessionExpirationReminder;
