import {useLocalization} from "@fluent/react";
import {Typography} from "@material-ui/core";
import React, {useContext, useEffect, useMemo} from "react";

import MathAnswer from "./MathAnswer";
import MultiAnswer from "./MultiAnswer";
import OpenAnswer from "./OpenAnswer";
import SidebarBody from "../SidebarBody";
import SidebarContext from "../SidebarContext";
import SidebarHeader from "../SidebarHeader";
import SidebarSection from "../SidebarSection";
import selectChapterExercise from "../../../store/chapterExercises/selectChapterExercise";
import ExerciseType from "../../../store/exercises/ExerciseType";
import fetchExerciseAnswer from "../../../store/exercises/fetchExerciseAnswer";
import selectExerciseAnswer from "../../../store/exercises/selectExerciseAnswer";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import makeSelectSection from "../../../store/sections/makeSelectSection";
import HtmlContent from "../../../utils/HtmlContent";
import AppBarDependentDrawer from "../../../utils/AppBarDependentDrawer";
import CodeEditor from "../../../utils/CodeEditor";

const AnswerSidebar = (props: {
	open: boolean;
	width: number | string;
	sectionKey: string;
	courseId: number;
	mobileMode: boolean;
}): JSX.Element => {
	const dispatch = useAppDispatch();
	const selectSection = useMemo(makeSelectSection, []);

	const {chapterExerciseKey, close, fullscreen, toggleFullscreen} = useContext(
		SidebarContext
	);

	const chapterExercise = useAppSelector(
		(state) => state.chapterExercises.byKey[chapterExerciseKey]
	);

	const exercise = useAppSelector((state) =>
		selectChapterExercise(state, chapterExerciseKey)
	);
	const exerciseNumber = chapterExercise?.number;

	const answer = useAppSelector(
		(state) =>
			chapterExercise && selectExerciseAnswer(state, chapterExercise.exercise)
	);

	const section = useAppSelector((state) =>
		selectSection(state, props.sectionKey)
	);

	useEffect(() => {
		close();
	}, [close, props.sectionKey]);

	const chapterId = chapterExercise?.chapterId;
	const exerciseId = exercise?.id;
	const {courseId, open} = props;

	useEffect(() => {
		if (open && !answer && chapterId && exerciseId) {
			dispatch(
				fetchExerciseAnswer({
					courseId: courseId,
					chapterId: chapterId,
					exerciseId: exerciseId,
				})
			);
		}
	}, [answer, chapterId, courseId, dispatch, exerciseId, open]);

	let final = null;

	if (answer) {
		switch (answer.exerciseType) {
			case ExerciseType.Math:
				final = (
					<SidebarSection title="Final answer">
						<MathAnswer value={answer.finalAnswer} />
					</SidebarSection>
				);
				break;
			case ExerciseType.Multi:
				final = (
					<SidebarSection title="Final answer">
						<MultiAnswer value={answer.finalAnswer} />
					</SidebarSection>
				);
				break;
			case ExerciseType.Open:
				if (answer.finalAnswer) {
					final = (
						<SidebarSection title="Final answer">
							<OpenAnswer value={answer.finalAnswer} />
						</SidebarSection>
					);
				}
				break;
			case ExerciseType.Prog:
				final = (
					<SidebarSection title="Example program">
						<CodeEditor readonly initialFiles={answer.files} />
					</SidebarSection>
				);
				break;
			default:
				throw new Error();
		}
	}

	const {l10n} = useLocalization();
	return (
		<AppBarDependentDrawer
			open={open}
			variant={props.mobileMode || fullscreen ? "temporary" : "persistent"}
			width={props.width}
			anchor="right"
			topFixedPanel={
				<SidebarHeader
					title={l10n.getString(
						"sidebars-sidebar-title-answer",
						null,
						"Answer"
					)}
					onClose={close}
					fullscreen={fullscreen}
					onToggleFullscreen={props.mobileMode ? undefined : toggleFullscreen}
				/>
			}
			onClose={close}
		>
			<SidebarBody>
				<SidebarSection
					title={l10n.getString(
						"sidebars-sidebar-subtitle-exercise",
						null,
						"Exercise"
					)}
				>
					<Typography variant="body2">{`${section?.number}.${exerciseNumber} ${exercise.title}`}</Typography>
				</SidebarSection>

				{answer && answer.solution && (
					<SidebarSection title="Solution">
						<HtmlContent content={answer.solution} />
					</SidebarSection>
				)}

				{final}
			</SidebarBody>
		</AppBarDependentDrawer>
	);
};

export default AnswerSidebar;
