import {Dialog} from "@material-ui/core";
import React, {useState} from "react";

import GrantAdminRoleDialog from "./GrantAdminRoleDialog";

function useGrantAdminInGroupOrganisationAction(
	groupName: string,
	onDone?: () => void
): [(userIds: number[], username: string) => void, JSX.Element] {
	const [userIds, setUserIds] = useState<number[]>([]);
	const [username, setUsername] = useState("");

	const [open, setOpen] = useState(false);

	function granAdmin(userIds: number[], username: string) {
		setUserIds(userIds);
		setUsername(username);
		setOpen(true);
	}

	const grantAdminDialogId = "grant-admin";

	return [
		granAdmin,
		<Dialog
			key="grant-admin"
			aria-labelledby={grantAdminDialogId}
			open={open}
			fullWidth
			maxWidth="xs"
			onClose={() => setOpen(false)}
		>
			<GrantAdminRoleDialog
				titleElementId={grantAdminDialogId}
				groupName={groupName}
				userIds={userIds}
				selectedUserName={username}
				onCancel={() => setOpen(false)}
				onGranted={() => {
					setOpen(false);
					onDone?.();
				}}
			/>
		</Dialog>,
	];
}

export default useGrantAdminInGroupOrganisationAction;
