import {Localized, useLocalization} from "@fluent/react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	FormControlLabel,
	Grid,
	MenuItem,
	Radio,
	TextField,
	Typography,
	useTheme,
} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";

import {
	addToDate,
	convertLocalTimeToUTC,
	formatToLocalTimeZone,
} from "../../helpers/dateTimeHelpers";
import {courseService} from "../../store/services/courseService";
import OrganisationCourseSearchResult from "../../store/services/dtos/OrganisationCourseSearchResult";
import useSnackbar from "../../store/ui/useSnackbar";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

type Schedule = {
	courseId: number;
	archivalDate?: string;
};

function useArchiveCoursesAction(
	onAction: (organisationName: string, schedules: Schedule[]) => Promise<void>,
	onSuccess: () => void,
	onError: () => void
): [
	action: (organisationName: string, courseIds: number[]) => void,
	dialog: JSX.Element
] {
	const [courseIds, setCourseIds] = useState<number[]>([]);
	const [orgName, setOrgName] = useState("");

	const close = useCallback(() => {
		setCourseIds([]);
	}, []);

	function archiveCourses(organisationName: string, courseIds: number[]) {
		setCourseIds(courseIds);
		setOrgName(organisationName);
	}

	async function archive(s: Schedule[]) {
		try {
			await onAction(orgName, s);

			onSuccess();
			close();
		} catch {
			onError();
		}
	}

	const archiveDialogId = "archive-course";

	return [
		archiveCourses,
		<Dialog
			key={archiveDialogId}
			maxWidth="sm"
			fullWidth
			open={courseIds.length > 0}
			aria-labelledby={archiveDialogId}
			onClose={close}
		>
			<ArchiveCoursesDialog
				titleElementId={archiveDialogId}
				organisationName={orgName}
				selectedCourses={courseIds}
				onArchive={archive}
				onCancel={close}
			/>
		</Dialog>,
	];
}

function ArchiveCoursesDialog(props: {
	titleElementId: string;
	organisationName: string;
	selectedCourses: number[];
	onArchive: (schedules: Schedule[]) => Promise<void>;
	onCancel: () => void;
}): JSX.Element {
	const {organisationName, selectedCourses, onCancel} = props;

	const theme = useTheme();
	const {l10n} = useLocalization();
	const showSnackbar = useSnackbar();

	const nowDateTimeLocal = formatToLocalTimeZone().slice(0, 16);
	const [specificDate, setSpecificDate] = useState<string>(nowDateTimeLocal);
	const [incorrectDate, setIncorrectDate] = useState(false);

	const [inProgress, setInProgress] = useState(false);
	const [loading, setLoading] = useState(false);

	const [courses, setCourses] = useState<OrganisationCourseSearchResult[]>([]);

	const [schedule, setSchedule] = useState<
		"now" | "after_end_date" | "on_date"
	>("now");

	const [periodAfter, setPeriodAfter] = useState<{
		value: number;
		unit: "day" | "month";
	}>({unit: "day", value: 1});

	const calculateArchivalDate = {
		now: () => null,
		on_date: () => convertLocalTimeToUTC(specificDate),
		after_end_date: (endDate: string, now: string) => {
			const date =
				periodAfter.unit === "day"
					? addToDate(endDate, {days: periodAfter.value})
					: addToDate(endDate, {months: periodAfter.value});
			return date > now ? date : null;
		},
	};

	async function archive() {
		const now = new Date().toISOString();

		if (!validate(now)) {
			return;
		}

		setInProgress(true);

		await props.onArchive(
			courses
				.filter((c) => !c.archivalDate || c.archivalDate > now)
				.map((c) => ({
					courseId: c.id,
					archivalDate:
						calculateArchivalDate[schedule](c.endDate, now) ?? undefined,
				}))
		);

		setInProgress(false);
	}

	useEffect(() => {
		if (selectedCourses.length > 0) {
			setLoading(true);
			courseService
				.getOrganisationCourses(organisationName, selectedCourses)
				.then((c) => {
					setCourses(c);
				})
				.catch(() => {
					showSnackbar("error", l10n.getString("error-general"));
					onCancel();
				})
				.finally(() => setLoading(false));
		}
	}, [l10n, organisationName, onCancel, selectedCourses, showSnackbar]);

	useEffect(() => {
		setIncorrectDate(false);
	}, [schedule]);

	function validate(now: string) {
		if (
			schedule === "on_date" &&
			(!specificDate || calculateArchivalDate["on_date"]() <= now)
		) {
			setIncorrectDate(true);
			return false;
		}

		return true;
	}

	const now = new Date().toISOString();

	const archivedSelected = courses.some(
		(c) => c.archivalDate && c.archivalDate <= now
	);

	const archivalDateInPast = courses.some(
		(c) => calculateArchivalDate["after_end_date"](c.endDate, now) === null
	);

	let name = "";
	if (selectedCourses.length === 1) {
		name = courses.find((c) => c.id === selectedCourses[0])?.name ?? "";
	}

	return (
		<>
			<WindowedDialogTitle
				id={props.titleElementId}
				title={
					<Localized
						id="courses-archiving-dialog-title"
						vars={{number: props.selectedCourses.length}}
					>
						Archive courses
					</Localized>
				}
				inProgress={inProgress}
				onClose={onCancel}
			/>

			<DialogContent dividers>
				<Localized
					id="courses-archiving-dialog-content"
					elems={{
						paragraph: <DialogContentText align="justify"></DialogContentText>,
					}}
					vars={{
						number: selectedCourses.length,
						name: name,
					}}
				>
					<>{`You are going to archive course
						After course archiving, only administrators will be able to access it; students and other staff members will no more see the course.
						If you schedule archiving for the future, teachers will not be able to move the course end date after the archiving date.
						When would you like the course to be archived?`}</>
				</Localized>
				<Grid container spacing={1}>
					<Grid item xs={12} container>
						<FormControlLabel
							control={
								<Radio
									checked={schedule === "now"}
									onChange={() => setSchedule("now")}
								/>
							}
							label={
								<Localized id="courses-archiving-dialog-archive-now">
									Now
								</Localized>
							}
						/>
					</Grid>

					<Grid item xs={12} container spacing={1}>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Radio
										checked={schedule === "after_end_date"}
										onChange={() => setSchedule("after_end_date")}
									/>
								}
								label={
									<Localized id="courses-archiving-dialog-archive-after-end-date">
										After course end date in
									</Localized>
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							style={{
								gap: theme.spacing(2),
								marginLeft: `calc(1.5rem + ${theme.spacing(1)}px)`,
							}}
						>
							<Grid item xs={3}>
								<TextField
									type="number"
									fullWidth
									autoFocus
									InputProps={{inputProps: {min: 1}}}
									value={periodAfter.value}
									disabled={schedule !== "after_end_date"}
									onChange={({target}) =>
										setPeriodAfter((prev) => ({
											...prev,
											value: parseInt(target.value),
										}))
									}
								/>
							</Grid>
							<Grid item xs>
								<TextField
									select
									fullWidth
									value={periodAfter.unit}
									onChange={({target}) =>
										setPeriodAfter((prev) => ({
											...prev,
											unit: target.value as "day" | "month",
										}))
									}
									disabled={schedule !== "after_end_date"}
								>
									<MenuItem value="day">
										<Localized
											id="courses-archiving-dialog-archive-after-end-date-in-days"
											vars={{number: periodAfter.value}}
										>
											Day
										</Localized>
									</MenuItem>
									<MenuItem value="month">
										<Localized
											id="courses-archiving-dialog-archive-after-end-date-in-months"
											vars={{number: periodAfter.value}}
										>
											Month
										</Localized>
									</MenuItem>
								</TextField>
							</Grid>
						</Grid>
						<Grid
							item
							style={{marginLeft: `calc(1.5rem + ${theme.spacing(1)}px)`}}
						>
							<Typography variant="caption" component="p">
								<Localized id="courses-archiving-dialog-note-end-date-change-not-impose-archival-date-change">
									Note: change of end date does not impose change of archival
									date
								</Localized>
							</Typography>
							{schedule === "after_end_date" && archivalDateInPast && (
								<Typography variant="caption" component="p">
									<Localized id="courses-archiving-dialog-warning-archival-date-in-past">
										Warning: some courses will be archived as of today because
										the planned archival date is in the past.
									</Localized>
								</Typography>
							)}
						</Grid>
					</Grid>

					<Grid item xs={12} container spacing={1}>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Radio
										checked={schedule === "on_date"}
										onChange={() => setSchedule("on_date")}
									/>
								}
								label={
									<Localized id="courses-archiving-dialog-archive-on-date">
										On specific date
									</Localized>
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							style={{marginLeft: `calc(1.5rem + ${theme.spacing(1)}px)`}}
						>
							<TextField
								fullWidth
								required
								error={incorrectDate}
								type="datetime-local"
								InputLabelProps={{
									shrink: true,
								}}
								helperText={
									incorrectDate &&
									specificDate && (
										<Localized id="courses-archiving-dialog-archive-on-date-past-date">
											Date must be in the future
										</Localized>
									)
								}
								autoFocus
								value={specificDate}
								onChange={(e) => {
									setSpecificDate(e.target.value);
									setIncorrectDate(false);
								}}
								disabled={schedule !== "on_date"}
								InputProps={{inputProps: {min: nowDateTimeLocal}}}
							/>
						</Grid>
					</Grid>

					{archivedSelected && (
						<Grid item xs={12} style={{marginTop: theme.spacing(2)}}>
							<DialogContentText align="justify">
								<Localized id="courses-archiving-dialog-warning-archived-selected">
									Warning: some of the selected courses are already archived.
									The change will not be applied to these courses.
								</Localized>
							</DialogContentText>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="primary" disabled={inProgress} onClick={onCancel}>
					<Localized id="courses-archiving-dialog-action-cancel">
						Cancel
					</Localized>
				</Button>
				<SubmitButton
					variant="text"
					disabled={loading}
					inProgress={inProgress}
					color="primary"
					onClick={archive}
				>
					<Localized id="courses-archiving-dialog-action-archive">
						Archive
					</Localized>
				</SubmitButton>
			</DialogActions>
		</>
	);
}

export default useArchiveCoursesAction;
