import type {AxiosInstance} from "axios";

import {axiosInstance as client} from "./axiosInstance";
import Feature from "../features/Feature";

type Response = {
	[key in Feature]: boolean;
};

function createService(client: AxiosInstance) {
	async function getFeatures() {
		const {data} = await client.get<Response>(`/features.json`);

		return Object.entries(data)
			.filter(([, value]) => value)
			.map(([key]) => key as Feature);
	}

	return {
		getFeatures: getFeatures,
	};
}

export default createService(client);
