import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@material-ui/core";
import {createStyles, makeStyles, useTheme} from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DomainIcon from "@material-ui/icons/Domain";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import React, {useEffect, useState} from "react";
import {Route, Link as RouterLink, useHistory} from "react-router-dom";

import LogoutIcon from "../../icons/LogoutIcon";
import NavProfileRoleSelector from "./NavProfileRoleSelector";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import fetchUserOrganisationGroups from "../../store/organisationGroups/fetchUserOrganisationGroups";
import selectUserOrganisationGroups, {
	selectUserOrganisationGroupsStatus,
} from "../../store/organisationGroups/selectUserOrganisationGroups";
import OrganisationGroup from "../../store/organisationGroups/UserOrganisationGroup";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import logoutUser from "../../store/userSession/logoutUser";

const useStyles = makeStyles((theme) =>
	createStyles({
		userName: {
			fontWeight: 500,
		},
		groupsListItem: {
			marginRight: theme.spacing(2),
		},
	})
);

interface Props {
	userId: number;
	children?: React.ReactNode;
	profileName: string;
	profileUserName: string;
	avatar: React.ReactElement;
	onClick: () => void;
}

function NavProfile(props: Props): JSX.Element {
	const dispatch = useAppDispatch();

	const history = useHistory();
	const classes = useStyles();

	const [groupSelectorOpen, setGroupSelectorOpen] = useState(false);

	const groups = useAppSelector(selectUserOrganisationGroups);
	const groupsFetchStatus = useAppSelector(selectUserOrganisationGroupsStatus);

	const userId = useAppSelector(selectUserId);

	useEffect(() => {
		if (groupsFetchStatus === "none") {
			dispatch(fetchUserOrganisationGroups({userId}));
		}
	}, [dispatch, groupsFetchStatus, userId]);

	const logout = () => {
		history.replace("/");
		dispatch(logoutUser({logoutOnServer: true}));
		props.onClick();
	};

	if (groupSelectorOpen) {
		return (
			<OrganisationGroupSelector
				groups={groups}
				onItemClick={props.onClick}
				onBack={() => setGroupSelectorOpen(false)}
			/>
		);
	}

	return (
		<>
			<List dense component="div">
				<ListItem>
					<ListItemAvatar>{props.avatar}</ListItemAvatar>
					<ListItemText
						primary={props.profileName}
						secondary={props.profileUserName}
						primaryTypographyProps={{
							variant: "subtitle1",
							className: classes.userName,
						}}
						secondaryTypographyProps={{variant: "caption"}}
					/>
				</ListItem>
				<ListItem
					button
					component={RouterLink}
					to={`/account`}
					onClick={() => {
						props.onClick();
					}}
				>
					<ListItemIcon>
						<AccountCircleIcon color="action" fontSize="small" />
					</ListItemIcon>
					<ListItemText
						primary={<Localized id="nav-account">Account</Localized>}
					/>
				</ListItem>
				{groups.length > 0 && (
					<ListItem button onClick={() => setGroupSelectorOpen(true)}>
						<ListItemIcon>
							<DomainIcon color="action" fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Localized id="nav-organisation-groups">
									Organisation groups
								</Localized>
							}
							className={classes.groupsListItem}
						/>
						<KeyboardArrowRightIcon color="action" />
					</ListItem>
				)}
				<Route path="/courses/:id">
					<NavProfileRoleSelector
						userId={props.userId}
						onClick={props.onClick}
					/>
				</Route>
			</List>
			<Divider />
			<List dense>
				<ListItem button onClick={logout}>
					<ListItemIcon>
						<LogoutIcon color="action" fontSize="small" />
					</ListItemIcon>
					<ListItemText
						primary={<Localized id="nav-logout">Logout</Localized>}
					/>
				</ListItem>
			</List>
		</>
	);
}

function OrganisationGroupSelector(props: {
	groups: OrganisationGroup[];
	onBack: () => void;
	onItemClick: () => void;
}) {
	const {groups} = props;

	const theme = useTheme();
	const {l10n} = useLocalization();

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				p={1}
				style={{gap: theme.spacing(0.5)}}
			>
				<IconButton
					aria-label={l10n.getString("nav-organisation-groups-label-back")}
					onClick={props.onBack}
				>
					<ArrowBackIcon />
				</IconButton>
				<Typography variant="subtitle1">
					<Localized id="nav-organisation-groups">
						Organisation groups
					</Localized>
				</Typography>
			</Box>

			<Divider />

			<List dense component="div">
				{groups.map((g) => (
					<ListItem
						key={g.name}
						button
						onClick={props.onItemClick}
						component="a"
						href={`/organisation-groups/${g.name}`}
					>
						<ListItemText primary={g.displayName} secondary={g.name} />
					</ListItem>
				))}
			</List>
		</>
	);
}

export default NavProfile;
