import {
	Box,
	IconButton,
	Slide,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

import Calculator from "./Calculator";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			position: "absolute",
			right: theme.spacing(-4),
			bottom: 0,
		},
		closeTab: {
			backgroundColor: theme.palette.background.default,
			borderRadius: "4px 0 0 4px",
			padding: theme.spacing(0.5),
			position: "absolute",
			transform: "translateX(-100%)",
			boxShadow: theme.shadows[4],
			clipPath: "inset(-8px 0px -8px -8px)",
		},
	})
);

const CalculatorPanel = (props: {
	open: boolean;
	onClose: () => void;
}): JSX.Element => {
	const {open} = props;

	const classes = useStyles();

	return (
		<Slide in={open} direction="left">
			<div className={classes.root}>
				<Box className={classes.closeTab}>
					<IconButton size="small" onClick={props.onClose}>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				</Box>
				<Calculator />
			</div>
		</Slide>
	);
};

export default CalculatorPanel;
