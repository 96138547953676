import {Avatar, createStyles, makeStyles} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		avatar: {
			background: theme.palette.secondary.main,
			color: "white",
		},
		small: {
			width: theme.spacing(3),
			height: theme.spacing(3),
			fontSize: "14px",
		},
	})
);

const StyledAvatar = (props: {
	children: React.ReactNode;
	size?: "small" | "medium";
}): JSX.Element => {
	const classes = useStyles();
	return (
		<Avatar
			className={clsx(classes.avatar, {
				[classes.small]: props.size === "small",
			})}
		>
			{props.children}
		</Avatar>
	);
};

export default StyledAvatar;
