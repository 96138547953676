import {Localized} from "@fluent/react";
import {Typography} from "@material-ui/core";
import React from "react";

import type Chapter from "../../store/chapters/Chapter";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import ProgressBar from "../../utils/ProgressBar";
import type ChapterProgress from "../../store/courseProgress/ChapterProgress";
import type {ProgressByType} from "../../store/services/analyticsService";
import ProgressBarLegend, {
	calculateValuesForColors,
} from "../analytics/ProgressBarLegend";
import ProgressTooltip from "../analytics/ProgressTooltip";
import StackedProgressBar from "../../utils/StackedProgressBar";
import CompletionLabel from "../analytics/CompletionLabel";
import PlainProgressBar from "../analytics/PlainProgressBar";
import ScoreLabel from "../analytics/ScoreLabel";

const TestProgress = (props: {
	test: Chapter;
	chapterProgress: ChapterProgress;
	bar?: {type: "plain"} | {type: "stacked"; progressByType: ProgressByType[]};
}): JSX.Element => {
	const {chapterProgress, test, bar} = props;

	if (!chapterProgress.startTime) {
		return (
			<ProgressBar
				theme="grey"
				leftLabel={
					<Typography variant="body2" color="textSecondary">
						<Localized id="chapter-progress-label-not-started">
							Not started
						</Localized>
					</Typography>
				}
			/>
		);
	}

	if (
		test.submissionMode === SubmissionMode.Individual ||
		chapterProgress.submissionTime
	) {
		const submitted = chapterProgress.outcomes.filter((o) => o.submissionTime);

		const score = submitted.reduce((prev, curr) => prev + curr.score, 0);

		const progressLabel = (
			<CompletionLabel
				completed={submitted.length}
				total={chapterProgress.numberOfExercises}
			/>
		);

		const scoreLabel = (
			<ScoreLabel score={score} maxScore={chapterProgress.maxScore} />
		);

		const overallProgress = {
			numberOfCompleted: submitted.length,
			score: score,
		};

		const chapterStats = {
			maxScore: chapterProgress.maxScore,
			numberOfExercises: chapterProgress.numberOfExercises,
		};

		return bar?.type === "stacked" ? (
			<StackedBar
				progressByType={bar.progressByType}
				overallProgress={overallProgress}
				chapterStats={chapterStats}
				leftLabel={progressLabel}
				rightLabel={scoreLabel}
			/>
		) : (
			<PlainProgressBar
				overallProgress={overallProgress}
				stats={chapterStats}
				leftLabel={progressLabel}
				rightLabel={scoreLabel}
			/>
		);
	}

	const answered = chapterProgress.outcomes.length;

	return (
		<ProgressBar
			theme="grey"
			value={answered}
			maxValue={chapterProgress.numberOfExercises}
			leftLabel={
				<Typography variant="body2" color="textSecondary">
					<Localized
						id="chapter-progress-label-answered"
						vars={{
							answered,
							total: chapterProgress.numberOfExercises,
						}}
					>
						{`Answered: ${answered}/${chapterProgress.numberOfExercises}`}
					</Localized>
				</Typography>
			}
		/>
	);
};

function StackedBar(props: {
	progressByType: ProgressByType[];
	overallProgress: {
		numberOfCompleted: number;
		score: number;
	};
	chapterStats: {
		numberOfExercises: number;
		maxScore: number;
	};
	leftLabel: React.ReactNode;
	rightLabel: React.ReactNode;
}) {
	const {progressByType, overallProgress, chapterStats} = props;

	const byType = progressByType.reduce(
		(prev, curr) => ({...prev, [curr.exerciseType]: curr}),
		{}
	);

	return (
		<ProgressTooltip
			completed={overallProgress.numberOfCompleted}
			total={chapterStats.numberOfExercises}
			score={overallProgress.score}
			maxScore={chapterStats.maxScore}
			additionalText={<ProgressBarLegend progress={byType} />}
		>
			<StackedProgressBar
				rightLabel={props.rightLabel}
				values={calculateValuesForColors(byType)}
				leftLabel={props.leftLabel}
				maxValue={chapterStats.numberOfExercises}
			/>
		</ProgressTooltip>
	);
}

export default TestProgress;
