import {useLocalization} from "@fluent/react";
import {Typography} from "@material-ui/core";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import ActionButtons from "./ActionButtons";
import SidebarBody from "../SidebarBody";
import SidebarContext from "../SidebarContext";
import SidebarHeader from "../SidebarHeader";
import SidebarSection from "../SidebarSection";
import selectChapterExercise from "../../../store/chapterExercises/selectChapterExercise";
import selectChapter from "../../../store/chapters/selectChapter";
import {useAppSelector} from "../../../store/hooks";
import {keyProvider} from "../../../store/keyProvider";
import makeSelectSection from "../../../store/sections/makeSelectSection";
import {tutoringService} from "../../../store/services/tutoringService";
import useQuestionFileUploader from "../../tutoring/useQuestionFileUploader";
import AppBarDependentDrawer from "../../../utils/AppBarDependentDrawer";
import {selectUserId} from "../../../store/userProfile/selectUserProfile";
import TextEditorWithAttachments from "../../../utils/TextEditor/TextEditorWithAttachments";

const AskTutorSidebar = (props: {
	open: boolean;
	width: number | string;
	sectionKey: string;
	courseId: number;
	mobileMode: boolean;
}): JSX.Element => {
	const {l10n} = useLocalization();

	const {chapterExerciseKey, close, fullscreen, toggleFullscreen} = useContext(
		SidebarContext
	);

	const [question, setQuestion] = useState("");
	const [submitting, setSubmitting] = useState(false);

	const exercise = useAppSelector((state) =>
		selectChapterExercise(state, chapterExerciseKey)
	);

	const chapterName = useAppSelector(
		(state) =>
			selectChapter(state, keyProvider.chapter(exercise.chapterId))?.title ?? ""
	);

	const selectSection = useMemo(makeSelectSection, []);
	const section = useAppSelector((state) =>
		selectSection(state, props.sectionKey)
	);

	const studentId = useAppSelector(selectUserId);

	const [files, fileUploader] = useQuestionFileUploader(
		props.courseId,
		studentId
	);

	useEffect(() => {
		setQuestion("");
		close();
	}, [close, props.sectionKey]);

	const currentExerciseKey = useRef(chapterExerciseKey);
	useEffect(() => {
		if (
			chapterExerciseKey !== "" &&
			currentExerciseKey.current !== chapterExerciseKey
		) {
			setQuestion("");
			currentExerciseKey.current = chapterExerciseKey;
		}
	}, [chapterExerciseKey]);

	function clearAndClose() {
		setQuestion("");
		close();
	}

	async function submit() {
		setSubmitting(true);

		try {
			await tutoringService.sendQuestion(
				props.courseId,
				studentId,
				{
					chapterId: exercise.chapterId,
					exerciseId: exercise.id,
					exerciseType: exercise.type,
					text: question,
				},
				files.map((f) => f.id)
			);

			clearAndClose();
		} finally {
			setSubmitting(false);
		}
	}

	return (
		<AppBarDependentDrawer
			open={props.open}
			variant={props.mobileMode || fullscreen ? "temporary" : "persistent"}
			width={props.width}
			anchor="right"
			topFixedPanel={
				<SidebarHeader
					title={l10n.getString("sidebars-ask-tutor-sidebar-header-title")}
					fullscreen={fullscreen}
					closureDisabled={submitting}
					onToggleFullscreen={props.mobileMode ? undefined : toggleFullscreen}
					onClose={close}
				/>
			}
			bottomFixedPanel={
				<ActionButtons
					submitDisabled={!question}
					submitInProgress={submitting}
					onSubmit={submit}
					onCancel={clearAndClose}
				/>
			}
			unmountOnExit
			onClose={close}
		>
			<SidebarBody>
				<SidebarSection
					title={l10n.getString(
						"sidebars-ask-tutor-sidebar-header-title-about"
					)}
				>
					<Typography variant="body1">
						{`${chapterName} / ${section?.title} / ` +
							`${section?.number}.${exercise.number} ${exercise.title}`}
					</Typography>
				</SidebarSection>

				<SidebarSection
					title={l10n.getString(
						"sidebars-ask-tutor-sidebar-section-question-title"
					)}
				>
					<TextEditorWithAttachments
						mode="basic"
						readonly={submitting}
						value={question}
						onChange={setQuestion}
						fileUploader={fileUploader}
						files={files}
					/>
				</SidebarSection>
			</SidebarBody>
		</AppBarDependentDrawer>
	);
};

export default AskTutorSidebar;
