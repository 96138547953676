import {Localized} from "@fluent/react";
import React from "react";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const AnswerStepEditMenu = (props: {
	onEditClick: () => void;
	onCopyClick: () => void;
	onDeleteClick: () => void;
}): JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const iconClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const editHandler = () => {
		setAnchorEl(null);
		props.onEditClick();
	};

	const copyHandler = () => {
		setAnchorEl(null);
		props.onCopyClick();
	};

	const deleteHandler = () => {
		setAnchorEl(null);
		props.onDeleteClick();
	};

	return (
		<>
			<IconButton onClick={iconClickHandler} disableRipple size="small">
				<MoreVertIcon color="primary" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				keepMounted
				onClose={() => {
					setAnchorEl(null);
				}}
				elevation={0}
			>
				<MenuItem onClick={editHandler}>
					<Localized id="exercise-math-response-area-step-action-edit">
						Edit
					</Localized>
				</MenuItem>
				<MenuItem onClick={copyHandler}>
					<Localized id="exercise-math-response-area-step-action-copy">
						Copy
					</Localized>
				</MenuItem>
				<MenuItem onClick={deleteHandler}>
					<Localized id="exercise-math-response-area-step-action-delete">
						Delete
					</Localized>
				</MenuItem>
			</Menu>
		</>
	);
};

export default AnswerStepEditMenu;
