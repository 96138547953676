import {Localized, useLocalization} from "@fluent/react";
import {Box, Dialog, Grid, useMediaQuery, useTheme} from "@material-ui/core";
import ListAlt from "@material-ui/icons/ListAlt";
import React, {useEffect, useMemo, useState} from "react";
import {unwrapResult} from "@reduxjs/toolkit";

import StudentResultsWidget from "./StudentResultsWidget";
import StudentActivityWidget from "./StudentActivityWidget";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {fetchCourseStats} from "../../store/courseStatistics/fetchCourseStats";
import selectCourseStatsFetchStatus from "../../store/courseStatistics/selectCourseStatsFetchStatus";
import selectCourseChaptersFetchStatus from "../../store/chapters/selectCourseChaptersFetchStatus";
import {getChapters} from "../../store/chapters/getChapters";
import {keyProvider} from "../../store/keyProvider";
import ExtendableBox from "../../utils/ExtendableBox";
import OpenNavigationButton from "../../utils/OpenNavigationButton";
import type {WidgetDefinitions} from "../analytics/widget";
import {DataFetcher} from "../analytics/widget/dataFetch";
import {createDataFetcher} from "../analytics/widget/dataFetch";
import useWidgetDataFetch from "../analytics/widget/useWidgetDataFetch";
import WidgetNavigation from "../analytics/widget/WidgetNavigation";
import fetchWidgets from "../../store/widgets/fetchWidgets";
import selectWidgetKeys, {
	selectWidgetFetchStatus,
} from "../../store/widgets/selectWidgetKeys";
import WidgetKey, {FollowUpWidgetKey} from "../../store/widgets/WidgetKey";
import insertWidget from "../../store/widgets/insertWidget";
import {useHistory} from "react-router-dom";
import removeWidget from "../../store/widgets/removeWidget";
import AddWidgetDialog from "../analytics/widget/AddWidgetDialog";
import NoWidgetsEmptyState from "../analytics/widget/NoWidgetsEmptyState";
import selectWidgetEditing from "../../store/widgets/selectWidgetEditing";
import useSnackbar from "../../store/ui/useSnackbar";
import ScrollToTopOnMount from "../../utils/ScrollToTopOnMount";
import fetchCourseContentStatistics from "../../store/courseStatistics/fetchCourseContentStatistics";
import selectCourseContentStatisticsFetchStatus from "../../store/courseStatistics/selectCourseContentStatisticsFetchStatus";

const widgetsNavigationDrawerWidth = 360;

type DataKeys = "course_stats" | "chapters" | "course_content_stats";

const widgetDefinitions: WidgetDefinitions<
	FollowUpWidgetKey,
	{courseId: number; userId: number},
	DataKeys
> = {
	student_results: {
		title: (
			<Localized id="follow-up-student-results-widget-title">
				Student results
			</Localized>
		),
		component: StudentResultsWidget,
		requiredData: ["course_stats", "chapters", "course_content_stats"],
	},
	student_activity: {
		title: (
			<Localized id="follow-up-student-activity-widget-title">
				Student activity
			</Localized>
		),
		component: StudentActivityWidget,
		requiredData: [],
	},
};

const allWidgetKeys: FollowUpWidgetKey[] = [
	"student_activity",
	"student_results",
];

const widgetDescriptions = {
	student_activity: {
		icon: <ListAlt color="primary" />,
		title: (
			<Localized id="follow-up-student-activity-widget-title">
				Student activity
			</Localized>
		),
		description: (
			<Localized id="follow-up-student-activity-widget-descr">
				Follow student activity in the course
			</Localized>
		),
	},
	student_results: {
		icon: <ListAlt color="primary" />,
		title: (
			<Localized id="follow-up-student-results-widget-title">
				Student results
			</Localized>
		),
		description: (
			<Localized id="follow-up-student-results-widget-descr">
				Search student results in the course
			</Localized>
		),
	},
};

const FollowUp = (props: {
	courseId: number;
	userId: number;
	viewOnly?: boolean;
}): JSX.Element => {
	const {courseId, userId} = props;

	const theme = useTheme();

	const courseKey = keyProvider.course(courseId);

	const widgetKeys = useAppSelector((state) =>
		selectWidgetKeys(state, "follow-up", courseKey)
	);
	const widgetFetchStatus = useAppSelector((state) =>
		selectWidgetFetchStatus(state, "follow-up", courseKey)
	);
	const widgetEditRunning = useAppSelector(selectWidgetEditing);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (widgetFetchStatus === "none") {
			dispatch(fetchWidgets({courseId, dashboardName: "follow-up", userId}));
		}
	}, [courseId, dispatch, userId, widgetFetchStatus]);

	const [navigationOpened, setNavigationOpened] = useState(false);

	const [addWidgetDialogOpen, setAddWidgetDialogOpen] = useState(false);

	const fetcher = useMemo<DataFetcher<DataKeys>>(() => {
		const courseKey = keyProvider.course(courseId);

		return createDataFetcher(
			dispatch,
			{
				chapters: (state) => selectCourseChaptersFetchStatus(state, courseKey),
				course_stats: (state) => selectCourseStatsFetchStatus(state, courseKey),
				course_content_stats: (state) =>
					selectCourseContentStatisticsFetchStatus(state, courseKey),
			},
			{
				chapters: () => getChapters(courseId),
				course_stats: () => fetchCourseStats({courseId}),
				course_content_stats: () => fetchCourseContentStatistics({courseId}),
			}
		);
	}, [courseId, dispatch]);

	const statuses = useWidgetDataFetch(
		fetcher,
		widgetDefinitions,
		["course_stats", "course_content_stats"],
		widgetKeys
	);

	const mobileMode = useMediaQuery(theme.breakpoints.down("sm"));

	const history = useHistory();

	const showSnackbar = useSnackbar();

	const {l10n} = useLocalization();
	const errorMsg = l10n.getString("error-general");

	function toggleNavigation() {
		setNavigationOpened((prev) => !prev);
	}

	function closeDialog() {
		setAddWidgetDialogOpen(false);
	}

	async function addWidget(widgetName: WidgetKey) {
		closeDialog();

		try {
			const res = await dispatch(
				insertWidget({
					courseId,
					dashboardName: "follow-up",
					userId,
					widgetName,
				})
			);

			unwrapResult(res);

			if (!mobileMode) {
				history.push(`#${name}`);
			}
		} catch {
			showSnackbar("error", errorMsg);
		}
	}

	async function deleteWidget(widgetKey: FollowUpWidgetKey) {
		try {
			const res = await dispatch(
				removeWidget({
					courseId,
					dashboardName: "follow-up",
					userId,
					widgetKey,
				})
			);

			unwrapResult(res);

			if (history.location.hash === `#${widgetKey}`) {
				history.push(history.location.pathname + history.location.search);
			}
		} catch {
			showSnackbar("error", errorMsg);
		}
	}

	async function reorderWidgets(
		widgetName: FollowUpWidgetKey,
		before: FollowUpWidgetKey
	) {
		try {
			const res = await dispatch(
				insertWidget({
					courseId,
					dashboardName: "follow-up",
					userId,
					widgetName,
					place: {before},
				})
			);

			unwrapResult(res);
		} catch {
			showSnackbar("error", errorMsg);
		}
	}

	const notAddedWidgets = allWidgetKeys.filter((k) => !widgetKeys.includes(k));

	return (
		<Box display="flex" maxWidth={theme.breakpoints.values.xl}>
			<ScrollToTopOnMount />

			<WidgetNavigation
				open={navigationOpened}
				mobileMode={mobileMode}
				header={
					<Localized id="course-management-subtitlenav-label-follow-up">
						Follow-up
					</Localized>
				}
				widgetKeys={widgetKeys}
				operationRunning={widgetEditRunning}
				onReorder={reorderWidgets}
				onDelete={deleteWidget}
				getWidgetTitle={(key) => widgetDefinitions[key].title}
				onClose={toggleNavigation}
				onAdd={
					notAddedWidgets.length > 0
						? () => setAddWidgetDialogOpen(true)
						: undefined
				}
			/>

			<ExtendableBox
				leftExtension={
					navigationOpened || mobileMode ? 0 : widgetsNavigationDrawerWidth
				}
				display="flex"
				p={6}
				minWidth={0}
			>
				<OpenNavigationButton
					hidden={navigationOpened && !mobileMode}
					onClick={toggleNavigation}
				/>

				{widgetKeys.length > 0 ? (
					<Grid container spacing={6}>
						{widgetKeys.map((key) => {
							const w = widgetDefinitions[key];
							return (
								<Grid item xs={12} key={key}>
									<w.component
										id={key}
										title={w.title}
										courseId={courseId}
										userId={userId}
										viewOnly={props.viewOnly}
										fetch={fetcher.fetch}
										fetchStatuses={statuses}
									/>
								</Grid>
							);
						})}
					</Grid>
				) : (
					widgetFetchStatus === "succeeded" && (
						<NoWidgetsEmptyState onAdd={() => setAddWidgetDialogOpen(true)} />
					)
				)}
			</ExtendableBox>

			<Dialog open={addWidgetDialogOpen} onClose={closeDialog} maxWidth="sm">
				<AddWidgetDialog
					widgetDescriptions={widgetDescriptions}
					widgetsToAdd={notAddedWidgets}
					onSelect={addWidget}
					onClose={closeDialog}
				/>
			</Dialog>
		</Box>
	);
};

export default FollowUp;
