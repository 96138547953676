import {Localized, useLocalization} from "@fluent/react";
import {Box, Typography, useTheme} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import {unwrapResult} from "@reduxjs/toolkit";
import React from "react";

import ProfileEditor from "../account/ProfileEditor";
import useMobileMode from "../../hooks/useMobileMode";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import type User from "../../store/services/dtos/User";
import useSnackbar from "../../store/ui/useSnackbar";
import selectUserProfile from "../../store/userProfile/selectUserProfile";
import updateUserProfile from "../../store/users/updateUserProfile";
import useChangeEmailAction from "./useChangeEmailAction";
import useChangePasswordAction from "./useChangePasswordAction";

function Profile(props: {
	user: User;
	onUpdate: (val: (u: User) => User) => void;
}) {
	const {user} = props;

	const currentUser = useAppSelector(selectUserProfile);

	const dispatch = useAppDispatch();
	const theme = useTheme();
	const showSnackbar = useSnackbar();
	const mobileMode = useMobileMode("xs");
	const {l10n} = useLocalization();

	const [changePassword, changePasswordDialog] = useChangePasswordAction(
		mobileMode,
		() => {
			showSnackbar("success", l10n.getString("users-profile-password-changed"));
			props.onUpdate((prev) => ({...prev, passwordSet: true}));
		},
		() =>
			showSnackbar(
				"error",
				l10n.getString("users-profile-password-change-failed")
			)
	);

	const [changeEmail, changeEmailDialog] = useChangeEmailAction(
		mobileMode,
		(email) => {
			showSnackbar("success", l10n.getString("users-profile-email-changed"));
			props.onUpdate((prev) => ({...prev, email}));
		},
		() => showSnackbar("error", l10n.getString("error-general"))
	);

	async function saveChanges(updated: {
		language: string;
		firstName: string;
		lastName: string;
		phoneNumber?: string;
	}) {
		try {
			const res = await dispatch(
				updateUserProfile({userId: user.id, profile: updated})
			);
			unwrapResult(res);

			showSnackbar("success", l10n.getString("success-on-saving"));

			props.onUpdate((prev) => ({...prev, ...updated}));

			return true;
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}
	}

	const canChangeEmailOrPassword =
		user.id !== currentUser.id &&
		(currentUser.isSuperAdmin || !user.memberOfMultipleOrganisations);

	return (
		<>
			<Box maxWidth={2048} p={6}>
				<ProfileEditor
					user={user}
					accountInfoNotice={
						!canChangeEmailOrPassword ? (
							<Box
								display="flex"
								alignItems="center"
								style={{gap: theme.spacing(2)}}
							>
								<WarningIcon style={{color: theme.palette.warning.main}} />
								{user.id === currentUser.id && (
									<Typography align="justify">
										<Localized id="users-profile-editing-warning-own-account">
											You cannot change email address and password on this page.
											Please, use Account page to update credentials.
										</Localized>
									</Typography>
								)}
								{user.id !== currentUser.id &&
									user.memberOfMultipleOrganisations && (
										<Typography align="justify">
											<Localized id="users-profile-editing-warning-multiple-orgs-member">
												Only account owner can change email address and password
												because this user is a member of multiple organisations.
											</Localized>
										</Typography>
									)}
							</Box>
						) : undefined
					}
					onChange={saveChanges}
					onPasswordChangeClick={
						canChangeEmailOrPassword ? () => changePassword(user.id) : undefined
					}
					onEmailChangeClick={
						canChangeEmailOrPassword
							? () => changeEmail(user.id, user.email)
							: undefined
					}
				/>
			</Box>

			{changeEmailDialog}
			{changePasswordDialog}
		</>
	);
}

export default Profile;
