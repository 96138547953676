import React, {useState} from "react";
import EnrolInGroupCourseDialog from "./EnrolInGroupCourseDialog";

function useEnrolInGroupCourseAction(
	groupName: string,
	mobileMode: boolean,
	onDone?: () => void
): [(userIds: number[]) => void, JSX.Element] {
	const [userIds, setUserIds] = useState<number[]>([]);

	function enrolInGroupCourse(userIds: number[]) {
		setUserIds(userIds);
	}

	return [
		enrolInGroupCourse,
		<EnrolInGroupCourseDialog
			key="enrol-in-course"
			open={userIds.length > 0}
			mobileMode={mobileMode}
			groupName={groupName}
			userIds={userIds}
			onCancel={() => {
				setUserIds([]);
			}}
			onDone={() => {
				setUserIds([]);
				onDone?.();
			}}
		/>,
	];
}

export default useEnrolInGroupCourseAction;
