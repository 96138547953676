import {Localized, useLocalization} from "@fluent/react";
import {
	Checkbox,
	IconButton,
	LinearProgress,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import type {TableCellProps} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";

import {populate} from "../../utils/populate";
import WidgetTitle from "../analytics/widget/WidgetTitle";
import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import parseDate from "../../helpers/parseDate";
import type {
	CourseStudentSearchCriteria,
	CourseStudentSearchResult,
} from "../../store/services/courseService";
import {courseService} from "../../store/services/courseService";
import StudentName from "./StudentName";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import TablePagination from "../../utils/tables/TablePagination";
import TableToolbar from "../../utils/tables/TableToolbar";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import usePaginationState from "../../utils/tables/usePaginationState";

function StudentsWidget(props: {courseId: number; viewOnly?: boolean}) {
	return (
		<Widget id="students">
			<WidgetTitle>
				<Localized id="student-management-students-title">Students</Localized>
			</WidgetTitle>
			<WidgetBody>
				<StudentSearch courseId={props.courseId} viewOnly={props.viewOnly} />
			</WidgetBody>
		</Widget>
	);
}

const headCells: {
	id: string;
	label: React.ReactNode;
	align?: TableCellProps["align"];
}[] = [
	{
		id: "name",
		label: (
			<Localized id="student-management-students-table-column-name">
				Student name
			</Localized>
		),
	},
	{
		id: "enrolmentDate",
		label: (
			<Localized id="student-management-students-table-column-enrolment-date">
				Enrolment date
			</Localized>
		),
		align: "right",
	},
];

const initialCriteria: CourseStudentSearchCriteria = {query: ""};

const emptyResult: CourseStudentSearchResult = {
	id: 0,
	userName: "",
	enrolmentDate: "2022-02-02",
	enrolmentStatus: "accepted",
};

function StudentSearch(props: {courseId: number; viewOnly?: boolean}) {
	const {courseId} = props;

	const [criteria, setCriteria] = useState(initialCriteria);

	const setQuery = useCallback((val: string) => {
		setCriteria((prev) => ({...prev, query: val}));
	}, []);

	const [studentsPerPage, setStudentsPerPage] = useState(10);

	const {
		page: studentsPage,
		fetchFirstPage,
		fetchRelatedPage,
		pageFetchStatus,
		retryFetching,
	} = usePaginationState<CourseStudentSearchResult>();

	const students = populate(
		studentsPerPage,
		pageFetchStatus === "succeeded" && studentsPage?.content
			? studentsPage.content
			: [],
		() => ({...emptyResult, id: Math.random()})
	);

	const {
		select: selectStudent,
		bulkSelectionCheckbox,
		selected: selectedStudents,
		resetSelection,
	} = useBulkSelection(studentsPage, (s) => s.id);

	const {l10n} = useLocalization();

	useEffect(() => {
		fetchFirstPage(
			() =>
				courseService.searchCourseStudents(
					courseId,
					criteria,
					{field: "lastName"},
					studentsPerPage
				),
			resetSelection
		);
	}, [courseId, criteria, fetchFirstPage, resetSelection, studentsPerPage]);

	function selectedStudent(id: number) {
		return selectedStudents.includes(id);
	}

	const loading = pageFetchStatus === "pending";

	const columnNumber = headCells.length + (props.viewOnly ? 0 : 1);

	return (
		<Stack spacing={2} sx={{width: 1}}>
			<TableToolbar
				query={criteria.query}
				actions={[]}
				onQueryChange={setQuery}
				searchPlaceholder={l10n.getString(
					"student-management-students-search-placeholder"
				)}
			/>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						{!props.viewOnly && (
							<TableCell padding="checkbox" style={{zIndex: 3}}>
								{bulkSelectionCheckbox}
							</TableCell>
						)}
						{headCells.map((c) => (
							<TableCell key={c.id} align={c.align}>
								{c.label}
							</TableCell>
						))}
						{false && <TableCell padding="checkbox" />}
					</TableRow>
					<TableRow sx={{visibility: loading ? "visible" : "hidden"}}>
						<TableCell
							colSpan={columnNumber}
							padding="none"
							sx={{
								top: (theme) => `calc(1.5rem + ${theme.spacing(4)} + 1px)`,
								border: 0,
							}}
						>
							<LinearProgress />
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody sx={{position: "relative"}}>
					{pageFetchStatus === "failed" && (
						<LoadingErrorState
							description={
								<Localized id="student-management-students-loading-error-descr">
									Something has gone wrong, and we cannot load students
								</Localized>
							}
							columnNumber={columnNumber}
							spanWholeTable
							onReload={retryFetching}
						/>
					)}
					{pageFetchStatus === "succeeded" &&
						studentsPage.content.length === 0 && (
							<NoSearchResultsState
								columnNumber={columnNumber}
								title={
									<Localized id="student-management-students-no-students">
										No students
									</Localized>
								}
								description={
									criteria === initialCriteria ? (
										<Localized id="student-management-students-no-students-descr">
											There are no students in the course
										</Localized>
									) : (
										<Localized id="student-management-students-no-results-descr">
											No students were found matching your search criteria. Try
											to adjust filters
										</Localized>
									)
								}
								spanWholeTable
							/>
						)}
					{students.map((s) => {
						const selected = selectedStudent(s.id);

						return (
							<StudentRow
								key={s.id}
								student={s}
								loading={loading}
								selectionCellContent={
									!props.viewOnly && (
										<Checkbox
											checked={selected}
											inputProps={{
												"aria-label": l10n.getString(
													"student-management-students-select-label"
												),
											}}
											onClick={() => selectStudent(s.id)}
										/>
									)
								}
								selected={selected}
							/>
						);
					})}
				</TableBody>
			</Table>
			<Stack
				direction="row"
				sx={{justifyContent: "space-between", alignItems: "center"}}
			>
				<Typography variant="subtitle2">
					{selectedStudents.length > 0 && (
						<Localized
							id="student-management-students-selected-row-number"
							vars={{selected: selectedStudents.length}}
						>{`${selectedStudents.length} selected`}</Localized>
					)}
				</Typography>
				<TablePagination
					onPageChange={fetchRelatedPage}
					pageSize={studentsPerPage}
					onPageSizeChange={setStudentsPerPage}
					first={Boolean(studentsPage.request.first)}
					last={Boolean(studentsPage.request.last)}
					next={Boolean(studentsPage.request.next)}
					previous={Boolean(studentsPage.request.previous)}
					label={
						<Localized id="student-management-students-per-page">
							Students per page
						</Localized>
					}
					disabled={pageFetchStatus !== "succeeded"}
				/>
			</Stack>
		</Stack>
	);
}

function StudentRow(props: {
	student: CourseStudentSearchResult;
	selected: boolean;
	loading?: boolean;
	selectionCellContent?: React.ReactNode;
	actionIcon?: React.ReactNode;
	onAction?: () => void;
}) {
	const {student: s} = props;

	const formatDate = useDateTimeFormat();

	return (
		<TableRow
			hover
			tabIndex={-1}
			selected={props.selected}
			sx={[
				!s.userName &&
					!props.loading && {
						visibility: "hidden",
						"& > td": {
							borderBottom: "1px solid transparent",
						},
					},
			]}
		>
			{props.selectionCellContent && (
				<TableCell padding="checkbox">{props.selectionCellContent}</TableCell>
			)}
			<TableCell sx={{width: 0.6}}>
				<StudentName student={s} loading={props.loading} />
			</TableCell>
			<TableCell align="right">
				{formatDate(parseDate(s.enrolmentDate))}
			</TableCell>
			{false && (
				<TableCell padding="checkbox">
					{!props.loading && (
						<IconButton onClick={props.onAction}>{props.actionIcon}</IconButton>
					)}
				</TableCell>
			)}
		</TableRow>
	);
}

export default StudentsWidget;
