import {Localized} from "@fluent/react";
import {Typography} from "@material-ui/core";
import React from "react";

import ForbiddenIcon from "../../icons/ForbiddenIcon";
import PageContainer from "../../utils/PageContainer";

function AccessDeniedError() {
	return (
		<PageContainer>
			<ForbiddenIcon style={{height: "50px", width: "50px"}} color="primary" />
			<Typography variant="h6">
				<Localized id="error-access-denied-title">Access denied</Localized>
			</Typography>
			<Typography>
				<Localized id="error-access-denied-text">
					We are sorry, but you do not have permission to access this page
				</Localized>
			</Typography>
		</PageContainer>
	);
}

export default AccessDeniedError;
