import {Localized} from "@fluent/react";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	Typography,
	createStyles,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, {useEffect, useState} from "react";
import LoginLogo from "./LoginLogo";

const useStyles = makeStyles(() =>
	createStyles({
		mainBox: {
			height: "100vh",
		},
	})
);

const PasswordReset = (): JSX.Element => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);

	const handleTogglePasswordVisible = () => {
		setShowPassword((prevState) => !prevState);
	};

	const [password, setPassword] = useState("");
	const [repeat, setRepeat] = useState("");
	const [passwordsMatch, setPasswordsMatch] = useState(true);

	const handleChange = (
		e: {target: {value: string}},
		set: (e: string) => void
	) => {
		set(e.target.value);
	};

	const handleSetPassword = () => {
		if (password !== repeat) {
			setPasswordsMatch(false);
		}
	};

	useEffect(() => {
		if (repeat.length > 0 && password !== repeat) {
			setPasswordsMatch(false);
		} else if (password === repeat || repeat.length === 0) {
			setPasswordsMatch(true);
		}
	}, [password, repeat]);

	return (
		<Box mr={6}>
			<Grid
				container
				direction="row"
				alignItems="stretch"
				className={classes.mainBox}
			>
				<Grid xs={6} item>
					<LoginLogo logo="" />
				</Grid>
				<Grid xs={6} item>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						className={classes.mainBox}
					>
						<Grid
							container
							item
							xs={7}
							direction="column"
							justifyContent="center"
							spacing={1}
						>
							<Grid item>
								<Typography component="h1" variant="h4">
									Reset password
								</Typography>
							</Grid>
							<Grid item>
								<FormControl style={{width: "100%"}}>
									<InputLabel htmlFor="password">
										<Localized id="login-new-password">New password</Localized>
									</InputLabel>
									<Input
										id="password"
										type={showPassword ? "text" : "password"}
										value={password}
										onChange={(e) => handleChange(e, setPassword)}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleTogglePasswordVisible}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
									/>
									<FormHelperText id="password">
										<Localized id="login-password-minimum-chars">
											Minimum 6 characters
										</Localized>
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item>
								<FormControl style={{width: "100%"}}>
									<InputLabel htmlFor="repeatPassword">
										<Localized id="login-repeat-new-password">
											Repeat new password
										</Localized>
									</InputLabel>
									<Input
										id="repeatPassword"
										type={showPassword ? "text" : "password"}
										value={repeat}
										onChange={(e) => handleChange(e, setRepeat)}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleTogglePasswordVisible}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							</Grid>
							<Grid item>
								<Box
									visibility={passwordsMatch ? "hidden" : "visible"}
									color="error.main"
									textAlign="center"
								>
									<Typography variant="body1">
										<Localized id="login-password-mismatch">
											Passwords mismatch
										</Localized>
									</Typography>
								</Box>
								<Box mt={1}>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										disabled={password.length === 0 || repeat.length === 0}
										onClick={handleSetPassword}
									>
										set new password
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PasswordReset;
