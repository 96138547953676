import {Localized} from "@fluent/react";
import {List} from "@material-ui/core";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ReplyIcon from "@material-ui/icons/Reply";
import React from "react";

import {useAppSelector} from "../../store/hooks";
import selectAnswered from "../../store/tutorQuestions/selectAnswered";
import selectSent from "../../store/tutorQuestions/selectSent";
import NavigationItem from "./NavigationItem";
import TutorMessagesTab from "./TutorMessagesTab";
import AppBarDependentDrawer from "../../utils/AppBarDependentDrawer";

const TutorNavigation = (props: {
	open: boolean;
	selectedTab: TutorMessagesTab;
	variant: "temporary" | "permanent";
	width: string | number;
	onClose?: () => void;
	onSelect: (tab: TutorMessagesTab) => void;
}): JSX.Element => {
	const questionsCount = useAppSelector((state) => selectSent(state).length);
	const answersCount = useAppSelector((state) => selectAnswered(state).length);

	return (
		<AppBarDependentDrawer
			anchor="left"
			open={props.open}
			variant={props.variant}
			width={props.width}
			onClose={props.onClose}
		>
			<List>
				<NavigationItem
					onClick={() => props.onSelect(TutorMessagesTab.Questions)}
					icon={<ContactSupportIcon />}
					label={
						<Localized id="tutoring-tutor-navigation-questions-label">
							Questions
						</Localized>
					}
					messageCount={questionsCount}
					active={props.selectedTab === TutorMessagesTab.Questions}
				/>
				<NavigationItem
					onClick={() => props.onSelect(TutorMessagesTab.Answers)}
					icon={<ReplyIcon />}
					label={
						<Localized id="tutoring-tutor-navigation-answers-label">
							Answers
						</Localized>
					}
					messageCount={answersCount}
					active={props.selectedTab === TutorMessagesTab.Answers}
				/>
			</List>
		</AppBarDependentDrawer>
	);
};

export default TutorNavigation;
