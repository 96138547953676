import {Localized} from "@fluent/react";
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	TextField,
	Typography,
	useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import React, {
	createRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";

import type ProgExerciseTest from "../../store/exercises/prog/ProgExerciseTest";
import type {TestRunParams} from "../../store/services/progExerciseService";
import LightTooltip from "../../utils/LightTooltip";
import SubmitButton from "../../utils/SubmitButton";
import ProgAdditionalFile from "./ProgAdditionalFile";
import ProgramOutput from "../content/exercises/prog/ProgramOutput";

const ProgExerciseTests = (props: {
	tests: ProgExerciseTest[];
	onRunTest: (runParams: TestRunParams) => Promise<void>;
	onAddTest: () => Promise<number>;
	onDeleteTest: (number: number) => Promise<void>;
	onUploadInputFile: (testNumber: number, file: File) => void;
	onDeleteInputFile: (testNumber: number, filename: string) => void;
	onClickInputFile: (testNumber: number, filename: string) => void;
}): JSX.Element => {
	const {tests} = props;

	const [expandedTests, setExpandedTests] = useState<number[]>(() => []);

	const [testRefs, setTestRefs] = useState<{
		[key: string]: React.RefObject<ProgTestApi>;
	}>(() => ({}));

	const [runningAll, setRunningAll] = useState(false);

	useEffect(() => {
		setTestRefs((prev) => {
			const refs: {[key: string]: React.RefObject<ProgTestApi>} = {};
			tests.forEach((t) => {
				refs[t.number] = prev[t.number] ?? createRef<ProgTestApi>();
			});
			return refs;
		});
	}, [tests]);

	const addTest = async () => {
		const testNumber = await props.onAddTest();
		setExpandedTests((prev) => prev.concat(testNumber));
	};

	const deleteTest = async (number: number) => {
		await props.onDeleteTest(number);
		setExpandedTests((prev) => prev.filter((n) => n !== number));
	};

	const runAll = async () => {
		setRunningAll(true);
		try {
			for (const key in testRefs) {
				await testRefs[key].current?.run();
			}
		} finally {
			setRunningAll(false);
		}
	};

	return (
		<>
			<Box display="flex" justifyContent="space-between" mb={1}>
				<Typography variant="h6">
					<Localized id="exercise-prog-editor-tests-title">Tests</Localized>
				</Typography>
				<SubmitButton onClick={runAll} variant="text" inProgress={runningAll}>
					<Localized id="exercise-prog-editor-tests-run-all-btn">
						Run all
					</Localized>
				</SubmitButton>
			</Box>
			{tests.map((t) => (
				<ProgTest
					key={t.number}
					test={t}
					expanded={expandedTests.includes(t.number)}
					actionsDisabled={runningAll}
					onExpansionChange={(expanded) =>
						setExpandedTests((prev) =>
							expanded
								? prev.concat(t.number)
								: prev.filter((n) => n !== t.number)
						)
					}
					onDelete={() => deleteTest(t.number)}
					onRun={props.onRunTest}
					onUploadInputFile={props.onUploadInputFile}
					onDeleteInputFile={props.onDeleteInputFile}
					onClickInputFile={props.onClickInputFile}
					ref={testRefs[t.number]}
				/>
			))}
			<Box display="flex" justifyContent="flex-end" mt={2}>
				<Button color="primary" onClick={addTest} startIcon={<AddIcon />}>
					<Localized id="exercise-prog-editor-tests-add-test-btn">
						Add new test
					</Localized>
				</Button>
			</Box>
		</>
	);
};

type ProgTestApi = {
	run: () => Promise<void>;
};

const TestItem = (
	props: {
		test: ProgExerciseTest;
		expanded: boolean;
		actionsDisabled: boolean;
		onExpansionChange: (expanded: boolean) => void;
		onDelete: () => void;
		onRun: (runParams: TestRunParams) => Promise<void>;
		onUploadInputFile: (testNumber: number, file: File) => void;
		onDeleteInputFile: (testNumber: number, filename: string) => void;
		onClickInputFile: (testNumber: number, filename: string) => void;
	},
	ref: React.ForwardedRef<ProgTestApi>
): JSX.Element => {
	const {test, actionsDisabled} = props;

	const theme = useTheme();

	const [args, setArgs] = useState(test.args ?? "");
	const [input, setInput] = useState(test.input);

	const [running, setRunning] = useState(false);

	const uploadInputFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = e.target.files;

		if (!fileList) {
			return;
		}

		props.onUploadInputFile(test.number, fileList[0]);
	};

	const deleteInputFile = (name: string) => {
		props.onDeleteInputFile(test.number, name);
	};

	const run = async () => {
		setRunning(true);
		try {
			await props.onRun({
				args,
				input,
				inputFiles: test.inputFiles,
				number: test.number,
			});
		} finally {
			setRunning(false);
		}
	};

	useImperativeHandle(ref, () => ({run}));

	return (
		<Accordion
			key={test.number}
			expanded={props.expanded}
			onChange={(_, expanded) => props.onExpansionChange(expanded)}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Box display="flex" alignItems="center" style={{gap: theme.spacing(1)}}>
					<TestStatus
						status={running ? "updating" : test.status}
						description={test.errorDescription}
					/>
					<Localized
						id="exercise-prog-editor-tests-test-name"
						vars={{number: test.number}}
					>
						{`Test ${test.number}`}
					</Localized>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							label={
								<Localized id="exercise-prog-editor-tests-cmd-line-args-label">
									Command line arguments
								</Localized>
							}
							value={args}
							onChange={({target}) => setArgs(target.value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label={
								<Localized id="exercise-prog-editor-tests-input-label">
									Input
								</Localized>
							}
							value={input}
							onChange={({target}) => setInput(target.value)}
							fullWidth
							multiline
						/>
					</Grid>
					<Grid item xs={12}>
						<Box display="flex" alignItems="center" onChange={uploadInputFile}>
							<Typography variant="subtitle2">
								<Localized id="exercise-prog-editor-tests-files-label">
									Files
								</Localized>
							</Typography>
							<IconButton size="small" color="primary" component="label">
								<AddIcon />
								<input type="file" hidden />
							</IconButton>
						</Box>
						{test.inputFiles.map((file) => (
							<ProgAdditionalFile
								key={file.name}
								file={file.name}
								onDelete={() => deleteInputFile(file.name)}
								onClick={() => props.onClickInputFile(test.number, file.name)}
							/>
						))}
					</Grid>
					{test.exampleOutput && (
						<Grid item xs={12}>
							<Typography
								variant="subtitle2"
								style={{marginBottom: theme.spacing(1)}}
							>
								<Localized id="exercise-prog-editor-tests-output-label">
									Generated output
								</Localized>
							</Typography>
							<ProgramOutput>{test.exampleOutput}</ProgramOutput>
						</Grid>
					)}
				</Grid>
			</AccordionDetails>
			<AccordionActions>
				<Button
					color="primary"
					onClick={props.onDelete}
					disabled={running || actionsDisabled}
				>
					<Localized id="exercise-prog-editor-tests-delete-btn">
						Delete
					</Localized>
				</Button>
				<SubmitButton
					variant="text"
					onClick={run}
					disabled={actionsDisabled}
					inProgress={running}
				>
					<Localized id="exercise-prog-editor-tests-run-btn">
						Save and run
					</Localized>
				</SubmitButton>
			</AccordionActions>
		</Accordion>
	);
};

const ProgTest = React.forwardRef(TestItem);

function TestStatus(props: {
	status: "error" | "updated" | "not_updated" | "updating";
	description?: string;
}) {
	const theme = useTheme();

	switch (props.status) {
		case "updating":
			return <CircularProgress color="primary" size="1.5rem" />;
		case "error":
			return (
				<LightTooltip
					title={
						<Typography>
							{props.description ?? (
								<Localized id="exercise-prog-editor-tests-status-error">
									Error
								</Localized>
							)}
						</Typography>
					}
					placement="top-start"
				>
					<ErrorOutlineOutlinedIcon color="error" />
				</LightTooltip>
			);
		case "updated":
			return (
				<LightTooltip
					title={
						<Typography>
							<Localized id="exercise-prog-editor-tests-status-updated">
								Updated
							</Localized>
						</Typography>
					}
					placement="top-start"
				>
					<CheckCircleOutlineOutlinedIcon
						style={{color: theme.palette.success.main}}
					/>
				</LightTooltip>
			);
		case "not_updated":
			return (
				<LightTooltip
					title={
						<Typography>
							<Localized id="exercise-prog-editor-tests-status-not-updated">
								Not updated
							</Localized>
						</Typography>
					}
					placement="top-start"
				>
					<WarningOutlinedIcon style={{color: theme.palette.warning.main}} />
				</LightTooltip>
			);
	}
}

export default ProgExerciseTests;
