import {Localized} from "@fluent/react";
import {Typography} from "@material-ui/core";
import React from "react";

import LightTooltip from "../../utils/LightTooltip";
import calculatePercentage from "../../helpers/calculatePercentage";

const ProgressTooltip = (props: {
	completed: number;
	total?: number;
	score: number;
	maxScore?: number;
	additionalText?: React.ReactNode;
	children: React.ReactElement;
}): JSX.Element => {
	const {completed, total, score, maxScore} = props;

	const progressPercentage = total && calculatePercentage(completed, total);
	const scorePercentage = maxScore && calculatePercentage(score, maxScore);

	return (
		<LightTooltip
			title={
				<>
					<Typography>
						<Localized
							id={
								(total || total === 0) && maxScore
									? "progress-tooltip-title"
									: "progress-tooltip-title-no-total"
							}
							vars={
								(total || total === 0) && maxScore
									? {
											completed,
											total,
											score,
											maxScore,
											progressPercentage: progressPercentage ?? 0,
											scorePercentage: scorePercentage ?? 0,
									  }
									: {completed, score}
							}
						>
							{`Completed ${completed}/${total}, ${progressPercentage}%; ` +
								`score ${score}/${maxScore}, ${scorePercentage}%`}
						</Localized>
					</Typography>
					{props.additionalText}
				</>
			}
			placement="top-start"
			enterDelay={500}
		>
			<div>{props.children}</div>
		</LightTooltip>
	);
};

export default ProgressTooltip;
