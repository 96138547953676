import {Container} from "@material-ui/core";
import React from "react";

import useNavBarHeight from "../hooks/useNavBarHeight";

const PageContainer = (props: {
	children: NonNullable<React.ReactNode>;
}): JSX.Element => {
	const navBarHeight = useNavBarHeight();

	return (
		<Container
			maxWidth="md"
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: `calc(100vh - ${navBarHeight}px)`,
			}}
		>
			{props.children}
		</Container>
	);
};

export default PageContainer;
