import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {courseService} from "./services/courseService";
import {Statistic, statisticsService} from "./services/statisticsService";
import StudentDashboardState from "./models/StudentDashboardState";
import UserRole from "./models/UserRole";
import Link from "./models/Link";
import changeCoursePicture from "./courses/changeCoursePicture";
import {setCoursePicture} from "../helpers/storeHelper";
import deleteCoursePicture from "./courses/deleteCoursePicture";
import fetchEnrolmentApplications from "./enrolment/fetchEnrolmentApplications";
import enrolInCourse from "./enrolment/enrolInCourse";
import inferFetchStatusFromError from "./inferFetchStatusFromError";
import deleteCourses from "./courses/deleteCourses";
import enrolledInCourse from "./courses/enrolledInCourse";

interface Params {
	orgName: string;
	userId: number;
}

const initialState: StudentDashboardState = {
	ordinary: [],
	ordinaryRequested: "none",
	exam: [],
	examRequested: "none",
	past: [],
	pastRequested: "none",
	enrolmentApplications: [],
	enrolmentApplicationsStatus: "none",
	myCoursesValue: 0,
	myExamsValue: 0,
	myPastCoursesValue: 0,
	nextOrdinary: {},
	nextExam: {},
	nextPast: {},
};

export const getStudentOrdinary = createAsyncThunk(
	"studentdashboard/getStudentOrdinary",
	(params: Params) =>
		courseService.getUserCourses(params.orgName, params.userId, {
			type: "ordinary",
			status: ["ongoing", "upcoming"],
			userRole: UserRole.Student,
			pageSize: 24,
		})
);

export const getStudentOrdinaryNext = createAsyncThunk(
	"studentdashboard/getStudentOrdinaryNext",
	courseService.loadMoreUserCourses
);

export const getStudentExam = createAsyncThunk(
	"studentdashboard/getStudentExam",
	(params: Params) =>
		courseService.getUserCourses(params.orgName, params.userId, {
			type: "exam",
			status: "ongoing",
			userRole: UserRole.Student,
			pageSize: 24,
		})
);

export const getStudentExamNext = createAsyncThunk(
	"studentdashboard/getStudentExamNext",
	courseService.loadMoreUserCourses
);

export const getStudentPast = createAsyncThunk(
	"studentdashboard/getStudentPast",
	(params: Params) =>
		courseService.getUserCourses(params.orgName, params.userId, {
			status: "past",
			userRole: UserRole.Student,
			pageSize: 24,
		})
);
export const getStudentPastNext = createAsyncThunk(
	"studentdashboard/getStudentPastNext",
	courseService.loadMoreUserCourses
);

export const getStudentStatistics = createAsyncThunk(
	"studentdashboard/getStudentStatistics",
	async (params: {userId: number; orgName: string}) => {
		const studentStatistics = [
			Statistic.NumberOfCourses,
			Statistic.NumberOfExams,
			Statistic.NumberOfPastCourses,
			Statistic.NumberOfPastExams,
		] as const;
		type StudentStatistic = typeof studentStatistics[number];

		const stats = await statisticsService.getUserStatistics(
			params.userId,
			params.orgName,
			studentStatistics,
			[UserRole.Student]
		);

		return stats as {
			[name in StudentStatistic]: number;
		};
	}
);

export const studentDashboardSlice = createSlice({
	name: "studentdashboard",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getStudentOrdinary.pending, (state) => {
			state.ordinaryRequested = "pending";
		});
		builder.addCase(getStudentOrdinary.rejected, (state, action) => {
			state.ordinaryRequested = inferFetchStatusFromError(action);
		});
		builder.addCase(getStudentOrdinary.fulfilled, (state, action) => {
			state.ordinary = action.payload.content;
			state.ordinaryRequested = "succeeded";
			state.nextOrdinary = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next"
					? (state.nextOrdinary = {nextHref: link.href})
					: null
			);
		});
		builder.addCase(getStudentOrdinaryNext.fulfilled, (state, action) => {
			state.ordinary = state.ordinary.concat(action.payload.content);
			state.nextOrdinary = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next"
					? (state.nextOrdinary = {nextHref: link.href})
					: null
			);
		});

		builder.addCase(getStudentExam.pending, (state) => {
			state.examRequested = "pending";
		});
		builder.addCase(getStudentExam.rejected, (state, action) => {
			state.examRequested = inferFetchStatusFromError(action);
		});
		builder.addCase(getStudentExam.fulfilled, (state, action) => {
			state.exam = action.payload.content;
			state.examRequested = "succeeded";
			state.nextExam = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next" ? (state.nextExam = {nextHref: link.href}) : null
			);
		});
		builder.addCase(getStudentExamNext.fulfilled, (state, action) => {
			state.exam = state.exam.concat(action.payload.content);
			state.nextExam = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next" ? (state.nextExam = {nextHref: link.href}) : null
			);
		});

		builder.addCase(getStudentPast.pending, (state) => {
			state.pastRequested = "pending";
		});
		builder.addCase(getStudentPast.rejected, (state, action) => {
			state.pastRequested = inferFetchStatusFromError(action);
		});
		builder.addCase(getStudentPast.fulfilled, (state, action) => {
			state.past = action.payload.content;
			state.pastRequested = "succeeded";
			state.nextPast = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next" ? (state.nextPast = {nextHref: link.href}) : null
			);
		});
		builder.addCase(getStudentPastNext.fulfilled, (state, action) => {
			state.past = state.past.concat(action.payload.content);
			state.nextPast = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next" ? (state.nextPast = {nextHref: link.href}) : null
			);
		});

		builder.addCase(fetchEnrolmentApplications.pending, (state) => {
			state.enrolmentApplicationsStatus = "pending";
		});
		builder.addCase(fetchEnrolmentApplications.rejected, (state, action) => {
			state.enrolmentApplicationsStatus = inferFetchStatusFromError(action);
		});
		builder.addCase(fetchEnrolmentApplications.fulfilled, (state, action) => {
			state.enrolmentApplications = action.payload;
			state.enrolmentApplicationsStatus = "succeeded";
		});

		builder.addCase(enrolInCourse.fulfilled, (state, {payload}) => {
			if (payload.status === "accepted") {
				state.ordinary = [];
				state.ordinaryRequested = "none";

				state.exam = [];
				state.examRequested = "none";
			} else if (payload.status === "pending") {
				state.enrolmentApplications = [];
				state.enrolmentApplicationsStatus = "none";
			}
		});

		builder.addCase(getStudentStatistics.fulfilled, (state, action) => {
			state.myCoursesValue =
				action.payload[Statistic.NumberOfCourses] -
				action.payload[Statistic.NumberOfPastCourses] -
				(action.payload[Statistic.NumberOfExams] -
					action.payload[Statistic.NumberOfPastExams]);
			state.myExamsValue =
				action.payload[Statistic.NumberOfExams] -
				action.payload[Statistic.NumberOfPastExams];
			state.myPastCoursesValue = action.payload[Statistic.NumberOfPastCourses];
		});

		builder.addCase(changeCoursePicture.fulfilled, (state, action) => {
			const {courseId, picture} = action.payload;
			setCoursePicture(state.ordinary, courseId, picture);
			setCoursePicture(state.past, courseId, picture);
			setCoursePicture(state.exam, courseId, picture);
		});
		builder.addCase(deleteCoursePicture.fulfilled, (state, action) => {
			const {courseId} = action.payload;
			setCoursePicture(state.ordinary, courseId);
			setCoursePicture(state.past, courseId);
			setCoursePicture(state.exam, courseId);
		});

		builder.addCase(deleteCourses.fulfilled, () => {
			return initialState;
		});

		builder.addCase(enrolledInCourse, () => {
			return initialState;
		});
	},
});

export const studentDashboardActions = studentDashboardSlice.actions;

export default studentDashboardSlice.reducer;
