import {Localized} from "@fluent/react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	useTheme,
} from "@material-ui/core";
import React, {useEffect} from "react";
import type {ReactNode} from "react";

import fetchExerciseAnswer from "../../../../store/exercises/fetchExerciseAnswer";
import selectExerciseAnswer from "../../../../store/exercises/selectExerciseAnswer";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {keyProvider} from "../../../../store/keyProvider";
import HtmlContent from "../../../../utils/HtmlContent";

const AnswerPanel = (props: {title: ReactNode; answer: string}) => {
	const {title, answer} = props;

	return (
		<Box display="flex" flexDirection="column" overflow="auto" flex="1 1 0">
			<Typography variant="subtitle2" style={{fontWeight: 500}}>
				{title}
			</Typography>

			<Box overflow="auto" maxHeight={320} mt={1}>
				<HtmlContent content={answer} />
			</Box>
		</Box>
	);
};

const ConfirmResponseDialog = (props: {
	open: boolean;
	studentResponse: string;
	exerciseId: number;
	courseId: number;
	chapterId: number;
	studentId: number;
	onCancel: () => void;
	onConfirm: () => void;
}): JSX.Element => {
	const {exerciseId, chapterId, courseId, open} = props;

	const dispatch = useAppDispatch();
	const theme = useTheme();

	const exampleAnswer = useAppSelector((state) =>
		selectExerciseAnswer(state, keyProvider.exercise(props.exerciseId))
	);

	useEffect(() => {
		if (open && !exampleAnswer && chapterId && exerciseId) {
			dispatch(
				fetchExerciseAnswer({
					courseId: courseId,
					chapterId: chapterId,
					exerciseId: exerciseId,
				})
			);
		}
	}, [chapterId, courseId, dispatch, exampleAnswer, exerciseId, open]);

	return (
		<Dialog
			open={props.open}
			maxWidth="md"
			aria-labelledby="confirm-response-dialog-title"
			onClose={props.onCancel}
		>
			<DialogTitle id="confirm-response-dialog-title">
				<Localized id="exercise-open-response-area-self-assessment-dialog-title">
					Have you managed to complete the exercise?
				</Localized>
			</DialogTitle>

			<DialogContent>
				<Box display="flex" style={{gap: theme.spacing(2)}}>
					<AnswerPanel
						title={
							<Localized id="exercise-open-response-area-self-assessment-dialog-subtitle-your-answer">
								Your answer
							</Localized>
						}
						answer={props.studentResponse}
					/>

					<AnswerPanel
						title={
							<Localized id="exercise-open-response-area-self-assessment-dialog-subtitle-example-answer">
								Example answer
							</Localized>
						}
						answer={exampleAnswer?.solution ?? ""}
					/>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					<Localized id="exercise-open-response-area-self-assessment-dialog-action-not-managed">
						No, I have not managed
					</Localized>
				</Button>

				<Button color="primary" onClick={() => props.onConfirm()}>
					<Localized id="exercise-open-response-area-self-assessment-dialog-action-managed">
						Yes, I have managed
					</Localized>
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmResponseDialog;
