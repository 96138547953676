import {Localized} from "@fluent/react";
import ErrorIcon from "@mui/icons-material/Error";
import MoreVert from "@mui/icons-material/MoreVert";
import {
	Box,
	Button,
	Menu,
	MenuItem,
	Stack,
	Table,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";

import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import enrolledInCourse from "../../store/courses/enrolledInCourse";
import staffMemberRolesChanged from "../../store/courses/staffMemberRolesChanged";
import type FetchStatus from "../../store/FetchStatus";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import UserRole from "../../store/models/UserRole";
import {userService} from "../../store/services/userService";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import useAssignToOrganisationCoursesAction from "./useAssignToOrganisationCoursesAction";
import useEnrolInOrganisationCourseAction from "./useEnrolInOrganisationCourseAction";
import UserCourses from "./UserCourses";
import UserRoleChips from "./UserRoleChips";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";

function OrganisationUserRoles(props: {
	organisationName: string;
	userId: number;
}) {
	const {organisationName, userId} = props;

	const navBarHeight = useNavBarHeight();
	const mobileMode = useMobileMode("sm");

	const [roles, setRoles] = useState<UserRole[]>([]);
	const [rolesFetchStatus, setRolesFetchStatus] = useState<FetchStatus>("none");

	const currentUserId = useAppSelector(selectUserId);

	const dispath = useAppDispatch();

	async function fetchRoles(userId: number, organisationName: string) {
		setRolesFetchStatus("pending");

		try {
			const roles = await userService.getUserRolesInOrganisation(
				userId,
				organisationName
			);

			setRolesFetchStatus("succeeded");
			setRoles(roles);
		} catch {
			setRolesFetchStatus("failed");
		}
	}

	const userCourses = useRef<{reload: () => void}>(null);

	const [
		assignToCourses,
		assignToCoursesDialog,
	] = useAssignToOrganisationCoursesAction(
		organisationName,
		mobileMode,
		(courseIds) => {
			fetchRoles(userId, organisationName);
			userCourses.current?.reload();

			if (userId === currentUserId) {
				dispath(staffMemberRolesChanged({courseIds}));
			}
		}
	);

	const [
		enrolInCourse,
		enrolInCourseDialog,
	] = useEnrolInOrganisationCourseAction(
		organisationName,
		mobileMode,
		(courseId) => {
			fetchRoles(userId, organisationName);
			userCourses.current?.reload();

			if (userId === currentUserId) {
				dispath(enrolledInCourse({courseId}));
			}
		}
	);

	useEffect(() => {
		if (rolesFetchStatus === "none") {
			fetchRoles(userId, organisationName);
		}
	}, [organisationName, rolesFetchStatus, userId]);

	return (
		<Box
			sx={{
				background: (theme) => theme.palette.background.paper,
				height: `calc(100vh - ${navBarHeight + 1}px)`,
				pt: mobileMode ? 3 : 5,
				px: mobileMode ? 3 : 6,
				pb: 1.5,
			}}
		>
			<UserCourses
				ref={userCourses}
				topPanel={
					<RolesSummary
						roles={roles}
						status={rolesFetchStatus}
						menuItems={[
							{
								label: (
									<Localized id="organisation-user-roles-action-enrol">
										Enrol in a course
									</Localized>
								),
								action: () => enrolInCourse([userId]),
							},
							{
								label: (
									<Localized id="organisation-user-roles-action-assign-to-courses">
										Assign to courses
									</Localized>
								),
								action: () => assignToCourses([userId]),
							},
						]}
						onReload={() => fetchRoles(userId, organisationName)}
					/>
				}
				organisationName={organisationName}
				userId={userId}
				onRolesUpdated={(courseId) => {
					fetchRoles(userId, organisationName);

					if (currentUserId === userId) {
						dispath(staffMemberRolesChanged({courseIds: [courseId]}));
					}
				}}
			/>

			{assignToCoursesDialog}
			{enrolInCourseDialog}
		</Box>
	);
}

function RolesSummary(props: {
	roles: UserRole[];
	status: FetchStatus;
	menuItems: {label: React.ReactNode; action: () => void}[];
	onReload: () => void;
}) {
	const [addRoleMenu, setAddRoleMenu] = useState<HTMLButtonElement | null>(
		null
	);

	function closeMenu() {
		setAddRoleMenu(null);
	}

	return (
		<Table stickyHeader sx={{mb: 1}}>
			<TableHead>
				<TableRow sx={{"& > th": {borderBottom: 0}}}>
					<TableCell>
						{props.status !== "failed" && (
							<UserRoleChips
								roles={props.roles}
								loading={props.status === "pending"}
							/>
						)}
						{props.status === "failed" && (
							<Stack
								spacing={1}
								direction="row"
								sx={{height: (theme) => theme.spacing(3), alignItems: "center"}}
							>
								<ErrorIcon color="error" fontSize="small" />
								<Localized id="organisation-user-roles-loading-error-desc">
									Failed to load user roles
								</Localized>
								<Button color="primary" size="small" onClick={props.onReload}>
									<Localized id="organisation-user-roles-loading-error-action-reload">
										Reload
									</Localized>
								</Button>
							</Stack>
						)}
					</TableCell>
					<TableCell padding="checkbox">
						<IconButtonWithTooltip
							tooltipTitle={
								<Localized id="organisation-user-roles-actions-label">
									Actions
								</Localized>
							}
							onClick={({currentTarget}) => {
								setAddRoleMenu(currentTarget);
							}}
						>
							<MoreVert />
						</IconButtonWithTooltip>
					</TableCell>
				</TableRow>
			</TableHead>

			<Menu
				anchorEl={addRoleMenu}
				open={Boolean(addRoleMenu)}
				onClose={closeMenu}
			>
				{props.menuItems.map((m, i) => (
					<MenuItem
						key={i}
						onClick={() => {
							closeMenu();
							m.action();
						}}
					>
						{m.label}
					</MenuItem>
				))}
			</Menu>
		</Table>
	);
}

export default OrganisationUserRoles;
