import {Localized} from "@fluent/react";
import {Container, Typography} from "@material-ui/core";
import React from "react";

import useNavBarHeight from "../../hooks/useNavBarHeight";

function IntegrationConfiguration() {
	const navBarHeight = useNavBarHeight();

	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: `calc(100vh - ${navBarHeight}px)`,
			}}
		>
			<Typography variant="h6">
				<Localized id="organisation-integration-not-available-title">
					Not yet available
				</Localized>
			</Typography>
			<Typography>
				<Localized id="organisation-integration-not-available-descr">
					If you need to configure LTI integration, please contact Viope support
				</Localized>
			</Typography>
		</Container>
	);
}

export default IntegrationConfiguration;
