import {IconButton} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import type {History} from "history";
import React from "react";
import {useHistory} from "react-router";

import {getSectionPathByKey} from "../../helpers/pathHelpers";

function SectionNavigation({
	currentKey,
	sectionKeys,
}: {
	currentKey: string;
	sectionKeys: string[];
}): JSX.Element {
	const history = useHistory();

	const index = sectionKeys.indexOf(currentKey);

	const backwardDisabled = index <= 0;
	const forwardDisabled = index < 0 || index === sectionKeys.length - 1;

	const backwardClickHandler = () => {
		goToSection(history, sectionKeys[index - 1]);
	};
	const forwardClickHandler = () => {
		goToSection(history, sectionKeys[index + 1]);
	};

	return (
		<div>
			<IconButton onClick={backwardClickHandler} disabled={backwardDisabled}>
				<KeyboardBackspaceIcon
					color={backwardDisabled ? "disabled" : "primary"}
				/>
			</IconButton>

			<IconButton onClick={forwardClickHandler} disabled={forwardDisabled}>
				<KeyboardBackspaceIcon
					style={{
						WebkitTransform: "scaleX(-1)",
						transform: "scaleX(-1)",
					}}
					color={forwardDisabled ? "disabled" : "primary"}
				/>
			</IconButton>
		</div>
	);
}

function goToSection(history: History, sectionKey: string) {
	const sectionPath = getSectionPathByKey(
		history.location.pathname,
		sectionKey
	);

	history.push(sectionPath);
}

export default SectionNavigation;
