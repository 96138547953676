import {Box, Typography, useTheme} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import React from "react";

const ExercisePlaceholder = (): JSX.Element => {
	const theme = useTheme();
	return (
		<>
			<Skeleton style={{marginBottom: theme.spacing(1)}}>
				<Typography variant="h5">
					1.1 Exercise title of some exercise
				</Typography>
			</Skeleton>
			<Box mb={2} display="flex" style={{gap: theme.spacing(5)}}>
				<Skeleton>
					<Typography variant="overline">Max score: 1</Typography>
				</Skeleton>
				<Skeleton>
					<Typography variant="overline">Category: category</Typography>
				</Skeleton>
				<Skeleton>
					<Typography variant="overline">Difficulty: 1</Typography>
				</Skeleton>
			</Box>
			<Skeleton
				variant="rect"
				width="100%"
				height={250}
				style={{marginBottom: theme.spacing(4)}}
			/>
			<Skeleton variant="rect" width="100%" height={50} />
		</>
	);
};

export default ExercisePlaceholder;
