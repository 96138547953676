import {Localized} from "@fluent/react";
import clsx from "clsx";
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";

import type {LeftTabName} from "./LeftTabName";
import useMobileMode from "../../hooks/useMobileMode";

const useStyles = makeStyles((theme) => ({
	typography: {
		color: theme.palette.text.primary,
		cursor: "pointer",
		marginBottom: theme.spacing(3),
		fontWeight: 500,
	},
	selected: {
		color: theme.palette.secondary.main,
	},
	statisticsOnTab: {
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(3),
		fontWeight: theme.typography.fontWeightRegular,
	},
}));

const LeftTabItem = (props: {
	statisticValue: number;
	tabName: LeftTabName;
	selected: boolean;
	onClick: () => void;
}): JSX.Element => {
	const classes = useStyles();

	const mobileMode = useMobileMode("sm");

	return (
		<Grid container justifyContent="space-between" alignItems="baseline">
			<Grid item xs={8}>
				<Localized id={props.tabName}>
					<Typography
						className={clsx(classes.typography, {
							[classes.selected]: props.selected,
						})}
						onClick={props.onClick}
						variant="subtitle1"
					>
						{props.tabName}
					</Typography>
				</Localized>
			</Grid>
			{!mobileMode && (
				<Grid item xs={4}>
					<Typography className={classes.statisticsOnTab}>
						{props.statisticValue}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

export default LeftTabItem;
