import {Localized} from "@fluent/react";
import React, {useCallback} from "react";

import OrganisationCoursesSelectorDialog from "../courses/OrganisationCoursesSelectorDialog";
import EnrolInCourseDialog from "./EnrolInCourseDialog";
import {userService} from "../../store/services/userService";

function EnrolInOrganisationCourseDialog(props: {
	open: boolean;
	mobileMode: boolean;
	organisationName: string;
	userIds: number[];
	onCancel: () => void;
	onDone: (courseId: number) => void;
}) {
	const {organisationName} = props;

	const fetchUsers = useCallback(
		async (ids: number[]) => {
			const res = await userService.searchUsersInOrganisation(
				organisationName,
				{ids},
				{field: "userName"},
				ids.length
			);

			return res.content;
		},
		[organisationName]
	);

	const coursesSelector = useCallback(
		(props: {onSelected: (ids: number[]) => void; onCancel: () => void}) => (
			<OrganisationCoursesSelectorDialog
				organisationName={organisationName}
				actionLabel={
					<Localized id="users-enrol-in-course-dialog-action-enrol">
						Enrol
					</Localized>
				}
				onSelected={props.onSelected}
				onCancel={props.onCancel}
			/>
		),
		[organisationName]
	);

	return (
		<EnrolInCourseDialog
			open={props.open}
			mobileMode={props.mobileMode}
			userIds={props.userIds}
			CoursesSelector={coursesSelector}
			fetchUsers={fetchUsers}
			onCancel={props.onCancel}
			onDone={props.onDone}
		/>
	);
}

export default EnrolInOrganisationCourseDialog;
