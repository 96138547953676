import {Box, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import React from "react";

const ExerciseEditorPlaceholder = (): JSX.Element => {
	return (
		<>
			<Box mb={0.5}>
				<Skeleton>
					<Typography variant="caption">Exercise title *</Typography>
				</Skeleton>
			</Box>
			<Box mb={2}>
				<Skeleton variant="rect" width="100%" />
			</Box>
			<Box mb={0.5}>
				<Skeleton>
					<Typography variant="caption">Score</Typography>
				</Skeleton>
			</Box>
			<Box mb={2}>
				<Skeleton variant="rect" width="30%" />
			</Box>
			<Box mb={0.5}>
				<Skeleton>
					<Typography variant="caption">Description</Typography>
				</Skeleton>
			</Box>
			<Box>
				<Skeleton variant="rect" width="100%" height={430} />
			</Box>
		</>
	);
};

export default ExerciseEditorPlaceholder;
