import {Localized} from "@fluent/react";
import {Box, Button} from "@material-ui/core";
import React from "react";
import SubmitButton from "../../../utils/SubmitButton";

const ActionButtons = (props: {
	onCancel: () => void;
	onSubmit: () => void;
	submitDisabled?: boolean;
	submitInProgress?: boolean;
}): JSX.Element => {
	return (
		<Box display="flex" justifyContent="flex-end" p={2}>
			<Button
				color="primary"
				onClick={props.onCancel}
				disabled={props.submitInProgress}
			>
				<Localized id="sidebars-action-button-cancel">Cancel</Localized>
			</Button>
			<Box ml={2}>
				<SubmitButton
					onClick={props.onSubmit}
					disabled={props.submitDisabled}
					inProgress={props.submitInProgress}
				>
					<Localized id="sidebars-action-button-send">Send</Localized>
				</SubmitButton>
			</Box>
		</Box>
	);
};

export default ActionButtons;
