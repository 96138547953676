import {Localized} from "@fluent/react";
import {
	Box,
	Fade,
	Grid,
	IconButton,
	Tooltip,
	useTheme,
} from "@material-ui/core";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";

import ContentNavigation from "./ContentNavigation";
import SectionContent from "./SectionContent";
import ContentSidebar from "../sidebars/ContentSidebar";
import SidebarContext, {SidebarType} from "../sidebars/SidebarContext";
import {resolveLinkToOldSite, resolvePath} from "../../helpers/pathHelpers";
import useMobileMode from "../../hooks/useMobileMode";
import {getChapters} from "../../store/chapters/getChapters";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import selectChapter from "../../store/chapters/selectChapter";
import CourseType from "../../store/courses/CourseType";
import parseDate from "../../helpers/parseDate";
import selectFirstPublishedChapterKey from "../../store/chapters/selectFirstPublishedChapterKey";
import selectCourseChapterKeys from "../../store/chapters/selectCourseChapterKeys";
import DeadlineTracker from "./DeadlineTracker";
import CountDown from "./CountDown";
import type TimeLeft from "../../utils/TimeLeft";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import fetchChapterSessions from "../../store/chapterSessions/fetchChapterSessions";
import ChapterSubmissionButton from "./ChapterSubmissionButton";
import selectSectionOfExercise from "../../store/sections/selectSectionOfExercise";
import BackDestination from "../nav/BackDestination";
import TestIntro from "./TestIntro";
import type Chapter from "../../store/chapters/Chapter";
import selectChapterSession from "../../store/chapterSessions/selectChapterSession";
import AssessmentDeliveryContext, {
	AssessmentDeliveryContextProvider,
	SessionAssessmentDeliveryContextProvider,
} from "../AssessmentDeliveryContext";
import {calculateChapterSessionEndTime} from "../../store/chapterSessions/ChapterSession";
import selectChapterSessionKeys from "../../store/chapterSessions/selectChapterSessionKeys";
import type ChapterSession from "../../store/chapterSessions/ChapterSession";
import SectionNavigation from "./SectionNavigation";
import AppBarDependentContainer from "../../utils/AppBarDependentContainer";
import ExtendableBox from "../../utils/ExtendableBox";
import OpenNavigationButton from "../../utils/OpenNavigationButton";
import CalculatorPanel from "../../utils/calculator/CalculatorPanel";
import CalculateIcon from "../../icons/CalculateIcon";
import selectCourse from "../../store/courses/selectCourse";
import CourseFeature from "../../store/courses/CourseFeature";
import {requiresExplicitSessionStart} from "../../store/chapters/Chapter";

const courseNavigationDrawerWidth = 360;
const sidebarDrawerWidth = 320;

const RedirectToBeginning = (props: {courseKey: string; basePath: string}) => {
	const chapterKey = useAppSelector((state) =>
		selectFirstPublishedChapterKey(state, props.courseKey)
	);

	if (!chapterKey) {
		return <></>;
	}

	return <Redirect to={resolvePath(props.basePath, chapterKey)} />;
};

function CourseContent(props: {
	courseId: number;
	userId: number;
	courseType: CourseType;
}): JSX.Element {
	const {courseId, userId, courseType} = props;

	const dispatch = useAppDispatch();

	const {url: basePath} = useRouteMatch();

	const mobileMode = useMobileMode("sm");

	const courseKey = keyProvider.course(courseId);

	const chaptersRequested = useAppSelector(
		(state) => courseKey in state.courseChapters.byKey
	);

	useEffect(() => {
		if (!chaptersRequested) {
			dispatch(getChapters(courseId));
		}
	}, [courseId, dispatch, chaptersRequested]);

	const chapterKeys = useAppSelector((state) =>
		selectCourseChapterKeys(state, courseKey)
	);

	const [navigationOpen, setNavigationOpen] = useState(false);

	const toggleNavigation = useCallback(() => {
		setNavigationOpen((prev) => !prev);
	}, []);

	const {
		type: sidebarType,
		close: closeSidebar,
		fullscreen: sidebarFullscreen,
	} = useContext(SidebarContext);

	const sidebarOpen = sidebarType !== SidebarType.None;

	useEffect(() => {
		if (mobileMode) {
			if (navigationOpen && sidebarOpen) {
				closeSidebar();
			}
		}
	}, [closeSidebar, mobileMode, navigationOpen, sidebarOpen]);

	if (chapterKeys.length === 0) {
		return <></>;
	}

	return (
		<Box display="flex">
			<Switch>
				<Route
					path={resolvePath(basePath, "/chapters/:chapterId")}
					render={({match}) => {
						const chapterId = Number(match.params.chapterId);
						const chapterKey = keyProvider.chapter(chapterId);
						const chapterPath = match.isExact;

						return (
							<>
								<ContentNavigation
									courseId={courseId}
									chapterKeys={chapterKeys}
									currentChapterKey={chapterKey}
									onClose={toggleNavigation}
									open={navigationOpen}
									width={courseNavigationDrawerWidth}
									mobileMode={mobileMode}
								/>

								<ExtendableBox
									leftExtension={
										navigationOpen || mobileMode
											? 0
											: courseNavigationDrawerWidth
									}
									rightExtension={
										chapterPath ||
										sidebarOpen ||
										sidebarFullscreen ||
										mobileMode
											? 0
											: sidebarDrawerWidth
									}
									maxWidth={2048}
									minWidth={0}
									p={mobileMode ? 3 : 6}
									display="flex"
								>
									<OpenNavigationButton
										onClick={toggleNavigation}
										hidden={navigationOpen}
									/>
									<Content
										courseId={courseId}
										chapterId={chapterId}
										studentId={userId}
										basePath={basePath}
										courseType={courseType}
										requestContent={!chapterPath}
									/>
								</ExtendableBox>

								<Route
									path={resolvePath(
										basePath,
										"/chapters/:chapterId/sections/:sectionId"
									)}
									render={({match}) => {
										const sectionId = Number(match.params.sectionId);

										return (
											<ContentSidebar
												courseId={courseId}
												sectionKey={keyProvider.section(chapterId, sectionId)}
												width={sidebarDrawerWidth}
												mobileMode={mobileMode}
											/>
										);
									}}
								></Route>
							</>
						);
					}}
				></Route>

				<Route>
					<RedirectToBeginning basePath={basePath} courseKey={courseKey} />
				</Route>
			</Switch>
		</Box>
	);
}

const RedirectToExerciseSection = (props: {
	chapterId: number;
	exerciseId: number;
	basePath: string;
	fallbackTo: string;
}) => {
	const chapterExerciseKey = keyProvider.chapterExercise(
		props.chapterId,
		props.exerciseId
	);

	const sectionId = useAppSelector(
		(state) => selectSectionOfExercise(state, chapterExerciseKey)?.id
	);

	let url = props.fallbackTo;

	if (sectionId) {
		const sectionKey = keyProvider.section(props.chapterId, sectionId);

		url = resolvePath(
			props.basePath,
			`${sectionKey}#subsection-${props.exerciseId}`
		);
	}

	return <Redirect to={url} />;
};

function Content(props: {
	courseId: number;
	courseType: CourseType;
	chapterId: number;
	studentId: number;
	basePath: string;
	requestContent: boolean;
}) {
	const {
		courseId,
		courseType,
		chapterId,
		studentId,
		basePath,
		requestContent,
	} = props;

	const chapterKey = keyProvider.chapter(chapterId);

	const dispatch = useAppDispatch();

	const chapter = useAppSelector((state) => selectChapter(state, chapterKey));

	const sessionRequired =
		chapter && requiresExplicitSessionStart(courseType, chapter);

	const [sessionKeys, sessionsFetchStatus] = useAppSelector((state) =>
		selectChapterSessionKeys(state, chapterKey)
	);
	const latestSessionKey = sessionKeys.length > 0 ? sessionKeys[0] : null;

	useEffect(() => {
		if (sessionRequired && sessionsFetchStatus === "none" && requestContent) {
			dispatch(
				fetchChapterSessions({
					studentId,
					courseId,
					chapterId,
				})
			);
		}
	}, [
		chapterId,
		courseId,
		dispatch,
		studentId,
		sessionRequired,
		sessionsFetchStatus,
		requestContent,
	]);

	const published = useMemo(() => {
		return Boolean(
			chapter?.startDate && parseDate(chapter.startDate) <= new Date()
		);
	}, [chapter?.startDate]);

	const session = useAppSelector((state) =>
		latestSessionKey ? selectChapterSession(state, latestSessionKey) : null
	);

	if (!chapter || (sessionRequired && sessionsFetchStatus === "pending")) {
		return <></>;
	}

	if (chapter.structureType !== "sections") {
		window.location.replace(resolveLinkToOldSite(`student/${courseId}/#`));
		return <></>;
	}

	let redirectPath = basePath;
	if (sessionRequired && courseType === CourseType.Ordinary) {
		redirectPath = resolvePath(basePath, chapterKey);
	}

	if (
		sessionRequired &&
		courseType === CourseType.Ordinary &&
		!requestContent
	) {
		return !chapter.startDate ? (
			<Redirect to={basePath} />
		) : (
			<TestIntro test={chapter} chapterKey={chapterKey} studentId={studentId} />
		);
	}

	const noSession = sessionsFetchStatus === "succeeded" && !session;
	if (
		!published ||
		(sessionRequired && (noSession || sessionsFetchStatus === "failed"))
	) {
		return <Redirect to={redirectPath} />;
	}

	const content = (
		<ChapterContent
			courseId={courseId}
			chapterId={chapterId}
			studentId={studentId}
			chapter={chapter}
			session={session}
			basePath={basePath}
			backDestination={sessionRequired ? redirectPath : "/"}
		/>
	);

	return sessionRequired ? (
		<SessionAssessmentDeliveryContextProvider
			sessionKey={latestSessionKey ?? ""}
			courseId={courseId}
			chapterId={chapterId}
			studentId={studentId}
		>
			{content}
		</SessionAssessmentDeliveryContextProvider>
	) : (
		<AssessmentDeliveryContextProvider
			courseId={courseId}
			chapterId={chapterId}
			studentId={studentId}
		>
			{content}
		</AssessmentDeliveryContextProvider>
	);
}

function ChapterContent(props: {
	courseId: number;
	chapterId: number;
	studentId: number;
	basePath: string;
	chapter: Chapter;
	session: ChapterSession | null;
	backDestination: string;
}): JSX.Element {
	const {
		courseId,
		chapterId,
		studentId,
		basePath,
		chapter,
		session,
		backDestination,
	} = props;

	const {
		fetchContent,
		fetchOutcomes,
		selectContentStatus,
		selectSectionKeys,
	} = useContext(AssessmentDeliveryContext);

	const contentRequested = useAppSelector(
		(state) => selectContentStatus(state) !== "none"
	);

	const sectionKeys = useAppSelector(selectSectionKeys);

	const deadline = useMemo(() => {
		let deadline = chapter.endDate;
		if (chapter.timeLimit && session?.startTime) {
			deadline = calculateChapterSessionEndTime(
				session.startTime,
				chapter.timeLimit
			).toISOString();

			if (chapter.endDate && chapter.endDate < deadline) {
				deadline = chapter.endDate;
			}
		}
		return deadline;
	}, [chapter.endDate, chapter.timeLimit, session?.startTime]);

	useEffect(() => {
		if (!contentRequested) {
			fetchContent();
		}
	}, [contentRequested, fetchContent]);

	useEffect(() => {
		fetchOutcomes();
	}, [fetchOutcomes]);

	const [calcOpened, setCalcOpened] = useState(false);

	const calcEnabled = useAppSelector((state) =>
		selectCourse(state, keyProvider.course(courseId))?.features.includes(
			CourseFeature.Calculator
		)
	);

	const theme = useTheme();
	const mobileMode = useMobileMode("sm");

	if (sectionKeys.length === 0) {
		return <></>;
	}

	const createSectionContent = (sectionKey: string, timeLeft?: TimeLeft) => (
		<Grid container>
			<Grid item xs={10}>
				<SectionContent
					courseId={courseId}
					sectionKey={sectionKey}
					submissionMode={chapter.submissionMode}
					selectionMode={chapter.selectionMode}
					readonly={timeLeft && timeLeft.total <= 0}
					submissionDisabled={Boolean(session?.submissionTime)}
				/>
			</Grid>
			<Grid
				item
				xs={2}
				style={{
					display: "flex",
					justifyContent: "flex-end",
					zIndex: 1,
				}}
			>
				<AppBarDependentContainer>
					<Box
						pt={mobileMode ? 2 : 5}
						pb={mobileMode ? 2 : 4.5}
						mr={-1.5}
						height="100%"
						display="flex"
						flexDirection="column"
						alignItems="flex-end"
						style={{gap: theme.spacing(1)}}
					>
						<SectionNavigation
							currentKey={sectionKey}
							sectionKeys={sectionKeys}
						/>
						{timeLeft && timeLeft.hours < 24 && (
							<CountDown timeLeft={timeLeft} />
						)}
						{calcEnabled && (
							<Fade in={!calcOpened}>
								<Tooltip
									title={
										<Localized id="calculator-button-tooltip">
											Calculator
										</Localized>
									}
								>
									<IconButton
										onClick={() => setCalcOpened(true)}
										color="primary"
									>
										<CalculateIcon />
									</IconButton>
								</Tooltip>
							</Fade>
						)}
						<Box flex="1" position="relative">
							{calcEnabled && (
								<CalculatorPanel
									open={calcOpened}
									onClose={() => setCalcOpened(false)}
								/>
							)}
						</Box>
						{chapter.submissionMode === SubmissionMode.Simultaneous &&
							!session?.submissionTime && (
								<ChapterSubmissionButton
									courseId={courseId}
									chapterId={chapterId}
									studentId={studentId}
								/>
							)}
					</Box>
				</AppBarDependentContainer>
			</Grid>
		</Grid>
	);

	const firstSection = resolvePath(basePath, sectionKeys[0]);

	return (
		<Switch>
			<Route
				path={resolvePath(basePath, "/chapters/:chapterId/sections/:sectionId")}
				render={({match}) => {
					const sectionId = Number(match.params.sectionId);
					const sectionKey = keyProvider.section(chapterId, sectionId);
					return (
						<BackDestination key="section-content" path={backDestination}>
							{deadline ? (
								<DeadlineTracker deadline={deadline}>
									{(timeLeft) => createSectionContent(sectionKey, timeLeft)}
								</DeadlineTracker>
							) : (
								<>{createSectionContent(sectionKey)}</>
							)}
						</BackDestination>
					);
				}}
			/>

			<Route
				path={resolvePath(
					basePath,
					"/chapters/:chapterId/exercises/:exerciseId"
				)}
				render={({match}) => {
					const params = match.params;

					return (
						<RedirectToExerciseSection
							chapterId={Number(params.chapterId)}
							exerciseId={Number(params.exerciseId)}
							basePath={basePath}
							fallbackTo={firstSection}
						/>
					);
				}}
			></Route>

			<Route>
				<Redirect to={firstSection} />
			</Route>
		</Switch>
	);
}

export default CourseContent;
