import {alpha, useTheme} from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {Button, ButtonProps, IconButton} from "@material-ui/core";
import React, {useContext} from "react";
import {useHistory} from "react-router-dom";

import {BackNavContext} from "./BackNavProvider";

const BackButton = (props: {disabled?: boolean}): JSX.Element => {
	const theme = useTheme();
	const moveBack = useMoveBack();

	const color = theme.palette.primary.contrastText;

	return (
		<IconButton
			style={{
				color: props.disabled
					? alpha(color, theme.palette.action.disabledOpacity)
					: color,
			}}
			disabled={props.disabled}
			onClick={moveBack}
		>
			<KeyboardBackspaceIcon />
		</IconButton>
	);
};

const TextBackButton = (props: Omit<ButtonProps, "onClick">) => {
	const moveBack = useMoveBack();
	return <Button {...props} onClick={moveBack} />;
};

function useMoveBack() {
	const history = useHistory();

	const destinations = useContext(BackNavContext);

	return () =>
		history.push(destinations[destinations.length - 1].path, {
			backButtonClicked: true,
		});
}

export default BackButton;
export {TextBackButton};
