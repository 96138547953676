import {Fab, Tooltip} from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import React, {useState} from "react";
import useSnackbar from "../../store/ui/useSnackbar";
import DialogResult from "../../utils/DialogResult";
import ConfirmChapterSubmissionDialog from "./ConfirmChapterSubmissionDialog";

const ChapterSubmissionButton = (props: {
	courseId: number;
	chapterId: number;
	studentId: number;
}): JSX.Element => {
	const {courseId, chapterId, studentId} = props;

	const [confirmOpen, setConfirmOpen] = useState(false);

	const showSnackbar = useSnackbar();

	const confirm = () => {
		setConfirmOpen(true);
	};

	const closeConfirm = (result: DialogResult) => {
		setConfirmOpen(false);
		if (result.status !== "cancelled") {
			showSnackbar(result.status, result.message ?? "");
		}
	};

	return (
		<>
			<Tooltip title="Submit">
				<Fab onClick={confirm} color="primary">
					<DoneAllIcon />
				</Fab>
			</Tooltip>
			<ConfirmChapterSubmissionDialog
				open={confirmOpen}
				onClose={closeConfirm}
				studentId={studentId}
				courseId={courseId}
				chapterId={chapterId}
			/>
		</>
	);
};

export default ChapterSubmissionButton;
