import AnswerVisibility from "./AnswerVisibility";
import {keyProvider} from "../keyProvider";
import {CompletionStatus} from "../outcomes/ExerciseOutcomes";
import type {RootState} from "../store";
import selectChapter from "../chapters/selectChapter";
import selectCourse from "../courses/selectCourse";

function selectAnswerShowingAllowed(
	state: RootState,
	chapterExeciseKey: string,
	completionStatus: CompletionStatus
): boolean {
	const exercise = state.chapterExercises.byKey[chapterExeciseKey];
	if (!exercise) {
		return false;
	}

	const chapter = selectChapter(state, keyProvider.chapter(exercise.chapterId));
	if (!chapter) {
		return false;
	}

	const course = selectCourse(state, keyProvider.course(chapter.courseId));
	if (!course) {
		return false;
	}

	const visibility =
		exercise.answerVisibility ??
		chapter.answerVisibility ??
		course.answerVisibility;

	return allowed(
		visibility,
		course.type === "exam",
		false,
		completionStatus === CompletionStatus.Complete
	);
}

function allowed(
	answerVisibility: AnswerVisibility,
	courseIsExam: boolean,
	submissionClosed: boolean,
	exerciseCompleted: boolean
) {
	return (
		(answerVisibility === AnswerVisibility.Always &&
			(!courseIsExam || submissionClosed || exerciseCompleted)) ||
		(answerVisibility === AnswerVisibility.AfterSubmission &&
			exerciseCompleted) ||
		(answerVisibility === AnswerVisibility.AfterDeadline && submissionClosed)
	);
}

export default selectAnswerShowingAllowed;
