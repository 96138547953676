import {
	Checkbox,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import React from "react";

import type {Option} from "./Option";

function optionRenderer<K>(
	option: Omit<Option<K>, "id">,
	state: {selected: boolean}
) {
	return (
		<ListItem component="div" dense disableGutters>
			<ListItemIcon>
				<Checkbox
					style={{marginRight: 8}}
					checked={state.selected}
					size="small"
				/>
			</ListItemIcon>
			<ListItemText primary={option.name} secondary={option.secondaryName} />
		</ListItem>
	);
}

export default optionRenderer;
