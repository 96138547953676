import {Localized, useLocalization} from "@fluent/react";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@material-ui/core";
import React from "react";

import type TableField from "../../../../store/exercises/prog/TableField";

const DbTable = (props: {fields: TableField[]}): JSX.Element => {
	const {l10n} = useLocalization();

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell style={{width: "5.1em"}} />
						<TableCell>
							<Localized id="exercise-prog-response-area-sql-table-column-title-column">
								Column
							</Localized>
						</TableCell>
						<TableCell>
							<Localized id="exercise-prog-response-area-sql-table-column-title-data-type">
								Data type
							</Localized>
						</TableCell>
						<TableCell>
							<Localized id="exercise-prog-response-area-sql-table-column-title-nullable">
								Nullable
							</Localized>
						</TableCell>
						<TableCell>
							<Localized id="exercise-prog-response-area-sql-table-column-title-default">
								Default
							</Localized>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.fields.map((field, index) => {
						let pKey = "";
						let pTitle = "";

						if (field.isPrimaryKey) {
							pKey = "PK";
							pTitle = l10n.getString(
								"exercise-prog-response-area-sql-table-cell-tooltip-primary-key"
							);
						}

						let fKey = "";
						let fTitle = "";

						if (field.isForeignKey) {
							fKey = "FK";
							fTitle = l10n.getString(
								"exercise-prog-response-area-sql-table-cell-tooltip-foreign-key"
							);
						}

						return (
							<TableRow hover key={index}>
								<TableCell>
									{pKey && (
										<Tooltip
											title={pTitle}
											enterDelay={500}
											enterNextDelay={500}
										>
											<span>{pKey}</span>
										</Tooltip>
									)}
									{pKey && fKey && <span> </span>}
									{fKey && (
										<Tooltip
											title={fTitle}
											enterDelay={500}
											enterNextDelay={500}
										>
											<span>{fKey}</span>
										</Tooltip>
									)}
								</TableCell>
								<TableCell>{field.name}</TableCell>
								<TableCell>
									{field.type +
										(field.varcharLength ? `(${field.varcharLength})` : "")}
								</TableCell>
								<TableCell>
									{field.nullable ? (
										<Typography variant="srOnly">null</Typography>
									) : (
										<>not null</>
									)}
								</TableCell>
								<TableCell>{field.default}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default DbTable;
