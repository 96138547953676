import {Localized} from "@fluent/react";
import {Divider, MenuItem, useTheme} from "@material-ui/core";
import React from "react";

import parseDate from "../../helpers/parseDate";

const ActionsDivider = (): JSX.Element => {
	const theme = useTheme();

	return (
		<Divider
			component="li"
			variant="middle"
			style={{marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}
		/>
	);
};

const ChapterMenu = (props: {
	startDate?: string;
	publish: () => void;
	unpublish: () => void;
	schedule: () => void;
	addSection: () => void;
	canAddSection: boolean;
	courseEditing: boolean;
	chapterEditing: boolean;
	onDelete: () => void;
}): JSX.Element => {
	const {
		startDate,
		publish,
		unpublish,
		canAddSection,
		courseEditing,
		chapterEditing,
	} = props;

	const unpublished = !startDate;

	const scheduled = startDate && new Date() < parseDate(startDate);

	return (
		<>
			{unpublished && (
				<>
					<MenuItem onClick={publish} disabled={chapterEditing}>
						<Localized id="dragdrop-chapter-publish">Publish</Localized>
					</MenuItem>
					<MenuItem onClick={props.schedule} disabled={chapterEditing}>
						<Localized id="dragdrop-chapter-schedule">
							Schedule publication
						</Localized>
					</MenuItem>
				</>
			)}

			{scheduled && (
				<>
					<MenuItem onClick={publish} disabled={chapterEditing}>
						<Localized id="dragdrop-chapter-publish-now">Publish now</Localized>
					</MenuItem>
					<MenuItem onClick={unpublish} disabled={chapterEditing}>
						<Localized id="dragdrop-chapter-cancel-schedule">
							Cancel schedule
						</Localized>
					</MenuItem>
				</>
			)}

			{!unpublished && !scheduled && (
				<MenuItem onClick={unpublish} disabled={chapterEditing}>
					<Localized id="dragdrop-chapter-unpublish">Unpublish</Localized>
				</MenuItem>
			)}

			<ActionsDivider />

			<MenuItem
				onClick={props.addSection}
				disabled={!canAddSection || chapterEditing}
			>
				<Localized id="dragdrop-chapter-add-section">Add section</Localized>
			</MenuItem>

			<ActionsDivider />

			{unpublished && (
				<MenuItem disabled>
					<Localized id="dragdrop-chapter-shelve">Shelve</Localized>
				</MenuItem>
			)}

			<MenuItem
				onClick={props.onDelete}
				disabled={courseEditing || chapterEditing}
			>
				<Localized id="dragdrop-chapter-delete">Delete</Localized>
			</MenuItem>
		</>
	);
};

export default ChapterMenu;
