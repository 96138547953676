import {useCallback, useRef, useState} from "react";

import {createEmptyPage} from "../../helpers/paginatedSearchHelpers";
import type {LinkToPage, Page} from "../../helpers/paginatedSearchHelpers";
import type FetchStatus from "../../store/FetchStatus";

function usePaginationState<T, K = number>() {
	const [page, setPage] = useState(createEmptyPage<T, K>());

	const [pageFetchStatus, setPageFetchStatus] = useState<FetchStatus>("none");

	const lastRequest = useRef<() => void>();

	const fetchFirstPage = useCallback(
		(fetch: () => Promise<Page<T, K>>, onFetched?: () => void) => {
			lastRequest.current = () => {
				setPageFetchStatus("pending");

				fetch()
					.then((p) => {
						setPageFetchStatus("succeeded");
						setPage(p);
						onFetched?.();
					})
					.catch(() => {
						setPageFetchStatus("failed");
					});
			};

			lastRequest.current();
		},
		[]
	);

	const fetchRelatedPage = useCallback(
		(pageRel: LinkToPage, onFetched?: () => void) => {
			const requestPage = page.request[pageRel];
			if (requestPage) {
				lastRequest.current = () => {
					setPageFetchStatus("pending");

					requestPage()
						.then((p) => {
							setPageFetchStatus("succeeded");
							setPage(p);
							onFetched?.();
						})
						.catch(() => setPageFetchStatus("failed"));
				};

				lastRequest.current();
			}
		},
		[page.request]
	);

	function reloadPage(onFetched?: () => void) {
		lastRequest.current = () => {
			if (!page.reload) {
				return;
			}

			setPageFetchStatus("pending");

			page
				.reload()
				.then((p) => {
					setPageFetchStatus("succeeded");
					setPage(p);
					onFetched?.();
				})
				.catch(() => setPageFetchStatus("failed"));
		};

		lastRequest.current();
	}

	return {
		page,
		pageFetchStatus,
		reloadPage,
		retryFetching: lastRequest.current,
		fetchFirstPage,
		fetchRelatedPage,
	};
}

export default usePaginationState;
