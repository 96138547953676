import React, {createContext, useMemo} from "react";

import {getChapterContent} from "../store/chapters/getChapterContent";
import {useAppDispatch} from "../store/hooks";
import selectChapterContentStatus from "../store/chapters/selectChapterContentStatus";
import selectChapterSessionContentStatus from "../store/chapterSessions/selectChapterSessionContentStatus";
import {fetchChapterSessionContent} from "../store/chapterSessions/fetchSessionContent";
import {getChapterOutcomes} from "../store/outcomes/getChapterOutcomes";
import {fetchChapterSessionOutcomes} from "../store/chapterSessions/fetchChapterSessionOutcomes";
import type FetchStatus from "../store/FetchStatus";
import type {RootState} from "../store/store";
import {keyProvider} from "../store/keyProvider";
import {fetchStudentResponses} from "../store/studentResponses/fetchStudentResponses";
import {fetchChapterSessionStudentResponses} from "../store/chapterSessions/fetchChapterSessionStudentResponses";
import {getSectionContent} from "../store/sections/getSectionContent";
import {fetchSessionSectionContent} from "../store/chapterSessions/fetchSessionSectionContent";
import selectSessionSectionContentStatus from "../store/chapterSessions/selectSessionSectionContentStatus";
import selectSubsectionKeys, {
	selectSectionContentStatus,
} from "../store/sections/selectSubsectionKeys";
import {
	Section,
	makeSelectChapterSessionSection,
	makeSelectSection,
} from "../store/sections/makeSelectSection";
import selectChapterSectionKeys from "../store/chapters/selectChapterSectionKeys";
import selectChapterSessionSectionKeys from "../store/chapterSessions/selectChapterSessionSectionKeys";
import selectSessionSubsectionKeys from "../store/chapterSessions/selectSessionSubsectionKeys";
import type {
	ChapterExercise,
	ChapterExerciseWithContent,
} from "../store/chapterExercises/makeSelectChapterExercise";
import {
	makeSelectChapterExercise,
	makeSelectChapterExerciseWithContent,
	makeSelectChapterSessionExercise,
	makeSelectChapterSessionExerciseWithContent,
} from "../store/chapterExercises/makeSelectChapterExercise";
import type Response from "../store/studentResponses/Response";
import type {
	ResponseToSave,
	ResponseToSubmit,
} from "../store/studentResponses/Response";
import selectStudentResponse from "../store/studentResponses/selectStudentResponse";
import selectChapterSessionStudentResponse from "../store/chapterSessions/selectChapterSessionStudentResponse";
import type SubmissionResult from "../store/studentResponses/SubmissionResult";
import {CompletionStatus} from "../store/outcomes/ExerciseOutcomes";
import type {ExerciseOutcomes} from "../store/outcomes/ExerciseOutcomes";
import submitStudentResponse from "../store/studentResponses/submitStudentResponse";
import {unwrapResult} from "@reduxjs/toolkit";
import {submitChapterSessionStudentResponse} from "../store/chapterSessions/submitChapterSessionStudentResponse";
import type Feedback from "../store/studentResponses/Feedback";
import {saveStudentResponse} from "../store/studentResponses/saveStudentResponse";
import {saveChapterSessionStudentResponse} from "../store/chapterSessions/saveChapterSessionStudentResponse";
import selectExerciseOutcomes from "../store/outcomes/selectOutcomes";
import selectChapterSessionOutcomes from "../store/chapterSessions/selectChapterSessionOutcomes";

const empty: string[] = [];

async function noop() {
	return;
}

function nullNoop() {
	return () => {
		return null;
	};
}

const AssessmentDeliveryContext = createContext<{
	fetchContent: () => Promise<void>;
	fetchOutcomes: () => Promise<void>;
	fetchSectionContent: (sectionKey: string) => Promise<void>;
	fetchResponses: (sectionKey: string) => Promise<void>;
	saveResponse: (
		exerciseId: number,
		response: ResponseToSave
	) => Promise<Feedback>;
	submitResponse: (
		exerciseId: number,
		response: ResponseToSubmit
	) => Promise<SubmissionResult>;

	selectContentStatus: (state: RootState) => FetchStatus;
	selectSectionContentStatus: (
		state: RootState,
		sectionKey: string
	) => FetchStatus;
	selectSectionKeys: (state: RootState) => string[];
	makeSelectSection: () => (
		state: RootState,
		sectionkey: string
	) => Section | null;
	selectSubsectionKeys: (state: RootState, sectionKey: string) => string[];
	makeSelectChapterExercise: () => (
		state: RootState,
		chapterExerciseKey: string
	) => ChapterExercise | null;
	makeSelectChapterExerciseWithContent: () => (
		state: RootState,
		chapterExerciseKey: string
	) => ChapterExerciseWithContent | null;
	selectResponse: (
		state: RootState,
		chapterExerciseKey: string
	) => Response | null;
	selectOutcomes: (
		state: RootState,
		chapterExerciseKey: string
	) => ExerciseOutcomes;
}>({
	fetchContent: noop,
	fetchOutcomes: noop,
	fetchResponses: noop,
	fetchSectionContent: noop,
	saveResponse() {
		throw new Error("This function should not be called.");
	},
	submitResponse() {
		throw new Error("This function should not be called.");
	},

	selectContentStatus() {
		return "none";
	},
	selectSectionContentStatus() {
		return "none";
	},
	selectSectionKeys() {
		return empty;
	},
	makeSelectSection: nullNoop,
	selectSubsectionKeys() {
		return empty;
	},
	makeSelectChapterExercise: nullNoop,
	makeSelectChapterExerciseWithContent: nullNoop,
	selectResponse() {
		return null;
	},
	selectOutcomes() {
		return {
			completionStatus: CompletionStatus.Unattempted,
			score: 0,
		};
	},
});

const AssessmentDeliveryContextProvider = (props: {
	children: React.ReactNode;
	courseId: number;
	chapterId: number;
	studentId: number;
}): JSX.Element => {
	const {courseId, chapterId, studentId} = props;

	const dispatch = useAppDispatch();

	const value = useMemo(() => {
		const chapterKey = keyProvider.chapter(chapterId);

		return {
			fetchContent: async () => {
				await dispatch(
					getChapterContent({
						courseId,
						chapterId,
					})
				);
			},
			fetchOutcomes: async () => {
				await dispatch(
					getChapterOutcomes({
						courseId,
						chapterId,
						studentId,
					})
				);
			},
			fetchResponses: async (sectionKey: string) => {
				await dispatch(
					fetchStudentResponses({
						courseId,
						sectionKey,
						studentId,
					})
				);
			},
			fetchSectionContent: async (sectionKey: string) => {
				await dispatch(
					getSectionContent({
						courseId,
						sectionKey,
					})
				);
			},
			saveResponse: async (exerciseId: number, response: ResponseToSave) => {
				const res = await dispatch(
					saveStudentResponse({
						chapterId,
						courseId,
						exerciseId,
						response,
						studentId,
					})
				);

				return unwrapResult(res).feedback;
			},
			submitResponse: async (
				exerciseId: number,
				response: ResponseToSubmit
			) => {
				const res = await dispatch(
					submitStudentResponse({
						chapterId,
						courseId,
						exerciseId,
						response,
						studentId,
					})
				);
				return unwrapResult(res).submissionResult;
			},
			selectContentStatus: (state: RootState) =>
				selectChapterContentStatus(state, chapterKey),
			selectSectionContentStatus: (state: RootState, sectionKey: string) =>
				selectSectionContentStatus(state, sectionKey),
			selectSectionKeys: (state: RootState) =>
				selectChapterSectionKeys(state, chapterKey),
			makeSelectSection,
			selectSubsectionKeys,
			makeSelectChapterExercise,
			makeSelectChapterExerciseWithContent,
			selectResponse: selectStudentResponse,
			selectOutcomes: selectExerciseOutcomes,
		};
	}, [chapterId, courseId, dispatch, studentId]);

	return (
		<AssessmentDeliveryContext.Provider value={value}>
			{props.children}
		</AssessmentDeliveryContext.Provider>
	);
};

const SessionAssessmentDeliveryContextProvider = (props: {
	children: React.ReactNode;
	sessionKey: string;
	courseId: number;
	chapterId: number;
	studentId: number;
}): JSX.Element => {
	const {sessionKey, courseId, chapterId, studentId} = props;

	const dispatch = useAppDispatch();

	const value = useMemo(() => {
		return {
			fetchContent: async () => {
				if (sessionKey) {
					await dispatch(
						fetchChapterSessionContent({
							courseId,
							chapterId,
							studentId,
							sessionKey,
						})
					);
				}
			},
			fetchOutcomes: async () => {
				await dispatch(
					fetchChapterSessionOutcomes({
						chapterId,
						courseId,
						sessionKey,
						studentId,
					})
				);
			},
			fetchResponses: async (sectionKey: string) => {
				dispatch(
					fetchChapterSessionStudentResponses({
						courseId,
						sectionKey,
						sessionKey,
						studentId,
					})
				);
			},
			fetchSectionContent: async (sectionKey: string) => {
				await dispatch(
					fetchSessionSectionContent({
						courseId,
						sectionKey,
						sessionKey,
						studentId,
					})
				);
			},
			saveResponse: async (exerciseId: number, response: ResponseToSave) => {
				const res = await dispatch(
					saveChapterSessionStudentResponse({
						sessionKey,
						chapterId,
						courseId,
						exerciseId,
						response,
						studentId,
					})
				);

				return unwrapResult(res).feedback;
			},
			submitResponse: async (
				exerciseId: number,
				response: ResponseToSubmit
			) => {
				const res = await dispatch(
					submitChapterSessionStudentResponse({
						sessionKey,
						chapterId,
						courseId,
						exerciseId,
						response,
						studentId,
					})
				);
				return unwrapResult(res).submissionResult;
			},
			selectContentStatus: (state: RootState) =>
				selectChapterSessionContentStatus(state, sessionKey),
			selectSectionContentStatus: (state: RootState, sectionKey: string) =>
				selectSessionSectionContentStatus(state, sectionKey),
			selectSectionKeys: (state: RootState) =>
				selectChapterSessionSectionKeys(state, sessionKey),
			makeSelectSection: makeSelectChapterSessionSection,
			selectSubsectionKeys: selectSessionSubsectionKeys,
			makeSelectChapterExercise: makeSelectChapterSessionExercise,
			makeSelectChapterExerciseWithContent: makeSelectChapterSessionExerciseWithContent,
			selectResponse: selectChapterSessionStudentResponse,
			selectOutcomes: (state: RootState, chapterExerciseKey: string) =>
				selectChapterSessionOutcomes(state, sessionKey, chapterExerciseKey),
		};
	}, [chapterId, courseId, dispatch, sessionKey, studentId]);

	return (
		<AssessmentDeliveryContext.Provider value={value}>
			{props.children}
		</AssessmentDeliveryContext.Provider>
	);
};

export {
	AssessmentDeliveryContextProvider,
	SessionAssessmentDeliveryContextProvider,
};
export default AssessmentDeliveryContext;
