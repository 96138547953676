import {Typography} from "@material-ui/core";
import React from "react";

const ContentItemTitle = (props: {
	number: string;
	name: string;
}): JSX.Element => {
	return (
		<>
			<Typography variant="inherit" color="textSecondary">
				{`${props.number} `}
			</Typography>
			{props.name}
		</>
	);
};

export default ContentItemTitle;
