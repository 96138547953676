import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {unwrapResult} from "@reduxjs/toolkit";

import {TextBackButton} from "../nav/BackButton";
import CourseType from "../../store/courses/CourseType";
import CourseTemplate from "../../store/services/dtos/CourseTemplate";
import createCourse from "../../store/courses/createCourse";
import SubmitButton from "../../utils/SubmitButton";
import {courseService} from "../../store/services/courseService";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import selectUserProfile from "../../store/userProfile/selectUserProfile";
import useSnackbar from "../../store/ui/useSnackbar";
import useUserHasRoleInOrganisation from "../../store/organisation/useUserHasRoleInOrganisation";
import UserRole from "../../store/models/UserRole";

type Draft = {
	courseName: string;
	startDate: string;
	endDate: string;
};

const initialDraft = {
	courseName: "",
	startDate: "",
	endDate: "",
};

const CourseCreation = (props: {organisationName: string}): JSX.Element => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const organisationName = props.organisationName;

	const [draft, setDraft] = useState<Draft>(initialDraft);
	const [type, setType] = useState<CourseType>(CourseType.Ordinary);
	const [templates, setTemplates] = useState<CourseTemplate[]>([]);
	const [template, setTemplate] = useState<CourseTemplate | null>(null);
	const [loadingTemplates, setLoadingTemplates] = useState(true);
	const [publishContent, setPublishContent] = useState(false);
	const [assignAsTeacher, setAssignAsTeacher] = useState(false);

	const [requiredEmpty, setRequiredEmpty] = useState<{
		courseName?: boolean;
		startDate?: boolean;
		endDate?: boolean;
		template?: boolean;
	}>({});

	const [inconsistentDates, setInconsistentDates] = useState(false);

	const [errorCourseCreate, setErrorCourseCreate] = useState<string | null>();
	const [inProgress, setInProgress] = useState(false);

	const showSnackbar = useSnackbar();

	const {l10n} = useLocalization();

	useEffect(() => {
		async function fetchTemplates() {
			setLoadingTemplates(true);

			try {
				let page = await courseService.searchTemplates(
					organisationName,
					{},
					{field: "name"},
					100
				);
				setTemplates(page.content);
				while (page.request.next) {
					page = await page.request.next();
					setTemplates((prev) => [...prev, ...page.content]);
				}
			} finally {
				setLoadingTemplates(false);
			}
		}

		fetchTemplates();
	}, [organisationName]);

	const user = useAppSelector(selectUserProfile);
	const [hasRole] = useUserHasRoleInOrganisation();
	const admin = user.isSuperAdmin || hasRole(UserRole.Administrator);

	const courseChangeHandler = (property: keyof Draft, value: string) => {
		setDraft((prevState) => {
			return {...prevState, [property]: value};
		});

		setRequiredEmpty((prevState) => ({...prevState, [property]: false}));

		setErrorCourseCreate("");
	};

	const min = draft.startDate.slice(0, 10);
	const max = draft.endDate.slice(0, 10);

	const validate = () => {
		const emptyFields = {
			courseName: draft.courseName.length === 0,
			startDate: draft.startDate.length === 0,
			endDate: draft.endDate.length === 0,
			template: !template,
		};
		const inconsistentDates =
			!emptyFields.startDate &&
			!emptyFields.endDate &&
			draft.startDate > draft.endDate;

		setRequiredEmpty(emptyFields);
		setInconsistentDates(inconsistentDates);

		return !(Object.values(emptyFields).some((e) => e) || inconsistentDates);
	};

	const create = async () => {
		const valid = validate();
		if (!valid) {
			return;
		}

		const course = {
			name: draft.courseName,
			type: type,
			startDate: new Date(draft.startDate).toISOString(),
			endDate: new Date(draft.endDate).toISOString(),
			templateId: template?.id ?? 0,
			teacherIds: admin && !assignAsTeacher ? [] : [user.id],
		};

		setInProgress(true);

		const result = await dispatch(
			createCourse({organisationName, course: course})
		);

		setInProgress(false);

		const {courseId} = unwrapResult(result);

		if (publishContent) {
			try {
				setInProgress(true);
				await courseService.publishAllChapters(courseId, type);
			} catch {
				showSnackbar(
					"error",
					l10n.getString(
						"course-creation-form-publishing-failed-error",
						null,
						"The new course was created successfully, but an error occurred during content publishing."
					)
				);
			} finally {
				setInProgress(false);
			}
		}

		history.replace(`/courses/${courseId}/management/settings`);
	};

	return (
		<Container maxWidth="md">
			<Box mt={2} mb={2}>
				<Typography variant="h6">
					<Localized id="course-creation-form-title">New course</Localized>
				</Typography>
			</Box>
			<Grid container spacing={2} direction="column">
				<Grid item>
					<TextField
						fullWidth
						required
						error={requiredEmpty.courseName}
						helperText={
							requiredEmpty.courseName && (
								<Localized id="course-creation-form-error-required-field">
									This field is required
								</Localized>
							)
						}
						id="course-name"
						label={
							<Localized id="course-creation-form-label-course-name">
								Course name
							</Localized>
						}
						value={draft.courseName}
						onChange={(event) =>
							courseChangeHandler("courseName", event.target.value)
						}
					/>
				</Grid>

				<Grid container item spacing={3}>
					<Grid item sm={6} xs={12}>
						<TextField
							select
							fullWidth
							required
							id="type"
							label={
								<Localized id="course-creation-form-field-type-label">
									Type
								</Localized>
							}
							value={type}
							onChange={(event) => setType(event.target.value as CourseType)}
						>
							<MenuItem value={CourseType.Ordinary}>
								<Localized id="course-creation-form-field-type-option-ordinary">
									Ordinary
								</Localized>
							</MenuItem>
							<MenuItem value={CourseType.Exam}>
								<Localized id="course-creation-form-field-type-option-exam">
									Exam
								</Localized>
							</MenuItem>
						</TextField>
					</Grid>
					<Grid item sm={3} xs={12}>
						<TextField
							required
							fullWidth
							error={requiredEmpty.startDate || inconsistentDates}
							helperText={
								(requiredEmpty.startDate && (
									<Localized id="course-creation-form-error-required-field">
										This field is required
									</Localized>
								)) ||
								(inconsistentDates && (
									<Localized id="course-creation-form-error-inconsistent-date">
										Start date cannot be later than the end date
									</Localized>
								))
							}
							id="start-date"
							label={
								<Localized id="course-creation-form-label-start-date">
									Start date
								</Localized>
							}
							type="date"
							value={draft.startDate}
							onChange={(event) => {
								courseChangeHandler("startDate", event.target.value);
								setInconsistentDates(false);
							}}
							InputProps={{inputProps: {max: max}}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item sm={3} xs={12}>
						<TextField
							required
							fullWidth
							error={requiredEmpty.endDate || inconsistentDates}
							helperText={
								requiredEmpty.endDate && (
									<Localized id="course-creation-form-error-required-field">
										This field is required
									</Localized>
								)
							}
							id="end-date"
							label={
								<Localized id="course-creation-form-label-end-date">
									End date
								</Localized>
							}
							type="date"
							value={draft.endDate}
							onChange={(event) => {
								courseChangeHandler("endDate", event.target.value);
								setInconsistentDates(false);
							}}
							InputProps={{inputProps: {min: min}}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
				</Grid>

				<Grid item>
					<Autocomplete
						fullWidth
						id="course-templates"
						options={templates}
						getOptionLabel={(option) => option.name}
						value={template}
						loading={loadingTemplates}
						noOptionsText={
							<Localized id="course-creation-form-dropdown-error-no-templates">
								No templates
							</Localized>
						}
						onChange={(_, newValue) => setTemplate(newValue)}
						renderInput={(params) => (
							<TextField
								required
								{...params}
								error={requiredEmpty.template}
								helperText={
									requiredEmpty.template && (
										<Localized id="course-creation-form-error-required-field">
											This field is required
										</Localized>
									)
								}
								label={
									<Localized id="course-creation-form-label-course-template">
										Course template
									</Localized>
								}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{loadingTemplates && (
												<CircularProgress color="inherit" size={20} />
											)}
											{params.InputProps.endAdornment}
										</>
									),
								}}
							/>
						)}
					/>
				</Grid>

				<Grid item>
					<FormControlLabel
						control={
							<Checkbox
								checked={publishContent}
								onChange={({target}) => setPublishContent(target.checked)}
							/>
						}
						label={
							<Localized id="course-creation-form-publish-content">
								Publish course material immediately
							</Localized>
						}
					/>
				</Grid>

				{admin && (
					<Grid item>
						<FormControlLabel
							control={
								<Checkbox
									checked={assignAsTeacher}
									onChange={({target}) => setAssignAsTeacher(target.checked)}
								/>
							}
							label={
								<Localized id="course-creation-form-assign-myself-as-teacher">
									Assign myself as a teacher
								</Localized>
							}
						/>
					</Grid>
				)}

				<Grid item>
					<Box display="flex" alignItems="center" flex="1">
						<Box flex="1">
							{errorCourseCreate && errorCourseCreate.length > 0 && (
								<Typography variant="body2" color="error">
									{errorCourseCreate}
								</Typography>
							)}
						</Box>
						<Box display="flex" alignItems="center">
							<Box mr={2}>
								<TextBackButton color="primary">
									<Localized id="course-creation-form-cancel-button">
										Cancel
									</Localized>
								</TextBackButton>
							</Box>
							<SubmitButton onClick={create} inProgress={inProgress}>
								<Localized id="course-creation-form-create-button">
									Create
								</Localized>
							</SubmitButton>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};

export default CourseCreation;
