import {Localized, useLocalization} from "@fluent/react";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	FormControlLabel,
	TextField,
	useTheme,
} from "@material-ui/core";
import React, {useRef, useState} from "react";

import {useAppSelector} from "../../store/hooks";
import {courseService} from "../../store/services/courseService";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

type CourseToCopy = {
	id: number;
	name: string;
};

function useCopyAction(
	organisationName: string,
	onSuccess: () => void,
	onError: () => void,
	options?: {autoAssignAsTeacher: boolean}
): [(ofCourse: CourseToCopy) => void, JSX.Element] {
	const userId = useAppSelector(selectUserId);

	const [copyOf, setCopyOf] = useState<CourseToCopy | null>(null);

	function copy(ofCourse: CourseToCopy) {
		setCopyOf(ofCourse);
	}

	function close() {
		setCopyOf(null);
	}

	async function copyCourse(
		name: string,
		options: {assignAsTeacher?: boolean}
	) {
		if (!copyOf) {
			return;
		}

		try {
			await courseService.copyCourse(organisationName, copyOf.id, name, {
				teacherIds: options.assignAsTeacher ? [userId] : undefined,
			});

			onSuccess();
			close();
		} catch {
			onError();
		}
	}

	const copyDialogId = "copy-course";

	return [
		copy,

		<Dialog
			open={Boolean(copyOf)}
			maxWidth="sm"
			fullWidth
			aria-labelledby={copyDialogId}
			key={copyDialogId}
			onClose={close}
		>
			<CopyCourseDialog
				titleElementId={copyDialogId}
				courseName={copyOf?.name ?? ""}
				autoAssignAsTeacher={options?.autoAssignAsTeacher}
				onCancel={close}
				onCopy={copyCourse}
			/>
		</Dialog>,
	];
}

function CopyCourseDialog(props: {
	titleElementId: string;
	courseName: string;
	autoAssignAsTeacher?: boolean;
	onCancel: () => void;
	onCopy: (name: string, options: {assignAsTeacher?: boolean}) => Promise<void>;
}) {
	const {l10n} = useLocalization();
	const theme = useTheme();

	const origName = useRef(props.courseName);
	const [name, setName] = useState(
		l10n.getString(
			"course-copy-dialog-default-course-name",
			{name: props.courseName.trim()},
			`${props.courseName} (copy)`
		)
	);
	const [emptyName, setEmptyName] = useState(false);
	const [assignAsTeacher, setAssignAsTeacher] = useState(
		props.autoAssignAsTeacher
	);

	const [inProgress, setInProgress] = useState(false);

	async function copy() {
		if (name.length === 0) {
			setEmptyName(true);
			return;
		}

		setInProgress(true);
		await props.onCopy(name, {assignAsTeacher});
		setInProgress(false);
	}

	return (
		<>
			<WindowedDialogTitle
				id={props.titleElementId}
				title={<Localized id="course-copy-dialog-title">Copy course</Localized>}
				onClose={props.onCancel}
			/>

			<DialogContent dividers>
				<DialogContentText align="justify">
					<Localized
						id="course-copy-dialog-content-confirmation"
						vars={{name: origName.current}}
					>
						{`You are going to make a structural copy of course '${name}'.`}
					</Localized>
				</DialogContentText>
				<DialogContentText align="justify">
					<Localized id="course-copy-dialog-content-copied">
						What is copied:
					</Localized>
				</DialogContentText>
				<DialogContentText align="justify" component="ul">
					<Localized
						id="course-copy-dialog-content-copied-elems"
						elems={{listitem: <li></li>}}
					>
						<>{`course structure; course settings`}</>
					</Localized>
				</DialogContentText>
				<DialogContentText align="justify">
					<Localized id="course-copy-dialog-content-not-copied">
						What is not copied:
					</Localized>
				</DialogContentText>
				<DialogContentText align="justify" component="ul">
					<Localized
						id="course-copy-dialog-content-not-copied-elems"
						elems={{listitem: <li></li>}}
					>
						<>{`theory content and exercises`}</>
					</Localized>
				</DialogContentText>
				<TextField
					required
					fullWidth
					label={
						<Localized id="course-copy-dialog-label-course-name">
							New course name
						</Localized>
					}
					value={name}
					error={emptyName}
					onChange={({target}) => {
						setEmptyName(false);
						setName(target.value);
					}}
				/>

				{!props.autoAssignAsTeacher && (
					<FormControlLabel
						control={
							<Checkbox
								checked={assignAsTeacher}
								onChange={(_, checked) => setAssignAsTeacher(checked)}
							/>
						}
						label={
							<Localized id="course-copy-dialog-assign-myself-as-teacher">
								Assign myself as a teacher
							</Localized>
						}
						style={{marginTop: theme.spacing(1)}}
					/>
				)}
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					<Localized id="course-copy-dialog-action-cancel">Cancel</Localized>
				</Button>
				<SubmitButton inProgress={inProgress} variant="text" onClick={copy}>
					<Localized id="course-copy-dialog-action-copy">Copy</Localized>
				</SubmitButton>
			</DialogActions>
		</>
	);
}

export default useCopyAction;
