import {Box, Stack} from "@mui/material";
import React from "react";

import StudentsWidget from "./StudentsWidget";

function StudentManagement(props: {courseId: number; viewOnly?: boolean}) {
	return (
		<Box sx={{padding: {md: 6, xs: 3}}}>
			<Stack spacing={3}>
				<StudentsWidget courseId={props.courseId} viewOnly={props.viewOnly} />
			</Stack>
		</Box>
	);
}

export default StudentManagement;
