import clsx from "clsx";
import {
	Chip,
	ChipProps,
	Theme,
	alpha,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles<Theme, {color?: ChipProps["color"]}>((theme) =>
	createStyles({
		root: {
			"&$selected, &$selected:focus": {
				backgroundColor: ({color}) =>
					alpha(
						color === "primary"
							? theme.palette.primary.main
							: theme.palette.secondary.main,
						theme.palette.action.selectedOpacity
					),
				color: theme.palette.text.primary,
			},
		},
		selected: () => ({}),
	})
);

function ChoiceChip(
	props: ChipProps & {
		selected: boolean;
	}
) {
	const {selected, ...chip} = props;

	const classes = useStyles({color: chip.color});

	return (
		<Chip
			{...chip}
			className={clsx(classes.root, {[classes.selected]: selected})}
			color={selected ? chip.color : undefined}
			clickable={chip.clickable ?? true}
		/>
	);
}

export default ChoiceChip;
