import {Localized} from "@fluent/react";
import {Tab} from "@material-ui/core";
import React, {useState} from "react";
import {Link, Redirect, Route, Switch} from "react-router-dom";

import GroupCourses from "../../components/courses/GroupCourses";
import GroupCourseTemplates from "../../components/courseTemplates/GroupCourseTemplates";
import SubtitleNav from "../../components/nav/SubtitleNav";
import GroupDataRetentionSettings from "../../components/organisations/GroupDataRetentionSettings";
import GroupOrganisations from "../../components/organisations/GroupOrganisations";
import GroupUsers from "../../components/users/GroupUsers";
import useSubrouteCreator from "../../hooks/useSubrouteCreator";
import useFetchOrganisation from "../../store/organisation/useFetchOrganisation";
import ObservableRoute from "../../utils/ObservableRoute";

function Administration(props: {groupName: string}) {
	const {groupName} = props;

	const [organisation] = useFetchOrganisation(groupName);

	const [selectedTab, setSelectedTab] = useState<string | boolean>(false);

	const createSubroute = useSubrouteCreator();

	const organisationsPage = createSubroute("/organisations");
	const coursesPage = createSubroute("/courses");
	const coursesTemplatesPage = createSubroute("/course-templates");
	const usersPage = createSubroute("/users");
	const retentionPage = createSubroute("/data-retention");

	return (
		<>
			<SubtitleNav
				title={organisation?.displayName}
				selectedTab={selectedTab}
				backButtonDisabled
			>
				<Tab
					label={
						<Localized id="group-administration-tab-name-organisations">
							Organisations
						</Localized>
					}
					component={Link}
					value={organisationsPage.link}
					to={organisationsPage.link}
				/>

				<Tab
					label={
						<Localized id="group-administration-tab-name-users">
							Users
						</Localized>
					}
					component={Link}
					value={usersPage.link}
					to={usersPage.link}
				/>

				<Tab
					label={
						<Localized id="group-administration-tab-name-courses">
							Courses
						</Localized>
					}
					component={Link}
					value={coursesPage.link}
					to={coursesPage.link}
				/>

				<Tab
					label={
						<Localized id="group-administration-tab-name-course-templates">
							Templates
						</Localized>
					}
					component={Link}
					value={coursesTemplatesPage.link}
					to={coursesTemplatesPage.link}
				/>
				<Tab
					label={
						<Localized id="group-administration-tab-name-data-retention">
							Data retention
						</Localized>
					}
					component={Link}
					value={retentionPage.link}
					to={retentionPage.link}
				/>
			</SubtitleNav>

			<Switch>
				<ObservableRoute
					path={organisationsPage.route}
					onRender={() => setSelectedTab(organisationsPage.link)}
				>
					<GroupOrganisations groupName={groupName} />
				</ObservableRoute>

				<ObservableRoute
					path={usersPage.route}
					onRender={() => setSelectedTab(usersPage.link)}
				>
					<GroupUsers groupName={groupName} />
				</ObservableRoute>

				<ObservableRoute
					path={coursesPage.route}
					onRender={() => setSelectedTab(coursesPage.link)}
				>
					<GroupCourses groupName={groupName} />
				</ObservableRoute>

				<ObservableRoute
					path={coursesTemplatesPage.route}
					onRender={() => setSelectedTab(coursesTemplatesPage.link)}
				>
					<GroupCourseTemplates groupName={groupName} />
				</ObservableRoute>

				<ObservableRoute
					path={retentionPage.route}
					onRender={() => setSelectedTab(retentionPage.link)}
				>
					<GroupDataRetentionSettings groupName={groupName} />
				</ObservableRoute>

				<Route>
					<Redirect to="/organisations" />
				</Route>
			</Switch>
		</>
	);
}

export default Administration;
