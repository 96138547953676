import React from "react";
import type {ReactNode} from "react";
import {Box} from "@material-ui/core";
import HtmlContent from "../../../../utils/HtmlContent";

const Choice = (props: {
	control: ReactNode;
	label: string;
	disabled?: boolean;
	onChange?: () => void;
	labelId: string;
	feedback?: ReactNode;
}): JSX.Element => {
	return (
		<Box display="flex" alignItems="center" justifyContent="space-between">
			<Box display="flex" alignItems="center">
				<Box minWidth={"1.5rem"} display="flex">
					{props.feedback}
				</Box>

				{props.control}

				<Box
					id={props.labelId}
					onClick={() => !props.disabled && props.onChange && props.onChange()}
					color={props.disabled ? "text.disabled" : ""}
					style={{cursor: props.disabled ? "inherit" : "pointer"}}
				>
					<HtmlContent content={props.label} />
				</Box>
			</Box>
		</Box>
	);
};

export default Choice;
