import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, {useState} from "react";
import {unwrapResult} from "@reduxjs/toolkit";

import type DialogResult from "../../utils/DialogResult";
import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import SubmitButton from "../../utils/SubmitButton";
import {useAppDispatch} from "../../store/hooks";
import deleteUser from "../../store/users/deleteUser";
import logoutUser from "../../store/userSession/logoutUser";

function DeleteAccountDialog(props: {
	user: {
		id: number;
		name: string;
		email?: string;
	};
	mobileMode: boolean;
	titleElementId: string;
	onClose: (result: DialogResult) => void;
}): JSX.Element {
	const {user, onClose} = props;
	const theme = useTheme();

	const [nameOrEmail, setNameOrEmail] = useState("");
	const [nameOrEmailEmpty, setNameOrEmailEmpty] = useState(false);
	const [nameOrEmailMismatch, setNameOrEmailMismatch] = useState(false);

	const [password, setPassword] = useState("");
	const [passwordEmpty, setPasswordEmpty] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const [inProgress, setInProgress] = useState(false);

	const {l10n} = useLocalization();
	const dispatch = useAppDispatch();

	function close() {
		onClose({status: "cancelled"});
	}

	async function submit() {
		const valid = validate();
		if (!valid) {
			return;
		}

		setInProgress(true);
		try {
			const res = await dispatch(
				deleteUser({password, userId: user.id, username: user.name})
			);
			unwrapResult(res);
			dispatch(logoutUser({logoutOnServer: false}));
		} catch (err) {
			setInProgress(false);
			onClose({
				status: "error",
				message:
					(err as {code: string}).code === "forbidden"
						? l10n.getString(
								"account-delete-incorrect-password",
								null,
								"Incorrect password"
						  )
						: l10n.getString("error-general"),
			});
		}
	}

	function validate() {
		const passwordValid = password.length !== 0;
		setPasswordEmpty(!passwordValid);

		let nameValid = nameOrEmail.length !== 0;
		setNameOrEmailEmpty(!nameValid);

		if (!nameValid) {
			return false;
		}

		nameValid = nameOrEmail.includes("@")
			? user.email === nameOrEmail
			: user.name === nameOrEmail;

		setNameOrEmailMismatch(!nameValid);

		return nameValid && passwordValid;
	}

	return (
		<>
			{props.mobileMode ? (
				<FullScreenDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="account-delete-dialog-title">
							Delete account?
						</Localized>
					}
					actionButtonLabel={
						<Localized id="account-delete-dialog-delete-btn">Delete</Localized>
					}
					actionInProgress={inProgress}
					onAction={submit}
					onClose={close}
				/>
			) : (
				<WindowedDialogTitle onClose={close} id={props.titleElementId} />
			)}

			<DialogContent dividers>
				<Localized
					id="account-delete-dialog-description"
					elems={{
						paragraph: <DialogContentText align="justify"></DialogContentText>,
						bold: (
							<DialogContentText
								align="justify"
								style={{fontWeight: 500}}
							></DialogContentText>
						),
					}}
				>
					<>
						You will leave all your organisations and your account will be
						deleted.
					</>
				</Localized>

				<TextField
					required
					fullWidth
					autoFocus
					value={nameOrEmail}
					label={
						<Localized id="account-delete-dialog-username-email">
							Username or email address
						</Localized>
					}
					error={nameOrEmailEmpty || nameOrEmailMismatch}
					style={{marginBottom: theme.spacing(1)}}
					helperText={
						nameOrEmailMismatch && (
							<Localized id="account-delete-dialog-username-email-mismatch">
								Does not match your username or email address
							</Localized>
						)
					}
					onChange={({target}) => {
						setNameOrEmailEmpty(false);
						setNameOrEmailMismatch(false);
						setNameOrEmail(target.value);
					}}
				/>

				<input type="text" autoComplete="username" style={{display: "none"}} />

				<TextField
					required
					fullWidth
					label={
						<Localized id="account-delete-dialog-password">Password</Localized>
					}
					error={passwordEmpty}
					type={showPassword ? "text" : "password"}
					value={password}
					autoComplete="current-password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword((prev) => !prev)}>
									{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={({target}) => {
						setPasswordEmpty(false);
						setPassword(target.value);
					}}
				/>

				<DialogContentText
					style={{marginTop: theme.spacing(3), marginBottom: 0}}
				>
					<Localized id="account-delete-dialog-description-confirm">
						There will be no turning back. Are you sure?
					</Localized>
				</DialogContentText>
			</DialogContent>

			{!props.mobileMode && (
				<DialogActions>
					<Button onClick={close} color="primary">
						<Localized id="account-delete-dialog-cancel-btn">Cancel</Localized>
					</Button>
					<SubmitButton onClick={submit} inProgress={inProgress} variant="text">
						<Localized id="account-delete-dialog-delete-btn">Delete</Localized>
					</SubmitButton>
				</DialogActions>
			)}
		</>
	);
}

function WindowedDialogTitle(props: {id: string; onClose: () => void}) {
	return (
		<DialogTitle id={props.id}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography variant="h6">
					<Localized id="account-delete-dialog-title">Delete account</Localized>
				</Typography>
				<IconButton onClick={props.onClose}>
					<CloseIcon />
				</IconButton>
			</Box>
		</DialogTitle>
	);
}

export default DeleteAccountDialog;
