import {Localized} from "@fluent/react";
import {
	Button,
	DialogActions,
	DialogContent,
	IconButton,
	InputAdornment,
	TextField,
	useTheme,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, {useRef, useState} from "react";

import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

const ChangePasswordDialog = (props: {
	titleElementId: string;
	mobileMode: boolean;
	onChange: (password: string) => Promise<void>;
	onCancel: () => void;
}): JSX.Element => {
	const [passwordHidden, setPasswordHidden] = useState(true);

	const [password, setPassword] = useState("");
	const confirmRef = useRef<{value: string} | null>(null);
	const [equal, setEqual] = useState<boolean>(true);
	const [passwordShort, setPasswordShort] = useState(false);

	const [inProgress, setInProgress] = useState(false);

	const theme = useTheme();

	const toggleHidePassword = () => {
		setPasswordHidden((prev) => !prev);
	};

	const submit = async () => {
		if (password !== confirmRef.current?.value) {
			setEqual(false);
			return;
		}

		if (password.length < 8) {
			setPasswordShort(true);
			return;
		}

		setInProgress(true);

		await props.onChange(password);

		setInProgress(false);
	};

	return (
		<form
			onKeyDown={(e) => {
				if (e.code === "Enter") {
					e.preventDefault();
					submit();
				}
			}}
		>
			{props.mobileMode ? (
				<FullScreenDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="users-change-password-dialog-title">
							Change password
						</Localized>
					}
					actionInProgress={inProgress}
					actionButtonLabel={
						<Localized id="users-change-password-dialog-action-change">
							Change
						</Localized>
					}
					onAction={submit}
					onClose={props.onCancel}
				/>
			) : (
				<WindowedDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="users-change-password-dialog-title">
							Change password
						</Localized>
					}
					inProgress={inProgress}
					onClose={props.onCancel}
				/>
			)}

			<DialogContent>
				<TextField
					required
					fullWidth
					label={
						<Localized id="users-change-password-dialog-new-password">
							New password
						</Localized>
					}
					type={passwordHidden ? "password" : "text"}
					value={password}
					error={passwordShort}
					helperText={
						passwordShort && (
							<Localized id="users-change-password-dialog-error-short-password">
								Passwords should be at least 8 characters long
							</Localized>
						)
					}
					autoFocus
					autoComplete="new-password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={toggleHidePassword}>
									{passwordHidden ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={(event) => {
						setPassword(event.target.value);
						setEqual(true);
						setPasswordShort(false);
					}}
				/>
				<TextField
					inputRef={confirmRef}
					required
					fullWidth
					label={
						<Localized id="users-change-password-dialog-repeat-password">
							Repeat new password
						</Localized>
					}
					type={passwordHidden ? "password" : "text"}
					autoComplete="new-password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={toggleHidePassword}>
									{passwordHidden ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={() => setEqual(true)}
					error={!equal}
					helperText={
						!equal && (
							<Localized id="users-change-password-dialog-error-password-mismatch">
								Passwords mismatch
							</Localized>
						)
					}
					style={{marginTop: theme.spacing(2)}}
				/>
			</DialogContent>

			{!props.mobileMode && (
				<DialogActions>
					<Button onClick={props.onCancel} color="primary">
						<Localized id="users-change-password-dialog-action-cancel">
							Cancel
						</Localized>
					</Button>
					<SubmitButton onClick={submit} inProgress={inProgress} variant="text">
						<Localized id="users-change-password-dialog-action-change">
							Change
						</Localized>
					</SubmitButton>
				</DialogActions>
			)}
		</form>
	);
};

export default ChangePasswordDialog;
