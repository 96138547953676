import {Container, TableCell, useTheme} from "@material-ui/core";
import React from "react";

import LoadingError from "../errors/LoadingError";

function LoadingErrorState(props: {
	title?: React.ReactNode;
	description: React.ReactNode;
	colSpan: number;
	onReload?: () => void;
}) {
	const theme = useTheme();

	return (
		<TableCell colSpan={props.colSpan} style={{borderBottom: "none"}}>
			<Container
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					paddingTop: theme.spacing(6),
				}}
			>
				<LoadingError
					title={props.title}
					description={props.description}
					variant="block"
					onReload={props.onReload}
				/>
			</Container>
		</TableCell>
	);
}

export default LoadingErrorState;
