import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import React, {useCallback, useEffect, useRef, useState} from "react";

import useExerciseFileUploader from "./useExerciseFileUploader";
import ExercisePrivacy from "../../store/exercises/ExercisePrivacy";
import {useAppSelector} from "../../store/hooks";
import TextEditor from "../../utils/TextEditor";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";
import ExerciseDifficultySelector from "../content/exercises/ExerciseDifficultySelector";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";
import exerciseService from "../../store/services/exerciseService";
import ExerciseTagsSelector from "./ExerciseTagsSelector";
import ContentEditorFooter from "./ContentEditorFooter";
import ExerciseType from "../../store/exercises/ExerciseType";
import {OpenExerciseSubtype} from "../../store/exercises/ExerciseSubtype";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import ExerciseEditorProps from "./ExerciseEditorProps";
import PrivacyLevelSelector from "./PrivacyLevelSelector";
import useSnackbar from "../../store/ui/useSnackbar";

const OpenExerciseEditor = (props: ExerciseEditorProps): JSX.Element => {
	const {courseId, exercise, exerciseId, organisationName} = props;

	const {l10n} = useLocalization();
	const [draft, setDraft] = useState(() => ({
		id: exerciseId ?? 0,
		difficultyLevel: 0,
		privacy: ExercisePrivacy.PublicToOrganisation,
		title: l10n.getString(
			"learning-material-open-exercise-editor-exercise-default-title"
		),
		finalAnswer: "",
		maxScore: 1,
		category: "",
		tags: [] as string[],
	}));
	const question = useRef<TextEditorApi | null>(null);
	const solution = useRef<TextEditorApi | null>(null);

	const initialQuestion = useRef("");
	const initialSolution = useRef("");

	const updated = useRef(false);

	if (exercise && exercise.type !== ExerciseType.Open) {
		throw new Error("Unexpected exercise type");
	}

	const [featureEnabled] = useFeatureEnabled();

	useEffect(() => {
		if (exercise) {
			initialQuestion.current = exercise.question;
			initialSolution.current = exercise.solution;

			setDraft({
				id: exerciseId ?? 0,
				category: exercise.category,
				difficultyLevel: exercise.difficultyLevel,
				maxScore: exercise.maxScore,
				privacy: exercise.privacy,
				finalAnswer: exercise.finalAnswer,
				title: exercise.title,
				tags: exercise.tags,
			});
		}
	}, [exercise, exerciseId]);

	const userId = useAppSelector(selectUserId);

	const [editingStarted, setEditingStarted] = useState(!exercise);

	const [files, fileUploader] = useExerciseFileUploader(
		userId,
		courseId,
		draft.id
	);

	const saveDisabled = !editingStarted || draft.title === "";

	const showSnackbar = useSnackbar();

	useEffect(() => props.onUnmount, [props.onUnmount]);

	const controlEditingStarted = () => {
		if (!editingStarted) {
			setEditingStarted(true);
		}
	};

	const handleTitleUpdate = (title: string) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, title}));
	};

	const handlePointsUpdate = (maxScore: number) => {
		if (maxScore < 0) {
			return;
		}
		controlEditingStarted();
		setDraft((prev) => ({...prev, maxScore}));
	};

	const handleContentFinalAnswerUpdate = (finalAnswer: string) => {
		setDraft((prev) => ({...prev, finalAnswer}));
		if (draft.finalAnswer !== finalAnswer) {
			controlEditingStarted();
		}
	};

	const handlePrivacyLevelUpdate = (privacy: ExercisePrivacy) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, privacy}));
	};

	const handleDifficultyUpdate = (difficultyLevel: number | null) => {
		if (difficultyLevel === null) {
			difficultyLevel = 0;
		}
		controlEditingStarted();
		setDraft((prev) => ({
			...prev,
			difficultyLevel: difficultyLevel as number,
		}));
	};

	const handleCategoryUpdate = (category: string) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, category}));
	};

	const handleTagsUpdate = (tags: string[]) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, tags}));
	};

	const handleDraftSave = async () => {
		try {
			const base = exercise ?? {
				type: ExerciseType.Open,
				authorId: userId,
				originId: courseId,
			};

			const id = await props.onSave({
				...base,
				title: draft.title,
				category: draft.category,
				question: question.current?.getContent() ?? "",
				solution: solution.current?.getContent() ?? "",
				finalAnswer: draft.finalAnswer,
				maxScore: draft.maxScore,
				privacy: draft.privacy,
				difficultyLevel: draft.difficultyLevel,
				tags: draft.tags,
				subtype:
					draft.finalAnswer.length > 0
						? OpenExerciseSubtype.AutoAssessed
						: OpenExerciseSubtype.Ordinary,
			});

			setDraft((prev) => ({...prev, id}));
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}

		setEditingStarted(false);
		updated.current = true;

		return true;
	};

	const handleDraftDone = async () => {
		const saved = await handleDraftSave();
		if (!saved) {
			return;
		}

		props.onClose(updated.current);
	};

	const searchTags = useCallback(
		async (prefix: string, pageSize: number) => {
			const page = await exerciseService.searchTags(
				{
					scope: "organisation_or_user_exercises",
					organisationName,
					userId,
					prefix,
				},
				pageSize
			);

			return page;
		},
		[organisationName, userId]
	);

	return (
		<>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<TextField
						required
						autoFocus
						fullWidth
						value={draft.title}
						id="exercise title"
						label={
							<Localized id="exercise-editor-title-label">
								Exercise title
							</Localized>
						}
						onChange={(e) => handleTitleUpdate(e.target.value)}
					/>
				</Grid>

				<Grid item>
					<Grid container alignItems="center" spacing={4}>
						<Grid item>
							<TextField
								id="score-number"
								label={
									<Localized id="exercise-editor-score-label">Score</Localized>
								}
								type="number"
								value={draft.maxScore}
								onChange={(e) => handlePointsUpdate(Number(e.target.value))}
							/>
						</Grid>
						<Grid item>
							{false && (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label="Ability to question"
								/>
							)}
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					<Box mt={2} mb={1}>
						<Typography variant="body2">
							<Localized id="exercise-editor-description-label">
								Description
							</Localized>
						</Typography>
					</Box>
					<TextEditorWithAttachments
						initialValue={initialQuestion.current}
						ref={question}
						onChange={() => controlEditingStarted()}
						fileUploader={fileUploader ?? undefined}
						files={files}
					/>
				</Grid>

				<Grid item>
					<Box mt={2} mb={1}>
						<Typography variant="body2">
							<Localized id="exercise-editor-solution-label">
								Example solution
							</Localized>
						</Typography>
					</Box>
					<TextEditor
						initialValue={initialSolution.current}
						onChange={() => controlEditingStarted()}
						fileUploader={fileUploader ?? undefined}
						ref={solution}
					/>
				</Grid>

				<Grid item>
					<TextField
						fullWidth
						value={draft.finalAnswer}
						id="final-answer"
						label={
							<Localized id="learning-material-open-exercise-editor-exercise-final-answer">
								Final answer
							</Localized>
						}
						onChange={(e) => handleContentFinalAnswerUpdate(e.target.value)}
					/>
				</Grid>

				<Grid item container spacing={3} alignItems="flex-end">
					<Grid item xs={12} md={6}>
						<PrivacyLevelSelector
							onChange={handlePrivacyLevelUpdate}
							value={draft.privacy}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ExerciseDifficultySelector
							name="difficulty"
							value={draft.difficultyLevel}
							onChange={handleDifficultyUpdate}
						/>
					</Grid>
				</Grid>

				<Grid item>
					<TextField
						value={draft.category}
						id="caterogy"
						label={
							<Localized id="exercise-editor-category-label">
								Category
							</Localized>
						}
						onChange={(e) => handleCategoryUpdate(e.target.value)}
					/>
				</Grid>

				{featureEnabled(Feature.ExerciseTags) && (
					<Grid item>
						<ExerciseTagsSelector
							freeSolo
							value={draft.tags}
							onChange={handleTagsUpdate}
							searchTags={searchTags}
						/>
					</Grid>
				)}
			</Grid>

			<ContentEditorFooter
				onCancel={() => props.onClose(updated.current)}
				onCompeleted={handleDraftDone}
				onSave={handleDraftSave}
				disabled={saveDisabled}
			/>
		</>
	);
};

export default OpenExerciseEditor;
