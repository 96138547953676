import {useLocalization} from "@fluent/react";
import {Box} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React from "react";

import IconButtonWithTooltip from "../IconButtonWithTooltip";

function ServerPaginationControls(props: {
	disabledControls?: {
		first: boolean;
		next: boolean;
		previous: boolean;
	};
	onClick: (rel: "first" | "next" | "previous") => void;
}) {
	const {disabledControls, onClick} = props;

	const {l10n} = useLocalization();

	const labelBeginning = l10n.getString("server-pagination-controls-beginning");
	const labelPrevious = l10n.getString("server-pagination-controls-previous");
	const labelNext = l10n.getString("server-pagination-controls-next");

	return (
		<Box flexShrink={0}>
			<IconButtonWithTooltip
				tooltipTitle={labelBeginning}
				disabled={disabledControls?.first}
				aria-label={labelBeginning}
				onClick={() => onClick("first")}
			>
				<FirstPageIcon />
			</IconButtonWithTooltip>
			<IconButtonWithTooltip
				tooltipTitle={labelPrevious}
				disabled={disabledControls?.previous}
				aria-label={labelPrevious}
				onClick={() => onClick("previous")}
			>
				<KeyboardArrowLeft />
			</IconButtonWithTooltip>
			<IconButtonWithTooltip
				tooltipTitle={labelNext}
				disabled={disabledControls?.next}
				aria-label={labelNext}
				onClick={() => onClick("next")}
			>
				<KeyboardArrowRight />
			</IconButtonWithTooltip>
		</Box>
	);
}

export default ServerPaginationControls;
