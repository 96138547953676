import {Localized, useLocalization} from "@fluent/react";
import type {Theme} from "@material-ui/core";
import {
	Box,
	Button,
	Tab,
	Tabs,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, {useState} from "react";

import ProgramOutput from "./ProgramOutput";
import ProgramRunLog from "./ProgramRunLog";
import {describeStrictness} from "./descriptions";
import ComparisonStrictness from "../../../../store/exercises/ComparisonStrictness";
import {
	CompilationError,
	CorrectOutputResult,
	IncorrectOutputResult,
	compilationError,
} from "../../../../store/studentResponses/Feedback";
import HtmlContent from "../../../../utils/HtmlContent";

import "./prog.css";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			background: theme.palette.background.default,
			display: "flex",
			flexDirection: "column",
			[theme.breakpoints.up("xl")]: {
				flexDirection: "row",
			},
			"& > *": {
				[theme.breakpoints.up("xl")]: {
					flex: "1 1",
				},
			},
		},
		success: {
			color: theme.palette.success.main,
		},
	})
);

const TestResultPanel = (props: {
	result: CorrectOutputResult | IncorrectOutputResult | CompilationError;
	strictness: ComparisonStrictness;
}): JSX.Element => {
	const {result} = props;

	const classes = useStyles();
	const {l10n} = useLocalization();

	const [selectedTab, setSelectedTab] = useState(0);

	const selectTab = (_: unknown, i: number) => setSelectedTab(i);

	if (compilationError(result)) {
		return (
			<Box className={classes.root}>
				<Console number={1}></Console>

				<Box display="flex" flexDirection="column">
					<Tabs value={selectedTab} onChange={selectTab}>
						<Tab
							label={
								<Localized id="exercise-prog-response-area-test-tab-report">
									Report
								</Localized>
							}
						/>

						{result.compilerMessage && (
							<Tab
								label={
									<Localized id="exercise-prog-response-area-test-tab-compiler-message">
										Compiler message
									</Localized>
								}
							/>
						)}
					</Tabs>

					<Box p={3} pb={4}>
						<TabPanel index={0} value={selectedTab}>
							<Typography component="div">
								<HtmlContent content={result.message} />
							</Typography>
						</TabPanel>

						{result.compilerMessage && (
							<TabPanel index={1} value={selectedTab}>
								<div style={{whiteSpace: "pre", fontFamily: "monospace"}}>
									<HtmlContent content={result.compilerMessage} />
								</div>
							</TabPanel>
						)}
					</Box>
				</Box>
			</Box>
		);
	}

	if ("error" in result) {
		return (
			<Box className={classes.root}>
				<Console
					number={result.runtimeSerial}
					time={result.cputime}
					log={result.log}
					errorPlace={result.errorPlace}
				></Console>

				<Box display="flex" flexDirection="column">
					<Tabs value={selectedTab} onChange={selectTab}>
						<Tab
							label={
								<Localized id="exercise-prog-response-area-test-tab-report">
									Report
								</Localized>
							}
						/>

						<Tab
							label={
								<Localized id="exercise-prog-response-area-test-tab-help">
									Help
								</Localized>
							}
						/>
					</Tabs>

					<Box p={3} pb={4}>
						<TabPanel index={0} value={selectedTab}>
							<ReportPanel runtime={result} />
						</TabPanel>

						<TabPanel index={1} value={selectedTab}>
							<HelpPanel
								strictness={describeStrictness(l10n, props.strictness)}
							/>
						</TabPanel>
					</Box>
				</Box>
			</Box>
		);
	}

	return (
		<Box className={classes.root}>
			<Console
				number={result.runtimeSerial}
				time={result.cputime}
				log={result.log}
			></Console>

			<Box p={3} pb={4}>
				<Typography className={classes.success}>{"OK"}</Typography>
			</Box>
		</Box>
	);
};

const useConsoleStyles = makeStyles((theme: Theme) =>
	createStyles({
		consoleHeader: {
			background: "#2b2b2b",
		},
		console: {
			color: theme.palette.common.white,
			background: theme.palette.common.black,
			height: 340,
		},
	})
);

function Console(props: {
	number: number;
	time?: number;
	log?: Parameters<typeof ProgramRunLog>[0]["records"];
	errorPlace?: NonNullable<Parameters<typeof ProgramRunLog>[0]["errorPlace"]>;
}) {
	const classes = useConsoleStyles();

	const {number, time, log, errorPlace} = props;

	return (
		<Box display="flex" flexDirection="column" className={classes.console}>
			<Box
				display="flex"
				justifyContent="space-between"
				className={classes.consoleHeader}
				p={1}
			>
				<Typography variant="caption">
					<Localized
						id="exercise-prog-response-area-test-number"
						vars={{number: number}}
					>
						{"Test {$number}"}
					</Localized>
				</Typography>

				{time && (
					<Typography variant="caption">
						<Localized
							id="exercise-prog-response-area-test-execution-time"
							vars={{time: time * 1000}}
						>
							{"Execution time: {$time}ms"}
						</Localized>
					</Typography>
				)}
			</Box>

			{log && (
				<Box overflow="auto">
					<ProgramRunLog records={log} errorPlace={errorPlace} />
				</Box>
			)}
		</Box>
	);
}

const fileOutputMaxLength = 100000;

function ReportPanel(props: {runtime: IncorrectOutputResult}) {
	const {runtime} = props;

	const [clipOutput, setClipOutput] = useState(
		Boolean(runtime.wrong && runtime.wrong.length > fileOutputMaxLength)
	);

	return (
		<Box display="flex" flexDirection="column" className="prog-report">
			<Typography component="div">
				<HtmlContent content={runtime.message} />
			</Typography>

			<Box mt={2}>
				<Typography variant="caption">
					{runtime.file ? (
						<Localized
							id="exercise-prog-response-area-test-expected-output-to-file"
							vars={{name: runtime.file}}
						>
							{"Expected output to file {$name}"}
						</Localized>
					) : (
						<Localized id="exercise-prog-response-area-test-expected-output">
							Expected output
						</Localized>
					)}
				</Typography>

				<ProgramOutput maxHeight={320} overflow="auto">
					<HtmlContent content={runtime.right} />
				</ProgramOutput>

				{runtime.wrong && runtime.file && (
					<Box mt={1}>
						<Typography variant="caption">
							<Localized
								id="exercise-prog-response-area-test-printed-to-file"
								vars={{name: runtime.file}}
							>
								{"Your program's output to file {$file}"}
							</Localized>
						</Typography>

						<ProgramOutput maxHeight={320} overflow="auto">
							{clipOutput ? (
								<Localized
									id="exercise-prog-response-area-test-output-too-long"
									elems={{
										action: (
											<Button
												size="small"
												color="primary"
												onClick={() => setClipOutput(false)}
											></Button>
										),
									}}
								>
									<>{"Output is too long. <action>Show</action>"}</>
								</Localized>
							) : (
								<HtmlContent content={runtime.wrong} />
							)}
						</ProgramOutput>
					</Box>
				)}
			</Box>
		</Box>
	);
}

function HelpPanel(props: {strictness: string}) {
	return (
		<Typography component="div">
			<ul style={{margin: 0}}>
				<li>
					<Localized id="exercise-prog-response-area-test-help-wrong-output">
						Wrong output is highlighted with red colour.
					</Localized>
				</li>
				<li>
					<Localized
						id="exercise-prog-response-area-test-help-new-line"
						elems={{
							newline: <i className="image-newline"></i>,
						}}
					>
						<>{"<newline></newline> represents a newline."}</>
					</Localized>
				</li>
				<li>{props.strictness}</li>
			</ul>
		</Typography>
	);
}

function TabPanel(props: {
	children: React.ReactNode;
	index: number;
	value: number;
}) {
	const {children, value, index} = props;

	return (
		<div role="tabpanel" hidden={value !== index}>
			{value === index && children}
		</div>
	);
}

export default TestResultPanel;
