import {createSlice} from "@reduxjs/toolkit";
import fetchCoursesAvailableForEnrolment from "./fetchCoursesAvailableForEnrolment";
import type FetchStatus from "../FetchStatus";
import type UserCourse from "../models/UserCourse";
import enrolInCourse from "./enrolInCourse";
import updateCourseSettings from "../courses/updateCourseSettings";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type State = {
	courses: UserCourse[];
	status: FetchStatus;
	userId?: number;
};

const initialState: State = {
	courses: [],
	status: "none",
};

export const availableCoursesSlice = createSlice({
	name: "availableCourses",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			fetchCoursesAvailableForEnrolment.fulfilled,
			(state, action) => {
				state.courses = action.payload.courses;
				state.status = "succeeded";
			}
		);
		builder.addCase(
			fetchCoursesAvailableForEnrolment.pending,
			(state, action) => {
				state.courses = [];
				state.status = "pending";

				if (action.meta.arg.userId) {
					state.userId = action.meta.arg.userId;
				} else {
					delete state.userId;
				}
			}
		);
		builder.addCase(
			fetchCoursesAvailableForEnrolment.rejected,
			(state, action) => {
				state.status = inferFetchStatusFromError(action);
			}
		);

		builder.addCase(enrolInCourse.fulfilled, (state) => {
			state.status = "none";
		});

		builder.addCase(updateCourseSettings.fulfilled, (state) => {
			state.status = "none";
		});
	},
});

export default availableCoursesSlice.reducer;
