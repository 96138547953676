import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	IconButton,
	TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, {useState} from "react";

import authService from "../../store/services/authService";
import BackdropComponent from "../../utils/Backdrop";
import type DialogResult from "../../utils/DialogResult";

interface Props {
	open: boolean;
	onClose: (result: DialogResult) => void;
}

const ForgotPassword = (props: Props): JSX.Element => {
	const {open, onClose} = props;
	const [value, setValue] = useState("");
	const [sending, setSending] = useState(false);
	const [disabled, setDisabled] = useState(true);

	const handleChange = (e: {target: {value: string}}) => {
		setValue(e.target.value);
		setDisabled(!e.target.value);
	};

	const handleClose = () => {
		onClose({status: "cancelled"});
		setValue("");
		setDisabled(true);
	};

	const {l10n} = useLocalization();
	const handleRecoverButtonClick = async () => {
		let onCloseProps: DialogResult, request;

		if (value.includes("@")) {
			onCloseProps = {
				status: "success",
				message: `Recover instructions are sent to ${value}`,
			};
			request = {email: value};
		} else {
			onCloseProps = {
				status: "success",
				message: `${value}, recover instructions are sent to your email`,
			};
			request = {username: value};
		}

		setSending(true);

		try {
			await authService.resetPassword(request);
		} catch (e) {
			onClose({
				status: "error",
				message: l10n.getString("login-error"),
			});
			setValue("");
			setDisabled(true);
			setSending(false);
			throw e;
		}

		onClose(onCloseProps);
		setValue("");
		setDisabled(true);
		setSending(false);
	};

	return (
		<>
			<Dialog open={open && !sending} onClose={handleClose}>
				<Box m={3}>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<DialogTitle style={{padding: 0}}>
							<Localized id="login-forgot-password">Forgot Password?</Localized>
						</DialogTitle>
						<IconButton aria-label="close" onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>

					<TextField
						autoFocus
						label={
							<Localized id="login-email-or-username">
								Email or username
							</Localized>
						}
						fullWidth
						margin="normal"
						value={value}
						onChange={handleChange}
					/>
					<Box mt={2} display="flex" flexDirection="row">
						<Box mr={2}>
							<Button onClick={handleClose} color="primary">
								<Localized id="login-cancel">cancel</Localized>
							</Button>
						</Box>
						<Button
							onClick={handleRecoverButtonClick}
							variant="contained"
							color="primary"
							disabled={disabled}
						>
							<Localized id="login-send-recover">
								Send recover instructions
							</Localized>
						</Button>
					</Box>
				</Box>
			</Dialog>
			<BackdropComponent open={sending} />
		</>
	);
};

export default ForgotPassword;
