import type {AxiosInstance} from "axios";

import {
	axiosInstance as client,
	isAxiosError as httpClientError,
} from "./axiosInstance";
import type EnrolmentApplicationStatus from "../enrolment/EnrolmentApplicationStatus";
import type UserCourse from "../models/UserCourse";
import DefaultCoursePicture from "../../images/default-course-picture.jpg";

function createService(client: AxiosInstance) {
	async function enrolInCourse(
		courseId: number,
		userId: number
	): Promise<void> {
		try {
			await client.post(`/api/courses/${courseId}/students`, {id: userId});
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 409) {
				throw {code: "conflict"};
			}

			throw err;
		}
	}

	async function sendEnrolmentApplication(
		courseId: number,
		studentId: number,
		password?: string
	): Promise<EnrolmentApplicationStatus> {
		try {
			const {data} = await client.post(
				`/api/courses/${courseId}/enrolment-applications`,
				{
					userId: studentId,
				},
				{auth: password ? {password, username: ""} : undefined}
			);

			return data.status;
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 403) {
				throw {code: "forbidden"};
			}

			throw err;
		}
	}

	async function getEnrolmentApplications(
		orgName: string,
		studentId: number
	): Promise<UserCourse[]> {
		const {data} = await client.get<{course: UserCourse}[]>(
			`/api/organisations/${orgName}/students/${studentId}/enrolment-applications`
		);

		const courses = data.map((d) => d.course);

		for (const item of courses) {
			item.defaultPicture = DefaultCoursePicture;
		}

		return courses;
	}

	return {
		enrolInCourse,
		getEnrolmentApplications,
		sendEnrolmentApplication,
	};
}

export default createService(client);
