import {Box, IconButton, useScrollTrigger} from "@material-ui/core";
import TocIcon from "@material-ui/icons/Toc";
import React from "react";

import useNavBarHeight from "../hooks/useNavBarHeight";

const OpenNavigationButton = (props: {
	hidden: boolean;
	onClick: () => void;
}): JSX.Element => {
	const navBarHeight = useNavBarHeight();
	const top = `${navBarHeight}px`;

	const navBarCollapsed = useScrollTrigger({disableHysteresis: true});

	return (
		<Box
			style={{
				position: "sticky",
				top,
				alignSelf: "flex-start",
				display: props.hidden ? "none" : undefined,
			}}
			mt={-1.5}
			ml={-1.5}
			pt={navBarCollapsed ? 4.5 : 0}
		>
			<IconButton onClick={props.onClick} color="primary">
				<TocIcon fontSize="large" />
			</IconButton>
		</Box>
	);
};

export default OpenNavigationButton;
