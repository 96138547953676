import {Theme, Tooltip, withStyles} from "@material-ui/core";

const LightTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: theme.shadows[1],
		maxWidth: "none",
	},
}))(Tooltip);

export default LightTooltip;
