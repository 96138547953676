import {Box, Typography} from "@material-ui/core";
import React from "react";

import DashboardEmptyState from "../../images/DashboardEmptyState.svg";

const EmptyState = (props: {message: string}): JSX.Element => {
	return (
		<Box textAlign="center">
			<img src={DashboardEmptyState} />
			<Box mt={3}>
				<Typography variant="h5">{props.message}</Typography>
			</Box>
		</Box>
	);
};

export default EmptyState;
