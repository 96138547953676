import {Dialog} from "@material-ui/core";
import React, {useState} from "react";

import ChangePasswordDialog from "./ChangePasswordDialog";
import {userService} from "../../store/services/userService";
import SlideUp from "../../utils/SlideUp";

function useChangePasswordAction(
	mobileMode: boolean,
	onSuccess: () => void,
	onError: () => void
): [(userId: number) => void, JSX.Element] {
	const [userId, setUserId] = useState(0);

	function changePassword(userId: number) {
		setUserId(userId);
	}

	function close() {
		setUserId(0);
	}

	async function updatePassword(password: string) {
		try {
			await userService.patchCredentials(userId, {password});
			onSuccess();
			close();
		} catch {
			onError();
		}
	}

	const changePasswordTitleId = "change-password";

	return [
		changePassword,
		<Dialog
			key={changePasswordTitleId}
			open={Boolean(userId)}
			aria-labelledby={changePasswordTitleId}
			maxWidth="xs"
			fullWidth
			fullScreen={mobileMode}
			TransitionComponent={mobileMode ? SlideUp : undefined}
			onClose={close}
		>
			<ChangePasswordDialog
				mobileMode={mobileMode}
				titleElementId={changePasswordTitleId}
				onCancel={close}
				onChange={updatePassword}
			/>
		</Dialog>,
	];
}

export default useChangePasswordAction;
