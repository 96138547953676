import {Localized, useLocalization} from "@fluent/react";
import React, {useState} from "react";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	FormControlLabel,
	TextField,
	useTheme,
} from "@material-ui/core";

import type {CourseAssociated} from "../../store/services/adminService";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function useDeleteAction(
	onAction: (
		organisationName: string,
		ids: number[],
		deleteAssociated: CourseAssociated[]
	) => Promise<void>,
	onSuccess: () => void,
	onError: () => void
): [
	action: (
		organisationName: string,
		ids: number[],
		courseName?: string
	) => void,
	dialog: JSX.Element
] {
	const [coursesToDelete, setCoursesToDelete] = useState<number[]>([]);
	const [orgName, setOrgName] = useState("");
	const [courseName, setCourseName] = useState("");

	function close() {
		setCoursesToDelete([]);
	}

	async function deleteSelectedCourses(deleteAssociated: CourseAssociated[]) {
		try {
			await onAction(orgName, coursesToDelete, deleteAssociated);

			close();
			onSuccess();
		} catch {
			onError();
		}
	}

	function selectCourses(orgName: string, id: number[], courseName?: string) {
		setOrgName(orgName);
		setCoursesToDelete(id);
		setCourseName(courseName ?? "");
	}

	const deleteDialogId = "delete-course";

	return [
		selectCourses,

		<Dialog
			key={deleteDialogId}
			open={coursesToDelete.length > 0}
			aria-labelledby={deleteDialogId}
			onClose={close}
		>
			<DeleteCoursesDialog
				titleElementId={deleteDialogId}
				courses={coursesToDelete}
				courseName={courseName}
				onDelete={deleteSelectedCourses}
				onCancel={close}
			/>
		</Dialog>,
	];
}

function DeleteCoursesDialog(props: {
	titleElementId: string;
	courses: number[];
	courseName: string;
	onDelete: (deleteAssociated: CourseAssociated[]) => Promise<void>;
	onCancel: () => void;
}) {
	const {courses} = props;

	const [inProgress, setInProgress] = useState(false);

	const [associated, setAssociated] = useState<CourseAssociated[]>([]);

	const [confirm, setConfirm] = useState("");
	const [incorrectConfirm, setIncorrectConfirm] = useState(false);

	const {l10n} = useLocalization();
	const theme = useTheme();

	const deletePermanently = l10n.getString(
		"administration-courses-delete-dialog-confirmation-string",
		null,
		"delete permanently"
	);

	async function deleteCourses() {
		if (confirm !== deletePermanently) {
			setIncorrectConfirm(true);
			return;
		}

		setInProgress(true);

		await props.onDelete(associated);

		setInProgress(false);
	}

	return (
		<>
			<WindowedDialogTitle
				id={props.titleElementId}
				title={
					<Localized
						id="administration-courses-delete-dialog-title"
						vars={{number: courses.length}}
					>
						Delete courses?
					</Localized>
				}
				inProgress={inProgress}
				onClose={props.onCancel}
			/>

			<DialogContent dividers>
				<DialogContentText>
					{props.courseName !== "" ? (
						<Localized
							id="administration-courses-delete-dialog-content-with-name"
							vars={{number: courses.length, name: props.courseName}}
						>
							{`You are going to delete course ${props.courseName}.`}
						</Localized>
					) : (
						<Localized
							id="administration-courses-delete-dialog-content-no-name"
							vars={{number: courses.length}}
						>
							You are going to delete the selected courses.
						</Localized>
					)}
				</DialogContentText>
				<Localized
					id="administration-courses-delete-dialog-content-descr"
					elems={{paragraph: <DialogContentText></DialogContentText>}}
				>
					<></>
				</Localized>
				<FormControlLabel
					label={
						<Localized id="administration-courses-delete-dialog-delete-students">
							Delete accounts of students who do not participate in any other
							course
						</Localized>
					}
					control={<Checkbox />}
					checked={associated.includes("students")}
					disabled={inProgress}
					onChange={(_, checked) =>
						checked
							? setAssociated((prev) => [...prev, "students"])
							: setAssociated((prev) => prev.filter((a) => a !== "students"))
					}
				/>
				<FormControlLabel
					label={
						<Localized id="administration-courses-delete-dialog-delete-staff">
							Delete accounts of staff members who are not assigned to any other
							course
						</Localized>
					}
					control={<Checkbox />}
					checked={associated.includes("staff")}
					disabled={inProgress}
					onChange={(_, checked) =>
						checked
							? setAssociated((prev) => [...prev, "staff"])
							: setAssociated((prev) => prev.filter((a) => a !== "staff"))
					}
				/>
				<DialogContentText style={{marginTop: theme.spacing(1.5)}}>
					<Localized id="administration-courses-delete-dialog-suggest-archival">
						If you are not sure whether the content is needed, you can archive a
						course instead of deleting it.
					</Localized>
				</DialogContentText>
				<DialogContentText style={{marginTop: theme.spacing(1.5)}}>
					<Localized id="administration-courses-delete-dialog-confirmation-demand">
						To proceed, type delete permanently below.
					</Localized>
				</DialogContentText>
				<TextField
					required
					value={confirm}
					error={incorrectConfirm}
					placeholder={deletePermanently}
					helperText={
						incorrectConfirm &&
						confirm.length > 0 && (
							<Localized id="administration-courses-delete-dialog-confirmation-mismatch">
								Must match delete permanently
							</Localized>
						)
					}
					fullWidth
					disabled={inProgress}
					onChange={({target}) => {
						setConfirm(target.value);
						setIncorrectConfirm(false);
					}}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={props.onCancel} color="primary" disabled={inProgress}>
					<Localized id="administration-courses-delete-dialog-btn-cancel">
						Cancel
					</Localized>
				</Button>
				<SubmitButton
					variant="text"
					inProgress={inProgress}
					color="primary"
					onClick={deleteCourses}
				>
					<Localized id="administration-courses-delete-dialog-btn-delete">
						Delete
					</Localized>
				</SubmitButton>
			</DialogActions>
		</>
	);
}

export default useDeleteAction;
