import {Localized} from "@fluent/react";
import {
	Box,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Tooltip,
	useTheme,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, {useEffect, useRef, useState} from "react";

import OutlinedSearchInput from "../OutlinedSearchInput";

function noop() {
	return;
}

const TableToolbar = (props: {
	query: string;
	mainAction?: React.ReactNode;
	actionsHint?: React.ReactNode;
	actions: {
		name: string;
		disabled?: boolean;
		divider?: boolean;
		onClick: () => void;
	}[];
	searchPlaceholder?: string;
	onQueryChange: (val: string) => void;
	onToggleFilters?: () => void;
}): JSX.Element => {
	const [text, setText] = useState(props.query);

	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

	const initial = useRef(true);

	const theme = useTheme();

	useEffect(() => {
		if (initial.current) {
			initial.current = false;
			return noop;
		}

		const onSearchChange = props.onQueryChange;
		const timeoutId = setTimeout(() => onSearchChange(text), 500);
		return () => clearTimeout(timeoutId);
	}, [props.onQueryChange, text]);

	const closeMenu = () => {
		setMenuAnchor(null);
	};

	return (
		<>
			<Toolbar disableGutters style={{flexGrow: 1}}>
				{props.mainAction && (
					<Box ml={0.5} mr={2}>
						{props.mainAction}
					</Box>
				)}
				<Box flexGrow={1}>
					<OutlinedSearchInput
						value={text}
						placeholder={props.searchPlaceholder}
						onChange={setText}
						endAdornment={
							props.onToggleFilters && (
								<Tooltip
									title={
										<Localized id="table-toolbar-tooltip-filters">
											Filters
										</Localized>
									}
								>
									<IconButton onClick={props.onToggleFilters} color="primary">
										<FilterListIcon />
									</IconButton>
								</Tooltip>
							)
						}
					/>
				</Box>
				{props.actions.length > 0 && (
					<Box mx={0.5}>
						<Tooltip
							title={
								<Localized id="table-toolbar-tooltip-actions">
									Actions
								</Localized>
							}
						>
							<IconButton
								onClick={(e) => setMenuAnchor(e.currentTarget)}
								color="primary"
							>
								<MoreVertIcon />
							</IconButton>
						</Tooltip>
					</Box>
				)}
			</Toolbar>
			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				PaperProps={{style: {minWidth: theme.spacing(14)}}}
				onClose={closeMenu}
			>
				{props.actionsHint && <MenuItem disabled>{props.actionsHint}</MenuItem>}
				{props.actionsHint && <Divider style={{margin: theme.spacing(1, 0)}} />}

				{props.actions.map((opt) => (
					<span key={opt.name}>
						<MenuItem
							onClick={() => {
								closeMenu();
								opt.onClick();
							}}
							disabled={opt.disabled}
						>
							{opt.name}
						</MenuItem>

						{opt.divider && <Divider style={{margin: theme.spacing(1, 0)}} />}
					</span>
				))}
			</Menu>
		</>
	);
};

export default TableToolbar;
