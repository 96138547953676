import {Localized, useLocalization} from "@fluent/react";
import {
	Button,
	DialogActions,
	DialogContent,
	IconButton,
	InputAdornment,
	TextField,
	useTheme,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, {useState} from "react";

import {validEmail} from "../../helpers/validationHelpers";
import {userService} from "../../store/services/userService";
import SubmitButton from "../../utils/SubmitButton";
import type DialogResult from "../../utils/DialogResult";
import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function ChangeEmailDialog(props: {
	titleElementId: string;
	userId: number;
	initialEmail: string;
	mobileMode?: boolean;
	onClose: (result: DialogResult, email?: string) => void;
}): JSX.Element {
	const [email, setEmail] = useState(props.initialEmail);
	const [password, setPassword] = useState("");
	const [passwordHidden, setPasswordHidden] = useState(true);

	const [empty, setEmpty] = useState({password: false, email: false});
	const [invalidEmail, setInvalidEmail] = useState(false);

	const [inProgress, setInProgress] = useState(false);

	const {l10n} = useLocalization();
	const theme = useTheme();

	function close() {
		props.onClose({status: "cancelled"}, props.initialEmail);
	}

	function validate() {
		setEmpty({email: email.length === 0, password: password.length === 0});

		if (email.length === 0) {
			return false;
		}

		const valid = validEmail(email);
		setInvalidEmail(!valid);

		return password.length > 0 && valid;
	}

	async function submit() {
		if (!validate()) {
			return;
		}

		setInProgress(true);

		try {
			await userService.patchCredentials(props.userId, {email}, password);

			props.onClose(
				{
					status: "success",
					message: l10n.getString(
						"account-email-changed",
						null,
						"Email address changed"
					),
				},
				email
			);
		} catch (err) {
			props.onClose({
				status: "error",
				message:
					(err as {code: string}).code === "forbidden"
						? l10n.getString(
								"account-change-email-incorrect-password",
								null,
								"Incorrect password"
						  )
						: l10n.getString("error-general"),
			});
		} finally {
			setInProgress(false);
		}
	}

	return (
		<form
			onKeyDown={(e) => {
				if (e.code === "Enter") {
					e.preventDefault();
					submit();
				}
			}}
		>
			{props.mobileMode ? (
				<FullScreenDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="account-change-email-dialog-title">
							Change email address
						</Localized>
					}
					actionInProgress={inProgress}
					actionButtonLabel={
						<Localized id="account-change-email-btn">Change</Localized>
					}
					onAction={submit}
					onClose={close}
				/>
			) : (
				<WindowedDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="account-change-email-dialog-title">
							Change email address
						</Localized>
					}
					inProgress={inProgress}
					onClose={close}
				/>
			)}

			<DialogContent>
				<TextField
					required
					fullWidth
					autoFocus
					value={email}
					error={empty.email || invalidEmail}
					label={<Localized id="account-email">Email address</Localized>}
					helperText={
						invalidEmail && (
							<Localized id="account-incorrect-email">
								Email address is incorrect
							</Localized>
						)
					}
					placeholder="name@example.org"
					onChange={({target}) => {
						setEmail(target.value);
						setEmpty((prev) => ({...prev, email: false}));
						setInvalidEmail(false);
					}}
				/>
				<input type="text" autoComplete="username" style={{display: "none"}} />

				<TextField
					required
					fullWidth
					label={
						<Localized id="account-change-email-password">Password</Localized>
					}
					type={passwordHidden ? "password" : "text"}
					value={password}
					autoComplete="current-password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setPasswordHidden((prev) => !prev)}>
									{passwordHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
								</IconButton>
							</InputAdornment>
						),
					}}
					error={empty.password}
					style={{marginTop: theme.spacing(2)}}
					onChange={(event) => {
						setPassword(event.target.value);
						setEmpty((prev) => ({...prev, password: false}));
					}}
				/>
			</DialogContent>

			{!props.mobileMode && (
				<DialogActions>
					<Button onClick={close} color="primary" disabled={inProgress}>
						<Localized id="account-change-email-cancel-btn">Cancel</Localized>
					</Button>
					<SubmitButton onClick={submit} inProgress={inProgress} variant="text">
						<Localized id="account-change-email-btn">Change</Localized>
					</SubmitButton>
				</DialogActions>
			)}
		</form>
	);
}

export default ChangeEmailDialog;
