import type {Color} from "@material-ui/lab/Alert";
import type {PayloadAction, SerializedError} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";

import {getSectionContent} from "../sections/getSectionContent";
import eventOccurred from "./eventOccurred";
import notificationShown from "./notificationShown";
import Notification from "./Notification";
import {getChapters} from "../chapters/getChapters";
import {getChapterContent} from "../chapters/getChapterContent";
import {saveStudentResponse} from "../studentResponses/saveStudentResponse";
import submitStudentResponse from "../studentResponses/submitStudentResponse";
import {createChapterSession} from "../chapterSessions/createChapterSession";
import createCourse from "../courses/createCourse";
import updateCourseSettings from "../courses/updateCourseSettings";
import updateChapter from "../chapters/updateChapter";
import sendAnswer from "../tutorQuestions/sendAnswer";
import {sendNewQuestion} from "../tutorQuestions/sendNewQuestion";
import NotificationAction from "./NotificationAction";
import postOutcomes from "../outcomes/postOutcomes";
import {fetchSessionSectionContent} from "../chapterSessions/fetchSessionSectionContent";
import deleteChapter from "../chapters/deleteChapter";
import {fetchChapterSessionContent} from "../chapterSessions/fetchSessionContent";
import {submitChapterSessionStudentResponse} from "../chapterSessions/submitChapterSessionStudentResponse";
import {saveChapterSessionStudentResponse} from "../chapterSessions/saveChapterSessionStudentResponse";
import buildProgExercise from "../exercises/prog/buildExercise";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type State = Notification[];

const initialState: State = [];

function enqueue(
	message: string,
	severity: Color,
	options?: {action?: NotificationAction}
) {
	return function (state: State) {
		return state.concat({message, severity, options});
	};
}

function dequeue(state: State) {
	return state.slice(1);
}

const genericError = (state: State) =>
	enqueue("An error has occured", "error")(state);
const fetchError = (message: string) => (
	state: State,
	action: PayloadAction<unknown, string, never, SerializedError>
) => {
	return inferFetchStatusFromError(action) === "none"
		? state
		: enqueue(message, "error", {
				action: "reload",
		  })(state);
};

export const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(eventOccurred, (state, action) =>
			enqueue(
				action.payload.message,
				action.payload.severity,
				action.payload.options
			)(state)
		);

		builder.addCase(notificationShown, dequeue);

		builder.addCase(
			getChapters.rejected,
			fetchError("Failed to load course content")
		);

		builder.addCase(
			getChapterContent.rejected,
			fetchError("Failed to load chapter content")
		);

		builder.addCase(
			fetchChapterSessionContent.rejected,
			fetchError("Failed to load chapter content")
		);

		builder.addCase(
			getSectionContent.rejected,
			fetchError("Failed to load section content")
		);

		builder.addCase(
			fetchSessionSectionContent.rejected,
			fetchError("Failed to load section content")
		);

		builder.addCase(saveStudentResponse.rejected, genericError);

		builder.addCase(saveChapterSessionStudentResponse.rejected, genericError);

		builder.addCase(submitStudentResponse.rejected, genericError);

		builder.addCase(submitChapterSessionStudentResponse.rejected, genericError);

		builder.addCase(postOutcomes.rejected, genericError);

		builder.addCase(createChapterSession.rejected, genericError);

		builder.addCase(createCourse.rejected, genericError);

		builder.addCase(
			updateCourseSettings.fulfilled,
			enqueue("Saved", "success")
		);
		builder.addCase(updateCourseSettings.rejected, genericError);

		builder.addCase(deleteChapter.rejected, genericError);

		builder.addCase(updateChapter.fulfilled, enqueue("Success", "success"));
		builder.addCase(updateChapter.rejected, genericError);

		builder.addCase(sendAnswer.rejected, genericError);

		builder.addCase(sendNewQuestion.rejected, genericError);

		builder.addCase(buildProgExercise.rejected, genericError);
	},
});

export default notificationsSlice.reducer;
