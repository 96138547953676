import {
	Box,
	Container,
	TableCell,
	Typography,
	useTheme,
} from "@material-ui/core";
import React from "react";

function NoSearchResultsState(props: {
	title: React.ReactNode;
	description: React.ReactNode;
	colSpan: number;
	action?: React.ReactNode;
}) {
	const theme = useTheme();

	return (
		<TableCell colSpan={props.colSpan} style={{borderBottom: "none"}}>
			<Container
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					paddingTop: theme.spacing(6),
				}}
			>
				<Typography variant="h6">{props.title}</Typography>
				<Typography>{props.description}</Typography>
				{props.action && <Box mt={2}>{props.action}</Box>}
			</Container>
		</TableCell>
	);
}

export default NoSearchResultsState;
