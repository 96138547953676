import React, {useMemo} from "react";

import CourseProgressChart from "./CourseProgressChart";
import parseDate from "../../helpers/parseDate";
import {
	addToDate,
	daysBetween,
	formatToLocalTimeZone,
} from "../../helpers/dateTimeHelpers";
import useCurrentLocale from "../../i18n/useCurrentLocale";
import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import WidgetTitle from "../analytics/widget/WidgetTitle";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import selectCourseProgress from "../../store/courseProgress/selectCourseProgress";
import selectCourseStats from "../../store/courseStatistics/selectCourseStats";
import selectStudyPeriod from "../../store/studyPreferences/selectStudyPeriod";
import ExerciseOutcomes from "../../store/courseProgress/ExerciseOutcomes";
import selectCourseProgressFetchStatus from "../../store/courseProgress/selectCourseProgressFetchStatus";
import {Skeleton} from "@material-ui/lab";
import selectStudyPeriodStatus from "../../store/studyPreferences/selectStudyPeriodStatus";
import selectCourseStatsFetchStatus from "../../store/courseStatistics/selectCourseStatsFetchStatus";

const maxProgressValue = 100;

function generateLabels(startDate: string, endDate: string, locale: string) {
	let numberOfDays = daysBetween(startDate, endDate) + 1;

	const day = parseDate(startDate);

	const formatOptions = {
		year:
			day.getFullYear() === parseDate(endDate).getFullYear()
				? undefined
				: ("numeric" as const),
		month: "short" as const,
		day: "numeric" as const,
	};

	const labels = [];

	while (numberOfDays > 0) {
		labels.push(day.toLocaleDateString(locale, formatOptions));
		day.setDate(day.getDate() + 1);
		numberOfDays--;
	}

	return labels;
}

function truncateToDate(date: string): string {
	return date.substring(0, 10);
}

function calculateProgressDataset(
	outcomes: ExerciseOutcomes[],
	totalScore: number,
	startDate: string,
	endDate: string
) {
	const pointsByDate: {[key: string]: number} = {};
	outcomes.forEach((e) => {
		if (e.submissionTime) {
			let date = truncateToDate(formatToLocalTimeZone(e.submissionTime));

			if (date <= endDate) {
				if (date < startDate) {
					date = startDate;
				}

				if (date in pointsByDate) {
					pointsByDate[date] += e.maxScore;
				} else {
					pointsByDate[date] = e.maxScore;
				}
			}
		}
	});

	const dailyAchievements = Object.entries(pointsByDate)
		.map(([date, points]) => ({date, points}))
		.sort((left, right) => {
			let result = 0;
			if (left.date < right.date) {
				result = -1;
			} else if (left.date > right.date) {
				result = 1;
			}
			return result;
		});

	let lastDate = endDate;
	const today = truncateToDate(new Date().toISOString());
	if (today < lastDate) {
		lastDate = today;
	}

	if (
		dailyAchievements.length === 0 ||
		dailyAchievements[dailyAchievements.length - 1].date !== lastDate
	) {
		dailyAchievements.push({
			date: lastDate,
			points: 0,
		});
	}

	const weight = totalScore === 0 ? 0 : maxProgressValue / totalScore;

	const dataset: number[] = [];
	let lastStartDate = startDate;
	let progress = 0;

	dailyAchievements.forEach((achievement) => {
		let daysFromStart = daysBetween(achievement.date, lastStartDate);
		while (daysFromStart > 0) {
			daysFromStart--;
			dataset.push(progress);
		}

		progress += achievement.points * weight;
		if (progress > 100) {
			progress = 100;
		}

		dataset.push(progress);

		lastStartDate = addToDate(achievement.date, {days: 1});
	});

	return dataset;
}

function calculateTargetDataset(total: number, start: string, end: string) {
	let days = daysBetween(start, end) + 1;

	const dataset = [];
	const delta = total / days;

	let progress = 0;
	while (days > 0) {
		progress += delta;
		if (progress > total) {
			progress = total;
		}

		dataset.push(progress);
		days--;
	}

	return dataset;
}

const CourseProgressWidget = (props: {
	id: string;
	title: React.ReactNode;
	courseId: number;
}): JSX.Element => {
	const {courseId} = props;
	const courseKey = keyProvider.course(courseId);

	const locale = useCurrentLocale();

	const progress = useAppSelector((state) =>
		selectCourseProgress(state, courseKey)
	);
	const progressStatus = useAppSelector((state) =>
		selectCourseProgressFetchStatus(state, courseKey)
	);

	const courseStats = useAppSelector((state) =>
		selectCourseStats(state, courseKey)
	);
	const courseStatsStatus = useAppSelector((state) =>
		selectCourseStatsFetchStatus(state, courseKey)
	);

	const studyPeriod = useAppSelector((state) =>
		selectStudyPeriod(state, courseKey)
	);
	const studyPeriodStatus = useAppSelector((state) =>
		selectStudyPeriodStatus(state, courseKey)
	);

	const labels = useMemo(() => {
		return studyPeriod
			? generateLabels(studyPeriod.startDate, studyPeriod.endDate, locale)
			: [];
	}, [locale, studyPeriod]);
	const progressData = useMemo(() => {
		return studyPeriod && courseStats
			? calculateProgressDataset(
					progress,
					courseStats.maxScore,
					studyPeriod.startDate,
					studyPeriod.endDate
			  )
			: [];
	}, [courseStats, progress, studyPeriod]);

	const targetData = useMemo(() => {
		return studyPeriod
			? calculateTargetDataset(
					maxProgressValue,
					studyPeriod.startDate,
					studyPeriod.endDate
			  )
			: [];
	}, [studyPeriod]);

	const pending =
		studyPeriodStatus === "none" ||
		(studyPeriodStatus === "pending" && studyPeriod === null) ||
		courseStatsStatus === "none" ||
		(courseStatsStatus === "pending" && courseStats === null) ||
		progressStatus === "none" ||
		(progressStatus === "pending" && progress.length === 0);

	return (
		<Widget id={props.id}>
			<WidgetTitle>{props.title}</WidgetTitle>
			<WidgetBody>
				{pending ? (
					<Skeleton variant="rect" width="100%" height="50vh" />
				) : (
					<div style={{width: "100%", minHeight: "50vh"}}>
						<CourseProgressChart
							labels={labels}
							progressData={progressData}
							targetData={targetData}
						/>
					</div>
				)}
			</WidgetBody>
		</Widget>
	);
};

export default CourseProgressWidget;
