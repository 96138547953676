import {Localized} from "@fluent/react";
import {Box, Typography, useTheme} from "@material-ui/core";
import React from "react";

import DbData from "./DbData";
import DbSchemaAndState from "./DbSchemaAndState";
import type {
	CompilationError,
	CorrectOutputResult,
	IncorrectOutputResult,
} from "../../../../store/studentResponses/Feedback";
import {compilationError} from "../../../../store/studentResponses/Feedback";
import HtmlContent from "../../../../utils/HtmlContent";

const SqlResultPanel = (props: {
	result: IncorrectOutputResult | CorrectOutputResult | CompilationError;
}): JSX.Element => {
	const {result} = props;

	const theme = useTheme();

	if (compilationError(result)) {
		return (
			<>
				<Typography variant="subtitle1">
					<Localized id="exercise-prog-response-area-test-tab-compiler-message">
						Compiler message
					</Localized>
				</Typography>
				<Box p={2} style={{background: theme.palette.background.default}}>
					<div style={{whiteSpace: "pre", fontFamily: "monospace"}}>
						<HtmlContent content={result.compilerMessage ?? ""} />
					</div>
				</Box>
			</>
		);
	}

	let expected = <></>;

	if ("error" in result) {
		expected = (
			<>
				{result.db?.expectedSchemaAndState &&
					result.db?.expectedSchemaAndState.length > 0 && (
						<>
							<Typography variant="subtitle1">
								<Localized id="exercise-prog-response-area-sql-result-expected-schema">
									Expected schema and state
								</Localized>
							</Typography>
							<DbSchemaAndState schema={result.db.expectedSchemaAndState} />
						</>
					)}
				{result.db?.expectedQueryResult && (
					<>
						<Typography variant="subtitle1">
							<Localized id="exercise-prog-response-area-sql-result-expected-query">
								Expected query result
							</Localized>
						</Typography>
						<DbData data={result.db.expectedQueryResult} />
					</>
				)}
			</>
		);
	}

	return (
		<>
			{result.db?.schemaAndState && result.db?.schemaAndState.length > 0 && (
				<>
					<Typography variant="subtitle1">
						<Localized id="exercise-prog-response-area-sql-result-schema">
							Schema and state
						</Localized>
					</Typography>
					<DbSchemaAndState schema={result.db.schemaAndState} />
				</>
			)}
			{result.db?.queryResult && (
				<>
					<Typography variant="subtitle1">
						<Localized id="exercise-prog-response-area-sql-result-query">
							Query result
						</Localized>
					</Typography>
					<DbData data={result.db.queryResult} />
				</>
			)}

			{expected}
		</>
	);
};

export default SqlResultPanel;
