import {Localized} from "@fluent/react";
import {Chip, Typography} from "@material-ui/core";
import React, {useCallback} from "react";

import {organisationService} from "../../store/services/organisationService";
import PaginatedAutocomplete from "../../utils/autocomplete/PaginatedAutocomplete";
import type {PaginatedAutocompleteApi} from "../../utils/autocomplete/PaginatedAutocomplete";
import LightTooltip from "../../utils/LightTooltip";

function OrganisationSelector(
	props: {
		groupName: string;
		label?: React.ReactNode;
		onChange: (names: string[]) => void;
	},
	ref: React.ForwardedRef<PaginatedAutocompleteApi>
) {
	const {groupName, onChange} = props;

	const fetchPage = useCallback(
		(query: string, pageSize: number) => {
			return organisationService.searchGroupOrganisations(
				groupName,
				{query},
				{field: "displayName"},
				pageSize
			);
		},
		[groupName]
	);

	return (
		<PaginatedAutocomplete
			apiRef={ref}
			label={props.label}
			noOptionsText={
				<Localized id="organisation-selector-no-organisation">
					No such organisation
				</Localized>
			}
			limitTags={3}
			pageFetcher={fetchPage}
			optionMapper={(v) => ({
				id: v.name,
				name: v.displayName,
				secondaryName: v.name,
			})}
			tagsRenderer={(options, getTagProps) =>
				options.map((o, i) => (
					<LightTooltip
						key={i}
						title={
							<>
								<Typography component="div">{o.name}</Typography>
								{o.secondaryName && (
									<Typography
										variant="subtitle2"
										color="textSecondary"
										component="div"
									>
										{o.secondaryName}
									</Typography>
								)}
							</>
						}
						enterDelay={500}
						enterNextDelay={500}
					>
						<Chip
							label={o.secondaryName}
							size="small"
							className="MuiAutocomplete-tagSizeSmall"
							{...getTagProps({index: i})}
						/>
					</LightTooltip>
				))
			}
			onChange={onChange}
		/>
	);
}

export default React.forwardRef(OrganisationSelector);
