import {Localized} from "@fluent/react";
import {Box} from "@material-ui/core";
import React, {ReactNode, useEffect, useRef, useState} from "react";

import {
	ExternalInteractions,
	H5PInteractions,
} from "../../../../store/exercises/Interactions";
import {CompletionStatus} from "../../../../store/outcomes/ExerciseOutcomes";
import AdditionalActions from "../AdditionalActions";
import ExerciseExpandablePanel from "../ExerciseExpandablePanel";
import H5PPlayer from "./H5PPlayer";

const ResponseArea = (props: {
	subtype: "h5p";
	interactions: ExternalInteractions;
	additionalActions?: ReactNode;
	onPostOutcomes: (
		outcomes: {
			completionStatus: CompletionStatus;
			score?: number;
		},
		maxScore: number
	) => Promise<void>;
	readonly?: boolean;
}): JSX.Element => {
	const {subtype, interactions} = props;

	let player = <></>;

	const readonly = useRef(props.readonly);

	const [key, setKey] = useState(Math.random());

	useEffect(() => {
		if (readonly.current && !props.readonly) {
			setKey(Math.random());
		}
		readonly.current = props.readonly;
	}, [props.readonly]);

	const submit = async (result: {score: number; maxScore: number}) => {
		if (readonly.current) {
			return;
		}

		try {
			readonly.current = true;

			await props.onPostOutcomes(
				{
					completionStatus: CompletionStatus.Complete,
					score: result.score,
				},
				result.maxScore
			);
		} catch {
			readonly.current = false;
		}
	};

	switch (subtype) {
		case "h5p": {
			const h5pInteractions = interactions as H5PInteractions;
			player = (
				<H5PPlayer
					key={key}
					contentId={h5pInteractions.contentId}
					onSubmit={submit}
				/>
			);

			break;
		}
	}

	return (
		<ExerciseExpandablePanel
			lazyLoading
			summary={<Localized id="content-exercise-do">Do the exercise</Localized>}
		>
			<Box display="flex" flexDirection="column" flexGrow={1}>
				<AdditionalActions>{props.additionalActions}</AdditionalActions>

				{player}
			</Box>
		</ExerciseExpandablePanel>
	);
};

export default ResponseArea;
