import React from "react";
import ProgressBar from "../../utils/ProgressBar";
import ProgressTooltip from "./ProgressTooltip";
import {Skeleton} from "@material-ui/lab";

function PlainProgressBar(props: {
	overallProgress: {numberOfCompleted: number; score: number};
	stats: {
		numberOfExercises: number;
		maxScore: number;
	};
	loading?: boolean;
	leftLabel: React.ReactNode;
	rightLabel: React.ReactNode;
}): JSX.Element {
	const {stats, overallProgress, loading, leftLabel, rightLabel} = props;

	return (
		<ProgressTooltip
			completed={overallProgress.numberOfCompleted}
			total={stats.numberOfExercises}
			score={overallProgress.score}
			maxScore={stats.maxScore}
		>
			<ProgressBar
				theme={props.loading ? "grey" : "secondary"}
				value={overallProgress.numberOfCompleted}
				maxValue={stats.numberOfExercises}
				leftLabel={
					loading ? <Skeleton variant="rect">{leftLabel}</Skeleton> : leftLabel
				}
				rightLabel={
					loading ? (
						<Skeleton variant="rect">{rightLabel}</Skeleton>
					) : (
						rightLabel
					)
				}
			/>
		</ProgressTooltip>
	);
}

export default PlainProgressBar;
