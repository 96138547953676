import {Localized} from "@fluent/react";
import React from "react";
import {Button, makeStyles} from "@material-ui/core";

type Props = {
	disabled?: boolean;
	onClick?: () => void;
};

const useStyles = makeStyles(() => ({
	button: {
		width: "84px",
	},
}));

const SaveButton = (props: Props): JSX.Element => {
	const classes = useStyles();

	return (
		<Button
			className={classes.button}
			color="primary"
			disabled={props.disabled}
			onClick={props.onClick}
		>
			<Localized id="utils-button-save">Save</Localized>
		</Button>
	);
};

export default SaveButton;
