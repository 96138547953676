import {Localized} from "@fluent/react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";

import {formatAtLocalTimeZone} from "../../helpers/dateTimeHelpers";
import {LinkToPage, Page} from "../../helpers/paginatedSearchHelpers";
import parseDate from "../../helpers/parseDate";
import type {
	CourseStudentSearchCriteria,
	CourseStudentSearchResult,
} from "../../store/services/courseService";
import {courseService} from "../../store/services/courseService";
import TablePagination from "../../utils/tables/TablePagination";
import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import WidgetTitle from "../analytics/widget/WidgetTitle";
import StudentActivity from "./StudentActivity";
import StudentActivityRow from "./StudentActivityRow";
import StudentActivityTableToolbar from "./StudentActivityTableToolbar";
import StudentSearchEmptyState from "./StudentSearchEmptyState";
import {populate} from "../../utils/populate";
import WidgetBodyOverlay from "../analytics/widget/WidgetBodyOverlay";

const emptyResult: CourseStudentSearchResult = {
	id: 0,
	userName: "",
	enrolmentDate: "2022-02-02",
	enrolmentStatus: "accepted",
};

const StudentActivityWidget = (props: {
	id: string;
	title: React.ReactNode;
	courseId: number;
}): JSX.Element => {
	const [
		selectedStudent,
		setSelectedStudent,
	] = useState<CourseStudentSearchResult | null>(null);

	return (
		<Widget id={props.id}>
			<WidgetTitle>{props.title}</WidgetTitle>
			<WidgetBody>
				<Box
					position="relative"
					display="flex"
					flexDirection="column"
					width="100%"
					height="100%"
				>
					<StudentSearch
						courseId={props.courseId}
						onSearchResultExpand={setSelectedStudent}
					/>
					<WidgetBodyOverlay in={Boolean(selectedStudent)}>
						<StudentActivity
							courseId={props.courseId}
							student={selectedStudent ?? emptyResult}
							onCollapse={() => setSelectedStudent(null)}
						/>
					</WidgetBodyOverlay>
				</Box>
			</WidgetBody>
		</Widget>
	);
};

const columns = [
	{
		id: "name",
		label: (
			<Localized id="follow-up-student-activity-widget-column-name">
				Student name
			</Localized>
		),
	},
	{
		id: "study-start-date",
		label: (
			<Localized id="follow-up-student-activity-widget-column-study-status">
				Study start date
			</Localized>
		),
	},
];

function StudentSearch(props: {
	courseId: number;
	onSearchResultExpand: (student: CourseStudentSearchResult) => void;
}) {
	const {courseId} = props;
	const [
		studentsPage,
		setStudentsPage,
	] = useState<Page<CourseStudentSearchResult> | null>(null);

	const [studentsPerPage, setStudentsPerPage] = useState(10);

	const [pageLoading, setPageLoading] = useState(true);

	const [
		searchCriteria,
		setSearchCriteria,
	] = useState<CourseStudentSearchCriteria>(() => ({query: ""}));

	useEffect(() => {
		setPageLoading(true);
		setStudentsPage(null);

		courseService
			.searchCourseStudents(
				courseId,
				{
					...searchCriteria,
					enrolledAfter: searchCriteria.enrolledAfter
						? formatAtLocalTimeZone(parseDate(searchCriteria.enrolledAfter))
						: undefined,
					enrolledBefore: searchCriteria.enrolledBefore
						? formatAtLocalTimeZone(parseDate(searchCriteria.enrolledBefore))
						: undefined,
				},
				{
					field: "lastName",
				},
				studentsPerPage
			)
			.then((response) => {
				setStudentsPage(response);
			})
			.finally(() => setPageLoading(false));
	}, [courseId, searchCriteria, studentsPerPage]);

	const fetchStudentsPage = useCallback(
		async (pageRel: LinkToPage) => {
			if (!studentsPage) {
				return;
			}
			const requestPage = studentsPage.request[pageRel];
			if (requestPage) {
				const newPage = await requestPage();
				setStudentsPage(newPage);
			}
		},
		[studentsPage]
	);

	const students = populate(
		studentsPerPage,
		studentsPage?.content ?? [],
		() => ({...emptyResult, id: Math.random()})
	);

	return (
		<Box display="flex" flexDirection="column" width="100%">
			<Box pr={1} pb={2} pl={2}>
				<StudentActivityTableToolbar
					criteria={searchCriteria}
					onCriteriaChange={setSearchCriteria}
				/>
			</Box>
			<Table>
				<TableHead>
					<TableRow>
						{columns.map((c) => (
							<TableCell key={c.id}>{c.label}</TableCell>
						))}
						<TableCell padding="checkbox" />
					</TableRow>
				</TableHead>
				<TableBody style={{position: "relative"}}>
					{studentsPage && studentsPage.content.length === 0 && (
						<TableRow>
							<TableCell style={{borderBottom: "none"}}>
								<StudentSearchEmptyState />
							</TableCell>
						</TableRow>
					)}
					{students.map((s) => (
						<StudentActivityRow
							key={s.id}
							student={s}
							loading={pageLoading}
							actionIcon={<FullscreenIcon />}
							onAction={() => props.onSearchResultExpand(s)}
						/>
					))}
				</TableBody>
			</Table>
			<Localized
				id="follow-up-student-activity-widget-pagination-label"
				attrs={{label: true}}
			>
				<TablePagination
					onPageChange={fetchStudentsPage}
					pageSize={studentsPerPage}
					onPageSizeChange={setStudentsPerPage}
					first={Boolean(studentsPage?.request.first)}
					last={Boolean(studentsPage?.request.last)}
					next={Boolean(studentsPage?.request.next)}
					previous={Boolean(studentsPage?.request.previous)}
					label="Students per page"
				/>
			</Localized>
		</Box>
	);
}

export default StudentActivityWidget;
