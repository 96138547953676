import type {AxiosInstance} from "axios";

export type LinkRel =
	| "self"
	| "first"
	| "last"
	| "next"
	| "previous"
	| "http://viope.com/relations/ids";

export type LinkToPage = Exclude<
	LinkRel,
	"self" | "http://viope.com/relations/ids"
>;

export type RequestInPage<T, K = number> = {
	[key in LinkToPage]?: () => Promise<Page<T, K>>;
};
export type Page<T, K = number> = {
	content: T[];
	reload?: () => Promise<Page<T, K>>;
	allIds?: () => Promise<{content: K[]}>;
	request: RequestInPage<T, K>;
};

type Link = {
	rel: LinkRel;
	href: string;
};

export type PagedResponse<T> = {
	content: T[];
	links: Link[];
};

export function createPage<T, K = number>(
	client: AxiosInstance,
	content: T[],
	links: Link[]
): Page<T, K> {
	function createPagedLinkHandler(link: Link) {
		return async function () {
			return client.get<PagedResponse<T>>(link.href).then((response) => {
				if (response.status !== 200) {
					throw new Error();
				}
				return createPage<T, K>(
					client,
					response.data.content,
					response.data.links
				);
			});
		};
	}

	function createLinkHandler<TRes>(link: Link) {
		return async function () {
			return client.get<TRes>(link.href).then((response) => {
				if (response.status !== 200) {
					throw new Error();
				}
				return response.data;
			});
		};
	}

	const page: Page<T, K> = {
		content: content,
		request: {},
	};

	for (let i = 0; i < links.length; i++) {
		const link = links[i];

		switch (link.rel) {
			case "self":
				page.reload = createPagedLinkHandler(link);
				break;
			case "http://viope.com/relations/ids":
				page.allIds = createLinkHandler(link);
				break;
			default:
				page.request[link.rel] = createPagedLinkHandler(link);
				break;
		}
	}

	return page;
}

export function createEmptyPage<T, K = number>(): Page<T, K> {
	return {content: [], request: {}};
}
