import React, {useRef, useState} from "react";
import {Popover, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import RightWithCommentIcon from "../../../icons/RightWithCommentIcon";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";

import HtmlContent from "../../../utils/HtmlContent";

const useStyles = makeStyles((theme: Theme) => ({
	correct: {
		color: theme.palette.success.main,
	},
	incorrect: {
		color: theme.palette.error.main,
	},
	popover: {
		pointerEvents: "none",
	},
	popoverContent: {
		pointerEvents: "auto",
		padding: theme.spacing(0, 2),
	},
}));

const ResponseFeedback = (props: {
	state: "correct" | "incorrect";
	comment: string;
}): JSX.Element => {
	const classes = useStyles();

	const [opened, setOpened] = useState(false);

	const anchorEl = useRef(null);

	const open = () => {
		setOpened(true);
	};
	const close = () => {
		setOpened(false);
	};

	const {state, comment} = props;

	let icon = <></>;

	switch (true) {
		case state === "correct" && Boolean(comment):
			icon = (
				<RightWithCommentIcon
					className={classes.correct}
					ref={anchorEl}
					onMouseEnter={open}
					onMouseLeave={close}
				/>
			);

			break;
		case state === "correct":
			icon = <CheckCircleOutlineOutlinedIcon className={classes.correct} />;

			break;
		case state === "incorrect" && Boolean(comment):
			icon = (
				<FeedbackOutlinedIcon
					className={classes.incorrect}
					ref={anchorEl}
					onMouseEnter={open}
					onMouseLeave={close}
				/>
			);

			break;
		case state === "incorrect":
			icon = <ErrorOutlineIcon className={classes.incorrect} />;

			break;
	}

	return (
		<>
			{icon}

			{comment && (
				<Popover
					open={opened}
					anchorEl={anchorEl.current}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					onClose={close}
					PaperProps={{
						onMouseEnter: open,
						onMouseLeave: close,
					}}
					classes={{
						paper: classes.popoverContent,
						root: classes.popover,
					}}
				>
					<HtmlContent content={comment} />
				</Popover>
			)}
		</>
	);
};

export default ResponseFeedback;
