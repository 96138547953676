import {Localized} from "@fluent/react";
import {MenuItem, TextField} from "@material-ui/core";
import React from "react";

import AnswerVisibility from "../../store/chapterExercises/AnswerVisibility";
import CourseType from "../../store/courses/CourseType";

const displayNames = {
	[AnswerVisibility.Never]: (
		<Localized id="exercise-answer-visibility-never">Never</Localized>
	),
	[AnswerVisibility.AfterSubmission]: (
		<Localized id="exercise-answer-visibility-submission">
			After submission
		</Localized>
	),
	[AnswerVisibility.AfterDeadline]: (
		<Localized id="exercise-answer-visibility-deadline">
			After deadline
		</Localized>
	),
	[AnswerVisibility.Always]: (
		<Localized id="exercise-answer-visibility-always">Always</Localized>
	),
};

function AnswerVisibilitySelector(props: {
	courseType: CourseType | undefined;
	inheritedValue: AnswerVisibility;
	value: AnswerVisibility | "";
	disabled?: boolean;
	readonly?: boolean;
	onChange: (value: AnswerVisibility | "") => void;
}): JSX.Element {
	return (
		<TextField
			select
			label={
				<Localized id="exercise-answer-visibility">Answer visibility</Localized>
			}
			value={props.value}
			onChange={(e) => props.onChange(e.target.value as AnswerVisibility | "")}
			fullWidth
			disabled={props.disabled}
			InputLabelProps={{shrink: true}}
			InputProps={{readOnly: props.readonly}}
			SelectProps={{
				displayEmpty: true,
				renderValue: function render(value) {
					return value === "" ? (
						<>
							{displayNames[props.inheritedValue]}{" "}
							<Localized id="exercise-answer-visibility-label-inherited">
								(inherited)
							</Localized>
						</>
					) : (
						displayNames[value as AnswerVisibility]
					);
				},
			}}
		>
			<MenuItem value={""}>
				<Localized id="exercise-answer-visibility-not-set">Not set</Localized>
			</MenuItem>
			<MenuItem value={AnswerVisibility.Never}>
				{displayNames[AnswerVisibility.Never]}
			</MenuItem>
			<MenuItem value={AnswerVisibility.AfterSubmission}>
				{displayNames[AnswerVisibility.AfterSubmission]}
			</MenuItem>
			{props.courseType === CourseType.Exam && (
				<MenuItem value={AnswerVisibility.AfterDeadline}>
					{displayNames[AnswerVisibility.AfterDeadline]}
				</MenuItem>
			)}
			<MenuItem value={AnswerVisibility.Always}>
				{displayNames[AnswerVisibility.Always]}
			</MenuItem>
		</TextField>
	);
}

export default AnswerVisibilitySelector;
