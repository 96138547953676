import {Box} from "@material-ui/core";
import React, {ReactNode} from "react";

function SidebarBody({
	children,
}: {
	children: ReactNode | ReactNode[];
}): JSX.Element {
	return (
		<Box display="flex" flexDirection="column">
			{children}
		</Box>
	);
}

export default SidebarBody;
