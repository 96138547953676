import type FetchStatus from "../FetchStatus";
import type UserCourse from "../models/UserCourse";
import type {RootState} from "../store";

const empty: UserCourse[] = [];

function selectCoursesAvailableForEnrolment(
	state: RootState,
	userId?: number
): UserCourse[] {
	if (state.coursesAvailableForEnrolment.userId !== userId) {
		return empty;
	}

	return state.coursesAvailableForEnrolment.courses;
}

export function selectCoursesAvailableForEnrolmentFetchStatus(
	state: RootState,
	userId?: number
): FetchStatus {
	if (state.coursesAvailableForEnrolment.userId !== userId) {
		return "none";
	}

	return state.coursesAvailableForEnrolment.status;
}

export default selectCoursesAvailableForEnrolment;
