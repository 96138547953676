import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Card,
	CardContent,
	Grid,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import type {Theme} from "@material-ui/core";
import React, {useEffect, useMemo, useRef, useState} from "react";
import type {ReactNode} from "react";

import type AnswerStep from "./AnswerStep";
import {createStep, marshalStep, unmarshalStep} from "./AnswerStep";
import AnswerStepLine from "./AnswerStepLine";
import FormulaInput from "./FormulaInput";
import OperationSelector from "./OperationSelector";
import AdditionalActions from "../AdditionalActions";
import ExerciseExpandablePanel from "../ExerciseExpandablePanel";
import SubmitableResponseArea from "../SubmitableResponseArea";
import {MathExerciseSubtype} from "../../../../store/exercises/ExerciseSubtype";
import ExerciseType from "../../../../store/exercises/ExerciseType";
import type {MathInteractions} from "../../../../store/exercises/Interactions";
import type Feedback from "../../../../store/studentResponses/Feedback";
import type {MathFeedback} from "../../../../store/studentResponses/Feedback";
import type {
	MathResponse,
	ResponseToSave,
	ResponseToSubmit,
} from "../../../../store/studentResponses/Response";
import type SubmissionResult from "../../../../store/studentResponses/SubmissionResult";
import AsciiMathParser from "../../../../utils/asciiMathParser";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardContent: {
			padding: theme.spacing(0, 2),
		},
	})
);

const emptyFeedback: MathFeedback = {
	type: ExerciseType.Math,
	status: "unspecified",
};

const MathSolutionForm = (props: {
	subtype: MathExerciseSubtype;
	interactions: MathInteractions;
	response: MathResponse | null;

	readonly?: boolean;
	submissionDisabled?: boolean;
	submitting: boolean;

	additionalActions?: ReactNode | ReactNode[];

	onSave: (response: ResponseToSave) => Promise<Feedback>;
	onSubmit?: (response: ResponseToSubmit) => Promise<SubmissionResult>;
}): JSX.Element => {
	const classes = useStyles();

	const [answerSteps, setAnswerSteps] = useState<AnswerStep[]>([]);
	const [stepFeedbacks, setStepFeedbacks] = useState<MathFeedback[]>([]);

	const [stepToEdit, setStepToEdit] = useState(-1);
	const [typedAnswer, setTypedAnswer] = useState("");

	const [incorrectStep, setIncorrectStep] = useState(false);

	const [submitSummary, setSubmitSummary] = useState<{
		success: boolean;
		text: string;
	} | null>(null);

	const [operationLabel, setOperationLabel] = useState("");
	const [operationLabels, setOperationLabels] = useState<string[]>([]);

	useEffect(() => {
		let labels = [];
		switch (props.subtype) {
			case MathExerciseSubtype.Differentiation:
				labels = ["f'(x)=", "f(x)="];
				break;
			case MathExerciseSubtype.Integration:
				labels = ["∫f(x)dx=", "f(x)="];
				break;
			case MathExerciseSubtype.Equation:
				labels = ["⇔"];
				break;
			default:
				labels = ["="];
		}
		setOperationLabels(labels);
		setOperationLabel(labels[0]);
	}, [props.subtype]);

	const submitAttempted = useRef(false);

	useEffect(() => {
		const savedResponse = props.response
			? props.response.steps.map((r) => unmarshalStep(r))
			: [];
		setAnswerSteps(savedResponse);

		if (!submitAttempted.current) {
			setStepFeedbacks(new Array(savedResponse.length + 1).fill(emptyFeedback));
		}

		if (savedResponse.length === 0) {
			setIncorrectStep(false);
			setSubmitSummary(null);
			setStepFeedbacks([emptyFeedback]);
		}
	}, [props.response]);

	const parser = useMemo(() => {
		return new AsciiMathParser();
	}, []);

	function addOrUpdateStep() {
		if (stepToEdit < 0) {
			addStep();
		} else {
			updateStep();
		}
	}

	async function addStep() {
		if (typedAnswer.length > 0) {
			const result = await trySaveAdd();

			if (result.status === "incorrect") {
				setStepFeedbacks((prev) => {
					const f = [...prev];
					f[prev.length - 1] = result;
					return f;
				});

				setIncorrectStep(true);

				return;
			}

			const step = createStep(typedAnswer, operationLabel);
			setAnswerSteps((prev) => [...prev, step]);

			setStepFeedbacks((prev) => {
				const f = [...prev, emptyFeedback];
				f[prev.length - 1] = result;
				return f;
			});

			setTypedAnswer("");
			setIncorrectStep(false);
		}
	}

	async function updateStep() {
		if (typedAnswer.length > 0) {
			const result = await trySaveUpdate();

			if (result.status === "incorrect") {
				setIncorrectStep(true);
			} else {
				setAnswerSteps((prev) => {
					const updatedStep: AnswerStep = {
						...prev[stepToEdit],
						asciiFormula: typedAnswer,
						operation: operationLabel,
					};
					const updatedSteps = [...prev];
					updatedSteps[stepToEdit] = updatedStep;
					return updatedSteps;
				});

				setTypedAnswer("");
				setStepToEdit(-1);
				setIncorrectStep(false);
			}

			setStepFeedbacks((prev) => {
				const f = [...prev];
				f[stepToEdit] = result;
				return f;
			});
		}
	}

	const editStep = (i: number) => {
		setStepToEdit(i);
		setTypedAnswer(answerSteps[i].asciiFormula);
		setOperationLabel(answerSteps[i].operation);

		setStepFeedbacks((prev) => {
			const copy = [...prev];
			copy[i] = emptyFeedback;
			copy[copy.length - 1] = emptyFeedback;
			return copy;
		});
	};

	const copyStep = (i: number) => {
		setTypedAnswer(answerSteps[i].asciiFormula);
	};

	const deleteStep = (i: number) => {
		function remove<T>(arr: Array<T>, i: number) {
			const f = [...arr];
			f.splice(i, 1);
			return f;
		}

		trySaveDelete(i);
		setAnswerSteps((prev) => remove(prev, i));
		setStepFeedbacks((prev) => remove(prev, i));
	};

	function trySaveAdd() {
		const response = answerSteps
			.map(marshalStep)
			.concat(operationLabel + typedAnswer);
		return save(response, answerSteps.length, "add");
	}

	function trySaveUpdate() {
		const updatedResponse = answerSteps.map(marshalStep);
		updatedResponse[stepToEdit] = operationLabel + typedAnswer;
		return save(updatedResponse, stepToEdit, "edit");
	}

	function trySaveDelete(stepInd: number) {
		const updatedResponse = [...answerSteps];
		updatedResponse.splice(stepInd, 1);
		return save(updatedResponse.map(marshalStep), stepInd, "delete");
	}

	async function save(
		response: string[],
		index: number,
		requestType: "add" | "edit" | "delete"
	) {
		setSubmitSummary(null);

		const feedback = await props.onSave({
			index: index,
			exerciseSubtype: props.subtype,
			exerciseType: ExerciseType.Math,
			steps: response,
			action: requestType,
		});

		return feedback as MathFeedback;
	}

	const readonly = props.readonly || props.submitting;
	const submissionDisabled = props.submissionDisabled || props.submitting;
	const editing = stepToEdit !== -1;

	const getStepLines = () => {
		const stepLines = answerSteps.map((step, i) => {
			return i !== stepToEdit ? (
				<AnswerStepLine
					key={step.id}
					step={step}
					feedback={stepFeedbacks[i]}
					showMenu={!readonly && !editing}
					onCopy={() => copyStep(i)}
					onEdit={() => editStep(i)}
					onDelete={() => deleteStep(i)}
					parser={parser}
				/>
			) : (
				<AnswerStepLine
					key={step.id}
					step={{
						id: step.id,
						operation: operationLabel,
						asciiFormula: typedAnswer,
					}}
					parser={parser}
					feedback={stepFeedbacks[i]}
				/>
			);
		});

		if (!props.readonly) {
			stepLines.push(
				<AnswerStepLine
					key={"new"}
					step={{
						id: "new",
						operation: operationLabel,
						asciiFormula: stepToEdit < 0 ? typedAnswer : "",
					}}
					parser={parser}
					feedback={stepFeedbacks[stepFeedbacks.length - 1]}
				/>
			);
		}
		return stepLines;
	};

	const {l10n} = useLocalization();
	const onSubmit = props.onSubmit;
	const submitHandler =
		onSubmit &&
		(async () => {
			submitAttempted.current = true;

			const result = (
				await onSubmit({
					exerciseType: ExerciseType.Math,
					exerciseSubtype: props.subtype,
					steps: answerSteps.map((step) => step.operation + step.asciiFormula),
				})
			).feedback as MathFeedback;

			if (result.status === "incorrect") {
				setSubmitSummary({
					success: false,
					text: result.message,
				});
			} else if (result.status === "correct") {
				setSubmitSummary({
					success: true,
					text: l10n.getString(
						"exercise-math-response-area-successful-submission",
						null,
						"Success! Your final result successfully submitted"
					),
				});
			}
		});

	const initValue = useMemo(() => {
		const formula = props.interactions?.expression;

		if (formula) {
			return {operation: "", asciiFormula: formula, id: "init"};
		}

		return null;
	}, [props.interactions]);

	return (
		<ExerciseExpandablePanel
			lazyLoading
			summary={<Localized id="content-exercise-do">Do the exercise</Localized>}
		>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<AdditionalActions>{props.additionalActions}</AdditionalActions>
				</Grid>

				<Grid item>
					<SubmitableResponseArea
						onSubmit={submitHandler}
						submitDisabled={
							!answerSteps.length || incorrectStep || submissionDisabled
						}
						submitSummary={submitSummary}
						submitting={props.submitting}
					>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Card variant="outlined">
									<CardContent className={classes.cardContent}>
										{initValue && (
											<AnswerStepLine step={initValue} parser={parser} />
										)}

										{getStepLines()}
									</CardContent>
								</Card>
							</Grid>

							<Grid container item xs={12} alignItems="flex-end">
								<Grid item xs={2}>
									<OperationSelector
										operationLabels={operationLabels}
										selectedLabel={operationLabel}
										onSelect={setOperationLabel}
										parser={parser}
										disabled={readonly}
									/>
								</Grid>

								<Grid item xs={10}>
									<Box ml={3}>
										<FormulaInput
											onInputChange={setTypedAnswer}
											onInputFinish={addOrUpdateStep}
											value={typedAnswer}
											disabled={readonly}
											label={l10n.getString(
												"exercise-math-response-area-new-step-label",
												null,
												"Answer"
											)}
											confirmBtnText={l10n.getString(
												"exercise-math-response-area-new-step-button-add",
												null,
												"Add"
											)}
										/>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</SubmitableResponseArea>
				</Grid>
			</Grid>
		</ExerciseExpandablePanel>
	);
};

export default MathSolutionForm;
