import React from "react";
import {Box, ButtonBase} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import IndexedLabel from "../../utils/IndexedLabel";

const useStyles = makeStyles(() =>
	createStyles({
		link: {
			textDecoration: "none",
			color: undefined,
		},
	})
);

const SubItemLabel = (props: {
	link?: string;
	index: string;
	text: string;
	classes: {root: string; index: string; label: string};
	onClick?: () => void;
	metaIcon?: React.ReactElement;
}): JSX.Element => {
	const classes = useStyles();

	let label = (
		<IndexedLabel
			index={props.index}
			text={props.text}
			classes={{
				index: props.classes.index,
				label: props.classes.label,
			}}
			variant="body2"
		/>
	);

	if (props.link) {
		label = (
			<Link to={props.link} className={classes.link} onClick={props.onClick}>
				{label}
			</Link>
		);
	} else if (props.onClick) {
		label = <ButtonBase onClick={props.onClick}>{label}</ButtonBase>;
	}

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			className={props.classes.root}
		>
			{label}
			{props.metaIcon}
		</Box>
	);
};

export default SubItemLabel;
