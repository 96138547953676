import {combineReducers, configureStore} from "@reduxjs/toolkit";

import {announcementsSlice} from "./bulletinBoard/announcementsSlice";
import {chapterExercisesSlice} from "./chapterExercises/chapterExercisesSlice";
import {chapterSectionsSlice} from "./chapters/chapterSectionsSlice";
import {chaptersSlice} from "./chapters/chaptersSlice";
import sessionsSlice from "./chapterSessions/sessionsSlice";
import {courseChaptersSlice} from "./chapters/courseChaptersSlice";
import courseManagementModulesSlice from "./courses/courseManagementModulesSlice";
import {coursesSlice} from "./courses/coursesSlice";
import courseStudyModulesSlice from "./courses/courseStudyModulesSlice";
import {exerciseAnswersSlice} from "./exercises/exerciseAnswersSlice";
import exerciseDraftSlice from "./exercises/exericseDraftSlice";
import {exercisesSlice} from "./exercises/exercisesSlice";
import {extraMaterials} from "./extraMaterials";
import featuresSlice from "./features/featuresSlice";
import organisationFeaturesSlice from "./features/organisationFeaturesSlice";
import {organisationUserRolesSlice} from "./organisation/organisationUserRolesSlice";
import userOrganisationsSlice from "./organisation/userOrganisationsSlice";
import {outcomesSlice} from "./outcomes/outcomesSlice";
import {sectionsSlice} from "./sections/sectionsSlice";
import {studentDashboardSlice} from "./studentDashboardSlice";
import {studentResponsesSlice} from "./studentResponses/studentResponsesSlice";
import {teacherDashboardSlice} from "./teacherDashboardSlice";
import {themeSlice} from "./theme/themeSlice";
import tutorQuestionsSlice from "./tutorQuestions/tutorQuestionsSlice";
import modalDialogSlice from "./ui/modalDialogSlice";
import {userProfileSlice} from "./userProfile/userProfileSlice";
import notificationsSlice from "./ui/notificationsSlice";
import chapterSessionsSlice from "./chapterSessions/chapterSessionsSlice";
import lastRequestedSessionSlice from "./chapterSessions/lastRequestedSessionSlice";
import chapterSessionOutcomesSlice from "./chapterSessions/chapterSessionOutcomesSlice";
import courseProgressSlice from "./courseProgress/courseProgressSlice";
import courseStatisticsSlice from "./courseStatistics/courseStatisticsSlice";
import studyPreferencesSlice from "./studyPreferences/studyPreferencesSlice";
import availableCoursesSlice from "./enrolment/availableCoursesSlice";
import organisationsSlice from "./organisation/organisationsSlice";
import courseWidgetKeysSlice from "./widgets/courseWidgetKeysSlice";
import courseWidgetSettingsSlice from "./widgets/courseWidgetSettingsSlice";
import chapterStatisticsSlice from "./courseStatistics/chapterStatisticsSlice";
import courseContentStatisticsSlice from "./courseStatistics/courseContentStatisticsSlice";
import courseEditingSlice from "./ui/courseEditingSlice";
import chapterEditingSlice from "./ui/chapterEditingSlice";
import userOrganisationGroupsSlice from "./organisationGroups/userOrganisationGroupsSlice";
import userSessionSlice from "./userSession/userSessionSlice";

export const store = configureStore({
	reducer: {
		announcements: announcementsSlice.reducer,
		chapterExercises: chapterExercisesSlice.reducer,
		chapterSections: chapterSectionsSlice.reducer,
		chapterSessions: combineReducers({
			chapterSessions: chapterSessionsSlice,
			sessions: sessionsSlice,
			lastRequested: lastRequestedSessionSlice,
			outcomes: chapterSessionOutcomesSlice,
		}),
		chapters: chaptersSlice.reducer,
		chapterStatistics: chapterStatisticsSlice,
		courseChapters: courseChaptersSlice.reducer,
		courseContentStatistics: courseContentStatisticsSlice,
		courses: coursesSlice.reducer,
		coursesAvailableForEnrolment: availableCoursesSlice,
		courseManagementModules: courseManagementModulesSlice,
		courseProgress: courseProgressSlice,
		courseStatistics: courseStatisticsSlice,
		courseStudyModules: courseStudyModulesSlice,
		exerciseAnswers: exerciseAnswersSlice.reducer,
		exercises: exercisesSlice.reducer,
		extraMaterials: extraMaterials.reducer,
		features: combineReducers({
			global: featuresSlice,
			organisation: organisationFeaturesSlice,
		}),
		organisations: organisationsSlice,
		organisationUserRoles: organisationUserRolesSlice.reducer,
		outcomes: outcomesSlice.reducer,
		sections: sectionsSlice.reducer,
		studentPreferences: studyPreferencesSlice,
		studentResponses: studentResponsesSlice.reducer,
		studentdashboard: studentDashboardSlice.reducer,
		teacherdashboard: teacherDashboardSlice.reducer,
		theme: themeSlice.reducer,
		tutorQuestions: tutorQuestionsSlice,
		ui: combineReducers({
			chapterEditing: chapterEditingSlice,
			courseEditing: courseEditingSlice,
			exerciseDraft: exerciseDraftSlice,
			modalDialog: modalDialogSlice,
			notifications: notificationsSlice,
		}),
		userOrganisationGroups: userOrganisationGroupsSlice,
		userOrganisations: userOrganisationsSlice,
		userProfile: userProfileSlice.reducer,
		userSession: userSessionSlice,
		widgets: combineReducers({
			courseWidgetKeys: courseWidgetKeysSlice,
			courseWidgetSettings: courseWidgetSettingsSlice,
		}),
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
