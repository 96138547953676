import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useState} from "react";
import {fetchChapterSessionOutcomes} from "../../store/chapterSessions/fetchChapterSessionOutcomes";
import {submitChapterSession} from "../../store/chapterSessions/submitChapterSession";
import {useAppDispatch} from "../../store/hooks";
import DialogResult from "../../utils/DialogResult";
import SubmitButton from "../../utils/SubmitButton";

const ConfirmChapterSubmissionDialog = (props: {
	open: boolean;
	onClose: (result: DialogResult) => void;
	studentId: number;
	courseId: number;
	chapterId: number;
}): JSX.Element => {
	const {open, onClose, studentId, courseId, chapterId} = props;

	const [inProgress, setInProgress] = useState(false);

	const dispatch = useAppDispatch();

	const submit = async () => {
		setInProgress(true);

		try {
			const res = await dispatch(
				submitChapterSession({studentId, courseId, chapterId})
			);

			dispatch(
				fetchChapterSessionOutcomes({
					studentId,
					courseId,
					chapterId,
				})
			);

			unwrapResult(res);

			setInProgress(false);
			onClose({
				status: "success",
				message: "Submitted",
			});
		} catch {
			setInProgress(false);
			onClose({
				status: "error",
				message: "An error has occured",
			});
		}
	};

	const cancel = () => {
		onClose({status: "cancelled"});
	};

	return (
		<Dialog
			open={open}
			aria-labelledby="confirm-submit"
			maxWidth="xs"
			fullWidth
			onClose={cancel}
		>
			<DialogTitle id="confirm-submit">
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">{"Submit all answers"}</Typography>
					<IconButton onClick={cancel}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Typography>
					{
						"The submission of all answers will be final and won't be editable any more."
					}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={cancel} color="primary">
					{"Cancel"}
				</Button>
				<SubmitButton onClick={submit} inProgress={inProgress} variant="text">
					{"Submit"}
				</SubmitButton>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmChapterSubmissionDialog;
