import {CircularProgress, TextField} from "@material-ui/core";
import {AutocompleteRenderInputParams} from "@material-ui/lab";
import React from "react";

function inputRenderer(label?: React.ReactNode, loading?: boolean) {
	return function Input(params: AutocompleteRenderInputParams) {
		return (
			<TextField
				{...params}
				label={label}
				InputProps={{
					...params.InputProps,
					endAdornment: (
						<>
							{loading && <CircularProgress color="primary" size={20} />}
							{params.InputProps.endAdornment}
						</>
					),
				}}
			/>
		);
	};
}

export default inputRenderer;
