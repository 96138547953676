import {
	Box,
	LinearProgress,
	Theme,
	lighten,
	makeStyles,
} from "@material-ui/core";
import {createStyles} from "@material-ui/styles";
import React from "react";

const useProgressBarStyles = makeStyles<
	Theme,
	{themeVariant: "secondary" | "grey"}
>((theme: Theme) =>
	createStyles({
		colorSecondary: {
			backgroundColor: ({themeVariant}) =>
				themeVariant === "secondary"
					? lighten(theme.palette.secondary.main, 0.62)
					: theme.palette.grey[300],
		},
		bar: {
			backgroundColor: ({themeVariant}) =>
				themeVariant === "secondary"
					? theme.palette.secondary.main
					: theme.palette.grey[500],
		},
	})
);

function ProgressBar(props: {
	value?: number;
	maxValue?: number;
	leftLabel?: React.ReactNode;
	rightLabel?: React.ReactNode;
	theme: "secondary" | "grey";
}): JSX.Element {
	const classes = useProgressBarStyles({themeVariant: props.theme});

	return (
		<Box display="flex" flexDirection="column">
			<Box width="100%" mb={0.25}>
				<LinearProgress
					variant="determinate"
					value={((props.value ?? 0) * 100) / (props.maxValue ?? 100)}
					color="secondary"
					classes={{
						bar: classes.bar,
						colorSecondary: classes.colorSecondary,
					}}
				/>
			</Box>
			<Box display="flex" justifyContent="space-between">
				{props.leftLabel}
				{props.rightLabel}
			</Box>
		</Box>
	);
}

export default ProgressBar;
