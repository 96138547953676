import React, {useEffect, useState} from "react";
import {useParams} from "react-router";

import type User from "../../../store/services/dtos/User";
import {userService} from "../../../store/services/userService";
import Profile from "../../users/Profile";

const emptyUser: User = {
	id: 0,
	language: "",
	memberOfMultipleOrganisations: false,
	name: "",
	passwordSet: false,
};

const UserEdit = (): JSX.Element => {
	const {id} = useParams<{id: string}>();
	const userId = Number(id);

	const [user, setUser] = useState<User>(emptyUser);

	useEffect(() => {
		userService.getUserProfile(userId).then((res) => {
			setUser(res);
		});
	}, [userId]);

	if (user === emptyUser) {
		return <></>;
	}

	return <Profile user={user} onUpdate={setUser} />;
};

export default UserEdit;
