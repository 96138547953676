import {Localized} from "@fluent/react";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	Divider,
	IconButton,
	Link,
	ListItemText,
	Menu,
	MenuItem,
	MenuListProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	createStyles,
	makeStyles,
	useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreVert from "@material-ui/icons/MoreVert";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Link as RouterLink} from "react-router-dom";

import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import useCurrentLocale from "../../i18n/useCurrentLocale";
import NewGroupOrganisationDialog from "./NewGroupOrganisationDialog";
import type {
	GroupOrganisationSearchCriteria,
	GroupOrganisationSearchResult,
} from "../../store/services/organisationService";
import {organisationService} from "../../store/services/organisationService";
import useSnackbar from "../../store/ui/useSnackbar";
import useDeleteAction from "./useDeleteAction";
import DialogResult from "../../utils/DialogResult";
import SlideUp from "../../utils/SlideUp";
import HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import SortingHeader from "../../utils/tables/SortingHeader";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import TableToolbar from "../../utils/tables/TableToolbar";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import usePaginationState from "../../utils/tables/usePaginationState";
import useSorting from "../../utils/tables/useSorting";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
		},
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
	})
);

const headCells: HeadCell<GroupOrganisationSearchResult>[] = [
	{
		id: "name",
		label: "group-organisations-table-column-name",
		sortable: true,
	},
	{
		id: "displayName",
		label: "group-organisations-table-column-display-name",
		sortable: true,
		width: 350,
	},
	{
		id: "countryCode",
		label: "group-organisations-table-column-country",
		sortable: false,
		width: 160,
	},
	{
		id: "city",
		label: "group-organisations-table-column-city",
		sortable: false,
		width: 160,
	},
];

const columnNumber = headCells.length + 1;

const initialCriteria: GroupOrganisationSearchCriteria = {};

function GroupOrganisations(props: {groupName: string}) {
	const {groupName} = props;

	const navBarHeight = useNavBarHeight();
	const classes = useStyles();

	const [createOrgOpen, setCreateOrgOpen] = useState(false);

	const [criteria, setCriteria] = useState(initialCriteria);

	const setQuery = useCallback((val: string) => {
		setCriteria((prev) => ({...prev, query: val ?? undefined}));
	}, []);

	const [orgsPerPage, setOrgsPerPage] = useState(10);

	const [sortField, sortOrder, changeOrder] = useSorting<
		keyof GroupOrganisationSearchResult
	>("displayName");

	const [orgMenuAnchor, setOrgMenuAnchor] = useState<null | HTMLElement>(null);
	const [menuOrgName, setMenuOrgName] = useState("");

	const {
		page: orgsPage,
		fetchFirstPage,
		fetchRelatedPage,
		pageFetchStatus,
		retryFetching,
		reloadPage,
	} = usePaginationState<GroupOrganisationSearchResult, string>();

	const organisations = orgsPage.content;

	const {
		select: selectOrganisations,
		bulkSelectionCheckbox,
		selected: selectedOrganisations,
		resetSelection,
	} = useBulkSelection(orgsPage, (o) => o.name);

	const mobileMode = useMobileMode("sm");
	const theme = useTheme();

	const locale = useCurrentLocale();

	const countryName = useMemo(() => {
		return new Intl.DisplayNames([locale], {type: "region"});
	}, [locale]);

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();
	const showSnackbar = useSnackbar();

	const [deleteOrganisation, deletionErrorDialog] = useDeleteAction(
		reloadPage,
		openConfirmationDialog,
		showSnackbar
	);

	useEffect(() => {
		fetchFirstPage(
			() =>
				organisationService.searchGroupOrganisations(
					groupName,
					criteria,
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					orgsPerPage
				),
			resetSelection
		);
	}, [
		criteria,
		fetchFirstPage,
		groupName,
		orgsPerPage,
		resetSelection,
		sortField,
		sortOrder,
	]);

	function isSelected(id: string) {
		return selectedOrganisations.indexOf(id) !== -1;
	}

	function closeOrgMenu() {
		setOrgMenuAnchor(null);
	}

	function closeCreateOrgDialog(result: DialogResult) {
		if (result.status === "success") {
			reloadPage();
			resetSelection();
		}

		if (result.status !== "error") {
			setCreateOrgOpen(false);
		}
	}

	const createOrgTitleId = "create-organisation";

	return (
		<div className={classes.root}>
			<Box
				display="flex"
				flexDirection="column"
				pt={mobileMode ? 3 : 5}
				px={mobileMode ? 3 : 6}
				pb={1.5}
				style={{height: `calc(100vh - ${navBarHeight + 1}px)`, width: "100%"}}
			>
				<TableToolbar
					mainAction={
						<Button
							variant="contained"
							color="primary"
							startIcon={<AddIcon />}
							onClick={() => setCreateOrgOpen(true)}
						>
							<Localized id="group-organisations-main-action-new-organisation">
								New organisation
							</Localized>
						</Button>
					}
					query={criteria.query ?? ""}
					onQueryChange={setQuery}
					actions={[]}
				/>
				<TableContainer className={classes.container}>
					<Table stickyHeader>
						<SortingHeader
							onOrderChange={changeOrder}
							order={sortOrder}
							orderBy={sortField}
							headCells={headCells}
							leftAnnex={
								false && (
									<TableCell padding="checkbox" style={{zIndex: 3}}>
										{bulkSelectionCheckbox}
									</TableCell>
								)
							}
							rightAnnex={<TableCell />}
							loading={pageFetchStatus === "pending"}
						/>
						<TableBody>
							{pageFetchStatus === "failed" && (
								<TableRow>
									<LoadingErrorState
										description={
											<Localized id="group-organisations-loading-error-descr">
												Something has gone wrong, and we cannot load
												organisations
											</Localized>
										}
										colSpan={columnNumber}
										onReload={retryFetching}
									/>
								</TableRow>
							)}
							{pageFetchStatus === "succeeded" && organisations.length === 0 && (
								<TableRow>
									<NoSearchResultsState
										colSpan={columnNumber}
										title={
											<Localized id="group-organisations-no-organisations">
												No organisations
											</Localized>
										}
										description={
											criteria === initialCriteria ? (
												<Localized id="group-organisations-no-organisations-descr">
													There are no organisations in the group
												</Localized>
											) : (
												<Localized id="group-organisations-no-results-descr">
													No organisations were found matching your search
													criteria. Try to adjust filters
												</Localized>
											)
										}
									/>
								</TableRow>
							)}
							{pageFetchStatus !== "failed" &&
								organisations.length > 0 &&
								organisations.map((org, index) => {
									const isItemSelected = isSelected(org.name);
									const labelId = `table-checkbox-${index}`;
									return (
										<TableRow
											hover
											key={org.name}
											tabIndex={-1}
											selected={isItemSelected}
										>
											{false && (
												<TableCell padding="checkbox">
													<Checkbox
														checked={isItemSelected}
														inputProps={{"aria-labelledby": labelId}}
														onClick={() => selectOrganisations(org.name)}
													/>
												</TableCell>
											)}
											<TableCell component="th" id={labelId} scope="row">
												<Link
													component={RouterLink}
													to={`/organisations/${org.name}`}
												>
													{org.name}
												</Link>
											</TableCell>
											<TableCell>{org.displayName}</TableCell>
											<TableCell>{countryName.of(org.countryCode)}</TableCell>
											<TableCell>{org.city}</TableCell>
											<TableCell padding="checkbox">
												<IconButton
													onClick={(e) => {
														setOrgMenuAnchor(e.currentTarget);
														setMenuOrgName(org.name);
													}}
												>
													<MoreVert />
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					ml={2}
				>
					<Typography variant="subtitle2">
						{selectedOrganisations.length > 0 && (
							<Localized
								id="group-organisations-selected-row-number"
								vars={{selected: selectedOrganisations.length}}
							>{`${selectedOrganisations.length} selected`}</Localized>
						)}
					</Typography>
					<TablePagination
						onPageChange={fetchRelatedPage}
						pageSize={orgsPerPage}
						onPageSizeChange={setOrgsPerPage}
						first={Boolean(orgsPage.request.first)}
						last={Boolean(orgsPage.request.last)}
						next={Boolean(orgsPage.request.next)}
						previous={Boolean(orgsPage.request.previous)}
						label={
							<Localized id="group-organisations-per-page">
								Organisations per page
							</Localized>
						}
						disabled={pageFetchStatus !== "succeeded"}
					/>
				</Box>
			</Box>

			<Menu
				anchorEl={orgMenuAnchor}
				open={Boolean(orgMenuAnchor)}
				onClose={closeOrgMenu}
				PaperProps={{style: {minWidth: theme.spacing(14)}}}
				MenuListProps={{component: "div"} as MenuListProps}
			>
				<MenuItem
					onClick={() => {
						deleteOrganisation(menuOrgName);
						closeOrgMenu();
					}}
				>
					<Localized id="group-organisations-action-delete">Delete</Localized>
				</MenuItem>

				<Divider style={{margin: theme.spacing(1, 0)}} />

				<MenuItem
					onClick={closeOrgMenu}
					component="a"
					href={`/organisations/${menuOrgName}/administration`}
					target="_blank"
				>
					<ListItemText
						primary={
							<Localized id="group-organisations-action-administration">
								Administration
							</Localized>
						}
					/>
					<OpenInNewIcon
						fontSize="inherit"
						style={{marginLeft: theme.spacing(1)}}
					/>
				</MenuItem>
			</Menu>

			<Dialog
				open={createOrgOpen}
				maxWidth="sm"
				fullScreen={mobileMode}
				fullWidth
				onClose={() => setCreateOrgOpen(false)}
				aria-labelledby={createOrgTitleId}
				TransitionComponent={mobileMode ? SlideUp : undefined}
			>
				<NewGroupOrganisationDialog
					groupName={groupName}
					titleElementId={createOrgTitleId}
					mobileMode={mobileMode}
					onClose={closeCreateOrgDialog}
				/>
			</Dialog>

			{confirmationDialog}
			{deletionErrorDialog}
		</div>
	);
}

export default GroupOrganisations;
