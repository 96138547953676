import {useLocalization} from "@fluent/react";
import {Checkbox, FormGroup} from "@material-ui/core";
import React, {useEffect, useState} from "react";

import Choice from "./Choice";
import SubmitableResponseArea from "../SubmitableResponseArea";
import createSubmitSummary from "../createSubmitSummary";
import {MultiExerciseSubtype} from "../../../../store/exercises/ExerciseSubtype";
import ExerciseType from "../../../../store/exercises/ExerciseType";
import {MultiInteractions} from "../../../../store/exercises/Interactions";
import {MultiFeedbackSubmitted} from "../../../../store/studentResponses/Feedback";
import type {MultiResponse} from "../../../../store/studentResponses/Response";
import type {
	ResponseToSave,
	ResponseToSubmit,
} from "../../../../store/studentResponses/Response";
import createChoiceFeedback from "./createChoiceFeedback";
import type SubmissionResult from "../../../../store/studentResponses/SubmissionResult";

const MultipleResponse = (props: {
	id: string;

	subtype: MultiExerciseSubtype;
	interactions: MultiInteractions;
	response: MultiResponse | null;

	readonly?: boolean;
	submissionDisabled?: boolean;
	submitting: boolean;

	onSave: (response: ResponseToSave) => void;
	onSubmit?: (response: ResponseToSubmit) => Promise<SubmissionResult>;
}): JSX.Element => {
	const {l10n} = useLocalization();

	const [responses, setResponses] = useState<number[]>([]);

	const [saved, setSaved] = useState(true);

	const [
		submissionResult,
		setSubmissionResult,
	] = useState<SubmissionResult | null>(null);

	useEffect(() => {
		const savedResponse = props.response ? props.response.selectedChoices : [];
		setResponses(savedResponse);
		if (savedResponse.length === 0) {
			setSubmissionResult(null);
		}
	}, [props.response]);

	const {onSave} = props;

	useEffect(() => {
		const delay = setTimeout(() => {
			if (!saved) {
				onSave({
					exerciseType: ExerciseType.Multi,
					exerciseSubtype: MultiExerciseSubtype.Multiple,
					selectedChoices: responses,
				});
				setSaved(true);
			}
		}, 500);

		return () => clearTimeout(delay);
	}, [onSave, responses, saved]);

	const changeHandler = (checkedId: number) => {
		setResponses((prev) => {
			if (prev.includes(checkedId)) {
				return prev.filter((id) => id !== checkedId);
			}
			return [...prev, checkedId];
		});
		setSaved(false);
	};

	const onSubmit = props.onSubmit;
	const submitHandler =
		onSubmit &&
		(async () => {
			if (responses.length) {
				const result = await onSubmit({
					exerciseType: ExerciseType.Multi,
					exerciseSubtype: MultiExerciseSubtype.Multiple,
					selectedChoices: responses,
				});

				setSubmissionResult(result);
			}
		});

	const readonly = props.readonly || props.submitting;
	const submissionDisabled = props.submissionDisabled || props.submitting;

	const submitSummary =
		submissionResult && createSubmitSummary(l10n, submissionResult);

	return (
		<SubmitableResponseArea
			submitDisabled={responses.length === 0 || submissionDisabled}
			submitSummary={submitSummary}
			onSubmit={submitHandler}
			submitting={props.submitting}
		>
			<FormGroup>
				{props.interactions.choices.map((choice) => (
					<Choice
						key={choice.id}
						control={
							<Checkbox
								inputProps={{"aria-labelledby": `${props.id}-${choice.id}`}}
								checked={responses.includes(choice.id)}
								color="primary"
								disabled={readonly}
								onChange={() => changeHandler && changeHandler(choice.id)}
							/>
						}
						label={choice.text}
						disabled={readonly}
						onChange={() => changeHandler && changeHandler(choice.id)}
						labelId={`${props.id}-${choice.id}`}
						feedback={
							submissionResult &&
							createChoiceFeedback(
								choice.id,
								submissionResult.feedback as MultiFeedbackSubmitted
							)
						}
					/>
				))}
			</FormGroup>
		</SubmitableResponseArea>
	);
};

export default MultipleResponse;
