import {Localized, useLocalization} from "@fluent/react";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Link,
	Typography,
} from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import CloseIcon from "@material-ui/icons/Close";
import ExerciseTypeSelector from "./ExerciseTypeSelector";
import DialogMenuItem from "./DialogMenuItem";
import {useAppDispatch} from "../../store/hooks";
import {
	ModalDialogName,
	closeDialog,
	openDialog,
} from "../../store/ui/modalDialogSlice";
import ExerciseType from "../../store/exercises/ExerciseType";
import {exerciseCreationStarted} from "../../store/exercises/exericseDraftSlice";
import {useHistory} from "react-router";
import {getSectionPath} from "../../helpers/pathHelpers";

const useStyles = makeStyles(() =>
	createStyles({
		link: {
			cursor: "pointer",
		},
	})
);

const AddExerciseDialog = (props: {
	courseId: number;
	sectionId: number;
	chapterId: number;
}): JSX.Element => {
	const classes = useStyles();

	const dispatch = useAppDispatch();

	const history = useHistory();

	const {courseId, chapterId, sectionId} = props;

	const close = () => {
		dispatch(closeDialog());
	};

	const selectExerciseType = (type: ExerciseType) => {
		close();
		moveToSection();
		dispatch(
			exerciseCreationStarted({
				exerciseType: type,
				sectionId,
			})
		);
	};

	const openExerciseBankDialog = () => {
		dispatch(
			openDialog({
				dialogName: ModalDialogName.ExerciseBank,
				dialogParams: {
					courseId: props.courseId,
					chapterId: props.chapterId,
					sectionId: props.sectionId,
				},
			})
		);
	};

	function moveToSection() {
		const sectionPath = getSectionPath(
			history.location.pathname,
			props.chapterId,
			props.sectionId
		);

		history.push(sectionPath);
	}

	const {l10n} = useLocalization();

	return (
		<Dialog
			onClose={close}
			aria-labelledby="add-exercise-dialogue-title"
			open
			maxWidth="sm"
		>
			<DialogTitle id="add-exercise-dialogue-title">
				<Box display="flex" justifyContent="flex-end" mb={-2}>
					<CloseIcon onClick={close} className={classes.link} />
				</Box>
				<Typography variant="h5" component="span">
					<Localized id="add-exercise-dialog-title">Add subsection</Localized>
				</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogMenuItem
					mb={4}
					ml={2}
					subheader={l10n.getString(
						"add-exercise-dialog-menu-item-title-exercise-bank-subheader",
						null,
						"Select and add one or more ready-made exercises"
					)}
					mainContent={
						<Link className={classes.link} onClick={openExerciseBankDialog}>
							<Localized id="add-exercise-dialog-menu-item-link-exercise-bank">
								ADD FROM EXERCISE BANK
							</Localized>
						</Link>
					}
					icon={<AddToPhotosIcon color="action" fontSize="small" />}
				/>

				<Typography variant="subtitle1" component="div">
					<Box fontWeight={500} mb={4}>
						<Localized id="add-exercise-dialog-menu-item-title-from-scratch">
							Create from scratch
						</Localized>
					</Box>
				</Typography>

				<ExerciseTypeSelector
					courseId={courseId}
					chapterId={chapterId}
					sectionId={sectionId}
					onExerciseTypeSelected={selectExerciseType}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default AddExerciseDialog;
