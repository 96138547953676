import {Localized, useLocalization} from "@fluent/react";
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	InputAdornment,
	MenuItem,
	TextField,
	Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Autocomplete} from "@material-ui/lab";
import React, {useCallback, useEffect, useRef, useState} from "react";

import ConfirmationFooter from "../../utils/ConfirmationFooter";
import {courseService} from "../../store/services/courseService";
import type StudyTarget from "../../store/courses/StudyTarget";
import useSnackbar from "../../store/ui/useSnackbar";
import ExerciseType from "../../store/exercises/ExerciseType";
import {useAppSelector} from "../../store/hooks";
import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import createSerialIdProvider from "../../helpers/createSerialIdProvider";
import type FetchStatus from "../../store/FetchStatus";
import LoadingError from "../../utils/errors/LoadingError";
import selectAvailableExerciseTypes from "../../store/courses/selectAvailableExerciseTypes";

const id = createSerialIdProvider();

function StudySettings(props: {
	courseId: number;
	readOnly?: boolean;
}): JSX.Element {
	const {courseId, readOnly} = props;

	const [studyTargets, setStudyTargets] = useState<
		(StudyTarget & {id: string})[]
	>([]);
	const initialSettings = useRef<{targets: StudyTarget[]}>({targets: []});
	const [targetsFetchStatus, setTargetsFetchStatus] = useState<FetchStatus>(
		"none"
	);

	const [expandedTargets, setExpandedTargets] = useState<string[]>([]);

	const [dirty, setDirty] = useState(false);
	const [saving, setSaving] = useState(false);

	const [featureEnabled] = useFeatureEnabled();

	const availableExerciseTypes = useAppSelector((state) =>
		selectAvailableExerciseTypes(
			state,
			courseId,
			featureEnabled(Feature.ExternalExercises)
		)
	);

	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	const fetchTargets = useCallback(async () => {
		setTargetsFetchStatus("pending");
		try {
			const targets = await courseService.getCourseStudyTargets(courseId);
			setStudyTargets(targets.map((t) => ({...t, id: id()})));
			initialSettings.current.targets = targets;
			setTargetsFetchStatus("succeeded");
		} catch {
			setTargetsFetchStatus("failed");
		}
	}, [courseId]);

	useEffect(() => {
		fetchTargets();
	}, [fetchTargets]);

	function updateStudyTarget(id: string) {
		return function (
			propName: keyof StudyTarget,
			value: string | ExerciseType[] | number | boolean
		) {
			setStudyTargets((prev) => {
				const targets = prev.slice();
				const i = prev.findIndex((f) => f.id === id);

				targets[i] = {...prev[i], [propName]: value};

				return targets;
			});

			setDirty(true);
		};
	}

	function deleteStudyTarget(id: string) {
		return function () {
			setStudyTargets((prev) => prev.filter((t) => t.id !== id));
			setExpandedTargets((prev) => prev.filter((t) => t !== id));
			setDirty(true);
		};
	}

	function addStudyTarget() {
		const newId = id();
		setStudyTargets((prev) => [
			...prev,
			{
				id: newId,
				parameter: "number_of_completed",
				threshold: 1,
				exerciseTypes: [],
			},
		]);

		setExpandedTargets((prev) => prev.concat(newId));
		setDirty(true);
	}

	async function saveChanges() {
		setSaving(true);

		const targets: StudyTarget[] = studyTargets.map((t) => ({
			parameter: t.parameter,
			threshold: t.threshold,
			exerciseTypes:
				!t.exerciseTypes ||
				t.exerciseTypes.length === availableExerciseTypes.length
					? []
					: t.exerciseTypes,
			weighted: t.weighted,
		}));

		try {
			await courseService.saveCourseStudyTargets(courseId, targets);
			showSnackbar("success", l10n.getString("success-on-saving"));
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return;
		} finally {
			setSaving(false);
		}

		initialSettings.current.targets = targets;
		setDirty(false);
	}

	function discardChanges() {
		setStudyTargets(
			initialSettings.current.targets.map((t) => ({...t, id: id()}))
		);
		setExpandedTargets([]);
		setDirty(false);
	}

	let targetsContent = <></>;
	if (targetsFetchStatus === "none" || targetsFetchStatus === "pending") {
		targetsContent = (
			<Grid item xs={12}>
				<Loading />
			</Grid>
		);
	} else if (targetsFetchStatus === "failed") {
		targetsContent = (
			<Grid item xs={12}>
				<Box pt={4}>
					<LoadingError
						description={
							<Localized id="course-settings-study-targets-loading-error">
								Failed to load study targets
							</Localized>
						}
						variant="block"
						onReload={fetchTargets}
					/>
				</Box>
			</Grid>
		);
	} else if (studyTargets.length > 0) {
		targetsContent = (
			<>
				<Grid item xs={12} container>
					{studyTargets.map((t) => (
						<StudyTargetRow
							key={t.id}
							availableExerciseTypes={availableExerciseTypes}
							target={t}
							expanded={expandedTargets.includes(t.id)}
							readOnly={readOnly}
							onChange={updateStudyTarget(t.id)}
							onDelete={deleteStudyTarget(t.id)}
							onExpand={(exp) =>
								setExpandedTargets((prev) =>
									exp ? prev.concat(t.id) : prev.filter((tt) => tt !== t.id)
								)
							}
						/>
					))}
				</Grid>
				<Grid item xs={12} container justifyContent="flex-end">
					<Button
						color="primary"
						startIcon={<AddIcon />}
						disabled={readOnly}
						onClick={addStudyTarget}
					>
						<Localized id="course-settings-study-targets-add-target-btn">
							Add
						</Localized>
					</Button>
				</Grid>
			</>
		);
	} else {
		targetsContent = (
			<Grid item xs={12}>
				<Empty
					disabled={
						readOnly ||
						!featureEnabled(Feature.TargetsAccomplishingNotification)
					}
					onClick={addStudyTarget}
				/>
			</Grid>
		);
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5">
						<Localized id="course-settings-study-targets-section-title">
							Study targets
						</Localized>
					</Typography>
				</Grid>
				{targetsContent}
			</Grid>

			{dirty && (
				<ConfirmationFooter
					onDiscard={discardChanges}
					onSave={saveChanges}
					saveInProgress={saving}
				/>
			)}
		</>
	);
}

function StudyTargetRow(props: {
	target: StudyTarget;
	availableExerciseTypes: ExerciseType[];
	expanded: boolean;
	readOnly?: boolean;
	onExpand: (expanded: boolean) => void;
	onChange: (
		field: keyof StudyTarget,
		value: string | ExerciseType[] | number | boolean
	) => void;
	onDelete: () => void;
}) {
	const {
		target,
		expanded,
		availableExerciseTypes,
		readOnly,
		onChange,
		onDelete,
	} = props;

	const {l10n} = useLocalization();

	let types = "";
	if (
		!target.exerciseTypes ||
		target.exerciseTypes.length === 0 ||
		target.exerciseTypes.length === availableExerciseTypes.length
	) {
		types = l10n.getString("course-settings-study-targets-exercise-type-name", {
			type: "all",
		});
	} else {
		types = target.exerciseTypes
			.map((t) =>
				l10n.getString("course-settings-study-targets-exercise-type-name", {
					type: t,
				})
			)
			.join(", ");
	}

	return (
		<Accordion
			expanded={expanded}
			style={{width: "100%"}}
			onChange={(_, expanded) => props.onExpand(expanded)}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>
					<Localized
						id="course-settings-study-targets-descr"
						vars={{
							types,
							threshold: target.threshold,
							parameter: target.parameter,
							weighted: target.weighted ? 1 : 0,
						}}
					/>
				</Typography>
			</AccordionSummary>

			<AccordionDetails>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<TextField
							label={
								<Localized id="course-settings-study-targets-threshold">
									Threshold
								</Localized>
							}
							value={target.threshold}
							type="number"
							fullWidth
							InputProps={{
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
								inputProps: {min: 1, max: 100},
								readOnly,
							}}
							onChange={(event) => {
								const value = parseInt(event.target.value || "0");
								if (0 < value && value <= 100) {
									onChange("threshold", value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={target.parameter === "score" ? 8 : 4}>
						<TextField
							select
							label={
								<Localized id="course-settings-study-targets-parameter">
									Parameter
								</Localized>
							}
							value={target.parameter}
							fullWidth
							SelectProps={{readOnly}}
							onChange={(event) => {
								onChange("parameter", event.target.value);
								if (event.target.value === "score") {
									onChange("weighted", false);
								}
							}}
						>
							<MenuItem value="score">
								<Localized id="course-settings-study-targets-parameter-score">
									score
								</Localized>
							</MenuItem>
							<MenuItem value="number_of_completed">
								<Localized id="course-settings-study-targets-parameter-completed-number">
									completed exercises
								</Localized>
							</MenuItem>
						</TextField>
					</Grid>
					{target.parameter === "number_of_completed" && (
						<Grid item xs={12} sm={4} style={{alignSelf: "flex-end"}}>
							<FormControlLabel
								control={
									<Checkbox
										checked={target.weighted ?? false}
										onChange={
											readOnly
												? undefined
												: (_, checked) => onChange("weighted", checked)
										}
										color="primary"
									/>
								}
								label={
									<Localized id="course-settings-study-targets-weighted">
										weighted by max score
									</Localized>
								}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<Autocomplete
							multiple
							filterSelectedOptions
							options={availableExerciseTypes}
							getOptionLabel={(opt) =>
								l10n.getString("course-settings-study-targets-exercise-type", {
									type: opt,
								})
							}
							value={target.exerciseTypes ?? []}
							onChange={(_, val) => !readOnly && onChange("exerciseTypes", val)}
							renderInput={(params) => (
								<TextField
									{...params}
									label={
										<Localized id="course-settings-study-targets-exercise-type-label">
											Exercise type
										</Localized>
									}
									fullWidth
									InputProps={{...params.InputProps, readOnly}}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</AccordionDetails>

			<AccordionActions>
				<Button color="primary" disabled={readOnly} onClick={onDelete}>
					<Localized id="course-settings-study-targets-delete-btn">
						Delete
					</Localized>
				</Button>
			</AccordionActions>
		</Accordion>
	);
}

function Loading() {
	return (
		<Box display="flex" justifyContent="center" pt={4}>
			<CircularProgress />
		</Box>
	);
}

function Empty(props: {disabled: boolean; onClick: () => void}) {
	return (
		<Box display="flex" flexDirection="column" alignItems="center" pt={4}>
			<Typography variant="h6">
				<Localized id="course-settings-study-targets-empty-state-title">
					No targets
				</Localized>
			</Typography>
			<Typography>
				<Localized id="course-settings-study-targets-empty-state-body">
					You can start by adding a first target for students to accomplish
				</Localized>
			</Typography>
			<Button color="primary" disabled={props.disabled} onClick={props.onClick}>
				<Localized id="course-settings-study-targets-empty-state-add-target-btn">
					Add target
				</Localized>
			</Button>
		</Box>
	);
}

export default StudySettings;
