import clsx from "clsx";
import {Localized} from "@fluent/react";
import {
	Avatar,
	Box,
	Button,
	Grid,
	Paper,
	TextField,
	Typography,
	createStyles,
	makeStyles,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";

import {validPhoneNumber} from "../../helpers/validationHelpers";
import InterfaceLanguageSelector from "../../i18n/InterfaceLanguageSelector";
import type User from "../../store/services/dtos/User";
import ConfirmationFooter from "../../utils/ConfirmationFooter";
import IndirectlyEditableTextField from "../../utils/IndirectlyEditableTextField";

const useStyles = makeStyles((theme) =>
	createStyles({
		avatar: {
			color: theme.palette.primary.contrastText,
			background: theme.palette.secondary.main,
		},
		avatarMd: {width: "15rem", height: "15rem", fontSize: "6rem"},
		avatarSm: {width: "10rem", height: "10rem", fontSize: "4rem"},
	})
);

type Draft = {
	language: string;
	firstName: string;
	lastName: string;
	phoneNumber?: string;
};

const ProfileEditor = (props: {
	user: User;
	accountInfoNotice?: React.ReactNode;
	onChange: (updated: Draft) => Promise<boolean>;
	onDeleteAccount?: () => void;
	onPasswordChangeClick?: () => void;
	onEmailChangeClick?: () => void;
}): JSX.Element => {
	const classes = useStyles();

	const {user, onChange} = props;

	const [initialDraft, setInitialDraft] = useState<Draft | null>(null);
	const [draft, setDraft] = useState<Draft | null>(null);

	const [dirty, setDirty] = useState(false);

	const [phoneInvalid, setPhoneInvalid] = useState(false);

	const [saving, setSaving] = useState(false);

	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up("md"));

	useEffect(() => {
		if (user && !draft) {
			const init = {
				language: user.language,
				firstName: user.firstName ?? "",
				lastName: user.lastName ?? "",
				phoneNumber: user.phoneNumber ?? "",
				userName: user.name,
			};

			setInitialDraft(init);
			setDraft(init);
		}
	}, [draft, user]);

	if (!user || !initialDraft || !draft) {
		return <></>;
	}

	const updateField = (propName: keyof Draft, value: string) => {
		setDirty(
			value !== initialDraft[propName] ||
				Object.keys(draft)
					.filter((key) => key !== propName)
					.reduce((prev: boolean, curr: string) => {
						const key = curr as keyof Draft;
						const equal = draft[key] !== initialDraft[key];
						return prev || equal;
					}, false)
		);

		setDraft((prev) => {
			const oldDraft = prev ? prev : initialDraft;

			return {...oldDraft, [propName]: value};
		});
	};

	const updatePhone = (phone: string) => {
		updateField("phoneNumber", phone);

		if (phone.length === 0) {
			setPhoneInvalid(false);
			return;
		}

		setPhoneInvalid(!validPhoneNumber(phone));
	};

	const saveChanges = async () => {
		setSaving(true);
		const saved = await onChange(draft);
		setSaving(false);

		if (saved) {
			setInitialDraft(draft);
			setDirty(false);
		}
	};

	const discardChanges = () => {
		setDraft(initialDraft);
		setDirty(false);
		setPhoneInvalid(false);
	};

	const userPhoto = user.avatarUrl ?? "";
	const userInitials =
		draft.firstName?.length && draft.lastName?.length
			? draft.firstName[0] + draft.lastName[0]
			: user.name[0];

	const formInvalid = !draft.firstName || !draft.lastName || phoneInvalid;

	return (
		<>
			<Box
				display="flex"
				flexDirection={md ? "row" : "column"}
				style={{gap: theme.spacing(5)}}
				mb={dirty ? 6 : 0}
			>
				<Box display="flex" style={{width: "auto", justifyContent: "center"}}>
					<Avatar
						src={userPhoto}
						className={clsx(classes.avatar, {
							[classes.avatarMd]: md,
							[classes.avatarSm]: !md,
						})}
						alt={userInitials}
					>
						{userInitials}
					</Avatar>
				</Box>
				<Grid container spacing={5}>
					<Grid item xs={12} container style={{gap: theme.spacing(3)}}>
						<Grid item xs={12}>
							<Typography variant="h5">
								<Localized id="account-information">
									Account information
								</Localized>
							</Typography>
						</Grid>
						{props.accountInfoNotice && (
							<Grid item xs={12}>
								<Paper
									style={{
										padding: theme.spacing(2),
										marginBottom: theme.spacing(2),
									}}
								>
									{props.accountInfoNotice}
								</Paper>
							</Grid>
						)}
						<Grid item container spacing={4}>
							<Grid item xs={12} md={6} lg={4}>
								<TextField
									label={
										<Localized id="account-user-name">User name</Localized>
									}
									required
									fullWidth
									value={user.name}
									InputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<IndirectlyEditableTextField
									required
									label={<Localized id="account-password">Password</Localized>}
									value={user.passwordSet ? "********" : ""}
									onChangeClick={props.onPasswordChangeClick}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<IndirectlyEditableTextField
									label={
										<Localized id="account-email">Email address</Localized>
									}
									required
									placeholder="name@example.org"
									value={user.email ?? ""}
									onChangeClick={props.onEmailChangeClick}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<InterfaceLanguageSelector
									label={<Localized id="account-language">Language</Localized>}
									value={draft.language}
									onChange={(value) => updateField("language", value)}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} container style={{gap: theme.spacing(3)}}>
						<Grid item xs={12}>
							<Typography variant="h5">
								<Localized id="account-basic-information">
									Basic information
								</Localized>
							</Typography>
						</Grid>
						<Grid item container spacing={4}>
							<Grid item xs={12} md={6} lg={4}>
								<TextField
									label={
										<Localized id="account-basic-information-first-name">
											First name
										</Localized>
									}
									fullWidth
									required
									helperText={
										draft.firstName.length === 0 && (
											<Localized id="account-required-field">
												The field is required
											</Localized>
										)
									}
									error={draft.firstName.length === 0}
									value={draft.firstName}
									onChange={(event) =>
										updateField("firstName", event.target.value)
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<TextField
									label={
										<Localized id="account-basic-information-last-name">
											Last name
										</Localized>
									}
									fullWidth
									required
									helperText={
										draft.lastName.length === 0 && (
											<Localized id="account-required-field">
												The field is required
											</Localized>
										)
									}
									error={draft.lastName.length === 0}
									value={draft.lastName}
									onChange={(event) =>
										updateField("lastName", event.target.value)
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<TextField
									label={
										<Localized id="account-basic-information-phone">
											Phone number
										</Localized>
									}
									fullWidth
									value={draft.phoneNumber}
									error={phoneInvalid}
									helperText={
										phoneInvalid && (
											<Localized id="account-phone-invalid">
												Phone number is invalid
											</Localized>
										)
									}
									onChange={(event) => updatePhone(event.target.value)}
									placeholder="+18005550100"
								/>
							</Grid>
						</Grid>
					</Grid>
					{props.onDeleteAccount && (
						<Grid item xs={12} container style={{gap: theme.spacing(3)}}>
							<Grid item xs={12}>
								<Typography variant="h5">
									<Localized id="account-delete-section-title">
										Delete account
									</Localized>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<Localized id="account-delete-description">
										Once you delete your account, there is no going back. Please
										be certain.
									</Localized>
								</Typography>
							</Grid>
							<Grid item>
								<Button color="primary" onClick={props.onDeleteAccount}>
									<Localized id="account-delete-btn">
										Delete your account
									</Localized>
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Box>

			{dirty && (
				<ConfirmationFooter
					onDiscard={discardChanges}
					onSave={saveChanges}
					saveInProgress={saving}
					saveDisabled={formInvalid}
				/>
			)}
		</>
	);
};

export default ProfileEditor;
