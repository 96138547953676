import {Localized} from "@fluent/react";
import {
	LinearProgress,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	useTheme,
} from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import React, {ReactNode} from "react";

import SortOrder from "./SortOrder";
import HeadCell from "./HeadCell";

type TableHeaderProps<T> = {
	onOrderChange: (orderBy: keyof T, order: SortOrder) => void;
	order: SortOrder;
	orderBy: keyof T;
	headCells: HeadCell<T>[];

	leftAnnex?: ReactNode;
	rightAnnex?: ReactNode;

	loading?: boolean;
};

function SortingHeader<T>(props: TableHeaderProps<T>): JSX.Element {
	const {order, orderBy, onOrderChange} = props;

	const theme = useTheme();

	const createSortHandler = (property: keyof T) => () => {
		const isAsc = orderBy === property && order === SortOrder.Asc;
		const newOrder = isAsc ? SortOrder.Desc : SortOrder.Asc;
		onOrderChange(property, newOrder);
	};

	function getLabel(headCell: HeadCell<T>) {
		let sortLabel = <Localized id={headCell.label}>{headCell.label}</Localized>;
		if (headCell.sortable) {
			sortLabel = (
				<TableSortLabel
					active={orderBy === headCell.id}
					direction={orderBy === headCell.id ? order : SortOrder.Asc}
					onClick={createSortHandler(headCell.id)}
					IconComponent={ArrowDownward}
				>
					{sortLabel}
				</TableSortLabel>
			);
		}
		return sortLabel;
	}

	return (
		<TableHead>
			<TableRow>
				{props.leftAnnex}

				{props.headCells.map((headCell) => (
					<TableCell
						key={headCell.id as string}
						sortDirection={
							headCell.sortable && orderBy === headCell.id ? order : false
						}
						align={headCell.align}
						style={{width: headCell.width}}
					>
						{getLabel(headCell)}
					</TableCell>
				))}

				{props.rightAnnex}
			</TableRow>

			<TableRow style={{visibility: props.loading ? "visible" : "hidden"}}>
				<TableCell
					colSpan={
						props.headCells.length +
						(props.rightAnnex ? 1 : 0) +
						(props.leftAnnex ? 1 : 0)
					}
					padding="none"
					style={{top: `calc(1.5rem + ${theme.spacing(4) + 1}px)`}}
				>
					<LinearProgress />
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

export default SortingHeader;
