import {Color} from "@material-ui/lab/Alert";
import {createAction} from "@reduxjs/toolkit";
import NotificationAction from "./NotificationAction";

const eventOccurred = createAction<{
	message: string;
	severity: Color;
	options?: {
		action?: NotificationAction;
	};
}>("notifications/eventOccurred");

export default eventOccurred;
