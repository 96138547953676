import React from "react";
import {Grid} from "@material-ui/core";
import HtmlContent from "../../../utils/HtmlContent";

function MultiAnswer(props: {value: string[]}): JSX.Element {
	return (
		<Grid container direction="column" spacing={1}>
			{props.value.map((val, i) => (
				<Grid item key={i}>
					<HtmlContent content={val} />
				</Grid>
			))}
		</Grid>
	);
}

export default MultiAnswer;
