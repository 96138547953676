import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Button,
	Checkbox,
	DialogActions,
	DialogContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";

import {organisationService} from "../../store/services/organisationService";
import type {GroupOrganisationSearchResult} from "../../store/services/organisationService";
import type HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import ServerPaginationControls from "../../utils/tables/ServerPaginationControls";
import SortOrder from "../../utils/tables/SortOrder";
import SortingHeader from "../../utils/tables/SortingHeader";
import TableToolbar from "../../utils/tables/TableToolbar";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import usePaginationState from "../../utils/tables/usePaginationState";
import useSorting from "../../utils/tables/useSorting";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginTop: theme.spacing(2),
			height: "100%",
		},
		dialogContent: {
			display: "flex",
			flexDirection: "column",
			height: "100vh",
			paddingBottom: 0,
		},
		toolbar: {
			display: "flex",
			gap: theme.spacing(0.5),
		},
		selectedNumber: {
			flexGrow: 1,
			paddingLeft: theme.spacing(4),
		},
	})
);

const headCells: HeadCell<GroupOrganisationSearchResult>[] = [
	{
		id: "name",
		label: "group-organisation-selection-dialog-table-column-id",
		sortable: true,
	},
	{
		id: "displayName",
		label: "group-organisation-selection-dialog-table-column-name",
		sortable: true,
		width: 350,
	},
];

const columnNumber = headCells.length + 1;

function GroupOrganisationSelectionDialog(props: {
	groupName: string;
	actionLabel: React.ReactNode;
	onSelected: (organisationNames: string[]) => void;
	onClose: () => void;
}) {
	const classes = useStyles();

	const [query, setQuery] = useState("");

	const [sortField, sortOrder, changeOrder] = useSorting<
		keyof GroupOrganisationSearchResult
	>("displayName");

	const {
		page: orgsPage,
		fetchFirstPage,
		fetchRelatedPage,
		pageFetchStatus,
		retryFetching,
	} = usePaginationState<GroupOrganisationSearchResult, string>();

	const organisations = orgsPage.content;

	const {
		select: selectOrganisations,
		bulkSelectionCheckbox,
		selected: selectedOrganisations,
		resetSelection,
	} = useBulkSelection(orgsPage, (o) => o.name);

	const {l10n} = useLocalization();

	useEffect(() => {
		fetchFirstPage(
			() =>
				organisationService.searchGroupOrganisations(
					props.groupName,
					{query},
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					10
				),
			resetSelection
		);
	}, [
		query,
		fetchFirstPage,
		props.groupName,
		resetSelection,
		sortField,
		sortOrder,
	]);

	function selectedOrganisation(id: string) {
		return selectedOrganisations.indexOf(id) !== -1;
	}

	const notFetched = pageFetchStatus !== "succeeded";

	return (
		<>
			<DialogContent className={classes.dialogContent} dividers>
				<Box className={classes.toolbar}>
					<TableToolbar
						actions={[]}
						query={query}
						searchPlaceholder={l10n.getString(
							"group-organisation-selection-dialog-search-placeholder"
						)}
						onQueryChange={setQuery}
					/>
					<ServerPaginationControls
						disabledControls={{
							first: notFetched || !orgsPage.request.first,
							next: notFetched || !orgsPage.request.next,
							previous: notFetched || !orgsPage.request.previous,
						}}
						onClick={fetchRelatedPage}
					/>
				</Box>
				<TableContainer className={classes.container}>
					<Table stickyHeader>
						<SortingHeader
							onOrderChange={changeOrder}
							order={sortOrder}
							orderBy={sortField}
							headCells={headCells}
							leftAnnex={
								<TableCell padding="checkbox" style={{zIndex: 3}}>
									{bulkSelectionCheckbox}
								</TableCell>
							}
							loading={pageFetchStatus === "pending"}
						/>
						<TableBody>
							{pageFetchStatus === "failed" && (
								<TableRow>
									<LoadingErrorState
										description={
											<Localized id="group-organisation-selection-dialog-loading-error-descr">
												Something has gone wrong, and we cannot load
												organisations
											</Localized>
										}
										colSpan={columnNumber}
										onReload={retryFetching}
									/>
								</TableRow>
							)}
							{pageFetchStatus === "succeeded" && organisations.length === 0 && (
								<TableRow>
									<NoSearchResultsState
										colSpan={columnNumber}
										title={
											<Localized id="group-organisation-selection-dialog-no-organisations">
												No organisations
											</Localized>
										}
										description={
											query === "" ? (
												<Localized id="group-organisation-selection-dialog-no-organisations-descr">
													There are no organisations in the group
												</Localized>
											) : (
												<Localized id="group-organisation-selection-dialog-no-results-descr">
													No organisations were found matching your search
													criteria. Try to adjust filters
												</Localized>
											)
										}
									/>
								</TableRow>
							)}
							{pageFetchStatus !== "failed" &&
								organisations.length > 0 &&
								organisations.map((org, index) => {
									const selected = selectedOrganisation(org.name);
									const labelId = `table-checkbox-${index}`;
									return (
										<TableRow
											hover
											key={org.name}
											tabIndex={-1}
											selected={selected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													checked={selected}
													inputProps={{"aria-labelledby": labelId}}
													onClick={() => selectOrganisations(org.name)}
												/>
											</TableCell>
											<TableCell component="th" id={labelId} scope="row">
												{org.name}
											</TableCell>
											<TableCell>{org.displayName}</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				{selectedOrganisations.length > 0 && (
					<Typography
						variant="subtitle2"
						component="span"
						className={classes.selectedNumber}
					>
						<Localized
							id="group-organisation-selection-dialog-selected-row-number"
							vars={{selected: selectedOrganisations.length}}
						>{`${selectedOrganisations.length} selected`}</Localized>
					</Typography>
				)}
				<Button color="primary" onClick={props.onClose}>
					<Localized id="group-organisation-selection-dialog-action-cancel">
						Cancel
					</Localized>
				</Button>
				<Button
					color="primary"
					disabled={selectedOrganisations.length === 0}
					onClick={() => props.onSelected(selectedOrganisations)}
				>
					{props.actionLabel}
				</Button>
			</DialogActions>
		</>
	);
}

export default GroupOrganisationSelectionDialog;
