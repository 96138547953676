import {Localized, useLocalization} from "@fluent/react";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {
	Autocomplete,
	Button,
	Container,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";

import {userService} from "../../store/services/userService";
import PageLayout from "./PageLayout";
import {validEmail, validUserName} from "../../helpers/validationHelpers";
import useCurrentLocale from "../../i18n/useCurrentLocale";
import useSnackbar from "../../store/ui/useSnackbar";
import OrganisationNotFoundError from "../../utils/errors/OrganisationNotFoundError";
import Loading from "./Loading";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import fetchCoursesAvailableForEnrolment from "../../store/enrolment/fetchCoursesAvailableForEnrolment";
import selectCoursesAvailableForEnrolment, {
	selectCoursesAvailableForEnrolmentFetchStatus,
} from "../../store/enrolment/selectCoursesAvailableForEnrolment";
import type UserCourse from "../../store/models/UserCourse";
import useFetchOrganisation from "../../store/organisation/useFetchOrganisation";

const SignUp = (props: {
	logo: string;
	organisationName: string;
}): JSX.Element => {
	const {logo, organisationName} = props;

	const theme = useTheme();

	const [selectedCourse, setSelectedCourse] = useState<UserCourse | null>(null);
	const [username, setUsername] = useState("");

	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");

	const [emptyField, setEmptyField] = useState(() => ({
		course: false,
		userName: false,
		password: false,
		repeatPassword: false,
		firstName: false,
		lastName: false,
		email: false,
	}));

	const [invalidField, setInvalidField] = useState(() => ({
		email: false,
		password: false,
		passwordMismatch: false,
		userName: false,
	}));

	const [created, setCreated] = useState(false);

	const [org, orgFetchStatus] = useFetchOrganisation(organisationName);

	const courses = useAppSelector(selectCoursesAvailableForEnrolment);
	const coursesFetchStatus = useAppSelector(
		selectCoursesAvailableForEnrolmentFetchStatus
	);

	const language = useCurrentLocale();
	const {l10n} = useLocalization();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (coursesFetchStatus === "none") {
			dispatch(fetchCoursesAvailableForEnrolment({orgName: organisationName}));
		}
	}, [coursesFetchStatus, dispatch, organisationName]);

	const showSnackbar = useSnackbar();

	const validate = () => {
		const empty = {
			course: !selectedCourse,
			email: email === "",
			firstName: firstName === "",
			lastName: lastName === "",
			password: password === "",
			repeatPassword: repeatPassword === "",
			userName: username === "",
		};

		setEmptyField(empty);

		const invalid = {
			email: email.length > 0 && !validEmail(email),
			password:
				(password.length > 0 && password.length < 8) || password.length > 128,
			passwordMismatch: repeatPassword !== password,
			userName:
				username.length > 0 &&
				(username.length < 3 ||
					username.length > 40 ||
					!validUserName(username)),
		};

		setInvalidField(invalid);

		return !(
			Object.values(empty).some((e) => e) ||
			Object.values(invalid).some((e) => e)
		);
	};

	const createAccount = async () => {
		const valid = validate();
		if (!valid) {
			return;
		}

		const res = await userService.enrolNewUser({
			email,
			firstName,
			lastName,
			password,
			language,
			username,
			courseId: selectedCourse?.id ?? 0,
		});

		if (res.status === "error") {
			showSnackbar("error", res.message ?? "An error has occured");
			return;
		}

		setCreated(true);
	};

	if (
		orgFetchStatus === "none" ||
		orgFetchStatus === "pending" ||
		coursesFetchStatus === "none" ||
		coursesFetchStatus === "pending"
	) {
		return (
			<PageLayout logo={logo}>
				<Loading />
			</PageLayout>
		);
	}

	if (coursesFetchStatus === "failed") {
		return (
			<PageLayout logo={logo} backRef="/login">
				<CourseLoadingError
					onReload={() => {
						dispatch(
							fetchCoursesAvailableForEnrolment({orgName: organisationName})
						);
					}}
				/>
			</PageLayout>
		);
	}

	if (orgFetchStatus === "succeeded" && !org) {
		return (
			<PageLayout logo={logo}>
				<OrganisationNotFoundError />
			</PageLayout>
		);
	}

	if (coursesFetchStatus === "succeeded" && courses.length === 0) {
		return (
			<PageLayout logo={logo} backRef="/login">
				<EmptyState />
			</PageLayout>
		);
	}

	if (created) {
		return (
			<PageLayout logo={logo} backRef="/login">
				<Success />
			</PageLayout>
		);
	}

	return (
		<PageLayout logo={logo} backRef="/login">
			<Grid
				container
				direction="column"
				spacing={2}
				style={{padding: theme.spacing(0, 10)}}
			>
				<Grid item>
					<Typography variant="body2">{org?.displayName}</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h4">
						<Localized id="signup-new-account">New account</Localized>
					</Typography>
				</Grid>
				<Grid item>
					<Autocomplete
						options={courses}
						getOptionLabel={(opt) => opt.name}
						renderInput={(params) => (
							<TextField
								{...params}
								label={
									<Localized id="signup-select-course-label">Course</Localized>
								}
								helperText={l10n.getString(
									"signup-select-course-helper-text",
									null,
									"A course in which you want to enrol"
								)}
								required
								error={emptyField.course}
							/>
						)}
						value={selectedCourse}
						onChange={(_, val) => {
							setSelectedCourse(val);
							setEmptyField((prev) => ({...prev, course: false}));
						}}
						fullWidth
					/>
				</Grid>
				<Grid item>
					<TextField
						label={<Localized id="login-username">Username</Localized>}
						required
						value={username}
						onChange={({target}) => {
							setUsername(target.value);
							setEmptyField((prev) => ({...prev, userName: false}));
							setInvalidField((prev) => ({...prev, userName: false}));
						}}
						fullWidth
						error={emptyField.userName || invalidField.userName}
						helperText={
							<Localized id="sign-up-username-requirements">
								Username should start with a letter and may only contain digits,
								lowercase Latin letters and non-repeated symbols .-_
							</Localized>
						}
						autoComplete="username"
					/>
				</Grid>
				<Grid item>
					<TextField
						label={<Localized id="login-password">Password</Localized>}
						required
						value={password}
						onChange={({target}) => {
							setPassword(target.value);
							setEmptyField((prev) => ({...prev, password: false}));
							setInvalidField((prev) => ({...prev, password: false}));
						}}
						type={showPassword ? "text" : "password"}
						autoComplete="new-password"
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPassword((prev) => !prev)}
										edge="end"
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={emptyField.password || invalidField.password}
						helperText={
							<>
								{invalidField.password && password.length > 128 ? (
									<Localized id="sign-up-password-max-length">
										Password cannot be more than 128 characters long
									</Localized>
								) : (
									<Localized id="sign-up-password-min-length">
										Password should be at least 8 characters long
									</Localized>
								)}
							</>
						}
					/>
				</Grid>
				<Grid item>
					<TextField
						label={
							<Localized id="signup-repeat-password-label">
								Repeat password
							</Localized>
						}
						required
						value={repeatPassword}
						onChange={({target}) => {
							setRepeatPassword(target.value);
							setEmptyField((prev) => ({...prev, repeatPassword: false}));
							setInvalidField((prev) => ({...prev, passwordMismatch: false}));
						}}
						type={showPassword ? "text" : "password"}
						autoComplete="new-password"
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPassword((prev) => !prev)}
										edge="end"
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={invalidField.passwordMismatch || emptyField.repeatPassword}
						helperText={
							invalidField.passwordMismatch && (
								<Localized id="signup-password-mismatch">
									Passwords mismatch
								</Localized>
							)
						}
					/>
				</Grid>
				<Grid item style={{marginTop: theme.spacing(6)}}>
					<TextField
						label={
							<Localized id="signup-first-name-label">First name</Localized>
						}
						required
						value={firstName}
						onChange={({target}) => {
							setFirstName(target.value);
							setEmptyField((prev) => ({...prev, firstName: false}));
						}}
						fullWidth
						error={emptyField.firstName}
					/>
				</Grid>
				<Grid item>
					<TextField
						label={<Localized id="signup-last-name-label">Last name</Localized>}
						required
						value={lastName}
						onChange={({target}) => {
							setLastName(target.value);
							setEmptyField((prev) => ({...prev, lastName: false}));
						}}
						fullWidth
						error={emptyField.lastName}
					/>
				</Grid>
				<Grid item>
					<TextField
						label={<Localized id="signup-last-email-label">Email</Localized>}
						required
						value={email}
						onChange={({target}) => {
							setEmail(target.value);
							setEmptyField((prev) => ({...prev, email: false}));
							setInvalidField((prev) => ({...prev, email: false}));
						}}
						fullWidth
						error={emptyField.email || invalidField.email}
						helperText={invalidField.email && "Email is incorrect"}
					/>
				</Grid>
				<Grid item style={{marginTop: theme.spacing(8)}}>
					<Button
						variant="contained"
						color="primary"
						onClick={createAccount}
						fullWidth
					>
						<Localized id="signup-create-btn">Create account</Localized>
					</Button>
				</Grid>
			</Grid>
		</PageLayout>
	);
};

function EmptyState() {
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<EventBusyIcon style={{height: "50px", width: "50px"}} color="primary" />
			<Typography variant="h6">
				<Localized id="signup-no-courses">
					No courses available for enrolment
				</Localized>
			</Typography>
		</Container>
	);
}

function Success() {
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DoneIcon style={{height: "50px", width: "50px"}} color="primary" />
			<Typography variant="h6">
				<Localized id="signup-success-message">
					You have successfully created an account.
				</Localized>
			</Typography>
			<Typography>
				<Localized
					id="signup-login-link-message"
					elems={{
						ref: <Link to="/login" component={RouterLink}></Link>,
					}}
				>
					<> {`Now you can log in <ref>here</ref>`}</>
				</Localized>
			</Typography>
		</Container>
	);
}

function CourseLoadingError(props: {onReload: () => void}) {
	const theme = useTheme();

	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<ErrorIcon
				style={{height: "50px", width: "50px", color: theme.palette.error.main}}
			/>
			<Typography variant="h6">
				<Localized id="signup-courses-loading-error-title">
					An error has occured
				</Localized>
			</Typography>
			<Typography>
				<Localized id="signup-courses-loading-error-description">
					Failed to load courses available for enrolment
				</Localized>
			</Typography>
			<Button
				color="primary"
				variant="contained"
				style={{marginTop: theme.spacing(2)}}
				onClick={props.onReload}
			>
				<Localized id="signup-courses-loading-error-reload-btn">
					Reload
				</Localized>
			</Button>
		</Container>
	);
}

export default SignUp;
