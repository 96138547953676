import {Box, Grid, Typography, createStyles} from "@material-ui/core";
import {alpha, makeStyles} from "@material-ui/core/styles";
import React from "react";

import loginBackground from "../../images/login-background.jpg";
import {useAppSelector} from "../../store/hooks";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			width: "100%",
			height: "100%",
			backgroundColor: alpha(theme.palette.primary.main, 0.6),
			backgroundImage: `url(${loginBackground})`,
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			backgroundBlendMode: "soft-light",
			color: theme.palette.primary.contrastText,
		},
		logo: {
			maxHeight: "160px",
			maxWidth: "100%",
		},
	})
);

const LoginLogo = (props: {logo: string}): JSX.Element => {
	const classes = useStyles();

	const customThemeEnabled = useAppSelector((state) => state.theme.enabled);

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			className={classes.root}
		>
			<Grid item>
				<img src={props.logo} className={classes.logo} alt="Logo" />
			</Grid>

			{!customThemeEnabled && (
				<Grid item>
					<Box mt={-6}>
						<Typography variant="subtitle1">
							SOLUTIONS FOR BLENDED LEARNING
						</Typography>
					</Box>
				</Grid>
			)}
		</Grid>
	);
};

export default LoginLogo;
