import {Localized} from "@fluent/react";
import {TextField} from "@material-ui/core";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import React, {useMemo} from "react";

import countryCodes from "./countryCodes.json";
import useCurrentLocale from "../i18n/useCurrentLocale";

const empty = {code: "", name: ""};

const filterOptions = createFilterOptions<{code: string; name: string}>();

function CountrySelector(props: {
	value: string;
	onChange: (val: string) => void;
	required?: boolean;
	error?: boolean;
}) {
	const locale = useCurrentLocale();

	const [countries, unknown] = useMemo(() => {
		const name = new Intl.DisplayNames([locale], {type: "region"});
		const collator = new Intl.Collator(locale);

		const options = countryCodes.map((c) => ({code: c, name: name.of(c) ?? c}));
		options.sort((a, b) => collator.compare(a.name, b.name));

		const unknown = {code: "ZZ", name: name.of("ZZ") ?? "ZZ"};

		return [options.concat(unknown, empty), unknown];
	}, [locale]);

	let value;
	if (props.value === "") {
		value = empty;
	} else {
		value = countries.find((c) => c.code === props.value) ?? unknown;
	}

	return (
		<Autocomplete
			id="country"
			fullWidth
			options={countries}
			autoHighlight
			value={value}
			onChange={(_, val) => props.onChange(val?.code ?? "")}
			filterOptions={(opts, s) =>
				filterOptions(opts, s).filter((o) => o.code !== "" && o.code !== "ZZ")
			}
			getOptionLabel={(option) => option.name}
			renderInput={(params) => (
				<TextField
					{...params}
					required={props.required}
					error={props.error}
					label={<Localized id="country-selector-label">Country</Localized>}
				/>
			)}
		/>
	);
}

export default CountrySelector;
