import {Localized} from "@fluent/react";
import {Box, TextField, useTheme} from "@material-ui/core";
import React from "react";

import CourseStatusSelector from "../../courses/CourseStatusSelector";
import CourseTypeSelector from "../../courses/CourseTypeSelector";
import useStateWithDebounce from "../../../hooks/useStateWithDebounce";
import SidebarBody from "../../sidebars/SidebarBody";
import SidebarHeader from "../../sidebars/SidebarHeader";
import SidebarSection from "../../sidebars/SidebarSection";
import type {OrganisationCourseSearchCriteria} from "../../../store/services/courseService";
import AppBarDependentDrawer from "../../../utils/AppBarDependentDrawer";
import ContentLanguageSelector from "../../../utils/ContentLanguageSelector";

const Filters = (props: {
	open: boolean;
	width: number | string;
	mobileMode: boolean;

	criteria: OrganisationCourseSearchCriteria;
	onCriteriaChange: (
		set: (
			prev: OrganisationCourseSearchCriteria
		) => OrganisationCourseSearchCriteria
	) => void;

	onClose: () => void;
}): JSX.Element => {
	const {onCriteriaChange} = props;

	const theme = useTheme();

	const [criteria, setCriteria] = useStateWithDebounce(
		props.criteria,
		onCriteriaChange
	);

	return (
		<AppBarDependentDrawer
			anchor="right"
			open={props.open}
			variant={props.mobileMode ? "temporary" : "persistent"}
			width={props.width}
			onClose={props.onClose}
		>
			<SidebarBody>
				<Localized id="administration-filters" attrs={{title: true}}>
					<SidebarHeader title="Filters" onClose={props.onClose} />
				</Localized>
				<SidebarSection title={""}>
					<CourseTypeSelector
						value={criteria.type}
						onChange={(type) => setCriteria((prev) => ({...prev, type}))}
					/>
				</SidebarSection>

				<SidebarSection title="">
					<ContentLanguageSelector
						value={criteria.language ?? ""}
						withAny
						onChange={(val) =>
							setCriteria((prev) => ({...prev, language: val}))
						}
					/>
				</SidebarSection>

				<SidebarSection
					title={
						<Localized id="administration-courses-status">Status</Localized>
					}
				>
					<CourseStatusSelector
						dates={criteria}
						onChange={(dates) => setCriteria((prev) => ({...prev, ...dates}))}
					/>
				</SidebarSection>

				<SidebarSection
					title={
						<Localized id="administration-courses-table-label-start-date">
							Start date
						</Localized>
					}
				>
					<Box display="flex">
						<TextField
							fullWidth
							type="date"
							label={<Localized id="administration-from">From</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							style={{
								marginRight: theme.spacing(1),
							}}
							value={criteria.startDateAfter ?? ""}
							onChange={({target}) => {
								setCriteria((prev) => ({
									...prev,
									startDateAfter: target.value ?? undefined,
								}));
							}}
							InputProps={{inputProps: {max: criteria.startDateBefore}}}
						/>
						<TextField
							fullWidth
							type="date"
							label={<Localized id="administration-to">To</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							value={criteria.startDateBefore ?? ""}
							onChange={({target}) => {
								setCriteria((prev) => ({
									...prev,
									startDateBefore: target.value ?? undefined,
								}));
							}}
							InputProps={{inputProps: {min: criteria.startDateAfter}}}
						/>
					</Box>
				</SidebarSection>

				<SidebarSection
					title={
						<Localized id="administration-courses-table-label-end-date">
							End date
						</Localized>
					}
				>
					<Box display="flex">
						<TextField
							fullWidth
							type="date"
							label={<Localized id="administration-from">From</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							style={{
								marginRight: theme.spacing(1),
							}}
							value={criteria.endDateAfter ?? ""}
							onChange={({target}) => {
								setCriteria((prev) => ({
									...prev,
									endDateAfter: target.value ?? undefined,
								}));
							}}
							InputProps={{inputProps: {max: criteria.endDateBefore}}}
						/>
						<TextField
							fullWidth
							type="date"
							label={<Localized id="administration-to">To</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							value={criteria.endDateBefore ?? ""}
							onChange={({target}) => {
								setCriteria((prev) => ({
									...prev,
									endDateBefore: target.value ?? undefined,
								}));
							}}
							InputProps={{inputProps: {min: criteria.endDateAfter}}}
						/>
					</Box>
				</SidebarSection>
			</SidebarBody>
		</AppBarDependentDrawer>
	);
};

export default Filters;
