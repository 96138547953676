import {useScrollTrigger} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

function useNavBarHeight(): number {
	const navBarCollapsed = useScrollTrigger();
	const theme = useTheme();

	let appToolbarHeight = 48;

	if (typeof theme.mixins.toolbar.minHeight === "string") {
		const parsed = parseFloat(theme.mixins.toolbar.minHeight);
		if (!Number.isNaN(parsed)) {
			appToolbarHeight = parsed;
		}
	}

	if (navBarCollapsed) {
		return appToolbarHeight;
	}

	const navBar = document.getElementById("subtitle-nav-bar");

	const fullBarHeight = (navBar?.offsetHeight ?? 0) + appToolbarHeight;

	return fullBarHeight;
}

export default useNavBarHeight;
