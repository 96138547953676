import clsx from "clsx";
import {Localized} from "@fluent/react";
import React from "react";
import {
	Box,
	Theme,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import parseDate from "../../helpers/parseDate";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		box: {
			borderRadius: theme.spacing(2),
			padding: theme.spacing(0, 1, 0, 1),
			height: "fit-content",
		},
		boxPublished: {
			backgroundColor: "#DCEDC8",
		},
		boxUnpublished: {
			backgroundColor: "#0000001F",
		},
		boxScheduled: {
			backgroundColor: "#FFC10766",
		},
		textSecondary: {
			color: theme.palette.text.secondary,
			width: theme.spacing(2),
			marginRight: theme.spacing(0.5),
		},
	})
);

function createTypographyStyle(size: "small" | "medium") {
	return makeStyles(() =>
		createStyles({
			root: {
				color: "#000000",
				fontSize: size === "small" ? "0.75rem" : "1rem",
				lineHeight: size === "small" ? "1rem" : "1.75rem",
				fontWeight: size === "small" ? "normal" : 400,
			},
		})
	)().root;
}

const ChapterStatusBadge = (props: {
	startDate: string | undefined;
	size: "small" | "medium";
}): JSX.Element => {
	const {startDate, size} = props;
	const typographyClass = createTypographyStyle(size);
	const classes = useStyles();

	const formatStartDate = useDateTimeFormat();

	if (!startDate) {
		return (
			<Box className={clsx(classes.box, classes.boxUnpublished)}>
				<Typography className={typographyClass}>
					<Localized id="dragdrop-chapter-unpublished">Unpublished</Localized>
				</Typography>
			</Box>
		);
	}
	const start = parseDate(startDate);
	if (start > new Date()) {
		return (
			<Box
				display="flex"
				alignItems="center"
				className={clsx(classes.box, classes.boxScheduled)}
			>
				<AccessTimeIcon className={classes.textSecondary} />
				<Typography className={typographyClass}>
					{formatStartDate(start)}
				</Typography>
			</Box>
		);
	}
	return (
		<Box className={clsx(classes.box, classes.boxPublished)}>
			<Typography className={typographyClass}>
				<Localized id="dragdrop-chapter-published">Published</Localized>
			</Typography>
		</Box>
	);
};

export default ChapterStatusBadge;
