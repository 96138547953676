import React from "react";
import {Typography} from "@material-ui/core";
import HtmlContent from "../../../utils/HtmlContent";

const ExerciseQuestion = (props: {text: string}): JSX.Element => {
	return (
		<Typography variant="body1" component="div">
			<HtmlContent content={props.text} />
		</Typography>
	);
};

export default ExerciseQuestion;
