import {Localized} from "@fluent/react";
import {
	Divider,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	createStyles,
	makeStyles,
	withStyles,
} from "@material-ui/core";
import {alpha} from "@material-ui/core/styles";
import ReplyIcon from "@material-ui/icons/Reply";
import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";

import StyledAvatar from "./StyledAvatar";
import parseDate from "../../helpers/parseDate";
import useDateFormat from "../../i18n/useDateFormat";
import selectChapter from "../../store/chapters/selectChapter";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import type TutorQuestion from "../../store/tutorQuestions/TutorQuestion";
import {scrollIntoView} from "../../helpers/scrollHelpers";

const useStyles = makeStyles((theme) =>
	createStyles({
		selected: {
			"&.Mui-selected": {
				background: alpha(theme.palette.secondary.main, 0.25),
				"&:hover": {background: alpha(theme.palette.secondary.main, 0.25)},
			},
		},
		root: {
			"&:hover": {background: alpha(theme.palette.secondary.main, 0.1)},
		},
	})
);

const Replied = withStyles({
	root: {
		verticalAlign: "sub",
	},
})(ReplyIcon);

const MessageListItem = (props: {
	selected: boolean;
	messageKey: string;
	infoSelector: (
		message: TutorQuestion
	) => {
		avatar: React.ReactElement;
		date?: string;
		name: React.ReactNode;
		answered?: boolean;
	};
}): JSX.Element => {
	const classes = useStyles();
	const {selected, messageKey, infoSelector} = props;

	const message = useAppSelector(
		(state) => state.tutorQuestions.byKey[messageKey]
	);

	const chapterTitle = useAppSelector((state) =>
		message.chapterId ? (
			selectChapter(state, keyProvider.chapter(message.chapterId))?.title
		) : (
			<Localized id="tutoring-subject-general-question">
				General question
			</Localized>
		)
	);

	const formatDate = useDateFormat();

	const {avatar, date, name, answered} = infoSelector(message);

	const item = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (selected) {
			setTimeout(() => scrollIntoView(item.current), 300);
		}
	}, [selected]);

	return (
		<>
			<ListItem
				selected={selected}
				classes={{selected: classes.selected, root: classes.root}}
				button
				component={Link}
				innerRef={item}
				to={`?questionId=${message.id}`}
			>
				<ListItemAvatar>
					<StyledAvatar>{avatar}</StyledAvatar>
				</ListItemAvatar>
				<ListItemText
					primary={
						<Grid container justifyContent="space-between" spacing={1}>
							<Grid item>
								<Typography
									component="span"
									variant="subtitle1"
									color="textPrimary"
								>
									{name}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									component="span"
									variant="caption"
									color="textSecondary"
								>
									{date && formatDate(parseDate(date))}
								</Typography>
							</Grid>
						</Grid>
					}
					secondary={
						answered ? (
							<>
								<Replied fontSize="small" /> {chapterTitle}
							</>
						) : (
							chapterTitle
						)
					}
				/>
			</ListItem>
			<Divider variant="inset" component="li" />
		</>
	);
};

export default MessageListItem;
