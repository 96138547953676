import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Grid,
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";

import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import type {
	NotificationRule,
	NotificationRuleDefinition,
} from "../../store/services/notificationsService";
import notificationsService from "../../store/services/notificationsService";
import useSnackbar from "../../store/ui/useSnackbar";
import ConfirmationFooter from "../../utils/ConfirmationFooter";
import {useAppSelector} from "../../store/hooks";
import selectCourse from "../../store/courses/selectCourse";
import {keyProvider} from "../../store/keyProvider";

const NotificationsSettings = (props: {
	courseId: number;
	readOnly?: boolean;
}): JSX.Element => {
	const {courseId, readOnly} = props;

	const [featureEnabled] = useFeatureEnabled();
	const {l10n} = useLocalization();
	const showSnackbar = useSnackbar();

	const [allRules, setAllRules] = useState<NotificationRuleDefinition[]>([]);
	const [courseRules, setCourseRules] = useState<NotificationRule[]>([]);
	const initialCourseRules = useRef<NotificationRule[]>([]);

	const [dirty, setDirty] = useState(false);

	const [saving, setSaving] = useState(false);

	const workload = useAppSelector(
		(state) => selectCourse(state, keyProvider.course(courseId))?.workload
	);

	useEffect(() => {
		async function fetchRules() {
			const rules = await notificationsService.getAvailableNotificationRules();
			setAllRules(rules);
		}

		async function fetchCourseRules() {
			const rules = await notificationsService.getCourseNotificationRules(
				courseId
			);

			initialCourseRules.current = rules;
			setCourseRules(rules);
		}

		fetchRules();
		fetchCourseRules();
	}, [courseId]);

	useEffect(() => {
		setDirty(
			initialCourseRules.current.length !== courseRules.length ||
				initialCourseRules.current.some(
					(r) => courseRules.findIndex((cr) => cr.name === r.name) < 0
				)
		);
	}, [courseRules]);

	const toggleRule = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCourseRules((prev) => {
			const rule = prev.find((r) => r.name === event.target.name);
			if (rule) {
				return prev.filter((r) => r.name !== rule.name);
			}
			return prev.concat({name: event.target.name});
		});
	};

	const saveChanges = async () => {
		setSaving(true);
		try {
			await notificationsService.updateCourseNotificationRules(
				courseId,
				courseRules
			);

			initialCourseRules.current = courseRules;
			setDirty(false);

			showSnackbar(
				"success",
				l10n.getString("success-on-saving", null, "Saved")
			);
		} catch {
			showSnackbar(
				"error",
				l10n.getString("error-general", null, "An error has occured")
			);
		} finally {
			setSaving(false);
		}
	};

	const discardChanges = () => {
		setCourseRules(initialCourseRules.current);
	};

	function disabled(r: NotificationRuleDefinition) {
		return (
			((r.name === "course_study_period_ends_soon" ||
				r.name === "half_of_course_study_period_passed") &&
				!workload) ||
			(r.name === "course_targets_accomplished" &&
				!featureEnabled(Feature.TargetsAccomplishingNotification))
		);
	}

	function helperText(r: NotificationRuleDefinition) {
		return (
			(r.name === "course_study_period_ends_soon" ||
				r.name === "half_of_course_study_period_passed") &&
			!workload && (
				<Box mt={-1.5} style={{marginLeft: `calc(1.5rem + 9px)`}}>
					<FormHelperText disabled>
						<Localized id="course-settings-notifications-set-workload-helper-text">
							Specify course workload to enable it
						</Localized>
					</FormHelperText>
				</Box>
			)
		);
	}

	return (
		<>
			<Grid container direction="column" spacing={1}>
				{allRules.map((r) => (
					<Grid item key={r.name}>
						<FormControlLabel
							control={
								<Checkbox
									checked={
										courseRules.findIndex((cr) => cr.name === r.name) >= 0
									}
									onChange={readOnly ? undefined : toggleRule}
									name={r.name}
								/>
							}
							label={l10n.getString(r.name)}
							disabled={disabled(r)}
						/>
						{helperText(r)}
					</Grid>
				))}
			</Grid>

			{dirty && (
				<ConfirmationFooter
					onDiscard={discardChanges}
					onSave={saveChanges}
					saveInProgress={saving}
				/>
			)}
		</>
	);
};

export default NotificationsSettings;
