import React from "react";
import {Theme, createStyles, makeStyles} from "@material-ui/core/styles";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import amber from "@material-ui/core/colors/amber";
import {CompletionStatus} from "../../store/outcomes/ExerciseOutcomes";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pauseIcon: {
			color: amber[500],
		},
		doneIcon: {
			color: theme.palette.success.main,
		},
	})
);

const CompletionStatusIcon = (props: {
	status: CompletionStatus;
}): JSX.Element => {
	const classes = useStyles();

	switch (props.status) {
		case CompletionStatus.Incomplete:
			return (
				<PauseCircleFilledIcon fontSize="small" className={classes.pauseIcon} />
			);
		case CompletionStatus.Complete:
			return <CheckCircleIcon fontSize="small" className={classes.doneIcon} />;
		default:
			return <></>;
	}
};

export default CompletionStatusIcon;
