import React from "react";
import {Theme, createStyles, makeStyles} from "@material-ui/core/styles";
import {TreeItem} from "@material-ui/lab";
import ChapterLabel from "./ChapterLabel";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			"&.MuiTreeItem-root > .MuiTreeItem-content:hover > .MuiTreeItem-label": {
				background: theme.palette.background.paper,
			},
			"&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
				background: theme.palette.background.paper,
			},
		},
		content: {
			cursor: "default",
			marginBottom: theme.spacing(3),
		},
	})
);

const DisabledChapterNavItem = (props: {
	chapterId: number;
	index: number;
	title: string;
}): JSX.Element => {
	const classes = useStyles();

	return (
		<TreeItem
			key={props.chapterId}
			nodeId={`${props.chapterId}`}
			label={
				<ChapterLabel index={`${props.index}`} label={props.title} disabled />
			}
			classes={{
				root: classes.label,
				content: classes.content,
			}}
		/>
	);
};

export default DisabledChapterNavItem;
