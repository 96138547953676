import {Localized, useLocalization} from "@fluent/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DomainIcon from "@mui/icons-material/Domain";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@mui/material";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import React, {useEffect, useState} from "react";
import {Route, Link as RouterLink, useHistory} from "react-router-dom";

import LogoutIcon from "../../icons/LogoutIcon";
import NavProfileRoleSelector from "./NavProfileRoleSelector";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import fetchUserOrganisationGroups from "../../store/organisationGroups/fetchUserOrganisationGroups";
import selectUserOrganisationGroups, {
	selectUserOrganisationGroupsStatus,
} from "../../store/organisationGroups/selectUserOrganisationGroups";
import OrganisationGroup from "../../store/organisationGroups/UserOrganisationGroup";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import logoutUser from "../../store/userSession/logoutUser";

const useStyles = makeStyles((theme) =>
	createStyles({
		userName: {
			fontWeight: 500,
		},
		groupsListItem: {
			marginRight: theme.spacing(2),
		},
	})
);

interface Props {
	userId: number;
	children?: React.ReactNode;
	profileName: string;
	profileUserName: string;
	avatar: React.ReactElement;
	onClick: () => void;
}

function NavProfile(props: Props): JSX.Element {
	const dispatch = useAppDispatch();

	const history = useHistory();
	const classes = useStyles();

	const [groupSelectorOpen, setGroupSelectorOpen] = useState(false);

	const groups = useAppSelector(selectUserOrganisationGroups);
	const groupsFetchStatus = useAppSelector(selectUserOrganisationGroupsStatus);

	const userId = useAppSelector(selectUserId);

	useEffect(() => {
		if (groupsFetchStatus === "none") {
			dispatch(fetchUserOrganisationGroups({userId}));
		}
	}, [dispatch, groupsFetchStatus, userId]);

	const logout = () => {
		history.replace("/");
		dispatch(logoutUser({logoutOnServer: true}));
		props.onClick();
	};

	if (groupSelectorOpen) {
		return (
			<OrganisationGroupSelector
				groups={groups}
				onItemClick={props.onClick}
				onBack={() => setGroupSelectorOpen(false)}
			/>
		);
	}

	return (
		<>
			<List dense component="div">
				<ListItem>
					<ListItemAvatar>{props.avatar}</ListItemAvatar>
					<ListItemText
						primary={props.profileName}
						secondary={props.profileUserName}
						primaryTypographyProps={{
							variant: "subtitle1",
							className: classes.userName,
						}}
						secondaryTypographyProps={{variant: "caption"}}
					/>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton
						onClick={props.onClick}
						component={RouterLink}
						to={`/account`}
					>
						<ListItemIcon>
							<AccountCircleIcon color="action" fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={<Localized id="nav-account">Account</Localized>}
						/>
					</ListItemButton>
				</ListItem>
				{groups.length > 0 && (
					<ListItem disablePadding>
						<ListItemButton onClick={() => setGroupSelectorOpen(true)}>
							<ListItemIcon>
								<DomainIcon color="action" fontSize="small" />
							</ListItemIcon>
							<ListItemText
								primary={
									<Localized id="nav-organisation-groups">
										Organisation groups
									</Localized>
								}
								className={classes.groupsListItem}
							/>
							<KeyboardArrowRightIcon color="action" />
						</ListItemButton>
					</ListItem>
				)}
				<Route path="/courses/:id">
					<NavProfileRoleSelector
						userId={props.userId}
						onClick={props.onClick}
					/>
				</Route>
			</List>
			<Divider />
			<List dense>
				<ListItem disablePadding>
					<ListItemButton onClick={logout}>
						<ListItemIcon>
							<LogoutIcon color="action" fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={<Localized id="nav-logout">Logout</Localized>}
						/>
					</ListItemButton>
				</ListItem>
			</List>
		</>
	);
}

function OrganisationGroupSelector(props: {
	groups: OrganisationGroup[];
	onBack: () => void;
	onItemClick: () => void;
}) {
	const {groups} = props;

	const theme = useTheme();
	const {l10n} = useLocalization();

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				p={1}
				style={{gap: theme.spacing(0.5)}}
			>
				<IconButton
					aria-label={l10n.getString("nav-organisation-groups-label-back")}
					onClick={props.onBack}
				>
					<ArrowBackIcon />
				</IconButton>
				<Typography variant="subtitle1">
					<Localized id="nav-organisation-groups">
						Organisation groups
					</Localized>
				</Typography>
			</Box>

			<Divider />

			<List dense component="div">
				{groups.map((g) => (
					<ListItem key={g.name} disablePadding>
						<ListItemButton
							onClick={props.onItemClick}
							component="a"
							href={`/organisation-groups/${g.name}`}
						>
							<ListItemText primary={g.displayName} secondary={g.name} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</>
	);
}

export default NavProfile;
