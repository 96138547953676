import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Typography,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import useDateFormat from "../../i18n/useDateFormat";
import parseDate from "../../helpers/parseDate";

export interface StudentCardProps {
	image?: string;
	title: string;
	startDate: string;
	endDate: string;
	id: number;
}

const useStyles = makeStyles(() => ({
	typographytitle: {
		fontWeight: 500,
	},
}));

const StudentCardDashboard = (props: StudentCardProps): JSX.Element => {
	const classes = useStyles();
	const {image, title, startDate, endDate, id} = props;
	const formatDate = useDateFormat();

	return (
		<Card>
			<CardActionArea component={Link} to={`/courses/${id}`}>
				<CardMedia component="img" alt="" height="136" image={image} />
				<CardContent>
					<Typography
						className={classes.typographytitle}
						color="textPrimary"
						gutterBottom
						variant="subtitle1"
						component="h2"
					>
						{title}
					</Typography>
					<Typography color="textSecondary" variant="body2">
						{formatDate(parseDate(startDate))} -{" "}
						{formatDate(parseDate(endDate))}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default StudentCardDashboard;
