import {Slide} from "@material-ui/core";
import type {TransitionProps} from "@material-ui/core/transitions";
import React from "react";

const SlideUp = React.forwardRef(function SlideUp(
	props: TransitionProps & {children?: React.ReactElement},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default SlideUp;
