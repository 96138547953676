import {Localized, useLocalization} from "@fluent/react";
import {Box, Dialog, DialogContent, List} from "@mui/material";
import React, {useEffect, useState} from "react";

import type FetchStatus from "../../store/FetchStatus";
import UserSearchResult from "../../store/services/dtos/UserSearchResult";
import enrolmentService from "../../store/services/enrolmentService";
import LoadingError from "../../utils/errors/LoadingError";
import StatusInfoNotice from "../../utils/StatusInfoNotice";
import StatusListItem from "../../utils/StatusListItem";
import useConfirmClosing from "../../utils/useConfirmClosing";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function EnrolInCourseDialog(props: {
	open: boolean;
	mobileMode: boolean;
	userIds: number[];

	CoursesSelector: (props: {
		onSelected: (ids: number[]) => void;
		onCancel: () => void;
	}) => JSX.Element;

	fetchUsers: (ids: number[]) => Promise<UserSearchResult[]>;

	onCancel: () => void;
	onDone: (courseId: number) => void;
}) {
	const [selectedCourse, setSelectedCourse] = useState(0);

	const [currentStep, setCurrentStep] = useState<"courses" | "status">(
		"courses"
	);

	const {
		status,
		changeStatus,
		close,
		confirmationDialog,
	} = useConfirmClosing(props.onCancel, () => props.onDone(selectedCourse));

	useEffect(() => {
		if (props.open) {
			setSelectedCourse(0);
			setCurrentStep("courses");
		}
	}, [props.open]);

	const titleId = "enrol-in-course";

	return (
		<>
			<Dialog
				open={props.open}
				maxWidth="lg"
				fullWidth
				fullScreen={props.mobileMode}
				aria-labelledby={titleId}
				onClose={close}
			>
				<WindowedDialogTitle
					id={titleId}
					title={
						<Localized
							id="users-enrol-in-course-dialog-title"
							vars={{step: currentStep}}
						>
							Enrol in a course
						</Localized>
					}
					onClose={close}
					onGoBack={
						currentStep !== "courses"
							? () => setCurrentStep("courses")
							: undefined
					}
				/>

				{currentStep === "courses" && (
					<props.CoursesSelector
						onSelected={(ids) => {
							if (ids.length > 0) {
								setSelectedCourse(ids[0]);
								setCurrentStep("status");
							}
						}}
						onCancel={close}
					/>
				)}

				{currentStep === "status" && (
					<ProcessStatusDialog
						courseId={selectedCourse}
						userIds={props.userIds}
						status={status}
						fetchUsers={props.fetchUsers}
						onStatusChanged={changeStatus}
					/>
				)}
			</Dialog>

			{confirmationDialog}
		</>
	);
}

type EnrolStatus = FetchStatus | "warning";

function ProcessStatusDialog(props: {
	courseId: number;
	userIds: number[];
	status: FetchStatus;
	fetchUsers: (ids: number[]) => Promise<UserSearchResult[]>;
	onStatusChanged: (status: FetchStatus) => void;
}) {
	const {userIds, courseId, onStatusChanged} = props;

	const [users, setUsers] = useState<UserSearchResult[]>([]);
	const [usersFetchStatus, setUsersFetchStatus] = useState<FetchStatus>("none");

	const [statuses, setStatuses] = useState<Record<number, EnrolStatus>>({});

	const {l10n} = useLocalization();

	const fetchUsers = props.fetchUsers;

	useEffect(() => {
		if (userIds.length === 0) {
			return;
		}

		setUsersFetchStatus("pending");

		fetchUsers(userIds)
			.then((res) => {
				setUsers(res);
				setUsersFetchStatus("succeeded");
			})
			.catch(() => setUsersFetchStatus("failed"));
	}, [fetchUsers, userIds]);

	useEffect(() => {
		let cancelled = false;

		async function enrolUsers() {
			onStatusChanged("pending");

			let failed = false;

			for (const u of users) {
				if (cancelled) {
					break;
				}

				setStatuses((prev) => ({...prev, [u.id]: "pending"}));

				let status: EnrolStatus;

				try {
					await enrolmentService.enrolInCourse(courseId, u.id);
					status = "succeeded";
				} catch (err) {
					if ((err as {code: string}).code === "conflict") {
						status = "warning";
					} else {
						failed = true;
						status = "failed";
					}
				}

				setStatuses((prev) => ({...prev, [u.id]: status}));
			}

			onStatusChanged(failed ? "failed" : "succeeded");
		}

		if (users.length > 0 && courseId) {
			enrolUsers();
		}

		return () => {
			cancelled = true;
			onStatusChanged("none");
		};
	}, [courseId, onStatusChanged, users]);

	return (
		<>
			<DialogContent dividers sx={{height: "100vh", position: "relative"}}>
				{usersFetchStatus === "succeeded" && (
					<StatusNotice status={props.status} />
				)}
				{usersFetchStatus === "pending" && (
					<StatusNotice status={usersFetchStatus} />
				)}

				{usersFetchStatus === "failed" && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							position: "absolute",
							inset: 0,
						}}
					>
						<LoadingError
							description={
								<Localized id="users-enrol-in-course-dialog-error-load-users">
									Failed to load the list of users. Please try again.
								</Localized>
							}
							variant="block"
						/>
					</Box>
				)}

				<List>
					{users.map((u) => (
						<StatusListItem
							key={u.id}
							primaryText={
								u.firstName && u.lastName
									? `${u.firstName} ${u.lastName}`
									: u.userName
							}
							secondaryText={u.firstName && u.lastName ? u.userName : undefined}
							status={statuses[u.id]}
							labelOnPending={l10n.getString(
								"users-enrol-in-course-dialog-status-user-in-progress"
							)}
							labelOnSuccess={l10n.getString(
								"users-enrol-in-course-dialog-status-user-success"
							)}
							labelOnWarning={l10n.getString(
								"users-enrol-in-course-dialog-status-user-conflict"
							)}
						/>
					))}
				</List>
			</DialogContent>
		</>
	);
}

function Notice(props: {status: FetchStatus}) {
	return (
		<StatusInfoNotice
			status={props.status}
			labelOnPending={
				<Localized id="users-enrol-in-course-dialog-status-overall-in-progress">
					Please do not close the dialogue window while the operation is in
					progress, otherwise not all users will be enrolled in the course.
				</Localized>
			}
			labelOnSuccess={
				<Localized id="users-enrol-in-course-dialog-status-overall-success">
					The users have been successfully enrolled in the selected course.
				</Localized>
			}
			labelOnFailure={
				<Localized id="users-enrol-in-course-dialog-status-overall-failure">
					Unfortunately, not all users have been enrolled successfully. Please,
					try again.
				</Localized>
			}
		/>
	);
}
const StatusNotice = React.memo(Notice);

export default EnrolInCourseDialog;
