import {Localized} from "@fluent/react";
import {DialogContentText} from "@material-ui/core";
import React from "react";

import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";

function useRestoreCoursesConfirmation(
	openConfirmationDialog: OpenConfirmationDialog,
	onConfirm: (organisationName: string, courseIds: number[]) => Promise<void>
) {
	function confirmRestore(
		organisationName: string,
		courseIds: number[],
		courseName?: string
	) {
		openConfirmationDialog({
			confirmBtnText: (
				<Localized id="courses-confirm-restore-dialog-action-restore">
					Restore
				</Localized>
			),
			title: (
				<Localized
					id="courses-confirm-restore-dialog-title"
					vars={{number: courseIds.length}}
				>
					Restore courses?
				</Localized>
			),
			description: (
				<>
					<DialogContentText align="justify">
						<Localized
							id={
								courseName
									? "courses-confirm-restore-dialog-descr-with-course-name"
									: "courses-confirm-restore-dialog-descr-no-names"
							}
							vars={{number: courseIds.length, name: courseName ?? ""}}
						>
							You are going to restore course from the archive.
						</Localized>
					</DialogContentText>
					<Localized
						id="courses-confirm-restore-dialog-descr"
						vars={{number: courseIds.length}}
						elems={{
							paragraph: (
								<DialogContentText align="justify"></DialogContentText>
							),
						}}
					>
						<>
							{`You are going to restore course from the archive.` +
								`After restoring, it will be instantly made available to all students ` +
								`who enrolled in it and to all staff members who were assigned to it.`}
						</>
					</Localized>
				</>
			),
			onConfirm: () => onConfirm(organisationName, courseIds),
		});
	}

	return confirmRestore;
}

export default useRestoreCoursesConfirmation;
