import {Localized, useLocalization} from "@fluent/react";
import TeX from "@matejmazur/react-katex";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Collapse,
	Divider,
	Fade,
	FormControlLabel,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	Switch,
	TextField,
	Theme,
	Typography,
	createStyles,
	makeStyles,
	useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpIcon from "@material-ui/icons/Help";
import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import ContentEditorFooter from "./ContentEditorFooter";
import type ExerciseEditorProps from "./ExerciseEditorProps";
import ExerciseDifficultySelector from "../content/exercises/ExerciseDifficultySelector";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";
import exerciseService from "../../store/services/exerciseService";
import ExerciseTagsSelector from "./ExerciseTagsSelector";
import FormulaInput from "../content/exercises/math/FormulaInput";
import {changeRootArgs, extractVariables} from "../../helpers/mathParseHelpers";
import useExerciseFileUploader from "./useExerciseFileUploader";
import ConditionType from "../../store/exercises/ConditionType";
import ExercisePrivacy from "../../store/exercises/ExercisePrivacy";
import {MathExerciseSubtype} from "../../store/exercises/ExerciseSubtype";
import ExerciseType from "../../store/exercises/ExerciseType";
import type FeedbackRule from "../../store/exercises/FeedbackRule";
import {useAppSelector} from "../../store/hooks";
import {mathService} from "../../store/services/mathService";
import useSnackbar from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import SubmitButton from "../../utils/SubmitButton";
import TextEditor from "../../utils/TextEditor";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";
import AsciiMathParser from "../../utils/asciiMathParser";
import type FileUploader from "../../utils/attachments/FileUploader";
import LightTooltip from "../../utils/LightTooltip";
import PrivacyLevelSelector from "./PrivacyLevelSelector";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		bold: {
			fontWeight: 500,
		},
		mathExprPaper: {
			display: "flex",
			flexDirection: "column",
			padding: theme.spacing(3),
			marginTop: theme.spacing(4),
		},
		generateExprContainer: {
			padding: theme.spacing(3),
			background: theme.palette.grey[100],
			position: "relative",
		},
		generateBtn: {
			marginLeft: theme.spacing(2),
		},
		generatedExpr: {
			"&:hover": {
				cursor: "pointer",
				color: theme.palette.text.hint,
			},
		},
		closeGenExprBtn: {
			position: "absolute",
			top: 0,
			right: 0,
			margin: theme.spacing(3),
		},
	})
);

const MathExpression = (props: {
	asciiFormula: string;
	parser: AsciiMathParser;
}) => {
	const {parser, asciiFormula} = props;

	const formula = useMemo(() => {
		const formula = changeRootArgs(asciiFormula);
		return parser.parse(formula);
	}, [parser, asciiFormula]);

	return <TeX>{formula}</TeX>;
};

const conditionTypeDescriptions = {
	[ConditionType.Default]: "",
	[ConditionType.Equality]: (
		<Localized id="learning-material-math-exercise-editor-condition-type-is-equal-to">
			is equal to
		</Localized>
	),
	[ConditionType.InInterval]: (
		<Localized id="learning-material-math-exercise-editor-condition-type-is-in">
			is in
		</Localized>
	),
};

const expressionLabels = {
	[ConditionType.Default]: "",
	[ConditionType.Equality]: (
		<Localized id="learning-material-math-exercise-editor-expression">
			expression
		</Localized>
	),
	[ConditionType.InInterval]: (
		<Localized id="learning-material-math-exercise-editor-interval">
			interval
		</Localized>
	),
};

const expressionPlaceholders = {
	[ConditionType.Default]: "",
	[ConditionType.Equality]: "2^16",
	[ConditionType.InInterval]: "(-oo, 2^16]",
};

function describeConditionType(type: ConditionType) {
	return conditionTypeDescriptions[type];
}

function describeExpression(type: ConditionType) {
	return expressionLabels[type];
}

const FeedbackRuleEditor = (props: {
	conditionType: ConditionType;
	expression: string;
	message: string;
	availableConditionTypes: ConditionType[];
	parser: AsciiMathParser;
	fileUploader?: FileUploader;
	onChange: (field: keyof FeedbackRule, value: string | ConditionType) => void;
	onDelete: () => void;
}) => {
	const {l10n} = useLocalization();

	const {
		conditionType,
		expression,
		message,
		availableConditionTypes,
		parser,
		fileUploader,
		onChange,
		onDelete,
	} = props;

	return (
		<Box display="flex" mb={4}>
			<Box width="100%" mr={2}>
				<Box display="flex" width="100%">
					<Box mr={2}>
						<TextField
							select
							label={l10n.getString(
								"learning-material-math-exercise-editor-label-when-answer"
							)}
							value={conditionType}
							style={{minWidth: "8rem"}}
							onChange={(event) =>
								onChange("conditionType", event.target.value as ConditionType)
							}
						>
							{availableConditionTypes.map((type) => (
								<MenuItem key={type} value={type}>
									{describeConditionType(type)}
								</MenuItem>
							))}
						</TextField>
					</Box>

					<Box width="100%">
						<FormulaInput
							label={describeExpression(conditionType)}
							placeholder={expressionPlaceholders[conditionType]}
							value={expression}
							onInputChange={(value) => onChange("expression", value)}
							required
						/>

						<Box mt={1} minHeight="2.5rem">
							<Typography variant="h6" component="span">
								<MathExpression asciiFormula={expression} parser={parser} />
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box>
					<TextEditor
						fileUploader={fileUploader}
						mode="inline"
						onChange={(value) => onChange("message", value)}
						value={message}
						label={l10n.getString(
							"learning-material-math-exercise-editor-label-show-message"
						)}
					/>
				</Box>
			</Box>

			<Box mt={1} mx={-1.5}>
				<IconButton onClick={onDelete} color="primary">
					<DeleteIcon />
				</IconButton>
			</Box>
		</Box>
	);
};

type MathExercise = {
	id: number;
	category: string;
	tags: string[];
	difficultyLevel: number;
	expression: string;
	maxScore: number;
	privacy: ExercisePrivacy;
	expressionVisible: boolean;
	subtype: MathExerciseSubtype;
	title: string;
	variable: string;
};

const availableConditionTypes = {
	[MathExerciseSubtype.Numerical]: [
		ConditionType.Equality,
		ConditionType.InInterval,
	],
	[MathExerciseSubtype.Polynomial]: [ConditionType.Equality],
	[MathExerciseSubtype.Equation]: [ConditionType.Equality],
	[MathExerciseSubtype.Differentiation]: [ConditionType.Equality],
	[MathExerciseSubtype.Integration]: [ConditionType.Equality],
};

let idCounter = 0;
function id() {
	return idCounter++;
}

const MathExerciseEditor = (props: ExerciseEditorProps): JSX.Element => {
	const {l10n} = useLocalization();
	const classes = useStyles();

	const {courseId, exercise, exerciseId, organisationName} = props;

	const userId = useAppSelector(selectUserId);

	const [draft, setDraft] = useState<MathExercise>(() => ({
		id: exerciseId ?? 0,
		category: "",
		tags: [],
		difficultyLevel: 0,
		expression: "",
		maxScore: 1,
		privacy: ExercisePrivacy.PublicToOrganisation,
		expressionVisible: true,
		subtype: MathExerciseSubtype.Numerical,
		title: l10n.getString(
			"learning-material-math-exercise-editor-exercise-default-title"
		),
		variable: "x",
	}));

	const question = useRef<TextEditorApi | null>(null);
	const solution = useRef<TextEditorApi | null>(null);

	const [dirty, setDirty] = useState(!exercise);

	const [exprGenPanelOpen, setExprGenPanelOpen] = useState(false);
	const [expressionDifficulty, setExpressionDifficulty] = useState<
		number | null
	>(3);
	const [exprVariable, setExprVariable] = useState("x");
	const [generatedExpr, setGeneratedExpr] = useState<string[]>([]);
	const [selectedExprIndex, setSelectedExprIndex] = useState<number | null>(
		null
	);

	const [variables, setVariables] = useState<string[]>([]);

	const [typedAnswer, setTypedAnswer] = useState("");
	const [finalAnswers, setFinalAnswers] = useState<
		{
			id: number;
			formula: string;
		}[]
	>([]);

	const [defaultFeedback, setDefaultFeedback] = useState("");

	const [feedbackRules, setFeedbackRules] = useState<
		(FeedbackRule & {
			id: number;
		})[]
	>([]);

	const [applyInProgress, setApplyInProgress] = useState(false);
	const [generateInProgress, setGenerateInProgress] = useState(false);

	const [emptyFields, setEmptyFields] = useState<{
		title?: boolean;
		expression?: boolean;
		finalAnswers?: boolean;
	}>({});

	const [feedbackError, setFeedbackError] = useState("");

	const initialQuestion = useRef("");
	const initialSolution = useRef("");

	const updated = useRef(false);

	if (exercise && exercise.type !== ExerciseType.Math) {
		throw new Error("Unexpected exercise type");
	}

	const [featureEnabled] = useFeatureEnabled();

	useEffect(() => {
		if (!exercise) {
			return;
		}

		if (exerciseId) {
			const fillEditor = () => {
				initialQuestion.current = exercise.question;
				initialSolution.current = exercise.solution;

				setDraft({
					id: exerciseId ?? 0,
					category: exercise.category,
					difficultyLevel: exercise.difficultyLevel,
					expression: exercise.expression,
					maxScore: exercise.maxScore,
					privacy: exercise.privacy,
					expressionVisible: exercise.expressionVisible,
					subtype: exercise.subtype,
					title: exercise.title,
					tags: exercise.tags,
					variable: exercise.variable ?? "x",
				});

				setFinalAnswers(
					exercise.finalAnswer.map((formula) => ({
						id: id(),
						formula,
					}))
				);

				setVariables([exercise.variable ?? "x"]);
			};

			const fetchFeedbackRules = async () => {
				const items = await exerciseService.getMathFeedbackRules(
					courseId,
					exerciseId
				);

				const defaultIndex = items.findIndex(
					(r) => r.conditionType === ConditionType.Default
				);
				if (defaultIndex > -1) {
					const rule = items.splice(defaultIndex, 1)[0];
					setDefaultFeedback(rule.message);
				}

				const rules = items.map((r) => ({
					...r,
					id: id(),
				}));

				setFeedbackRules(rules);
			};

			fillEditor();
			fetchFeedbackRules();
		}
	}, [courseId, exercise, exerciseId]);

	useEffect(() => props.onUnmount, [props.onUnmount]);

	useEffect(() => {
		if (variables.length === 0) {
			return;
		}
		if (!draft.variable || !variables.includes(draft.variable)) {
			update("variable", variables[0]);
		}
	}, [draft.variable, variables]);

	const parser = useMemo(() => {
		return new AsciiMathParser();
	}, []);

	const theme = useTheme();

	const [files, fileUploader] = useExerciseFileUploader(
		userId,
		courseId,
		draft.id
	);

	const showSnackbar = useSnackbar();

	const update = (
		key: keyof MathExercise,
		value:
			| string
			| string[]
			| number
			| boolean
			| ExercisePrivacy
			| MathExerciseSubtype
			| null
	) => {
		setDraft((prev) => ({...prev, [key]: value}));
		setDirty(true);
	};

	const generateExpressions = async () => {
		setGenerateInProgress(true);

		try {
			const expressions = await mathService.generateExpressions(
				draft.subtype,
				expressionDifficulty ?? 0,
				exprVariable,
				courseId
			);

			setGeneratedExpr(expressions);
			setSelectedExprIndex(null);
		} catch {
			showSnackbar("error", "Failed to generate math expression");
		}

		setGenerateInProgress(false);
	};

	const evaluateExpression = async (expression: string, variable: string) => {
		setApplyInProgress(true);

		try {
			const result = await mathService.evaluateExpression(
				expression,
				variable,
				draft.subtype,
				courseId
			);

			setFinalAnswers(
				result.map((val) => ({
					formula: val,
					id: id(),
				}))
			);
			setEmptyFields((prev) => ({...prev, finalAnswers: false}));
		} catch {
			showSnackbar("error", "Failed to evaluate math expression");
		}

		setApplyInProgress(false);
	};

	const updateTypedExpression = (value: string) => {
		if (requiresVariable(draft.subtype)) {
			const extracted = extractVariables(value);
			setVariables(extracted);
		}

		update("expression", value);
	};

	const selectGeneratedExpr = (index: number) => {
		setSelectedExprIndex(index);
		updateTypedExpression(generatedExpr[index]);
		evaluateExpression(generatedExpr[index], exprVariable);
	};

	const updateFeedbackRule = (id: number) => (
		field: keyof FeedbackRule,
		value: string | ConditionType
	) => {
		setFeedbackRules((prev) => {
			const rules = prev.slice();
			const i = prev.findIndex((f) => f.id === id);

			rules[i] = {...prev[i], [field]: value};

			return rules;
		});

		setFeedbackError("");
		setDirty(true);
	};

	const deleteFeedbackRule = (id: number) => () => {
		setFeedbackRules((prev) => prev.filter((r) => r.id !== id));

		setFeedbackError("");
		setDirty(true);
	};

	const addFeedbackRule = () => {
		setFeedbackRules((prev) => [
			...prev,
			{
				id: id(),
				expression: "",
				message: "",
				conditionType: ConditionType.Equality,
			},
		]);

		setDirty(true);
	};

	const searchTags = useCallback(
		async (prefix: string, pageSize: number) => {
			const page = await exerciseService.searchTags(
				{
					scope: "organisation_or_user_exercises",
					organisationName,
					userId,
					prefix,
				},
				pageSize
			);

			return page;
		},
		[organisationName, userId]
	);

	const validate = () => {
		const emptyFieldsMessage = l10n.getString(
			"exercise-editor-error-required-fields"
		);
		const invalidIntervalsMessage = l10n.getString(
			"learning-material-math-exercise-editor-validate-interval-invalid"
		);

		setEmptyFields({
			title: draft.title.length === 0,
			expression: draft.expression.length === 0,
			finalAnswers: finalAnswers.length === 0,
		});

		const emptyFeedbackExist = feedbackRules.some(
			(r) => r.message.length === 0 || r.expression.length === 0
		);

		if (emptyFeedbackExist) {
			setFeedbackError(emptyFieldsMessage);
		}

		if (
			draft.title.length === 0 ||
			draft.expression.length === 0 ||
			finalAnswers.length === 0 ||
			emptyFeedbackExist
		) {
			return emptyFieldsMessage;
		}

		if (
			feedbackRules.some(
				(r) =>
					r.conditionType === ConditionType.InInterval &&
					!validInterval(r.expression)
			)
		) {
			setFeedbackError(invalidIntervalsMessage);

			return invalidIntervalsMessage;
		}

		return null;
	};

	const save = async () => {
		const error = validate();
		if (error) {
			showSnackbar("error", error);

			return false;
		}

		let id: number;
		try {
			const base = exercise ?? {
				type: ExerciseType.Math,
				authorId: userId,
				originId: courseId,
			};

			id = await props.onSave({
				...base,
				finalAnswer: finalAnswers.map((fa) => fa.formula),
				category: draft.category,
				difficultyLevel: draft.difficultyLevel ?? 0,
				tags: draft.tags,
				expression: draft.expression,
				solution: solution.current?.getContent() ?? "",
				maxScore: draft.maxScore,
				privacy: draft.privacy,
				question: question.current?.getContent() ?? "",
				expressionVisible: draft.expressionVisible,
				subtype: draft.subtype,
				title: draft.title,
				variable: draft.variable,
			});

			setDraft((prev) => ({...prev, id}));
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}

		let rules: FeedbackRule[] = feedbackRules.map((r) => ({
			conditionType: r.conditionType,
			expression: r.expression,
			message: r.message,
		}));

		if (defaultFeedback) {
			rules = [
				{
					conditionType: ConditionType.Default,
					expression: "",
					message: defaultFeedback,
				},
				...rules,
			];
		}

		try {
			await exerciseService.saveMathFeedbackRules(courseId, id, rules);
		} catch {
			showSnackbar("error", "Failed to save custom feedback");

			updated.current = true;

			return false;
		}

		setDirty(false);
		updated.current = true;

		return true;
	};

	const complete = async () => {
		const saved = await save();
		if (!saved) {
			return;
		}

		props.onClose(updated.current);
	};

	function requiresVariable(subtype: MathExerciseSubtype) {
		return (
			subtype === MathExerciseSubtype.Equation ||
			subtype === MathExerciseSubtype.Differentiation ||
			subtype === MathExerciseSubtype.Integration
		);
	}

	return (
		<>
			<Box display="flex" flexDirection="column">
				<TextField
					label={
						<Localized id="exercise-editor-title-label">
							Exercise title
						</Localized>
					}
					required
					fullWidth
					error={emptyFields.title}
					helperText={
						emptyFields.title && (
							<Localized id="exercise-editor-error-required-fields">
								The field is required
							</Localized>
						)
					}
					value={draft.title}
					onChange={(event) => {
						setEmptyFields((prev) => ({...prev, title: false}));
						update("title", event.target.value);
					}}
				/>
				<Box mt={4}>
					<Typography variant="body2" style={{marginBottom: theme.spacing(1)}}>
						<Localized id="exercise-editor-description-label">
							Description
						</Localized>
					</Typography>
					<TextEditorWithAttachments
						initialValue={initialQuestion.current}
						onChange={() => setDirty(true)}
						fileUploader={fileUploader ?? undefined}
						files={files}
						ref={question}
					/>
				</Box>
				<Box mt={6}>
					<TextField
						select
						label={
							<Localized id="learning-material-math-exercise-editor-exercise-type">
								Excercise type
							</Localized>
						}
						value={draft.subtype}
						style={{minWidth: "35ch"}}
						onChange={(event) =>
							update("subtype", event.target.value as MathExerciseSubtype)
						}
					>
						<MenuItem value={MathExerciseSubtype.Numerical}>
							<Localized id="learning-material-math-exercise-editor-exercise-type-numerical">
								Numerical
							</Localized>
						</MenuItem>
						<MenuItem value={MathExerciseSubtype.Polynomial}>
							<Localized id="learning-material-math-exercise-editor-exercise-type-polynomial">
								Polynomial
							</Localized>
						</MenuItem>
						<MenuItem value={MathExerciseSubtype.Differentiation}>
							<Localized id="learning-material-math-exercise-editor-exercise-type-differentiation">
								Differentiation
							</Localized>
						</MenuItem>
						<MenuItem value={MathExerciseSubtype.Integration}>
							<Localized id="learning-material-math-exercise-editor-exercise-type-integration">
								Integration
							</Localized>
						</MenuItem>
						<MenuItem value={MathExerciseSubtype.Equation}>
							<Localized id="learning-material-math-exercise-editor-exercise-type-equation">
								Equation
							</Localized>
						</MenuItem>
					</TextField>
					<TextField
						label={
							<Localized id="exercise-editor-score-label">Score</Localized>
						}
						type="number"
						InputProps={{inputProps: {min: 0}}}
						style={{maxWidth: "10ch", marginLeft: theme.spacing(3)}}
						value={draft.maxScore}
						onChange={(event) =>
							update("maxScore", parseInt(event.target.value))
						}
					/>
				</Box>

				<Paper className={classes.mathExprPaper}>
					<Box display="flex" alignItems="baseline">
						<Typography variant="subtitle1" className={classes.bold}>
							<Localized id="learning-material-math-exercise-editor-math-expression">
								Math expression:*
							</Localized>
						</Typography>
						<Fade in={!exprGenPanelOpen}>
							<Button
								color="primary"
								onClick={() => setExprGenPanelOpen(true)}
								style={{marginLeft: theme.spacing(1)}}
							>
								<Localized id="learning-material-math-exercise-editor-generate">
									Generate
								</Localized>
							</Button>
						</Fade>
					</Box>
					<Collapse in={exprGenPanelOpen}>
						<Box className={classes.generateExprContainer}>
							<Typography variant="subtitle1" className={classes.bold}>
								<Localized id="learning-material-math-exercise-editor-generate-expression">
									Generate math expression
								</Localized>
							</Typography>
							<IconButton
								className={classes.closeGenExprBtn}
								size="small"
								onClick={() => setExprGenPanelOpen(false)}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
							<Box display="flex" alignItems="baseline" mt={2.5} mb={3}>
								<Typography style={{marginRight: theme.spacing(2)}}>
									<Localized id="learning-material-math-exercise-editor-difficulty">
										Difficulty level
									</Localized>
								</Typography>
								<ExerciseDifficultySelector
									name="expression-difficulty"
									value={expressionDifficulty}
									onChange={(value) => setExpressionDifficulty(value)}
								/>
								{requiresVariable(draft.subtype) && (
									<TextField
										value={exprVariable}
										inputProps={{maxLength: 1}}
										onChange={(event) => setExprVariable(event.target.value)}
										style={{width: "8ch", marginLeft: theme.spacing(2)}}
										label={
											<Localized id="learning-material-math-exercise-editor-generate-label-variable">
												Variable
											</Localized>
										}
									/>
								)}
								<SubmitButton
									onClick={generateExpressions}
									variant="text"
									inProgress={generateInProgress}
									className={classes.generateBtn}
								>
									<Localized id="learning-material-math-exercise-editor-generate">
										Generate
									</Localized>
								</SubmitButton>
							</Box>
							{generatedExpr.map((expr, i) => (
								<Box key={i} display="flex" mt={2}>
									<Typography
										variant="subtitle1"
										className={classes.generatedExpr}
										onClick={() => selectGeneratedExpr(i)}
									>
										<MathExpression asciiFormula={expr} parser={parser} />
									</Typography>
									{i === selectedExprIndex && (
										<CheckIcon
											fontSize="small"
											style={{
												color: theme.palette.success.main,
												marginLeft: theme.spacing(0.5),
											}}
										/>
									)}
								</Box>
							))}
						</Box>
					</Collapse>
					<Box display="flex" mt={exprGenPanelOpen ? 3 : 0}>
						<FormulaInput
							value={draft.expression}
							onInputChange={(value) => {
								setEmptyFields((prev) => ({...prev, expression: false}));
								updateTypedExpression(value);
								setSelectedExprIndex(null);
							}}
							onInputFinish={() =>
								evaluateExpression(draft.expression, draft.variable)
							}
							confirmBtnText={l10n.getString(
								"learning-material-math-exercise-editor-form-button-apply"
							)}
							confirmInProgress={applyInProgress}
							error={emptyFields.expression}
							helperText={
								emptyFields.expression && (
									<Localized id="exercise-editor-error-required-field">
										The field is required
									</Localized>
								)
							}
						/>
						<FormControlLabel
							label={
								<Localized id="learning-material-math-exercise-editor-visible-to-students">
									Visible to students
								</Localized>
							}
							control={
								<Switch
									color="secondary"
									checked={draft.expressionVisible}
									onChange={(event) =>
										update("expressionVisible", event.target.checked)
									}
								/>
							}
							style={{marginLeft: theme.spacing(1)}}
						/>
					</Box>
					<Box minHeight="2.5rem" mt={2}>
						<Typography variant="h6" component="span">
							<MathExpression asciiFormula={draft.expression} parser={parser} />
						</Typography>
					</Box>
					<Collapse in={requiresVariable(draft.subtype)}>
						<Box mt={6} display="flex" alignItems="center">
							<Typography variant="subtitle1" className={classes.bold}>
								<Localized id="learning-material-math-exercise-editor-choose-label-variable">
									Variable
								</Localized>
							</Typography>
							<LightTooltip
								title={
									<Localized id="learning-material-math-exercise-editor-tooltip-respect-variable">
										In which respect the equation should be solved
									</Localized>
								}
								placement="top-start"
							>
								<HelpIcon
									color="primary"
									style={{marginLeft: theme.spacing(0.5)}}
								/>
							</LightTooltip>
							<TextField
								value={draft.variable}
								select
								onChange={(event) => update("variable", event.target.value)}
								disabled={variables.length === 0}
								style={{width: "8ch", marginLeft: theme.spacing(2)}}
							>
								{variables.map((v) => (
									<MenuItem key={v} value={v}>
										<TeX>{v}</TeX>
									</MenuItem>
								))}
							</TextField>
						</Box>
					</Collapse>
					<Typography
						variant="subtitle1"
						className={classes.bold}
						style={{marginTop: theme.spacing(7)}}
					>
						<Localized id="learning-material-math-exercise-editor-exercise-final-answer">
							Final answer *
						</Localized>
					</Typography>
					<FormulaInput
						value={typedAnswer}
						onInputChange={(value) => setTypedAnswer(value)}
						onInputFinish={() => {
							setFinalAnswers((prev) => [
								{id: id(), formula: typedAnswer},
								...prev,
							]);
							setTypedAnswer("");
							setDirty(true);
							setEmptyFields((prev) => ({...prev, finalAnswers: false}));
						}}
						confirmBtnText={l10n.getString(
							"learning-material-math-exercise-editor-exercise-final-answer-button-add"
						)}
						error={emptyFields.finalAnswers}
						helperText={
							emptyFields.finalAnswers && (
								<Localized id="learning-material-math-exercise-editor-exercise-final-answer-not-found">
									No final answer found
								</Localized>
							)
						}
					/>
					<Box minHeight="2.5rem" mt={2}>
						<Typography variant="h6" component="span">
							<MathExpression asciiFormula={typedAnswer} parser={parser} />
						</Typography>
					</Box>
					{finalAnswers.map((answer) => (
						<Box key={answer.id} display="flex">
							<Typography variant="h6" component="span">
								<MathExpression asciiFormula={answer.formula} parser={parser} />
							</Typography>
							<IconButton
								size="small"
								color="primary"
								onClick={() => {
									setFinalAnswers((prev) =>
										prev.filter((fa) => fa.id !== answer.id)
									);
									setDirty(true);
								}}
								style={{
									marginLeft: theme.spacing(0.5),
								}}
							>
								<DeleteIcon />
							</IconButton>
						</Box>
					))}
				</Paper>

				<Box mt={4}>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>
								<Localized id="exercise-editor-solution-label">
									Solution
								</Localized>
							</Typography>
						</AccordionSummary>

						<AccordionDetails>
							<Box width="100%">
								<TextEditor
									initialValue={initialSolution.current}
									onChange={() => setDirty(true)}
									fileUploader={fileUploader ?? undefined}
									ref={solution}
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Box>

				<Box mt={5} mb={6}>
					<Typography variant="h6">
						<Localized id="learning-material-math-exercise-editor-exercise-feedback">
							Feedback
						</Localized>
					</Typography>

					<TextField
						label={
							<Localized id="learning-material-math-exercise-editor-exercise-default-feedback">
								Default feedback
							</Localized>
						}
						fullWidth
						value={defaultFeedback}
						onChange={(event) => {
							setDefaultFeedback(event.target.value);
							setDirty(true);
						}}
						style={{marginTop: theme.spacing(3)}}
					/>

					<Box mt={4}>
						<Typography variant="subtitle2" className={classes.bold}>
							<Localized id="learning-material-math-exercise-editor-exercise-custom-feedback">
								Custom feedback
							</Localized>
						</Typography>

						<Box visibility={feedbackError ? "visible" : "hidden"} mb={1}>
							<Typography variant="caption" color="error">
								{feedbackError || "placeholder"}
							</Typography>
						</Box>

						{feedbackRules.map((r, i) => (
							<Fragment key={r.id}>
								<FeedbackRuleEditor
									conditionType={r.conditionType}
									expression={r.expression}
									message={r.message}
									availableConditionTypes={
										availableConditionTypes[draft.subtype]
									}
									parser={parser}
									fileUploader={fileUploader ?? undefined}
									onChange={updateFeedbackRule(r.id)}
									onDelete={deleteFeedbackRule(r.id)}
								/>

								{i !== feedbackRules.length - 1 && (
									<Divider style={{marginBottom: theme.spacing(4)}} />
								)}
							</Fragment>
						))}
					</Box>

					<Box display="flex" flexGrow={1} justifyContent="flex-end">
						<Button
							color="primary"
							startIcon={<AddIcon />}
							onClick={addFeedbackRule}
						>
							<Localized id="learning-material-math-exercise-editor-exercise-add-feedback-rule">
								Add feedback rule
							</Localized>
						</Button>
					</Box>
				</Box>

				<Grid container spacing={3} alignItems="flex-end">
					<Grid item xs={12} md={6}>
						<PrivacyLevelSelector
							value={draft.privacy}
							onChange={(value) => update("privacy", value)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ExerciseDifficultySelector
							name="exercise-difficulty"
							value={draft.difficultyLevel}
							onChange={(value) => update("difficultyLevel", value)}
						/>
					</Grid>
				</Grid>

				<Box mt={6}>
					<TextField
						label={
							<Localized id="exercise-editor-category-label">
								Category
							</Localized>
						}
						value={draft.category}
						onChange={(event) => update("category", event.target.value)}
					/>
				</Box>

				{featureEnabled(Feature.ExerciseTags) && (
					<Box mt={3}>
						<ExerciseTagsSelector
							freeSolo
							value={draft.tags}
							onChange={(value) => update("tags", value)}
							searchTags={searchTags}
						/>
					</Box>
				)}
			</Box>

			<ContentEditorFooter
				onCancel={() => props.onClose(updated.current)}
				onCompeleted={complete}
				onSave={save}
				disabled={!dirty}
			/>
		</>
	);
};

function validInterval(interval: string): boolean {
	return interval.length > 0 && /^[[(][^,]+, ?[^,]+[\])]$/.test(interval);
}

export default MathExerciseEditor;
