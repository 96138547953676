import {Localized} from "@fluent/react";
import {IconButton, Skeleton, TableCell, TableRow} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import parseDate from "../../helpers/parseDate";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import type {CourseStudentSearchResult} from "../../store/services/courseService";
import StudentName from "../students/StudentName";

const useStyles = makeStyles(() =>
	createStyles({
		hidden: {
			visibility: "hidden",
			"& > td": {
				borderBottom: "1px solid transparent",
			},
		},
	})
);

const StudentActivityRow = (props: {
	student: CourseStudentSearchResult;
	loading?: boolean;
	actionIcon: React.ReactNode;
	onAction: () => void;
}): JSX.Element => {
	const {student, loading} = props;

	const classes = useStyles();

	const formatDate = useDateTimeFormat();

	const studyStartDate =
		student.studyStartDate && formatDate(parseDate(student.studyStartDate));

	return (
		<TableRow
			tabIndex={-1}
			className={!student.userName && !loading ? classes.hidden : undefined}
		>
			<TableCell style={{width: "40%"}}>
				<StudentName student={student} loading={loading} />
			</TableCell>
			<TableCell>
				{loading && <Skeleton variant="text" width="50%" />}
				{!loading &&
					(studyStartDate ? (
						<Localized
							id="follow-up-student-activity-widget-study-status-started-on"
							vars={{startDate: studyStartDate}}
						>
							{`Started on ${studyStartDate}`}
						</Localized>
					) : (
						<Localized id="follow-up-student-activity-widget-study-status-not-started">
							Not started
						</Localized>
					))}
			</TableCell>
			<TableCell padding="checkbox">
				{!loading && (
					<IconButton onClick={props.onAction}>{props.actionIcon}</IconButton>
				)}
			</TableCell>
		</TableRow>
	);
};

export default StudentActivityRow;
