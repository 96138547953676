import {Localized, useLocalization} from "@fluent/react";
import {Box, Dialog, DialogContent, List} from "@mui/material";
import React, {useEffect, useState} from "react";

import useMobileMode from "../../hooks/useMobileMode";
import GroupOrganisationSelectionDialog from "../organisations/GroupOrganisationSelectionDialog";
import type FetchStatus from "../../store/FetchStatus";
import type {GroupOrganisationSearchResult} from "../../store/services/organisationService";
import {organisationService} from "../../store/services/organisationService";
import LoadingError from "../../utils/errors/LoadingError";
import StatusInfoNotice from "../../utils/StatusInfoNotice";
import StatusListItem from "../../utils/StatusListItem";
import useConfirmClosing from "../../utils/useConfirmClosing";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function GrantCourseTemplatesDialog(props: {
	open: boolean;
	groupName: string;
	templateIds: number[];
	onCancel: () => void;
	onDone: () => void;
}) {
	const {groupName} = props;

	const [selectedOrgs, setSelectedOrgs] = useState<string[]>([]);

	const mobileMode = useMobileMode("md");

	const [currentStep, setCurrentStep] = useState<"orgs" | "status">("orgs");

	const {status, changeStatus, close, confirmationDialog} = useConfirmClosing(
		props.onCancel,
		props.onDone
	);

	useEffect(() => {
		if (props.open) {
			setSelectedOrgs([]);
			setCurrentStep("orgs");
		}
	}, [props.open]);

	const titleId = "grant-templates";

	return (
		<>
			<Dialog
				open={props.open}
				maxWidth="lg"
				fullWidth
				fullScreen={mobileMode}
				aria-labelledby={titleId}
				onClose={close}
			>
				<WindowedDialogTitle
					id={titleId}
					title={
						<Localized id="grant-course-templates-dialog-title">
							Grant the use of templates
						</Localized>
					}
					onClose={close}
					onGoBack={
						currentStep === "status" ? () => setCurrentStep("orgs") : undefined
					}
				/>

				{currentStep === "orgs" && (
					<GroupOrganisationSelectionDialog
						groupName={groupName}
						actionLabel={
							<Localized id="grant-course-templates-dialog-action-grant">
								Grant
							</Localized>
						}
						onSelected={(names) => {
							setSelectedOrgs(names);
							setCurrentStep("status");
						}}
						onClose={close}
					/>
				)}

				{currentStep === "status" && (
					<ProcessStatusDialog
						groupName={groupName}
						organisations={selectedOrgs}
						templateIds={props.templateIds}
						status={status}
						onStatusChanged={changeStatus}
					/>
				)}
			</Dialog>

			{confirmationDialog}
		</>
	);
}

function ProcessStatusDialog(props: {
	groupName: string;
	organisations: string[];
	templateIds: number[];
	status: FetchStatus;
	onStatusChanged: (status: FetchStatus) => void;
}) {
	const {templateIds, onStatusChanged} = props;

	const [organisations, setOrganisations] = useState<
		GroupOrganisationSearchResult[]
	>([]);

	const [
		organisationsFetchStatus,
		setOrganisationsFetchStatus,
	] = useState<FetchStatus>("none");

	const [statuses, setStatuses] = useState<Record<string, FetchStatus>>({});

	const {l10n} = useLocalization();

	useEffect(() => {
		if (props.organisations.length === 0) {
			return;
		}

		setOrganisationsFetchStatus("pending");

		organisationService
			.searchGroupOrganisations(
				props.groupName,
				{names: props.organisations},
				{field: "displayName"},
				props.organisations.length
			)
			.then((res) => {
				setOrganisations(res.content);
				setOrganisationsFetchStatus("succeeded");
			})
			.catch(() => setOrganisationsFetchStatus("failed"));
	}, [props.groupName, props.organisations]);

	useEffect(() => {
		let cancelled = false;

		async function grantToAll() {
			onStatusChanged("pending");

			let failed = false;

			for (const org of organisations) {
				if (cancelled) {
					break;
				}

				setStatuses((prev) => ({...prev, [org.name]: "pending"}));

				try {
					await organisationService.grantUseOfCourseTemplates(
						org.name,
						templateIds
					);

					setStatuses((prev) => ({...prev, [org.name]: "succeeded"}));
				} catch {
					setStatuses((prev) => ({...prev, [org.name]: "failed"}));
					failed = true;
				}
			}

			onStatusChanged(failed ? "failed" : "succeeded");
		}

		if (organisations.length > 0 && templateIds.length > 0) {
			grantToAll();
		}

		return () => {
			cancelled = true;
			onStatusChanged("none");
		};
	}, [organisations, onStatusChanged, templateIds]);

	return (
		<>
			<DialogContent dividers style={{height: "100vh", position: "relative"}}>
				{organisationsFetchStatus === "succeeded" && (
					<StatusNotice status={props.status} />
				)}
				{organisationsFetchStatus === "pending" && (
					<StatusNotice status={organisationsFetchStatus} />
				)}

				{organisationsFetchStatus === "failed" && (
					<Box
						display="flex"
						justifyContent="center"
						position="absolute"
						style={{inset: 0}}
					>
						<LoadingError
							description={
								<Localized id="grant-course-templates-dialog-error-load-organisations">
									Failed to load the list of organisations
								</Localized>
							}
							variant="block"
						/>
					</Box>
				)}

				<List>
					{organisations.map((o) => (
						<StatusListItem
							key={o.name}
							primaryText={o.displayName}
							secondaryText={o.name}
							status={statuses[o.name]}
							labelOnPending={l10n.getString(
								"grant-course-templates-dialog-status-organisation-in-progress"
							)}
							labelOnSuccess={l10n.getString(
								"grant-course-templates-dialog-status-organisation-granted"
							)}
						/>
					))}
				</List>
			</DialogContent>
		</>
	);
}

function Notice(props: {status: FetchStatus}) {
	return (
		<StatusInfoNotice
			status={props.status}
			labelOnPending={
				<Localized id="grant-course-templates-dialog-status-overall-in-progress">
					Please do not close the dialogue window while the operation is in
					progress, otherwise the use of templates would be granted to not all
					organisations.
				</Localized>
			}
			labelOnSuccess={
				<Localized id="grant-course-templates-dialog-status-overall-success">
					The use of the templates was successfully granted to all selected
					organisations.
				</Localized>
			}
			labelOnFailure={
				<Localized id="grant-course-templates-dialog-status-overall-failure">
					Unfortunately, not all requests have finished successfully. Please,
					try again.
				</Localized>
			}
		/>
	);
}
const StatusNotice = React.memo(Notice);

export default GrantCourseTemplatesDialog;
