import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Button,
	Divider,
	Grid,
	Typography,
	useTheme,
} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";

import TeacherCardDashboard from "./TeacherCardDashboard";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import UserCourse from "../../store/models/UserCourse";
import {
	getTeacherExam,
	getTeacherExamNext,
	getTeacherOrdinary,
	getTeacherOrdinaryNext,
	getTeacherPast,
	getTeacherPastNext,
	getTeacherStatistics,
} from "../../store/teacherDashboardSlice";
import LeftTabItem from "./LeftTabItem";
import {TeacherLeftTabName} from "./LeftTabName";
import EmptyState from "./EmptyState";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import useMobileMode from "../../hooks/useMobileMode";
import useCopyAction from "../courseCopying/useCopyAction";
import useSnackbar from "../../store/ui/useSnackbar";

function CourseStatistic(props: {title: string; value: number}) {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography variant="h3" color="secondary" component="span">
				{props.value}
			</Typography>

			<Typography variant="overline" color="textSecondary">
				{props.title}
			</Typography>
		</Box>
	);
}

const defaultPageSize = 24;

function TeacherDashboard(props: {organisationName: string}): JSX.Element {
	const theme = useTheme();
	const dispatch = useAppDispatch();

	const [selectedLeftTab, setSelectedLeftTab] = useState<TeacherLeftTabName>(
		TeacherLeftTabName.MyCourses
	);

	const [flagRequestTeacherExam, setFlagRequestTeacherExam] = useState(true);
	const [flagRequestTeacherPast, setFlagRequestTeacherPast] = useState(true);

	const {
		ordinary: ordinaryList,
		exam: examList,
		past: pastList,
	} = useAppSelector((state) => state.teacherdashboard);

	const {organisationName: orgName} = props;
	const userId = useAppSelector((store) => selectUserId(store));
	const {l10n} = useLocalization();

	const {
		nextOrdinary,
		nextExam,
		nextPast,
		numberOfCourses,
		numberOfTeachers,
		numberOfStudents,
		myCoursesValue,
		myExamsValue,
		myPastValue,
	} = useAppSelector((state) => state.teacherdashboard);

	const selectedTab = useCallback(
		(tabName: TeacherLeftTabName) => {
			return selectedLeftTab === tabName;
		},
		[selectedLeftTab]
	);

	const showSnackbar = useSnackbar();

	function reload() {
		let fetch;
		let n;

		switch (selectedLeftTab) {
			case TeacherLeftTabName.MyCourses:
				n = ordinaryList.length;
				fetch = getTeacherOrdinary;

				break;
			case TeacherLeftTabName.MyExams:
				n = examList.length;
				fetch = getTeacherExam;

				break;
			case TeacherLeftTabName.PastCourses:
				n = pastList.length;
				fetch = getTeacherPast;

				break;
		}

		dispatch(
			fetch({
				orgName,
				userId,
				pageSize: Math.ceil(n / defaultPageSize) * defaultPageSize,
				nextPageSize: defaultPageSize,
			})
		);

		dispatch(getTeacherStatistics({orgName, userId}));
	}

	const [copyCourse, copyCourseDialog] = useCopyAction(
		orgName,
		reload,
		() => showSnackbar("error", l10n.getString("error-general")),
		{autoAssignAsTeacher: true}
	);

	useEffect(() => {
		if (userId !== 0 && ordinaryList.length === 0) {
			dispatch(
				getTeacherOrdinary({orgName, userId, pageSize: defaultPageSize})
			);
		}
	}, [dispatch, ordinaryList.length, orgName, userId]);

	useEffect(() => {
		if (
			selectedTab(TeacherLeftTabName.MyExams) &&
			flagRequestTeacherExam &&
			examList.length === 0
		) {
			dispatch(getTeacherExam({orgName, userId, pageSize: defaultPageSize}));
			setFlagRequestTeacherExam(false);
		}
	}, [
		dispatch,
		examList.length,
		flagRequestTeacherExam,
		selectedTab,
		orgName,
		selectedLeftTab,
		userId,
	]);

	useEffect(() => {
		if (
			selectedTab(TeacherLeftTabName.PastCourses) &&
			flagRequestTeacherPast &&
			pastList.length === 0
		) {
			dispatch(getTeacherPast({orgName, userId, pageSize: defaultPageSize}));
			setFlagRequestTeacherPast(false);
		}
	}, [
		dispatch,
		flagRequestTeacherPast,
		selectedTab,
		orgName,
		pastList.length,
		selectedLeftTab,
		userId,
	]);

	useEffect(() => {
		if (userId !== 0) {
			dispatch(
				getTeacherStatistics({
					userId: userId,
					orgName,
				})
			);
		}
	}, [dispatch, orgName, userId]);

	const mobileMode = useMobileMode("sm");

	const getCoursesCards = (
		userCourses: UserCourse[],
		emptyStateMessage: string,
		onLoadMore?: () => void
	) => {
		if (userCourses.length === 0) {
			return (
				<Grid item xs={12}>
					<EmptyState message={emptyStateMessage} />
				</Grid>
			);
		}

		return (
			<>
				{userCourses.map((c) => (
					<Grid item xs={12} sm={6} md={4} xl={3} key={c.id}>
						<TeacherCardDashboard
							course={c}
							organisationName={orgName}
							userId={userId}
							onCopy={copyCourse}
						/>
					</Grid>
				))}

				{onLoadMore && (
					<Grid
						item
						xs={12}
						style={{display: "flex", justifyContent: "center"}}
					>
						<Button onClick={onLoadMore} color="primary">
							<Localized id="dashboard-load-more-btn">Load more</Localized>
						</Button>
					</Grid>
				)}
			</>
		);
	};

	const getCoursesCount = () => {
		switch (selectedLeftTab) {
			case TeacherLeftTabName.MyCourses:
				return myCoursesValue;
			case TeacherLeftTabName.MyExams:
				return myExamsValue;
			case TeacherLeftTabName.PastCourses:
				return myPastValue;
			default:
				return 0;
		}
	};

	const selectLeftTab = (tabName: TeacherLeftTabName) => () => {
		setSelectedLeftTab(tabName);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={3}>
					<LeftTabItem
						statisticValue={myCoursesValue}
						tabName={TeacherLeftTabName.MyCourses}
						selected={selectedTab(TeacherLeftTabName.MyCourses)}
						onClick={selectLeftTab(TeacherLeftTabName.MyCourses)}
					/>
					<LeftTabItem
						statisticValue={myExamsValue}
						tabName={TeacherLeftTabName.MyExams}
						selected={selectedTab(TeacherLeftTabName.MyExams)}
						onClick={selectLeftTab(TeacherLeftTabName.MyExams)}
					/>
					<LeftTabItem
						statisticValue={myPastValue}
						tabName={TeacherLeftTabName.PastCourses}
						selected={selectedTab(TeacherLeftTabName.PastCourses)}
						onClick={selectLeftTab(TeacherLeftTabName.PastCourses)}
					/>
				</Grid>

				<Grid item xs={9} container spacing={3}>
					{!mobileMode && (
						<>
							<Grid item xs={12} container justifyContent="space-evenly">
								<Grid item>
									<Localized
										id="dashboard-teacher-courses"
										attrs={{title: true}}
									>
										<CourseStatistic title="Courses" value={numberOfCourses} />
									</Localized>
								</Grid>

								<Grid item>
									<Localized
										id="dashboard-teacher-teachers"
										attrs={{title: true}}
									>
										<CourseStatistic
											title="Teachers"
											value={numberOfTeachers}
										/>
									</Localized>
								</Grid>

								<Grid item>
									<Localized
										id="dashboard-teacher-students"
										attrs={{title: true}}
									>
										<CourseStatistic
											title="Students"
											value={numberOfStudents}
										/>
									</Localized>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>
						</>
					)}

					<Grid item xs={12}>
						<Box display="flex" style={{gap: theme.spacing(1.25)}}>
							<Typography variant="h5">
								<Localized id={selectedLeftTab}>{selectedLeftTab}</Localized>
							</Typography>
							<Typography variant="h5" color="textSecondary">
								{getCoursesCount()}
							</Typography>
						</Box>
					</Grid>

					{selectedTab(TeacherLeftTabName.MyCourses) &&
						getCoursesCards(
							ordinaryList,
							l10n.getString(
								"dashboard-no-ongoing-or-upcoming-courses",
								null,
								"So far, you have no ongoing or upcoming courses"
							),
							nextOrdinary?.nextHref
								? () =>
										nextOrdinary?.nextHref &&
										dispatch(getTeacherOrdinaryNext(nextOrdinary.nextHref))
								: undefined
						)}

					{selectedTab(TeacherLeftTabName.MyExams) &&
						getCoursesCards(
							examList,
							l10n.getString(
								"dashboard-no-ongoing-or-upcoming-exams",
								null,
								"So far, you have no ongoing or upcoming exams"
							),
							nextExam?.nextHref
								? () =>
										nextExam?.nextHref &&
										dispatch(getTeacherExamNext(nextExam.nextHref))
								: undefined
						)}

					{selectedTab(TeacherLeftTabName.PastCourses) &&
						getCoursesCards(
							pastList,
							l10n.getString("dashboard-no-past-courses"),
							nextPast?.nextHref
								? () =>
										nextPast?.nextHref &&
										dispatch(getTeacherPastNext(nextPast.nextHref))
								: undefined
						)}
				</Grid>
			</Grid>

			{copyCourseDialog}
		</>
	);
}

export default TeacherDashboard;
