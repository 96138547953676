import {Localized} from "@fluent/react";
import React from "react";
import OpenExerciseIcon from "../../icons/OpenExerciseIcon";
import MultipleChoiceExerciseIcon from "../../icons/MultipleChoiceExerciseIcon";
import CodeIcon from "@material-ui/icons/Code";
import ExerciseType from "../../store/exercises/ExerciseType";
import ExtensionIcon from "@material-ui/icons/Extension";
import MathExerciseIcon from "../../icons/MathExerciseIcon";
import {
	Box,
	Theme,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import LightTooltip from "../../utils/LightTooltip";

export const exerciseTypes = [
	{
		name: ExerciseType.Open,
		displayName: (
			<Localized id="exercise-bank-type-label-open-exercise">
				Open exercise
			</Localized>
		),
		icon: <OpenExerciseIcon />,
	},
	{
		name: ExerciseType.Prog,
		displayName: (
			<Localized id="exercise-bank-type-label-programming-exercise">
				Programming exercise
			</Localized>
		),
		icon: <CodeIcon />,
	},
	{
		name: ExerciseType.Multi,
		displayName: (
			<Localized id="exercise-bank-type-label-multiple-choice-exercise">
				Multiple choice exercise
			</Localized>
		),
		icon: <MultipleChoiceExerciseIcon />,
	},
	{
		name: ExerciseType.Math,
		displayName: (
			<Localized id="exercise-bank-type-label-math-exercise">
				Math exercise
			</Localized>
		),
		icon: <MathExerciseIcon />,
	},
	{
		name: ExerciseType.External,
		displayName: (
			<Localized id="exercise-bank-type-label-external-exercise">
				External exercise
			</Localized>
		),
		icon: <ExtensionIcon />,
	},
];

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		exerciseTypeIcon: {
			color: theme.palette.action.active,
		},
	})
);

const ExerciseTypeLabel = ({
	type,
	size,
}: {
	type: ExerciseType;
	size?: "default" | "small";
}): JSX.Element => {
	const classes = useStyles();
	const currentType = exerciseTypes.find((t) => t.name === type);

	if (size === "small") {
		return (
			<LightTooltip
				title={
					<Typography variant="subtitle2">
						{currentType?.displayName}
					</Typography>
				}
				placement="right"
				enterDelay={500}
				enterNextDelay={500}
			>
				<Box display="flex" className={classes.exerciseTypeIcon}>
					{currentType?.icon}
				</Box>
			</LightTooltip>
		);
	}

	return (
		<Box display="flex" alignItems="center" whiteSpace="nowrap">
			<Box mr={1} display="flex" className={classes.exerciseTypeIcon}>
				{currentType?.icon}
			</Box>
			{currentType?.displayName}
		</Box>
	);
};

export default ExerciseTypeLabel;
