import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {createStyles, withStyles} from "@material-ui/styles";

const SuccessCircleIcon = withStyles((theme) =>
	createStyles({
		root: {
			color: theme.palette.success.main,
		},
	})
)(CheckCircleIcon);

export default SuccessCircleIcon;
