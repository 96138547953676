import clsx from "clsx";
import {
	Box,
	BoxProps,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles<
	Theme,
	{leftExtension?: number; rightExtension?: number}
>((theme) =>
	createStyles({
		content: {
			flexGrow: 1,
			marginLeft: 0,
			marginRight: 0,
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		leftMargin: {
			marginLeft: ({leftExtension}) =>
				leftExtension ? -leftExtension : undefined,
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		rightMargin: {
			marginRight: ({rightExtension}) =>
				rightExtension ? -rightExtension : undefined,
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
	})
);

const ExtendableBox = (
	props: {
		leftExtension?: number;
		rightExtension?: number;
	} & BoxProps
): JSX.Element => {
	const {leftExtension, rightExtension, ...box} = props;

	const classes = useStyles({leftExtension, rightExtension});

	return (
		<Box
			className={clsx(classes.content, {
				[classes.leftMargin]: leftExtension,
				[classes.rightMargin]: rightExtension,
			})}
			{...box}
		/>
	);
};

export default ExtendableBox;
