import type Feature from "./Feature";
import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

function selectGlobalFeatures(state: RootState): Feature[] {
	return state.features.global.features;
}

function selectGlobalFeaturesFetchStatus(state: RootState): FetchStatus {
	return state.features.global.status;
}

export default selectGlobalFeatures;
export {selectGlobalFeaturesFetchStatus};
