import {
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
	makeStyles,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, {useEffect, useMemo, useState} from "react";

import {useAppDispatch, useAppSelector} from "../../store/hooks";
import selectChapterContentStatus from "../../store/chapters/selectChapterContentStatus";
import {keyProvider} from "../../store/keyProvider";
import type ChapterProgress from "../../store/courseProgress/ChapterProgress";
import type ExerciseOutcomes from "../../store/courseProgress/ExerciseOutcomes";
import {getChapterContent} from "../../store/chapters/getChapterContent";
import chapterSessionsService from "../../store/services/chapterSessionsService";
import ContentItemTitle from "./ContentItemTitle";
import type {
	ChapterExercise,
	Section,
} from "../../store/services/dtos/ChapterContent";
import makeSelectChapterContent from "../../store/chapters/makeSelectChapterContent";
import ExerciseType from "../../store/exercises/ExerciseType";

const useRowStyles = makeStyles((theme) => ({
	root: {
		"& > td": {
			borderBottom: "unset",
		},
	},
	sectionTitle: {
		paddingLeft: theme.spacing(0.5),
	},
}));

function ProgressTableSections(props: {
	studentId: number;
	chapterId: number;
	courseId: number;
	chapterProgress: ChapterProgress;
	sessionRequired?: boolean;
	renderExerciseRow: (
		section: {
			id: number;
			number: number;
		},
		exercise: ChapterExercise,
		outcomes?: ExerciseOutcomes
	) => React.ReactElement;
}): JSX.Element {
	const {
		chapterId,
		courseId,
		studentId,
		chapterProgress,
		sessionRequired,
	} = props;

	const chapterKey = keyProvider.chapter(chapterId);

	const contentRequested = useAppSelector(
		(state) => selectChapterContentStatus(state, chapterKey) !== "none"
	);

	const selectChapterContent = useMemo(makeSelectChapterContent, []);

	const sections = useAppSelector((state) =>
		selectChapterContent(state, chapterKey)
	);

	const [sessionSections, setSessionSections] = useState<Section[]>([]);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!contentRequested && !sessionRequired) {
			dispatch(getChapterContent({courseId, chapterId}));
		}
	}, [chapterId, contentRequested, courseId, dispatch, sessionRequired]);

	useEffect(() => {
		if (sessionRequired && chapterProgress.startTime && studentId) {
			chapterSessionsService
				.getChapterSessionContent(
					studentId,
					courseId,
					chapterId,
					chapterProgress.startTime
				)
				.then((res) => setSessionSections(res.sections));
		}
	}, [
		chapterId,
		chapterProgress.startTime,
		courseId,
		sessionRequired,
		studentId,
	]);

	return (
		<Table>
			<TableBody>
				{(sessionRequired ? sessionSections : sections).map((section) => (
					<SectionRow
						key={section.id}
						section={section}
						outcomes={chapterProgress.outcomes}
						renderExerciseRow={props.renderExerciseRow}
					/>
				))}
			</TableBody>
		</Table>
	);
}

function SectionRow(props: {
	section: Section;
	outcomes: ExerciseOutcomes[];
	renderExerciseRow: (
		section: {
			id: number;
			number: number;
		},
		exercise: ChapterExercise,
		outcomes?: ExerciseOutcomes
	) => React.ReactElement;
}): JSX.Element {
	const classes = useRowStyles();

	const [open, setOpen] = useState(false);

	const {section} = props;

	const nonTheorySubsections = section.subsections.filter(
		(ss) => ss.type !== ExerciseType.Theory
	);

	const hasSubsections = nonTheorySubsections.length > 0;

	return (
		<>
			<TableRow
				className={classes.root}
				style={{cursor: hasSubsections ? "pointer" : "default"}}
				onClick={hasSubsections ? () => setOpen((prev) => !prev) : undefined}
				hover
			>
				<TableCell padding="checkbox">
					{hasSubsections && (
						<IconButton
							onClick={(e) => {
								e.stopPropagation();
								setOpen((prev) => !prev);
							}}
						>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</TableCell>
				<TableCell className={classes.sectionTitle}>
					<ContentItemTitle number={`${section.number}`} name={section.title} />
				</TableCell>
			</TableRow>
			{hasSubsections && (
				<TableRow className={classes.root}>
					<TableCell padding="checkbox" />
					<TableCell padding="none">
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Table>
								<TableBody>
									{nonTheorySubsections.map((exercise) =>
										props.renderExerciseRow(
											section,
											exercise,
											props.outcomes.find((o) => o.exerciseId === exercise.id)
										)
									)}
								</TableBody>
							</Table>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}

export default ProgressTableSections;
