import {Localized} from "@fluent/react";
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	Grid,
	Paper,
	Theme,
	Typography,
	createStyles,
	makeStyles,
	useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, {useState} from "react";
import type {ReactNode} from "react";

import parseDate from "../../helpers/parseDate";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import type Chapter from "../../store/chapters/Chapter";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		accordionActions: {
			padding: theme.spacing(2),
			"& > :not(:first-child)": {
				marginLeft: theme.spacing(2),
			},
		},
	})
);

const TestSummary = (props: {
	number: number;
	title: string;
	startDate?: string;
	endDate?: string;
	expandable?: boolean;
	expanded?: boolean;
}): JSX.Element => {
	const {number, title, startDate, endDate, expandable, expanded} = props;

	return (
		<>
			<Grid item xs={6}>
				<Typography variant="subtitle1" style={{fontWeight: 500}}>
					{`${number}. ${title}`}
				</Typography>
			</Grid>
			{expandable && !expanded && (
				<>
					<Grid item xs={3}>
						{startDate && (
							<Typography variant="caption" color="textSecondary">
								<Localized id="course-studying-start-time-label">
									Start time:
								</Localized>{" "}
								{startDate}
							</Typography>
						)}
					</Grid>
					<Grid item xs={3}>
						{endDate && (
							<Typography variant="caption" color="textSecondary">
								<Localized id="course-studying-end-time-label">
									End time:
								</Localized>{" "}
								{endDate}
							</Typography>
						)}
					</Grid>
				</>
			)}
		</>
	);
};

const TestDetails = (props: {
	startDate: string;
	endDate: string;
	timeLimit: number;
	sessionLimit: number;
	sessionsMade?: number;
	progress?: ReactNode;
}): JSX.Element => {
	const {startDate, endDate, timeLimit, sessionLimit, sessionsMade} = props;

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} md={6}>
				{startDate && (
					<DetailsRow
						description={
							<Localized id="course-studying-start-time-label">
								Start time:
							</Localized>
						}
						value={startDate}
					/>
				)}
				{endDate && (
					<DetailsRow
						description={
							<Localized id="course-studying-end-time-label">
								End time:
							</Localized>
						}
						value={endDate}
					/>
				)}
				{timeLimit > 0 && (
					<DetailsRow
						description={
							<Localized id="course-studying-time-limit-label">
								Time limit:
							</Localized>
						}
						value={
							<Localized
								id="course-studying-time-limit-value"
								vars={{timeLimit}}
							>
								{"'{$timeLimit}' min."}
							</Localized>
						}
					/>
				)}
				{sessionLimit > 0 && (
					<DetailsRow
						description={
							<Localized id="course-studying-attempts-label">
								Attempts:
							</Localized>
						}
						value={
							sessionsMade && sessionsMade > 0 ? (
								<Localized
									id="course-studying-attempts-value"
									vars={{
										attemptsLeft:
											sessionLimit > sessionsMade
												? sessionLimit - sessionsMade
												: 0,
										attemptLimit: sessionLimit,
									}}
								>
									{"{$sessionLeft} out of {$sessionLimit} left"}
								</Localized>
							) : (
								sessionLimit
							)
						}
					/>
				)}
			</Grid>
			<Grid item xs={12} md={6}>
				{props.progress}
			</Grid>
		</Grid>
	);
};

const TestLayout = (props: {
	test: Chapter;
	sessionsMade?: number;
	expandable?: boolean;
	onExpanded?: () => void;
	actions?: ReactNode;
	progress?: ReactNode;
}): JSX.Element => {
	const theme = useTheme();

	const formatDate = useDateTimeFormat();

	const {test, expandable, onExpanded, sessionsMade, actions, progress} = props;

	const classes = useStyles();

	const [expanded, setExpanded] = useState(false);

	const startDate = test.startDate ? formatDate(parseDate(test.startDate)) : "";
	const endDate = test.endDate ? formatDate(parseDate(test.endDate)) : "";

	const timeLimit = test.timeLimit ? Math.floor(test.timeLimit / 60) : 0;

	const sessionLimit = test.sessionLimit ?? 0;

	return (
		<>
			{expandable ? (
				<Accordion
					expanded={expanded}
					onChange={() => {
						setExpanded((prev) => !prev);
						onExpanded && onExpanded();
					}}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Grid container alignItems="baseline">
							<TestSummary
								number={test.number}
								title={test.title}
								startDate={startDate}
								endDate={endDate}
								expandable
								expanded={expanded}
							/>
						</Grid>
					</AccordionSummary>
					<AccordionDetails>
						<TestDetails
							startDate={startDate}
							endDate={endDate}
							sessionLimit={sessionLimit}
							timeLimit={timeLimit}
							sessionsMade={sessionsMade}
							progress={progress}
						/>
					</AccordionDetails>
					{actions && (
						<>
							<Divider />
							<AccordionActions className={classes.accordionActions}>
								{actions}
							</AccordionActions>
						</>
					)}
				</Accordion>
			) : (
				<Paper style={{width: "100%"}}>
					<Box display="flex" flexDirection="column" p={2}>
						<TestSummary number={test.number} title={test.title} expanded />
						<Box mt={2}>
							<TestDetails
								startDate={startDate}
								endDate={endDate}
								sessionLimit={sessionLimit}
								timeLimit={timeLimit}
								sessionsMade={sessionsMade}
								progress={progress}
							/>
						</Box>
					</Box>
					{actions && (
						<>
							<Divider />
							<Box
								display="flex"
								p={2}
								justifyContent="end"
								style={{gap: theme.spacing(2)}}
							>
								{actions}
							</Box>
						</>
					)}
				</Paper>
			)}
		</>
	);
};

function DetailsRow(props: {description: ReactNode; value: ReactNode}) {
	return (
		<Box display="flex" mb={1} color="text.secondary">
			<Typography variant="body2" style={{flexBasis: "25%"}}>
				{props.description}
			</Typography>
			<Typography variant="body2">{props.value}</Typography>
		</Box>
	);
}

export default TestLayout;
