import {Localized} from "@fluent/react";
import {Box, Chip, createStyles, makeStyles} from "@material-ui/core";
import React from "react";

import type UserRole from "../../store/models/UserRole";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"& $chip": {
				margin: theme.spacing(0.5),
			},
			"& $chip:first-child": {
				marginLeft: 0,
			},
		},
		chip: {},
	})
);

function UserRoleChips(props: {roles: UserRole[]; align?: "right"}) {
	const classes = useStyles();

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			justifyContent={props.align === "right" ? "flex-end" : undefined}
			className={classes.root}
		>
			{props.roles.map((r, i) => (
				<Chip
					key={i}
					label={
						<Localized id="user-role-chips-role" vars={{role: r}}>
							{r}
						</Localized>
					}
					className={classes.chip}
					variant="outlined"
					size="small"
				/>
			))}
		</Box>
	);
}

export default UserRoleChips;
