import {Localized} from "@fluent/react";
import ErrorIcon from "@mui/icons-material/Error";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	Paper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, {useEffect, useState} from "react";

import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function NewTemplateDialog(props: {
	courseName: string;
	titleElementId: string;
	onCreate: (templateName: string) => Promise<void>;
	onCancel: () => void;
	ensureCanCreate: () => Promise<string | null>;
}) {
	const [name, setName] = useState(props.courseName);
	const [empty, setEmpty] = useState(false);

	const [error, setError] = useState<string | null>(null);

	const [inProgress, setInProgress] = useState(false);
	const [checkInProgress, setCheckInProgress] = useState(true);

	const theme = useTheme();

	const {ensureCanCreate} = props;

	useEffect(() => {
		ensureCanCreate()
			.then(setError)
			.finally(() => setCheckInProgress(false));
	}, [ensureCanCreate]);

	async function create() {
		if (error) {
			return;
		}

		if (name === "") {
			setEmpty(true);
			return;
		}

		setInProgress(true);

		await props.onCreate(name);

		setInProgress(false);
	}

	return (
		<>
			<WindowedDialogTitle
				id={props.titleElementId}
				title={
					<Localized id="courses-new-template-dialog-title">
						New template
					</Localized>
				}
				onClose={props.onCancel}
			/>

			<DialogContent dividers>
				{error && (
					<Paper
						style={{padding: theme.spacing(2), marginBottom: theme.spacing(2)}}
					>
						<Box
							display="flex"
							alignItems="center"
							style={{gap: theme.spacing(2)}}
						>
							<ErrorIcon color="error" />
							<Typography align="justify">{error}</Typography>
						</Box>
					</Paper>
				)}

				<Localized
					id="courses-new-template-dialog-descr-start"
					vars={{courseName: props.courseName}}
					elems={{
						paragraph: <DialogContentText align="justify"></DialogContentText>,
					}}
				>
					<>
						You are going to create a template based on course $
						{props.courseName}. Template is a snapshot of the course structure
						and some settings. It contains:
					</>
				</Localized>
				<DialogContentText align="justify" component="ul">
					<Localized
						id="courses-new-template-dialog-descr-list"
						elems={{listitem: <li></li>}}
					>
						<>
							names and order of chapters, their structure (order of sections,
							exercises and theory blocks) and settings; settings of sections
							and exercises, e.g. random selection settings, answer visibility,
							max score; course settings (only answer visibility).
						</>
					</Localized>
				</DialogContentText>
				<DialogContentText align="justify">
					<Localized
						id="courses-new-template-dialog-descr-end"
						elems={{bold: <span style={{fontWeight: 500}}></span>}}
					>
						<>
							Because the template is a snapshot of the structure and settings,
							any changes made to the structure or settings of its base course
							are not propagated to the template or any courses created based on
							this template. However, any changes to exercises or theory content
							are reflected. Ensure that the course structure is fully ready
							before template creation.
						</>
					</Localized>
				</DialogContentText>
				<DialogContentText align="justify">
					<Localized id="courses-new-template-dialog-descr-ask-name">
						Please specify a name for the new template below.
					</Localized>
				</DialogContentText>
				<TextField
					value={name}
					required
					fullWidth
					error={empty}
					label={
						<Localized id="courses-new-template-dialog-name">
							Template name
						</Localized>
					}
					onChange={(e) => {
						setEmpty(false);
						setName(e.currentTarget.value);
					}}
				/>
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					<Localized id="courses-new-template-dialog-action-cancel">
						Cancel
					</Localized>
				</Button>
				<SubmitButton
					inProgress={inProgress}
					variant="text"
					disabled={Boolean(error) || checkInProgress}
					onClick={create}
				>
					<Localized id="courses-new-template-dialog-action-create">
						Create
					</Localized>
				</SubmitButton>
			</DialogActions>
		</>
	);
}

export default NewTemplateDialog;
