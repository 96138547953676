import {Localized} from "@fluent/react";
import {Box, TextField, useTheme} from "@material-ui/core";
import React from "react";

import CourseStatusSelector from "./CourseStatusSelector";
import CourseTypeSelector from "./CourseTypeSelector";
import useStateWithDebounce from "../../hooks/useStateWithDebounce";
import OrganisationSelector from "../organisations/OrganisationSelector";
import SidebarBody from "../sidebars/SidebarBody";
import SidebarHeader from "../sidebars/SidebarHeader";
import SidebarSection from "../sidebars/SidebarSection";
import type {OrganisationGroupCourseSearchCriteria} from "../../store/services/courseService";
import AppBarDependentDrawer from "../../utils/AppBarDependentDrawer";
import ContentLanguageSelector from "../../utils/ContentLanguageSelector";

function GroupCourseFilters(props: {
	groupName: string;

	open: boolean;
	width: number | string;
	mobileMode: boolean;

	criteria: OrganisationGroupCourseSearchCriteria;
	onCriteriaChange: (
		set: (
			prev: OrganisationGroupCourseSearchCriteria
		) => OrganisationGroupCourseSearchCriteria
	) => void;

	onClose: () => void;
}) {
	const {onCriteriaChange} = props;

	const theme = useTheme();

	const [criteria, setCriteria] = useStateWithDebounce(
		props.criteria,
		onCriteriaChange
	);

	return (
		<AppBarDependentDrawer
			anchor="right"
			open={props.open}
			variant={props.mobileMode ? "temporary" : "persistent"}
			width={props.width}
			topFixedPanel={
				<SidebarHeader
					title={<Localized id="group-courses-filters">Filters</Localized>}
					onClose={props.onClose}
				/>
			}
			onClose={props.onClose}
		>
			<SidebarBody>
				<SidebarSection
					title={
						<Localized id="group-courses-filters-organisations">
							Organisations
						</Localized>
					}
				>
					<OrganisationSelector
						groupName={props.groupName}
						onChange={(organisationNames) =>
							setCriteria((prev) => ({...prev, organisationNames}))
						}
					/>
				</SidebarSection>

				<SidebarSection title={""}>
					<CourseTypeSelector
						value={criteria.type}
						onChange={(type) => setCriteria((prev) => ({...prev, type}))}
					/>
				</SidebarSection>

				<SidebarSection title="">
					<ContentLanguageSelector
						value={criteria.language ?? ""}
						withAny
						onChange={(val) =>
							setCriteria((prev) => ({...prev, language: val}))
						}
					/>
				</SidebarSection>

				<SidebarSection
					title={<Localized id="group-courses-status">Status</Localized>}
				>
					<CourseStatusSelector
						dates={criteria}
						onChange={(dates) => setCriteria((prev) => ({...prev, ...dates}))}
					/>
				</SidebarSection>

				<SidebarSection
					title={
						<Localized id="group-courses-table-label-start-date">
							Start date
						</Localized>
					}
				>
					<Box display="flex">
						<TextField
							fullWidth
							type="date"
							label={<Localized id="group-courses-date-from">From</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							style={{
								marginRight: theme.spacing(1),
							}}
							value={criteria.startDateAfter ?? ""}
							onChange={({target}) => {
								setCriteria((prev) => ({
									...prev,
									startDateAfter: target.value ?? undefined,
								}));
							}}
							InputProps={{inputProps: {max: criteria.startDateBefore}}}
						/>
						<TextField
							fullWidth
							type="date"
							label={<Localized id="group-courses-date-to">To</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							value={criteria.startDateBefore ?? ""}
							onChange={({target}) => {
								setCriteria((prev) => ({
									...prev,
									startDateBefore: target.value ?? undefined,
								}));
							}}
							InputProps={{inputProps: {min: criteria.startDateAfter}}}
						/>
					</Box>
				</SidebarSection>

				<SidebarSection
					title={
						<Localized id="group-courses-table-label-end-date">
							End date
						</Localized>
					}
				>
					<Box display="flex">
						<TextField
							fullWidth
							type="date"
							label={<Localized id="group-courses-date-from">From</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							style={{
								marginRight: theme.spacing(1),
							}}
							value={criteria.endDateAfter ?? ""}
							onChange={({target}) => {
								setCriteria((prev) => ({
									...prev,
									endDateAfter: target.value ?? undefined,
								}));
							}}
							InputProps={{inputProps: {max: criteria.endDateBefore}}}
						/>
						<TextField
							fullWidth
							type="date"
							label={<Localized id="group-courses-date-to">To</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							value={criteria.endDateBefore ?? ""}
							onChange={({target}) => {
								setCriteria((prev) => ({
									...prev,
									endDateBefore: target.value ?? undefined,
								}));
							}}
							InputProps={{inputProps: {min: criteria.endDateAfter}}}
						/>
					</Box>
				</SidebarSection>
			</SidebarBody>
		</AppBarDependentDrawer>
	);
}

export default GroupCourseFilters;
