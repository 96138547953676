import {createAsyncThunk} from "@reduxjs/toolkit";
import {courseService} from "../services/courseService";

const fetchCoursesAvailableForEnrolment = createAsyncThunk(
	"availableCourses/fetchCoursesAvailableForEnrolment",
	async (params: {orgName: string; userId?: number}) => {
		const courses = await courseService.getCoursesAvailableForEnrolment(
			params.orgName,
			params.userId
		);

		return {courses, userId: params.userId};
	}
);

export default fetchCoursesAvailableForEnrolment;
