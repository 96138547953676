import {Localized} from "@fluent/react";
import {Box} from "@material-ui/core";
import React from "react";

import useMessageData from "./useMessageData";
import StudentMessagesTab from "./StudentMessagesTab";
import {useAppSelector} from "../../store/hooks";
import useAnswerFileLoader from "./useAnswerFileLoader";
import useQuestionFileLoader from "./useQuestionFileLoader";
import Subject from "./Subject";
import Conversation from "./Conversation";
import ScrollableContainer from "../../utils/ScrollableContainer";
import selectUserProfile from "../../store/userProfile/selectUserProfile";
import selectSentByUser from "../../store/tutorQuestions/selectSentByUser";
import selectAnsweredForUser from "../../store/tutorQuestions/selectAnsweredForUser";
import MessageNavigation from "./MessageNavigation";

const StudentMessage = (props: {
	currentMessage: string | null;
	selectedTab: StudentMessagesTab;
	courseId: number;
	studentId: number;
	mobileMode?: boolean;
}): JSX.Element => {
	const {currentMessage, selectedTab, courseId} = props;

	const {message, chapter, section, exercise} = useMessageData(
		currentMessage,
		courseId
	);

	const {userName, id: userId} = useAppSelector(selectUserProfile);

	const questionFileLoader = useQuestionFileLoader(
		courseId,
		message?.id,
		userId
	);

	const answerFileLoader = useAnswerFileLoader(
		courseId,
		message?.id,
		message?.tutorId
	);

	const messageKeys = useAppSelector((state) =>
		selectedTab === StudentMessagesTab.Sent
			? selectSentByUser(state, props.studentId)
			: selectAnsweredForUser(state, props.studentId)
	);

	let link;
	let subject: string | React.ReactNode = (
		<Localized id="tutoring-subject-general-question">
			General question
		</Localized>
	);
	if (message && message.chapterId) {
		subject = `${chapter?.title} / ${section?.title} / ${exercise?.title}`;
		link = `chapters/${message.chapterId}/sections/${section?.id}#subsection-${message.exerciseId}`;
	}

	return (
		<ScrollableContainer
			header={
				<Box display="flex" px={3} pt={2} pb={1}>
					<Subject text={subject} link={link} />
					{props.mobileMode && (
						<MessageNavigation
							messageKeys={messageKeys}
							current={currentMessage}
						/>
					)}
				</Box>
			}
		>
			<Conversation
				expandedByDefault={
					selectedTab === StudentMessagesTab.Answered ? "answer" : "question"
				}
				message={message}
				studentName={userName}
				tutorName={message?.tutorName}
				questionFileLoader={questionFileLoader}
				answerFileLoader={answerFileLoader}
			/>
		</ScrollableContainer>
	);
};

export default StudentMessage;
