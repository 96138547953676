import React, {ReactNode} from "react";
import {Divider, FormControl} from "@material-ui/core";
import {Theme, makeStyles} from "@material-ui/core/styles";
import SubmitButton from "../../../utils/SubmitButton";
import SubmitResultCard from "./SubmitResultCard";

const useStyles = makeStyles((theme: Theme) => ({
	button: {
		marginTop: theme.spacing(3),
		alignSelf: "flex-end",
	},
	divider: {
		marginTop: theme.spacing(4),
	},
	resultInfo: {
		marginTop: theme.spacing(2),
	},
}));

type Props = {
	onSubmit?: () => void;
	submitDisabled?: boolean;
	submitHidden?: boolean;
	submitting?: boolean;

	children: ReactNode | ReactNode[];

	submitSummary?: {
		success: boolean;
		text: string;
	} | null;
};

const SubmitableResponseArea = (props: Props): JSX.Element => {
	const classes = useStyles();

	const buttonAndDivider = (
		<>
			{props.onSubmit && (
				<>
					<Divider className={classes.divider} />
					<SubmitButton
						className={classes.button}
						disabled={props.submitDisabled}
						onClick={props.onSubmit}
						inProgress={props.submitting}
					/>
				</>
			)}
		</>
	);

	let resultCard = <></>;
	if (props.submitSummary) {
		resultCard = (
			<SubmitResultCard
				success={props.submitSummary.success}
				className={classes.resultInfo}
				text={props.submitSummary.text}
			/>
		);
	}

	const formElements = (
		<>
			{resultCard}
			{!props.submitSummary?.success && buttonAndDivider}
		</>
	);

	return (
		<FormControl fullWidth>
			{props.children}
			{formElements}
		</FormControl>
	);
};

export default SubmitableResponseArea;
