import {
	Box,
	DialogTitle,
	IconButton,
	Typography,
	useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import React from "react";

function WindowedDialogTitle(props: {
	id: string;
	title: React.ReactNode;
	inProgress?: boolean;
	onClose: () => void;
	onGoBack?: () => void;
}): JSX.Element {
	const theme = useTheme();

	return (
		<DialogTitle id={props.id}>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				mr={-1.5}
			>
				<Box display="flex" alignItems="center" style={{gap: theme.spacing(1)}}>
					{props.onGoBack && (
						<IconButton disabled={props.inProgress} onClick={props.onGoBack}>
							<KeyboardBackspaceIcon />
						</IconButton>
					)}
					<Typography variant="h6">{props.title}</Typography>
				</Box>
				<IconButton onClick={props.onClose} disabled={props.inProgress}>
					<CloseIcon />
				</IconButton>
			</Box>
		</DialogTitle>
	);
}

export default WindowedDialogTitle;
