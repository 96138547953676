import {useLocalization} from "@fluent/react";
import {
	InputBase,
	Paper,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";

const useStyles = makeStyles<Theme, {fullWidth?: boolean}>((theme) =>
	createStyles({
		root: {
			padding: "2px 4px",
			display: "flex",
			alignItems: "center",
			width: ({fullWidth}) => (fullWidth ? "100%" : undefined),
		},
		input: {
			marginLeft: theme.spacing(1),
			flex: 1,
		},
		icon: {
			margin: 10,
		},
	})
);

const OutlinedSearchInput = (
	props: {
		value: string;
		endAdornment?: React.ReactNode;
		fullWidth?: boolean;
		autofocus?: boolean;
		placeholder?: string;
		onChange: (val: string) => void;
	},
	ref: React.ForwardedRef<unknown>
): JSX.Element => {
	const {autofocus, fullWidth} = props;

	const classes = useStyles({fullWidth});

	const {l10n} = useLocalization();

	return (
		<Paper className={classes.root} ref={ref} square>
			<SearchIcon className={classes.icon} />
			<InputBase
				className={classes.input}
				placeholder={
					props.placeholder ??
					l10n.getString("utils-search-input-placeholder", null, "Search")
				}
				value={props.value}
				onChange={({target}) => props.onChange(target.value)}
				autoFocus={autofocus}
				fullWidth={fullWidth}
			/>
			{props.endAdornment}
		</Paper>
	);
};

export default React.forwardRef(OutlinedSearchInput);
