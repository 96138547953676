import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {courseService} from "./services/courseService";
import UserCourse from "./models/UserCourse";
import {Statistic, statisticsService} from "./services/statisticsService";
import UserRole from "./models/UserRole";
import Link from "./models/Link";
import changeCoursePicture from "./courses/changeCoursePicture";
import {setCoursePicture} from "../helpers/storeHelper";
import createCourse from "./courses/createCourse";
import deleteCoursePicture from "./courses/deleteCoursePicture";
import type RoleInCourse from "./courses/RoleInCourse";
import deleteCourses from "./courses/deleteCourses";

interface TeacherDashboardState {
	ordinary: UserCourse[];
	exam: UserCourse[];
	past: UserCourse[];
	myCoursesValue: number;
	myExamsValue: number;
	myPastValue: number;
	nextOrdinary: {nextHref?: string};
	nextExam: {nextHref?: string};
	nextPast: {nextHref?: string};
	numberOfCourses: number;
	numberOfStudents: number;
	numberOfTeachers: number;
}

interface Params {
	orgName: string;
	userId: number;
	pageSize: number;
	nextPageSize?: number;
}

const initialState: TeacherDashboardState = {
	ordinary: [],
	exam: [],
	past: [],
	myCoursesValue: 0,
	myExamsValue: 0,
	myPastValue: 0,
	nextOrdinary: {},
	nextExam: {},
	nextPast: {},
	numberOfCourses: 0,
	numberOfStudents: 0,
	numberOfTeachers: 0,
};

function setPageSize(link: string, pageSize: number) {
	const url = new URL(link);
	url.searchParams.set("pageSize", pageSize.toString());
	return url.toString();
}

async function fetchCourses(
	params: Params,
	criteria: {type?: string; status: string[]; userRole: RoleInCourse[]}
) {
	const res = await courseService.getUserCourses(
		params.orgName,
		params.userId,
		{...criteria, pageSize: params.pageSize}
	);

	if (params.nextPageSize) {
		const i = res.links.findIndex((l) => l.rel === "next");
		if (i >= 0) {
			res.links[i].href = setPageSize(res.links[i].href, params.nextPageSize);
		}
	}

	return res;
}

export const getTeacherOrdinary = createAsyncThunk(
	"teacherdashboard/getTeacherOrdinary",
	(params: Params) =>
		fetchCourses(params, {
			type: "ordinary",
			status: ["ongoing", "upcoming"],
			userRole: [UserRole.Teacher, UserRole.Tutor],
		})
);

export const getTeacherOrdinaryNext = createAsyncThunk(
	"teacherdashboard/getTeacherOrdinaryNext",
	courseService.loadMoreUserCourses
);

export const getTeacherExam = createAsyncThunk(
	"teacherdashboard/getTeacherExam",
	(params: Params) =>
		fetchCourses(params, {
			type: "exam",
			status: ["ongoing", "upcoming"],
			userRole: [UserRole.Teacher, UserRole.Tutor],
		})
);

export const getTeacherExamNext = createAsyncThunk(
	"teacherdashboard/getTeacherExamNext",
	courseService.loadMoreUserCourses
);

export const getTeacherPast = createAsyncThunk(
	"teacherdashboard/getTeacherPast",
	(params: Params) =>
		fetchCourses(params, {
			status: ["past"],
			userRole: [UserRole.Teacher, UserRole.Tutor],
		})
);

export const getTeacherPastNext = createAsyncThunk(
	"teacherdashboard/getTeacherPastNext",
	courseService.loadMoreUserCourses
);

export const getTeacherStatistics = createAsyncThunk(
	"teacherdashboard/getTeacherStatistics",
	async (params: {userId: number; orgName: string}) => {
		const teacherStatistics = [
			Statistic.NumberOfExams,
			Statistic.NumberOfPastCourses,
			Statistic.NumberOfPastExams,
			Statistic.NumberOfCourses,
			Statistic.NumberOfStudents,
			Statistic.NumberOfTeachers,
		] as const;
		type TeacherStatistics = typeof teacherStatistics[number];

		const stats = await statisticsService.getUserStatistics(
			params.userId,
			params.orgName,
			teacherStatistics,
			[UserRole.Teacher, UserRole.Tutor]
		);

		return stats as {
			[name in TeacherStatistics]: number;
		};
	}
);

export const teacherDashboardSlice = createSlice({
	name: "teacherdashboard",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getTeacherOrdinary.fulfilled, (state, action) => {
			state.ordinary = action.payload.content;
			state.nextOrdinary = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next"
					? (state.nextOrdinary = {nextHref: link.href})
					: null
			);
		});
		builder.addCase(getTeacherOrdinaryNext.fulfilled, (state, action) => {
			state.ordinary = state.ordinary.concat(action.payload.content);
			state.nextOrdinary = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next"
					? (state.nextOrdinary = {nextHref: link.href})
					: null
			);
		});
		builder.addCase(getTeacherExam.fulfilled, (state, action) => {
			state.exam = action.payload.content;
			state.nextExam = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next" ? (state.nextExam = {nextHref: link.href}) : null
			);
		});
		builder.addCase(getTeacherExamNext.fulfilled, (state, action) => {
			state.exam = state.exam.concat(action.payload.content);
			state.nextExam = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next" ? (state.nextExam = {nextHref: link.href}) : null
			);
		});
		builder.addCase(getTeacherPast.fulfilled, (state, action) => {
			state.past = action.payload.content;
			state.nextPast = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next" ? (state.nextPast = {nextHref: link.href}) : null
			);
		});
		builder.addCase(getTeacherPastNext.fulfilled, (state, action) => {
			state.past = state.past.concat(action.payload.content);
			state.nextPast = {};
			action.payload.links.forEach((link: Link) =>
				link.rel === "next" ? (state.nextPast = {nextHref: link.href}) : null
			);
		});
		builder.addCase(getTeacherStatistics.fulfilled, (state, action) => {
			state.myCoursesValue =
				action.payload[Statistic.NumberOfCourses] -
				action.payload[Statistic.NumberOfPastCourses] -
				(action.payload[Statistic.NumberOfExams] -
					action.payload[Statistic.NumberOfPastExams]);
			state.myExamsValue =
				action.payload[Statistic.NumberOfExams] -
				action.payload[Statistic.NumberOfPastExams];
			state.myPastValue = action.payload[Statistic.NumberOfPastCourses];
			state.numberOfCourses = action.payload[Statistic.NumberOfCourses];
			state.numberOfStudents = action.payload[Statistic.NumberOfStudents];
			state.numberOfTeachers = action.payload[Statistic.NumberOfTeachers];
		});
		builder.addCase(changeCoursePicture.fulfilled, (state, action) => {
			const {courseId, picture} = action.payload;
			setCoursePicture(state.ordinary, courseId, picture);
			setCoursePicture(state.past, courseId, picture);
			setCoursePicture(state.exam, courseId, picture);
		});
		builder.addCase(deleteCoursePicture.fulfilled, (state, action) => {
			const {courseId} = action.payload;
			setCoursePicture(state.ordinary, courseId);
			setCoursePicture(state.past, courseId);
			setCoursePicture(state.exam, courseId);
		});
		builder.addCase(createCourse.fulfilled, () => {
			return initialState;
		});

		builder.addCase(deleteCourses.fulfilled, () => {
			return initialState;
		});
	},
});

export const teacherDashboardActions = teacherDashboardSlice.actions;

export default teacherDashboardSlice.reducer;
