import {
	ListItem,
	ListItemIcon,
	ListItemText,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"&, .MuiListItemIcon-root": {
				color: (p: {active: boolean}) =>
					p.active
						? theme.palette.secondary.main
						: theme.palette.text.secondary,
			},
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
		},
		oneLine: {
			display: "flex",
			justifyContent: "space-between",
		},
	})
);

const NavigationItem = (props: {
	icon: React.ReactNode;
	label: React.ReactNode;
	messageCount: number;
	active: boolean;
	onClick: () => void;
}): JSX.Element => {
	const classes = useStyles({active: props.active});

	return (
		<ListItem button onClick={props.onClick} className={classes.root}>
			<ListItemIcon>{props.icon}</ListItemIcon>
			<ListItemText
				primary={props.label}
				primaryTypographyProps={{variant: "subtitle2"}}
				secondary={props.messageCount}
				secondaryTypographyProps={{variant: "body2"}}
				classes={{multiline: classes.oneLine}}
			/>
		</ListItem>
	);
};

export default NavigationItem;
