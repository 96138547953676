import {createStyles, makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: "flex",
			margin: theme.spacing(1, 3, 3),
		},
	})
);

const WidgetBody = (props: {
	children: React.ReactNode;
	style?: React.CSSProperties;
}): JSX.Element => {
	const classes = useStyles();

	return (
		<div className={classes.root} style={props.style}>
			{props.children}
		</div>
	);
};

export default WidgetBody;
