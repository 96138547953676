import {Localized, useLocalization} from "@fluent/react";
import {Box, Button, Grid, TextField, useTheme} from "@material-ui/core";
import {SerializedError, unwrapResult} from "@reduxjs/toolkit";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

import AnswerVisibilitySelector from "./AnswerVisibilitySelector";
import ExerciseEditorPlaceholder from "./ExerciseEditorPlaceholder";
import ExternalExerciseEditor from "./ExternalExerciseEditor";
import MathExerciseEditor from "./MathExerciseEditor";
import MultiExerciseEditor from "./MultiExerciseEditor";
import OpenExerciseEditor from "./OpenExerciseEditor";
import TheoryExerciseEditor from "./TheoryExerciseEditor";
import ExercisePlaceholder from "../content/ExercisePlaceholder";
import ExerciseQuestion from "../content/exercises/ExerciseQuestion";
import ExerciseTitle from "../content/exercises/ExerciseTitle";
import ExternalResponseArea from "../content/exercises/external/ResponseArea";
import MathSolutionForm from "../content/exercises/math/MathSolutionForm";
import MultiResponseAreaSelector from "../content/exercises/multi/MultiResponseAreaSelector";
import OpenResponseArea from "../content/exercises/open/ResponseArea";
import ProgResponseArea from "../content/exercises/prog/ResponseArea";
import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import AnswerVisibility from "../../store/chapterExercises/AnswerVisibility";
import {makeSelectChapterExerciseWithContent} from "../../store/chapterExercises/makeSelectChapterExercise";
import removeExercise from "../../store/chapterExercises/removeExercise";
import updateChapterExerciseSettings from "../../store/chapterExercises/updateChapterExerciseSettings";
import selectChapter from "../../store/chapters/selectChapter";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import selectCourse from "../../store/courses/selectCourse";
import ExerciseType from "../../store/exercises/ExerciseType";
import {
	DraftType,
	editingEnded,
	editingStarted,
} from "../../store/exercises/exericseDraftSlice";
import {saveExerciseDraft} from "../../store/exercises/saveExerciseDraft";
import selectExerciseDraft from "../../store/exercises/selectExerciseDraft";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import type {
	EditableExercise,
	ProgExercise,
} from "../../store/services/dtos/EditableExercise";
import exerciseService from "../../store/services/exerciseService";
import type Feedback from "../../store/studentResponses/Feedback";
import type SubmissionResult from "../../store/studentResponses/SubmissionResult";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import ProgExerciseEditor from "./ProgExerciseEditor";
import AnswerButton from "../sidebars/exerciseAnswers/AnswerButton";
import buildProgExercise from "../../store/exercises/prog/buildExercise";
import exampleOutputChanged from "../../store/exercises/prog/exampleOutputChanged";
import {keyProvider} from "../../store/keyProvider";

type Props = {
	organisationName: string;
	exerciseKey: string;
	courseId: number;
	section: {
		number: number;
		id: number;
		selectionSize: number;
	};
	submissionMode: SubmissionMode;
	deletionDisabled?: boolean;
	settingsEditingDisabled?: boolean;
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
	showSnackbar: ShowSnackbar;
};

const LearningMaterialExerciseContent = (props: Props): JSX.Element => {
	const {courseId, section, exerciseKey, showSnackbar} = props;

	const dispatch = useAppDispatch();
	const theme = useTheme();
	const {l10n} = useLocalization();

	const selectExercise = useMemo(makeSelectChapterExerciseWithContent, []);

	const exercise = useAppSelector((state) =>
		selectExercise(state, exerciseKey)
	);
	const exerciseId = exercise?.id;

	const chapterId = exercise?.chapterId;

	const [exerciseMaxScore, setExerciseMaxScore] = useState(
		exercise?.maxScore ?? 0
	);
	const [exerciseAnswerVisibility, setExerciseAnswerVisibility] = useState<
		AnswerVisibility | ""
	>(exercise?.answerVisibility ?? "");

	const savedExerciseSettings = useRef<{
		maxScore: number;
		answerVisibility: AnswerVisibility | "";
	}>({answerVisibility: "", maxScore: 0});

	useEffect(() => {
		setExerciseMaxScore(exercise?.maxScore ?? 0);
		setExerciseAnswerVisibility(exercise?.answerVisibility ?? "");

		savedExerciseSettings.current = {
			answerVisibility: exercise?.answerVisibility ?? "",
			maxScore: exercise?.maxScore ?? 0,
		};
	}, [exercise?.maxScore, exercise?.answerVisibility]);

	const courseKey = keyProvider.course(courseId);

	const courseAnswerVisibility = useAppSelector(
		(state) =>
			selectCourse(state, courseKey)?.answerVisibility ?? AnswerVisibility.Never
	);

	const chapterKey = chapterId && keyProvider.chapter(chapterId);

	const chapterAnswerVisibility = useAppSelector(
		(state) =>
			(chapterKey && selectChapter(state, chapterKey)?.answerVisibility) ||
			courseAnswerVisibility
	);

	const courseType = useAppSelector(
		(state) => selectCourse(state, courseKey)?.type
	);

	const patchExerciseSettings = useCallback(
		async (settings: {
			maxScore?: number;
			answerVisibility?: AnswerVisibility | null;
		}) => {
			if (!chapterId || !exerciseId) {
				return;
			}

			function revertChange() {
				if ("answerVisibility" in settings) {
					setExerciseAnswerVisibility(
						savedExerciseSettings.current.answerVisibility
					);
				}
				if ("maxScore" in settings) {
					setExerciseMaxScore(savedExerciseSettings.current.maxScore);
				}
			}

			const res = await dispatch(
				updateChapterExerciseSettings({
					courseId,
					chapterId,
					exerciseId,
					settings,
				})
			);

			try {
				unwrapResult(res);
			} catch (error) {
				showSnackbar("error", l10n.getString("error-general"));
				revertChange();
			}
		},
		[courseId, chapterId, exerciseId, dispatch, l10n, showSnackbar]
	);

	const maxScoreDirty = useRef(false);
	useEffect(() => {
		if (!maxScoreDirty.current) {
			return noop;
		}
		const id = setTimeout(() => {
			maxScoreDirty.current = false;
			patchExerciseSettings({maxScore: exerciseMaxScore});
		}, 500);
		return () => clearTimeout(id);
	}, [patchExerciseSettings, exerciseMaxScore]);

	const updateMaxScore = (value: string) => {
		const maxScoreValue = parseInt(value);
		if (maxScoreValue >= 0 && maxScoreValue !== exerciseMaxScore) {
			setExerciseMaxScore(maxScoreValue);
			maxScoreDirty.current = true;
		}
	};

	const answerVisibilityDirty = useRef(false);
	useEffect(() => {
		if (!answerVisibilityDirty.current) {
			return noop;
		}
		const id = setTimeout(() => {
			answerVisibilityDirty.current = false;
			patchExerciseSettings({
				answerVisibility:
					exerciseAnswerVisibility !== "" ? exerciseAnswerVisibility : null,
			});
		}, 500);
		return () => clearTimeout(id);
	}, [patchExerciseSettings, exerciseAnswerVisibility]);

	const updateAnswerVisibility = (value: AnswerVisibility | "") => {
		if (value !== exerciseAnswerVisibility) {
			setExerciseAnswerVisibility(value);
			answerVisibilityDirty.current = true;
		}
	};

	const [
		editableExercise,
		setEditableExercise,
	] = useState<EditableExercise | null>(null);

	const userId = useAppSelector(selectUserId);

	const draft = useAppSelector(selectExerciseDraft);

	const [editorOpen, setEditorOpen] = useState(false);

	useEffect(() => {
		if (editorOpen && exerciseId && exercise?.type !== ExerciseType.Theory) {
			(async () => {
				const res = await exerciseService.getExercise(exerciseId);
				setEditableExercise(res);
			})();
		}
	}, [editorOpen, exercise?.type, exerciseId]);

	const handleEditClick = useCallback(() => {
		if (!exercise) {
			return;
		}

		setEditorOpen(true);

		dispatch(
			editingStarted({
				id: exercise.id,
				type: DraftType.Exercise,
				title: exercise.title,
				content: exercise.question,
				exerciseType: exercise.type,
			})
		);
	}, [dispatch, exercise]);

	const endEditing = useCallback(() => {
		dispatch(editingEnded());
		setEditableExercise(null);
	}, [dispatch]);

	const additionalActions = useMemo(() => {
		return [<AnswerButton key="answer" chapterExerciseKey={exerciseKey} />];
	}, [exerciseKey]);

	const changeExampleOutput = useCallback(
		(exerciseId: number, exampleOutput: string) => {
			dispatch(
				exampleOutputChanged({
					exerciseId,
					exampleOutput,
				})
			);
		},
		[dispatch]
	);

	if (!exercise) {
		return <ExercisePlaceholder />;
	}

	const removeSubsection = async () => {
		try {
			const result = await dispatch(
				removeExercise({
					courseId,
					chapterExerciseKey: exerciseKey,
				})
			);
			unwrapResult(result);
		} catch (error) {
			let message = "An error has occured";
			if (
				(error as SerializedError).code === "responses_exist" &&
				exercise.type !== ExerciseType.Theory
			) {
				message = "Not allowed to delete: students have started this exercise";
			}
			showSnackbar("error", message);
		}
	};

	const closeEditor = () => {
		setEditorOpen(false);
	};

	const saveEdited = async (editableExercise: EditableExercise) => {
		const result = await dispatch(
			saveExerciseDraft({
				courseId,
				chapterId: exercise.chapterId,
				sectionId: section.id,
				exercise: editableExercise,
			})
		);

		return unwrapResult(result).id;
	};

	const build = async (exerciseId: number, exercise: ProgExercise) => {
		const result = await dispatch(
			buildProgExercise({
				courseId,
				exerciseId,
				exercise,
			})
		);

		return unwrapResult(result).buildResult;
	};

	const switchExerciseEditor = () => {
		if (exercise.type === ExerciseType.Theory) {
			return (
				<TheoryExerciseEditor
					onClose={closeEditor}
					courseId={courseId}
					chapterId={exercise.chapterId}
					sectionId={section.id}
				/>
			);
		}

		if (!editableExercise) {
			return <ExerciseEditorPlaceholder />;
		}

		const editorProps = {
			organisationName: props.organisationName,
			courseId,
			exerciseId,
			exercise: editableExercise,
			onClose: closeEditor,
			onSave: saveEdited,
			onUnmount: endEditing,
		};

		switch (exercise.type) {
			case ExerciseType.Open:
				return <OpenExerciseEditor {...editorProps} />;
			case ExerciseType.Multi:
				return <MultiExerciseEditor {...editorProps} />;
			case ExerciseType.Math:
				return <MathExerciseEditor {...editorProps} />;
			case ExerciseType.External:
				return <ExternalExerciseEditor {...editorProps} />;
			case ExerciseType.Prog:
				return (
					<ProgExerciseEditor
						{...editorProps}
						onBuild={build}
						onExampleOutputChanged={changeExampleOutput}
					/>
				);
			default:
				return <></>;
		}
	};

	if (editorOpen) {
		return switchExerciseEditor();
	}

	const editingAllowed = exercise.originId === courseId;

	const responseAreaProps = {
		response: null,
		readonly: true,
		submitting: false,
		additionalActions,
		onSave: save,
		onSubmit:
			props.submissionMode === SubmissionMode.Individual ? submit : undefined,
	};

	let responseArea = null;

	switch (exercise.type) {
		case ExerciseType.Multi:
			responseArea = (
				<MultiResponseAreaSelector
					id={`response-area-${exercise.id}`}
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Open:
			responseArea = (
				<OpenResponseArea
					subtype={exercise.subtype}
					emptyResponseAllowed={false}
					selfAssessment={false}
					chapterId={exercise.chapterId}
					courseId={courseId}
					userId={userId}
					exerciseId={exercise.id}
					createFileUploader={createFileUploader}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Math:
			responseArea = (
				<MathSolutionForm
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Prog:
			responseArea = (
				<ProgResponseArea
					interactions={exercise.interactions}
					openConfirmDialog={props.openConfirmDialog}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.External:
			responseArea = (
				<ExternalResponseArea
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					onPostOutcomes={postOutcomes}
					{...responseAreaProps}
				/>
			);

			break;
	}

	const editing = draft !== null;

	return (
		<>
			<Grid
				container
				style={{marginBottom: theme.spacing(3)}}
				wrap="nowrap"
				justifyContent="space-between"
				alignItems="center"
				alignContent="center"
			>
				<Grid item>
					<Box
						display={
							exercise.type === ExerciseType.Theory ? "none" : "inline-flex"
						}
						mr={3}
						style={{gap: theme.spacing(3)}}
					>
						<AnswerVisibilitySelector
							courseType={courseType}
							inheritedValue={chapterAnswerVisibility}
							value={exerciseAnswerVisibility}
							disabled={props.settingsEditingDisabled}
							onChange={updateAnswerVisibility}
						/>
						<Box
							visibility={section.selectionSize === 0 ? "inherit" : "hidden"}
						>
							<TextField
								type="number"
								value={String(exerciseMaxScore)}
								onChange={(e) => updateMaxScore(e.target.value)}
								disabled={props.settingsEditingDisabled || true}
								InputProps={{
									inputProps: {min: 0},
								}}
								InputLabelProps={{shrink: true, style: {whiteSpace: "nowrap"}}}
								label={
									<Localized id="content-exercise-max-score">
										Max score
									</Localized>
								}
							/>
						</Box>
					</Box>
				</Grid>
				{!props.viewOnly && (
					<Grid item>
						<Box
							display="flex"
							justifyContent="flex-end"
							style={{gap: theme.spacing(3)}}
						>
							<Button
								color="primary"
								disabled={editing || props.deletionDisabled}
								onClick={() =>
									props.openConfirmDialog({
										confirmBtnText: "Delete",
										description:
											exercise.type === ExerciseType.Theory
												? `You are going to remove subsection '${exercise.title}'. It will be deleted permanently.`
												: `You are going to remove exercise '${exercise.title}'.` +
												  ` The link will be deleted, but the exercise remains in the exercise bank.`,
										onConfirm: removeSubsection,
										title: "Delete subsection?",
									})
								}
							>
								<Localized id="learning-material-exercise-content-button-delete">
									Delete
								</Localized>
							</Button>
							{editingAllowed && (
								<Button
									color="primary"
									variant="contained"
									disabled={editing}
									onClick={handleEditClick}
								>
									<Localized id="learning-material-exercise-content-button-edit">
										Edit
									</Localized>
								</Button>
							)}
						</Box>
					</Grid>
				)}
			</Grid>
			<ExerciseTitle
				sectionNumber={section.number}
				exerciseNumber={exercise.number}
				title={exercise.title}
				exerciseId={exercise.id}
			/>

			<ExerciseQuestion text={exercise.question || ""} />

			<Box mt={4}>{responseArea}</Box>
		</>
	);
};

function save(): Promise<Feedback> {
	throw new Error("This function should not be called.");
}

function submit(): Promise<SubmissionResult> {
	throw new Error("This function should not be called.");
}

async function postOutcomes(): Promise<void> {
	return;
}

function createFileUploader() {
	return null;
}

function noop() {
	return;
}

export default LearningMaterialExerciseContent;
