import {useCallback, useMemo} from "react";

import Feature from "./Feature";
import {useAppSelector} from "../hooks";
import selectGlobalFeatures, {
	selectGlobalFeaturesFetchStatus,
} from "./selectGlobalFeatures";
import selectOrganisationFeatures, {
	selectOrganisationFeaturesFetchStatus,
} from "./selectOrganisationFeatures";

const commonFeatures = new Set<Feature>([Feature.ExternalExercises]);

function useFeatureEnabled(): [(name: Feature) => boolean, boolean] {
	const features = useAppSelector(selectGlobalFeatures);
	const status = useAppSelector(selectGlobalFeaturesFetchStatus);

	const organisationFeatures = useAppSelector(selectOrganisationFeatures);
	const orgStatus = useAppSelector(selectOrganisationFeaturesFetchStatus);

	const activeFeatures = useMemo(() => {
		const set = new Set<Feature>();

		features.forEach((feature: Feature) => {
			if (
				!commonFeatures.has(feature) ||
				organisationFeatures.includes(feature)
			) {
				set.add(feature);
			}
		});

		organisationFeatures.forEach((feature: Feature) => {
			if (!commonFeatures.has(feature) || features.includes(feature)) {
				set.add(feature);
			}
		});
		return set;
	}, [features, organisationFeatures]);

	const enabled = useCallback((name: Feature) => activeFeatures.has(name), [
		activeFeatures,
	]);

	const pending =
		status === "none" ||
		status === "pending" ||
		orgStatus === "none" ||
		orgStatus === "pending";

	return [enabled, pending];
}

export default useFeatureEnabled;
