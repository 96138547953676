import {Localized, useLocalization} from "@fluent/react";
import {Divider} from "@material-ui/core";
import type {SerializedError} from "@reduxjs/toolkit";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useMemo} from "react";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {useLocation, useRouteMatch} from "react-router-dom";

import {ChildComponent} from "./ChildComponent";
import ChildDraggableComponent from "./ChildDraggableComponent";
import type {MenuItem} from "./ChildDraggableComponent";
import {resolveLinkToOldSite, resolvePath} from "../../helpers/pathHelpers";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import deleteSection from "../../store/sections/deleteSection";
import makeSelectSection from "../../store/sections/makeSelectSection";
import {scrollToTop} from "../../helpers/scrollHelpers";
import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";

type Props = {
	courseId: number;
	chapterId: number;
	sectionKey: string;
	subsectionKeys: string[];
	index: number;
	deletionDisabled: boolean;
	chapterEditing: boolean;
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
	showSnackbar: ShowSnackbar;
};

const SectionDraggable = (props: Props): JSX.Element => {
	const {l10n} = useLocalization();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const {url: baseUrl} = useRouteMatch();

	const {courseId, chapterId, sectionKey, subsectionKeys, index} = props;

	const selectSection = useMemo(makeSelectSection, []);

	const userId = useAppSelector((state) => selectUserId(state));

	const section = useAppSelector((state) => selectSection(state, sectionKey));
	if (!section) {
		return <></>;
	}

	const navigationPath = resolvePath(baseUrl, sectionKey);

	const editLink = resolveLinkToOldSite(
		`/teacher/${courseId}/#/exercise_template/theory/${section.id}?chapter_id=${chapterId}`
	);

	const removeSection = async () => {
		try {
			const res = await dispatch(deleteSection({courseId, sectionKey}));
			unwrapResult(res);
		} catch (error) {
			let message = "An error has occured";
			if ((error as SerializedError).code === "responses_exist") {
				message =
					"Not allowed to delete: students have started exercises of this section";
			}
			props.showSnackbar("error", message);
		}
	};

	const menuItems: MenuItem[] = [
		{
			action: "Add exercise",
			text: (
				<Localized id="dragdrop-section-action-add-subsection">
					Add subsection
				</Localized>
			),
			sectionId: section.id,
			chapterId: chapterId,
			courseId: courseId,
			disabled: props.chapterEditing,
		},
		{
			action: "Delete",
			text: <Localized id="dragdrop-section-action-delete">Delete</Localized>,
			handleDelete: () =>
				props.openConfirmDialog({
					title: l10n.getString(
						"dragdrop-subsection-confirm-delete-dialog-delete",
						null,
						"Delete section?"
					),
					description: l10n.getString(
						"dragdrop-section-confirm-delete-dialog-description",
						{title: section.title}
					),
					confirmBtnText: l10n.getString(
						"dragdrop-subsection-confirm-delete-dialog-delete",
						null,
						"Delete"
					),
					onConfirm: removeSection,
				}),
			disabled: props.deletionDisabled || props.chapterEditing,
		},
	];

	return (
		<Draggable
			key={sectionKey}
			draggableId={sectionKey}
			index={index}
			isDragDisabled={props.chapterEditing || props.viewOnly}
		>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.draggableProps}>
					<ChildDraggableComponent
						onClick={scrollToTop}
						viewOnly={props.viewOnly}
						provided={provided.dragHandleProps}
						navigationPath={navigationPath}
						index={`${index + 1}`}
						content={section.title}
						menuItems={menuItems}
						active={
							location.pathname.includes(props.sectionKey) && !location.hash
						}
					/>

					<Droppable droppableId={sectionKey} type="subsection">
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								<Divider />

								{subsectionKeys.map((key: string, i: number) => (
									<ChildComponent
										key={key}
										parentIndex={index}
										index={i}
										courseId={courseId}
										subsectionKey={key}
										navigationPath={navigationPath}
										deletionDisabled={props.deletionDisabled}
										chapterEditing={props.chapterEditing}
										viewOnly={props.viewOnly}
										openConfirmDialog={props.openConfirmDialog}
										showSnackbar={props.showSnackbar}
									/>
								))}

								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</div>
			)}
		</Draggable>
	);
};

export default SectionDraggable;
