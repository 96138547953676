import {createSlice} from "@reduxjs/toolkit";

import type CourseManagementModule from "./CourseManagementModule";
import fetchCourseManagementModules from "./fetchCourseManagementModules";
import type FetchStatus from "../FetchStatus";
import inferFetchStatusFromError from "../inferFetchStatusFromError";
import {keyProvider} from "../keyProvider";
import updateCourseSettings from "./updateCourseSettings";
import logoutUser from "../userSession/logoutUser";

type Modules = {
	modules: CourseManagementModule[];
	status: FetchStatus;
};

type State = {
	byKey: {
		[key: string]: Modules | undefined;
	};
};

const initialState: State = {byKey: {}};

const courseManagementModulesSlice = createSlice({
	name: "courseManagementModules",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCourseManagementModules.pending, (state, {meta}) => {
			const s = obtainState(state, keyProvider.course(meta.arg.courseId));
			s.status = "pending";
		});
		builder.addCase(fetchCourseManagementModules.rejected, (state, action) => {
			const s = obtainState(
				state,
				keyProvider.course(action.meta.arg.courseId)
			);
			s.status = inferFetchStatusFromError(action);
		});
		builder.addCase(fetchCourseManagementModules.fulfilled, (state, action) => {
			const s = obtainState(state, action.payload.key);
			s.modules = action.payload.modules;
			s.status = "succeeded";
		});

		builder.addCase(updateCourseSettings.fulfilled, (state, {payload}) => {
			if (payload.settings.features) {
				const s = state.byKey[payload.courseKey];
				if (s) {
					s.status = "none";
				}
			}
		});

		builder.addCase(logoutUser.pending, () => initialState);
	},
});

function obtainState(state: State, key: string) {
	let s = state.byKey[key];
	if (!s) {
		s = {
			modules: [],
			status: "none",
		};
		state.byKey[key] = s;
	}

	return s;
}

export default courseManagementModulesSlice.reducer;
