import {createSlice} from "@reduxjs/toolkit";

import type CourseStudyModule from "./CourseStudyModule";
import enrolledInCourse from "./enrolledInCourse";
import fetchCourseStudyModules from "./fetchCourseStudyModules";
import type FetchStatus from "../FetchStatus";
import inferFetchStatusFromError from "../inferFetchStatusFromError";
import {keyProvider} from "../keyProvider";
import updateCourseSettings from "./updateCourseSettings";
import logoutUser from "../userSession/logoutUser";

type Modules = {
	modules: CourseStudyModule[];
	status: FetchStatus;
};

type State = {
	byKey: {
		[key: string]: Modules | undefined;
	};
};

const initialState: State = {byKey: {}};

const courseStudyModulesSlice = createSlice({
	name: "courseStudyModlules",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCourseStudyModules.pending, (state, {meta}) => {
			const s = obtainState(state, keyProvider.course(meta.arg.courseId));
			s.status = "pending";
		});
		builder.addCase(fetchCourseStudyModules.rejected, (state, action) => {
			const s = obtainState(
				state,
				keyProvider.course(action.meta.arg.courseId)
			);
			s.status = inferFetchStatusFromError(action);
		});
		builder.addCase(fetchCourseStudyModules.fulfilled, (state, action) => {
			const s = obtainState(state, action.payload.key);
			s.modules = action.payload.modules;
			s.status = "succeeded";
		});

		builder.addCase(updateCourseSettings.fulfilled, (state, {payload}) => {
			if (payload.settings.features) {
				const s = state.byKey[payload.courseKey];
				if (s) {
					s.status = "none";
				}
			}
		});

		builder.addCase(logoutUser.pending, () => initialState);

		builder.addCase(enrolledInCourse, (state, {payload}) => {
			const s = state.byKey[keyProvider.course(payload.courseId)];
			if (s) {
				s.modules = [];
				s.status = "none";
			}
		});
	},
});

function obtainState(state: State, key: string) {
	let s = state.byKey[key];
	if (!s) {
		s = {
			modules: [],
			status: "none",
		};
		state.byKey[key] = s;
	}

	return s;
}

export default courseStudyModulesSlice.reducer;
